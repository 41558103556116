import { INSURANCE_BREAKDOWN, INSURANCE_BREAKDOWN_STATUS, INSURANCE_BREAKDOWN_DEACTIVATE, INSURANCE_BREAKDOWN_S3, INSURANCE_BREAKDOWN_BY_VIN, GET_ALL_INSURANCE_BREAKDOWN_BY_VIN } from '../../utils/endpoints.js'

/**
 * Author: JDGT
 * Description: Creates a new insurance breakdown record by sending a POST request to the server.
 * @param {Object} data - The data object containing information about the insurance breakdown.
 * @param {string} data.company - The name of the insurance company.
 * @param {string} data.car_assigned - The car assigned to this breakdown.
 * @param {string} data.vin - The Vehicle Identification Number (VIN).
 * @param {Object} data.coverage - Coverage details as an object.
 * @param {number} data.total_cost - The total cost of the insurance breakdown.
 * @returns {Promise<Object>} The created insurance breakdown record.
 */
export async function createInsuranceBreakdown(data) {
    try {
        const response = await fetch(INSURANCE_BREAKDOWN, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            const errorResponse = await response.json();
            //throw new Error(`Error ${response.status}: ${errorResponse.message || response.statusText}`);
            return {
                response,
                result: null,
            };
        }

        const result = await response.json();
        console.log('Created:', result);
        return {
            response,
            result
        };
    } catch (error) {
        console.error('Error creating insurance breakdown:', error);
        throw error;
    }
}

/**
 * Author: JDGT
 * Description: Retrieves all insurance breakdown records by sending a GET request to the server.
 * @returns {Promise<Array>} An array of insurance breakdown records.
 */
export async function getAllInsuranceBreakdowns() {
    try {
        const response = await fetch(INSURANCE_BREAKDOWN, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            const errorResponse = await response.json();
            throw new Error(`Error ${response.status}: ${errorResponse.message || response.statusText}`);
        }

        const results = await response.json();
        console.log('All Breakdowns:', results);
        return results;
    } catch (error) {
        console.error('Error fetching insurance breakdowns:', error);
        throw error;
    }
}

/**
 * Author: JDGT
 * Description: Function to retrieve an insurance breakdown record by company.
 * Parameters:
 *   company (string): The company name to search for.
 * Returns:
 *   A promise that resolves with an array of InsuranceBreakdown objects if found, or null if not found.
 *   If an error occurs, the promise is rejected with an Error object.
 */
export async function getInsuranceBreakdownByCompany(company) {
    /**
     * Check if the company parameter is valid
     */
    if (!company || typeof company !== 'string') {
        throw new Error('Invalid company parameter');
    }

    try {
        /**
         * Construct the URL for the API endpoint
         */
        const url = `${INSURANCE_BREAKDOWN}/${company}`;

        /**
         * Send a GET request to the API endpoint
         */
        const response = await fetch(url);

        /**
         * Check if the response was successful
         */
        if (response.ok) {
            /**
             * Parse the response body as JSON
             */
            const breakdowns = await response.json();
            console.log("breakdowns: ", breakdowns)
            return breakdowns;
        } else {
            /**
             * Handle error responses
             */
            switch (response.status) {
                case 404:
                    throw new Error(`No records found for company: ${company}`);
                case 500:
                    throw new Error('Database error');
                default:
                    throw new Error(`Error ${response.status}: ${response.statusText}`);
            }
        }
    } catch (error) {
        /**
         * Handle any errors that occurred during the request
         */
        console.error(error);
        return null;
    }
}


/**
 * Author: JDGT
 * Description: Updates an existing insurance breakdown record by ID by sending a PUT request to the server.
 * @param {string} id - The ID of the insurance breakdown record to update.
 * @param {Object} data - The data object containing the updated information.
 * @returns {Promise<Object>} The updated insurance breakdown record.
 */
export async function updateInsuranceBreakdown(id, data) {
    try {
        const response = await fetch(`${INSURANCE_BREAKDOWN}/${encodeURIComponent(id)}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            const errorResponse = await response.json();
            //throw new Error(`Error ${response.status}: ${errorResponse.message || response.statusText}`);
            return response;
        }

        const result = await response.json();
        console.log('response:', response);
        return response;
    } catch (error) {
        console.error(`Error updating insurance breakdown ${id}:`, error);
        throw error;
    }
}

/**
 * Author: JDGT
 * Description: Deletes an existing insurance breakdown record by ID by sending a DELETE request to the server.
 * @param {string} id - The ID of the insurance breakdown record to delete.
 * @returns {Promise<void>} Resolves if the deletion was successful.
 */
export async function deleteInsuranceBreakdown(id) {
    try {
        const response = await fetch(`${INSURANCE_BREAKDOWN}/${encodeURIComponent(id)}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            const errorResponse = await response.json();
            throw new Error(`Error ${response.status}: ${errorResponse.message || response.statusText}`);
        }

        console.log(`Deleted insurance breakdown ${id}`);
    } catch (error) {
        console.error(`Error deleting insurance breakdown ${id}:`, error);
        throw error;
    }
}

/**
 * Author: JDGT
 * Description: Function to deactivate an insurance breakdown record by ID.
 * Makes a PUT request to the /insurance_breakdown/:id endpoint to update the record's status to False and set the termination_date to the current date.
 * @param {string} id - The ID of the insurance breakdown record to deactivate.
 * @returns {Promise} - A promise that resolves with the response from the server.
 */
/* export async function deactivateInsuranceBreakdown(id) {
    console.log("ID: ", id);
    try {
        const response = await fetch(`${INSURANCE_BREAKDOWN_DEACTIVATE}/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        console.log("response: ", response);
        if (response.ok) {
            return response;
        } else {
            console.log("response: ", response);
            throw new Error(`Error deactivating insurance breakdown: ${response.status}`);
        }
    } catch (error) {
        console.error(error);
        return { error: 'Error deactivating insurance breakdown' };
    }
} */

/**
* Author: JDGT
* Description: Function to retrieve an insurance breakdown record by company and status.
* Makes a GET request to the /insurance_breakdown/<string:company> endpoint.
* Returns a promise that resolves with the JSON response or rejects with an error.
* @param {string} company - The company to retrieve the insurance breakdown for.
*/
export async function getInsuranceBreakdownByCompanyStatus(company) {
    try {
        console.log("<<<<<<<<<<<<<<<<<<<<<<<< getInsuranceBreakdownByCompanyStatus");
        const response = await fetch(`${INSURANCE_BREAKDOWN_STATUS}/${company}`);
        console.log("response: ", response);
        if (!response.ok) {
            if(response.status === 404){
                console.log("No data");
                return [];
            }
            //throw new Error(`Error ${response.status}: ${response.statusText}`);
        }
        const breakdowns = await response.json();
        console.log("breakdowns: ", breakdowns);
        return breakdowns;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

/**
 * Author: JDGT
 * Description: Function to consume the endpoint that updates the status to False and sets the termination_date
 *              of an InsuranceBreakdown record by ID.
 * 
 * @param {string} id - The unique identifier of the InsuranceBreakdown record.
 * @param {string} terminationDate - The termination date to set for the record in 'YYYY-MM-DD' format.
 * 
 * @returns {Promise<void>} - A promise that resolves when the request is complete.
 */
export async function updateInsuranceStatus(id, terminationDate) {
    try {
        console.log("terminationDate: ", terminationDate);
        const url = `${INSURANCE_BREAKDOWN_DEACTIVATE}/${id}`;
        const data = {
            termination_date: terminationDate
        };

        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (response.ok) {
            console.log('Insurance status updated successfully');
        } else {
            const errorData = await response.json();
            console.error('Failed to update insurance status:', errorData);
        }
        return response;
    } catch (error) {
        console.error('An unexpected error occurred:', error);
    }
}

export async function uploadFileToFleetS3(vin, file) {
    console.log("file: ", file);
    console.log("file.type: ", file.type);
    const url = `${INSURANCE_BREAKDOWN_S3}${vin}`;
    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await fetch(url, {
            method: 'PUT',
            body: formData
        });
        const result = await response.json();
        if (!response.ok) {
            // Handle specific errors returned by the server
            switch (response.status) {
                case 400:
                    console.error(`Bad Request: ${result.error}`);
                    break;
                case 404:
                    console.error(`Not Found: ${result.error}`);
                    break;
                case 500:
                    console.error(`Internal Server Error: ${result.error}`);
                    break;
                default:
                    console.error(`Unexpected Error: ${result.error}`);
                    break;
            }
            throw new Error(result.error || 'Error uploading file.');
        }
        return response;

    } catch (error) {
        // Handle fetch errors
        console.error('Error occurred during file upload:', error.message);
        throw error;
    }
}

/**
 * Author: JDGT
 * Description: Function to fetch an insurance breakdown record by VIN.
 * 
 * @param {string} vin - The VIN (Vehicle Identification Number) of the vehicle.
 * @returns {Promise<Object|null>} - Returns the insurance breakdown record as an object, or null if not found.
 */
export async function getInsuranceBreakdownByVin(vin) {
    try {
        // Verifica que el VIN no esté vacío
        if (!vin) {
            throw new Error("VIN is required.");
        }

        // Realiza la solicitud GET al endpoint con el VIN como parámetro
        const response = await fetch(`${INSURANCE_BREAKDOWN_BY_VIN}${vin}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        // Verifica si la respuesta fue exitosa
        if (response.ok) {
            const data = await response.json();
            return data; // Devuelve el registro encontrado
        } else if (response.status === 404) {
            console.log("No record found for the given VIN.");
            return null;
        } else {
            // Manejo de otros posibles códigos de error
            const errorData = await response.json();
            console.error("Error:", errorData.error);
            return null;
        }
    } catch (error) {
        console.error("Error fetching the insurance breakdown:", error);
        return null;
    }
}

/**
 * Función para obtener todos los registros de seguros asociados con un VIN específico.
 * ---
 * Descripción:
 * Esta función realiza una solicitud GET al endpoint `/insurance_breakdown/all_by_vin` para recuperar 
 * todos los registros de seguros relacionados con el número de identificación del vehículo (VIN) proporcionado.
 * 
 * Maneja las siguientes respuestas:
 * - 200: Éxito, se encontraron registros asociados con el VIN proporcionado.
 * - 400: Error, VIN no proporcionado o inválido.
 * - 404: Advertencia, no se encontraron registros para el VIN proporcionado.
 * - 500: Error en el servidor, se produjo un problema al procesar la solicitud.
 * - Otro: Error inesperado.
 * 
 * Autor: JDGT
 * 
 * @param {string} vin - El número de identificación del vehículo (VIN).
 * @returns {Promise<void>}
 */
export async function fetchAllInsuranceBreakdownsByVin(vin) {
    const url = `${GET_ALL_INSURANCE_BREAKDOWN_BY_VIN}${vin}`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        // Manejar las diferentes respuestas HTTP
        switch (response.status) {
            case 200:
                const data = await response.json();
                console.log("Registros obtenidos:", data);
                return data;
            case 400:
                console.error("Error: VIN no proporcionado o inválido.");
                //alert("Por favor, proporcione un VIN válido.");
                break;
            case 404:
                console.warn("Advertencia: No se encontraron registros para el VIN proporcionado.");
                //alert("No se encontraron registros para el VIN proporcionado.");
                break;
            case 500:
                const errorData = await response.json();
                console.error("Error en el servidor: ", errorData.message);
                //alert("Ocurrió un error en el servidor. Intente nuevamente más tarde.");
                break;
            default:
                console.error("Error inesperado: ", response.statusText);
                //alert("Ocurrió un error inesperado. Intente nuevamente más tarde.");
                break;
        }

    } catch (error) {
        // Manejar errores de red u otros errores inesperados
        console.error("Error al realizar la solicitud:", error);
        //alert("No se pudo conectar con el servidor. Por favor, verifique su conexión a internet e intente nuevamente.");
    }
}