<template>
    <type-of-comissions />
</template>

<script>
import TypeOfComissions from '../../components/Employees/TypeOfComissions'

export default {
    name: 'TypeOfComissionsView',
    components: {
        TypeOfComissions,
    },
}
</script>