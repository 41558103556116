import { GET_ALL_EXPENSE_RECORD, ADD_EXPENSE_RECORD, EXPENSE_RECORD_S3, GET_ALL_EXPENSE_RECORD_BY_EMPLOYEE_NUMBER, GET_ALL_EXPENSE_RECORD_BY_STATUS, GET_ALL_EXPENSE_RECORD_BY_STATUS_AND_EMPLOYEE_NUMBER, UPDATE_EXPENSE_RECORD_BY_UUID, CREATE_ZOHO_EXPENSE, AUTH_STATE_ZOHO_EXPENSE, UPDATE_STATUS_EXPENSE_RECORD, DELETE_EXPENSE_RECORD, GET_DRIVER_NAME, DELETE_EXPENSE_RECORD_ZOHO, UPDATE_APPROVE_HISTORY, UPDATE_LAST_MODIFICATION } from '../../utils/endpoints.js'

// JDGT - Function to create a new expense record
/**
 * createExpense
 * 
 * Sends a POST request to create a new expense record in the database.
 * 
 * @param {Object} expenseData - The data of the new expense record to be created.
 * @returns {Promise<Object>} The created expense record or an error message.
 * 
 * Author: JDGT
 */
export async function createExpense(expenseData) {
    try {
        console.log("createExpense: ", expenseData)
        const response = await fetch(ADD_EXPENSE_RECORD, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(expenseData)
        });

        if (!response.ok) {
            throw new Error(`Failed to create expense: ${response.statusText}`);
        }

        const data = await response.json();
        console.log("SUCCESS to create expense:: ", data);
        return data;
    } catch (error) {
        console.error(error);
        return { error: error.message };
    }
}

// JDGT - Function to get all expense records
/**
 * getExpenses
 * 
 * Sends a GET request to retrieve all active expense records from the database.
 * 
 * @returns {Promise<Array>} A list of all active expense records or an error message.
 * 
 * Author: JDGT
 */
export async function getExpenses() {
    try {
        const response = await fetch(GET_ALL_EXPENSE_RECORD, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error(`Failed to retrieve expenses: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error(error);
        return { error: error.message };
    }
}

// JDGT - Function to get a specific expense record by UUID
/**
 * getExpense
 * 
 * Sends a GET request to retrieve a specific expense record by UUID.
 * 
 * @param {string} expenseUUID - The UUID of the expense record to be retrieved.
 * @returns {Promise<Object>} The requested expense record or an error message.
 * 
 * Author: JDGT
 */
async function getExpense(expenseUUID) {
    try {
        const response = await fetch(`/expenses/${expenseUUID}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error(`Failed to retrieve expense: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error(error);
        return { error: error.message };
    }
}

// JDGT - Function to update an existing expense record
/**
 * updateExpense
 * 
 * Sends a PUT request to update an existing expense record by UUID.
 * 
 * @param {string} expenseUUID - The UUID of the expense record to be updated.
 * @param {Object} updatedData - The updated data for the expense record.
 * @returns {Promise<Object>} The updated expense record or an error message.
 * 
 * Author: JDGT
 */
export async function updateExpense(expenseUUID, updatedData) {
    const url = `${UPDATE_EXPENSE_RECORD_BY_UUID}${expenseUUID}`;

    try {
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(updatedData)
        });

        // Verificar si la respuesta es exitosa
        if (response.ok) {
            //const updatedExpense = await response.json();
            //console.log('Updated expense record:', updatedExpense);
            return response; // Devuelve el registro actualizado
        } else {
            const error = await response.json();
            console.error('Failed to update expense:', error);
            throw new Error(error.error); // Lanza un error con el mensaje recibido
        }
    } catch (error) {
        console.error('An error occurred while updating the expense:', error);
        throw error;
    }
}

// JDGT - Function to soft delete an expense record by UUID
/**
 * deleteExpense
 * 
 * Sends a DELETE request to perform a soft delete of a specific expense record by UUID.
 * 
 * @param {string} expenseUUID - The UUID of the expense record to be soft deleted.
 * @returns {Promise<Object>} A success message or an error message.
 * 
 * Author: JDGT
 */
export async function deleteExpense(expenseUUID) {
    try {
        const response = await fetch(`${DELETE_EXPENSE_RECORD}/${expenseUUID}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error(`Failed to delete expense: ${response.statusText}`);
        }

        const data = await response.json();
        console.log("deleteExpense/data: ", data);
        return response;
    } catch (error) {
        console.error(error);
        return { error: error.message };
    }
}


export async function uploadFileToAccountingS3(uuid, file) {
    console.log("file: ", file);
    console.log("file.type: ", file.type);
    const url = `${EXPENSE_RECORD_S3}${uuid}`;
    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await fetch(url, {
            method: 'PUT',
            body: formData
        });
        const result = await response.json();
        if (!response.ok) {
            // Handle specific errors returned by the server
            switch (response.status) {
                case 400:
                    console.error(`Bad Request: ${result.error}`);
                    break;
                case 404:
                    console.error(`Not Found: ${result.error}`);
                    break;
                case 500:
                    console.error(`Internal Server Error: ${result.error}`);
                    break;
                default:
                    console.error(`Unexpected Error: ${result.error}`);
                    break;
            }
            throw new Error(result.error || 'Error uploading file.');
        }
        return response;

    } catch (error) {
        // Handle fetch errors
        console.error('Error occurred during file upload:', error.message);
        throw error;
    }
}


export async function checkFiles(filesArray, uuid) {
    console.log("Checking files....");
    console.log("uuid: ", uuid);
    console.log("filesArray: ", filesArray);
    let existFile = false;
    try {
        let response;
        for (let i = 0; i < filesArray.length; i++) {
            if (filesArray[i] === null) {
                console.log(`File at index ${i} es vacío`);
            } else {
                existFile = true;
                console.log(`File at index ${i} está lleno`);
                response = await uploadFileToAccountingS3(uuid, filesArray[i]);
                console.log("response: ", response);
            }
        }
        console.log("For finished......");
        if (!existFile) {
            return true;
        } else {
            if (response.ok) {
                console.log(">>>>>>>>>>>>>>>>>>>>>>>> Uploading files finished.......");
                return true;
            }
        }
    } catch (error) {
        console.error("Error uploading Expense files.....", error);
        return true;
    }
}

export async function getExpenseRecordsByEmployeeNumber(employeeNumber) {
    const url = `${GET_ALL_EXPENSE_RECORD_BY_EMPLOYEE_NUMBER}${employeeNumber}`;
    console.log("<<<<<<<<<<<<< url: ", url);

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        console.log("response/getExpenseRecordsByEmployeeNumber: ", response);
        if (!response.ok) {
            return []
            //throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }

        const data = await response.json();

        // Maneja los datos devueltos
        console.log("Expense Records: ", data);

        return data; // Retorna los registros si los necesitas en otro lugar
    } catch (error) {
        console.error("Error fetching expense records: ", error);
        return null; // Puedes manejar el error o retornar null si falla
    }
}

export async function getExpenseRecordsByStatus(status) {
    const url = `${GET_ALL_EXPENSE_RECORD_BY_STATUS}${status}`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            return []
            //throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }

        const data = await response.json();

        // Maneja los datos devueltos
        console.log("Expense Records: ", data);

        return data; // Retorna los registros si los necesitas en otro lugar
    } catch (error) {
        console.error("Error fetching expense records: ", error);
        return null; // Puedes manejar el error o retornar null si falla
    }
}


export async function getExpenseRecordsByEmployeeAndStatus(employeeNumber, status = null) {
    let url = `${GET_ALL_EXPENSE_RECORD_BY_STATUS_AND_EMPLOYEE_NUMBER}${employeeNumber}`;

    // Si el parámetro status está presente, agregarlo a la URL
    if (status !== null) {
        url += `&status=${status}`;
    }

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            return [];
            //throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }

        const data = await response.json();

        // Maneja los datos devueltos
        console.log("Expense Records: ", data);

        return data; // Retorna los registros si los necesitas en otro lugar
    } catch (error) {
        console.error("Error fetching expense records: ", error);
        return null; // Puedes manejar el error o retornar null si falla
    }
}

export async function createExpense_ZOHO(payloadData, uuid) {
    try {
        const response = await fetch(CREATE_ZOHO_EXPENSE, {
            method: 'POST', // Método HTTP POST
            headers: {
                'Content-Type': 'application/json', // El tipo de contenido es JSON
            },
            body: JSON.stringify({
                payload: payloadData, // Enviamos el payload recibido como parámetro
                uuid: uuid // Enviamos el uuid recibido como parámetro
            }),
        });

        // Verificar si la respuesta es exitosa
        if (!response.ok) {
            const errorResponse = await response.json(); // Obtener el cuerpo del error
            console.error("<<<<<<<<<<<<<<<< Error response from server:", errorResponse);
            alert(errorResponse.details.error);
            //throw new Error(`Error HTTP: ${response.status}, Message: ${errorResponse.details.error}`);
            return response;
        }

        // Obtener la respuesta en formato JSON
        const data = await response.json();
        
        return data; // Retornar la respuesta JSON

    } catch (error) {
        console.error('Error creating expense:', error);
        throw error; // Relanzar el error para manejo posterior
    }
}

export async function checkAuthorizationStatus(unique_id) {
    let maxRetries = 100; // Número máximo de intentos de consulta
    let attempts = 0;

    return new Promise((resolve, reject) => {
        let intervalId = setInterval(async () => {
            if (attempts >= maxRetries) {
                clearInterval(intervalId);
                console.error("No se pudo verificar el estado de la autorización después de varios intentos.");
                reject(new Error("Se alcanzó el número máximo de intentos sin obtener un resultado."));
                return;
            }

            try {
                // Llama al backend para verificar el estado de la autorización
                let response = await fetch(`${AUTH_STATE_ZOHO_EXPENSE}${unique_id}`);
                let data = await response.json();
                console.log("data: ", data);

                if (data.status === 'successful') {
                    console.log("Autorización exitosa!");
                    clearInterval(intervalId);  // Detener el chequeo
                    resolve("successful");  // Resolver la promesa
                } else if (data.status === 'failed') {
                    console.error("failed");
                    clearInterval(intervalId);  // Detener el chequeo
                    resolve("Autorización fallida.");  // Resolver la promesa
                } else {
                    console.log("Autorización pendiente...");
                }

            } catch (error) {
                console.error("Error checking authorization status:", error);
                clearInterval(intervalId);
                reject(error);  // Rechazar la promesa en caso de error
            }

            attempts++;
        }, 5000);  // Consultar cada 5 segundos
    });
}

/**
 * Function to update the status of a record by its UUID.
 * @param {string} uuidValue - The UUID of the record to update.
 */
export async function updateStatus(uuidValue, status) {
    console.log("updateStatus: ", uuidValue, status);
    const url = `${UPDATE_STATUS_EXPENSE_RECORD}${uuidValue}`;
    console.log("url: ", url);

    try {
        const response = await fetch(url, {
            method: 'PUT', // Or 'PATCH' if needed
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ status }) // Enviar el status en el cuerpo de la solicitud
        });

        console.log("response: ", response);
        const data = await response.json();
        console.log("data: ", data);

        if (response.ok) {
            console.log('Status updated successfully:', data.message);
            return response;
        } else {
            console.error('Error updating status:', data.message);
            return response;
        }
    } catch (error) {
        console.error('Unexpected error:', error);
    }
}

export async function getDriverNames() {
    try {
        const response = await fetch(GET_DRIVER_NAME, {  // Cambié el endpoint al correcto
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Error en la solicitud');
        }

        const data = await response.json();
        console.log("data: ", data);
        return data;  // Devolver el array con 'name' y 'driver_name'
    } catch (error) {
        console.error('Error al obtener los nombres de los conductores:', error);
        return [];  // Devolver un array vacío en caso de error
    }
}

export async function deleteZohoExpense(expenseId) {
    /**
     * Deletes an expense by making a DELETE request to the Flask endpoint.
     *
     * @param {string} expenseId - ID of the expense to delete.
     * @returns {Object} - JSON response containing success message or error details.
     *
     * Author: JDGT
     */

    const url = `${DELETE_EXPENSE_RECORD_ZOHO}${expenseId}`;  // Asegúrate de ajustar el dominio
    console.log("url: ", url);
    try {
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error("Failed to delete expense:", errorData);
            if (response.status === 404) {
                console.warn("Expense not found:", errorData);
                return { error: "Expense not found in Zoho system", status: 404 };
            }

            return { error: "Failed to delete expense", details: errorData };
        }

        const data = await response.json();
        console.log("Expense deleted successfully:", data);
        return response;

    } catch (error) {
        console.error("An error occurred:", error);
        return { error: "An error occurred while trying to delete the expense", details: error.message };
    }
}

export async function updateApprovedHistory(employee_number, approved, uuid) {
    try {
        const response = await fetch(UPDATE_APPROVE_HISTORY, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                employee_number: employee_number,
                approved: approved,
                uuid: uuid,
            })
        });

        if (!response.ok) {
            // Manejo de error en caso de que el servidor responda con un código de error
            const errorData = await response.json();
            console.error('Error:', errorData);
            //alert('Error: ' + errorData.error);
            return;
        }

        const data = await response.json();
        console.log('Success:', data);
        //alert('History updated successfully!');
        return response;
    } catch (error) {
        console.error('Fetch error:', error);
        //alert('An error occurred while updating the history');
    }
}


export async function updateLastModification(uuid, employeeNumber, reason) {
    const url = `${UPDATE_LAST_MODIFICATION}${uuid}`;

    // Datos a enviar en la solicitud
    const data = {
        employee_number: employeeNumber,
        reason: reason
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            // Manejo de errores en la respuesta
            const errorData = await response.json();
            console.error("Error en la solicitud:", errorData.error);
            return { success: false, error: errorData.error };
        }

        const result = await response.json();
        console.log("Modificación exitosa:", result);
        return { success: true, data: result };

    } catch (error) {
        console.error("Error en la solicitud:", error);
        return { success: false, error: error.message };
    }
}
