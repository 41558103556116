<template>
    <div>
        <v-card>
            <v-card-title>
                <h2>New insurance breakdown</h2>
            </v-card-title>

            <v-card-text>
                <v-divider class="mb-4" style="border-width: 1px;"></v-divider>
                <v-form ref='form'>
                    <v-row>
                        <!-- Company -->
                        <v-col cols="6">
                            <v-text-field readonly v-model="editedItem.company" :rules="[rules.required]"
                                label="Company" required class="purple-text-field"></v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <v-combobox v-model="carSelected" :items="itemVehicles" item-text="name" label="Vehicle"
                                :rules="[rules.required]" required
                                return-object></v-combobox><!-- :readonly="TypeAction === 'update'" -->
                        </v-col>
                    </v-row>

                    <v-row>
                        <!-- Driver Assigned -->
                        <v-col cols="6">
                            <v-text-field readonly v-model="driver_name" label="Driver Assigned"
                                :rules="[rules.required]" required></v-text-field>
                        </v-col>
                        <!-- Year -->
                        <v-col cols="6">
                            <v-text-field readonly v-model="year" label="Year" :rules="[rules.required]"
                                required></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <!-- VIN -->
                        <v-col cols="6">
                            <v-text-field readonly v-model="editedItem.vin" label="VIN" :rules="[rules.required]"
                                required></v-text-field>
                        </v-col>
                    </v-row>
                    <v-divider class="mt-4 mb-6"></v-divider>
                    <h4>EFFECTIVE DATE</h4>
                    <v-divider class="mt-4 mb-6"></v-divider>
                    <!-- Effective Date -->
                    <v-row>
                        <v-col cols="6">
                            <v-menu v-model="effectiveMenu" :close-on-content-click="false"
                                transition="scale-transition" offset-y>
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="editedItem.effective_date" label="Effective Date" readonly
                                        v-on="on"
                                        prepend-icon="mdi-calendar"></v-text-field><!-- :rules="[rules.required]" required-->
                                </template>
                                <v-date-picker v-model="editedItem.effective_date"
                                    @input="effectiveMenu = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-divider class="mt-4 mb-6"></v-divider>
                    <h4>INSURANCE FILE</h4>
                    <v-divider class="mt-4 mb-6"></v-divider>
                    <v-row>
                        <v-col cols="6">
                            <div class="d-flex align-center">
                                <v-file-input label="Insurance File" v-model="files.insuranceFile"
                                    @change="onFileSelected(files.insuranceFile, 'insurance_file', 'insuranceFile')"
                                    accept="application/pdf">
                                </v-file-input>
                                <v-btn icon
                                    :class="{ 'green--text': editedItem.insurance_file === true || files.insuranceFile != null, 'red--text': !editedItem.insurance_file }">
                                    <v-icon
                                        @click="openModal('Insurance', editedItem.file_name, 'insurance_file', files.insuranceFile)">mdi-file</v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>

                    <v-divider class="mt-4 mb-6"></v-divider>
                    <h4>COVERAGE</h4>
                    <v-divider class="mt-4 mb-6"></v-divider>
                    <v-row>
                        <v-col cols="12">
                            <v-row>
                                <v-col cols="6">
                                    <v-combobox v-model="coverageSelected" :items="typeOfCoverages" item-text="coverage"
                                        label="Type"
                                        return-object></v-combobox><!-- :rules="[rules.required]" required -->
                                </v-col>
                            </v-row>
                            <v-row>
                                <!-- Description -->
                                <v-col cols="6">
                                    <!-- <v-text-field v-model="type.description" label="Description"></v-text-field> -->
                                    <v-textarea v-model="type.description" label="Description"></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <!-- Price -->
                                <v-col cols="6">
                                    <v-text-field v-model="type.price" label="Price" type="number"
                                        step="0.1"></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-btn :disabled="!isFormComplete" @click="addItem" :class="buttonClasses" icon>
                                        <v-icon class="white--text icon-thick">mdi-plus</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12">
                                    <template>
                                        <v-simple-table>
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left"
                                                            style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                            Type
                                                        </th>
                                                        <th class="text-left"
                                                            style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                            Description
                                                        </th>
                                                        <th class="text-left"
                                                            style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                            Price
                                                        </th>
                                                        <th class="text-left"
                                                            style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                            Actions
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in editedItem.coverage.type" :key="index">
                                                        <td style="white-space: normal; word-wrap: break-word;">{{
                                                            item.type }}</td>
                                                        <td style="white-space: normal; word-wrap: break-word;">{{
                                                            item.description }}</td>
                                                        <td>$ {{ parseFloat(item.price).toFixed(2) }}</td>
                                                        <td>
                                                            <v-btn icon @click="removeDriver(index)">
                                                                <v-icon color="red">mdi-delete</v-icon>
                                                            </v-btn>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </template>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-divider class="mt-4 mb-6"></v-divider>
                    <h4>PRICE</h4>
                    <v-divider class="mt-4 mb-6"></v-divider>
                    <v-row>
                        <!-- Total Cost -->
                        <v-col cols="6">
                            <v-text-field readonly v-model="formattedTotalCost" label="Total Cost"
                                prefix="$"></v-text-field><!-- v-model="editedItem.total_cost" -->
                        </v-col>
                    </v-row>
                </v-form>
                <file-modal :modalOpen.sync="modalOpen" :title="modalTitle" :dataFileUrl="dataFileUrl"></file-modal>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="submitForm">
                    SAVE
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>

</template>

<script>
import { fetchVehicles } from '../../utils/vehicles_CRUD.js'
import { getCoverages } from '../../utils/Insurance/typeOfCoverageCRUD.js'
import { createInsuranceBreakdown, updateInsuranceBreakdown } from '../../utils/Insurance/insuranceBreakdownCRUD.js'
import { generatePublicURL } from '../../utils/presignedUrl.js'
import FileModal from '../../components/Modals/FileModal.vue'
import { validateExtension, renamedFile, checkFiles } from '../../utils/Files/files_functions.js'
import _ from 'lodash';
import { editVehicle } from '../../utils/vehicles_CRUD.js'
//import { employeeNumber } from '../../utils/employee_number.js'
import { get_role } from '../../utils/role.js'

export default {
    components: {
        FileModal
    },
    props: {
        ItemVehicle: Object, // Vehicle info
        ItemInsurance: Object, // Insurance info
        Index: String,
        TypeAction: String,
    },
    data: () => ({
        effectiveMenu: false,
        //objItemVehicle: {},
        objItemInsurance: {},
        // Modal
        modalOpen: false,
        modalTitle: '',
        dataFileUrl: '',
        //
        files: {
            insuranceFile: null,
        },
        year: '',
        driver_name: '',
        headers: [
            { text: 'Type', value: 'type' },
            { text: 'Description', value: 'description' },
            { text: 'Price', value: 'price' },
            { text: 'Actions', value: 'actions', sortable: false },
        ],
        coverageSelected: '',
        typeOfCoverages: [],
        carSelected: null,
        itemVehicles: [],
        type: {
            type: '',
            description: '',
            price: null
        },
        defaultType: {
            type: '',
            description: '',
            price: null
        },
        editedItem: {
            company: '',
            car_assigned: '',
            vin: '',
            coverage: {
                type: []
            },
            total_cost: '',
            status: true,
            termination_date: null,
            effective_date: new Date().toISOString().slice(0, 10),
            /* driver_name: '', */
            insurance_file: false,
            last_user: '',
            //created_date: null,
            //last_modification_date: null,
            file_name: '',
        },
        rules: {
            required: value => !!value || 'Required.',
        },
        editedIndex: '',
        vehicle: {
            insurance_file: false,
            insurance_expiration_date: null,
            insurance_company: '',
            insurance_file_name: '',
        }
    }),
    methods: {
        async openModal(title, fileName, property, file) {
            //console.log("Opening modal....");
            //console.log("file: ", file);
            //console.log("title: ", title);
            //console.log("fileName: ", fileName);
            //console.log("property: ", property);
            this.modalTitle = title;
            //console.log("this.editedItem[property]: ", this.editedItem[property]);
            if (this.editedItem[property] === true) {
                const presignedUrl = await generatePublicURL(`Fleet/${this.editedItem.vin}`, fileName);
                //console.log("presignedUrl: ", presignedUrl);
                if (presignedUrl.hasOwnProperty('url')) {
                    this.dataFileUrl = presignedUrl.url;
                    this.modalOpen = true;
                } else {
                    //console.log("No se generó ninguna , debido a que no existe el archivo");
                    return;
                }
            } else {
                if (file != null) {
                    //console.log("File diferente a null");
                    const blob = new Blob([file], { type: file.type });
                    // Crear una URL para el Blob
                    this.dataFileUrl = URL.createObjectURL(blob);
                    //console.log(">>>>>>>>>>>>>>>> this.dataFileUrl: ", this.dataFileUrl);
                    if (this.dataFileUrl) {
                        this.modalOpen = true;
                    }
                } else {
                    alert("⚠️ No file loaded! Please load a file before proceeding.");
                }
            }
        },
        onFileSelected(file, fileName, property_File) {
            //console.log("file: ", file);
            //console.log("fileName: ", fileName);
            //console.log("property_File: ", property_File);
            if (validateExtension(file)) {
                //console.log("Renaming file....");
                this.files[property_File] = renamedFile(file, fileName);
                //this.editedItem[fileName] = true;
            } else {
                this.files[property_File] = null;
            }
        },
        calculateTotalCost() {
            let totalCost = 0;
            this.editedItem.coverage.type.forEach(item => {
                totalCost += parseFloat(item.price);
            });
            this.editedItem.total_cost = totalCost.toFixed(2);
        },
        removeDriver(index) {
            this.editedItem.coverage.type.splice(index, 1);
            this.calculateTotalCost();
        },
        addItem() {
            //console.log("this.type.type: ", this.type.type);
            try {
                //console.log("this.type: ", this.type);
                ////console.log("type: ", typeof this.editedItem.coverage);
                ////console.log("Is drivers an array? ", Array.isArray(this.editedItem.coverage.type));
                if (this.type.type !== '' && this.type.description !== '' && this.type.price !== '') {
                    let newItem = null;
                    newItem = Object.assign({}, this.type);
                    //console.log("newItem: ", newItem);
                    /* if (!Array.isArray(this.editedItem.drivers)) {
                        //console.log("ARRAY DRIVERS ES DIFERENTE DE ARRAY[......]");
                        this.editedItem.drivers.drivers = [];
                    } */
                    this.editedItem.coverage.type.push(newItem);
                    this.calculateTotalCost();
                    this.type = Object.assign({}, this.defaultType);
                    this.coverageSelected = null;
                    this.$refs.form.resetValidation();
                    /*  this.type.type = '';
                     this.type.description = '';
                     this.type.price = ''; */
                    //console.log("Added driver:", this.editedItem.coverage.type);
                }
                //console.log("----------- editedItem: ", this.editedItem);
                //console.log("Item added!");
            } catch (error) {
                //console.error("Error adding coverage: ", error);
            }
        },
        getLastEndDate(insuranceArray) {
            console.log("getLastEndDate/insuranceArray: ", insuranceArray);
            return insuranceArray.map(insurance => {
                const periods = insurance.policy_period.period;
                if (periods.length > 0) {
                    const lastPeriod = periods[periods.length - 1]; // Acceder al último objeto en el array
                    console.log("lastPeriod.endDate: ", lastPeriod.endDate)
                    return lastPeriod.endDate; // Obtener el valor de endDate del último objeto
                }
                return null; // Devuelve null si no hay periodos
            });
        },
        async updateVehicle(itemInsurance) {
            try {
                console.log("UPDATING VEHICLE....... ", itemInsurance);
                console.log("this.Item: ", this.Item);
                console.log("this.editedItem: ", this.editedItem);
                //this.vehicle.insurance_file = itemInsurance[0].insurance_file;
                //console.log("itemInsurance.policy_period: ", itemInsurance.policy_period);
                const expirationDateStr = this.getLastEndDate(itemInsurance)[0];
                this.vehicle.insurance_expiration_date = new Date(expirationDateStr);
                console.log("this.vehicle.insurance_expiration_date: ", this.vehicle.insurance_expiration_date)
                this.vehicle.insurance_company = itemInsurance[0].insurance_name;
                this.vehicle.insurance_file_name = this.editedItem.file_name;
                console.log("this.vehicle: ", this.vehicle);
                console.log("this.editedItem: ", this.editedItem);
                //const {response, result} = await editVehicle(this.editedItem.vin, this.vehicle);
                //console.log("<<<<<<<<<<<<<<<<< updateVehicle/response: ", response);
                //return response; 
            } catch (error) {
                console.error("Error/updateVehicle: ", error)
            }
        },
        async submitForm() {
            //console.log("Adding new record....");
            try {
                if (this.$refs.form.validate()) {
                    this.showLoader = true;
                    //console.log("<<<<<<<<<< this.editedItem: ", this.editedItem);
                    let responseBreakdown = null;
                    const infoEmployee = get_role();
                    this.editedItem.last_user = infoEmployee.employee_number;
                    if (this.files.insuranceFile != null) {
                        this.editedItem.file_name = this.files.insuranceFile.name;
                        console.log("this.editedItem.file_name: ", this.editedItem.file_name);
                    }
                    //console.log("this.editedItem.coverage.type.length: ", this.editedItem.coverage.type.length);
                    if (this.TypeAction === 'add') {
                        if (this.editedItem.coverage.type.length > 0) {
                            //this.editedItem.effective_date = new Date().toISOString().split('T')[0];
                            //const infoEmployee = get_role();
                            //this.editedItem.last_user = infoEmployee.employee_number;
                            /* if (this.files.insuranceFile != null) {
                                this.editedItem.file_name = this.files.insuranceFile.name;
                            } */
                            console.log("this.editedItem: ", this.editedItem);
                            //console.log("this.editedItem.effective_date: ", this.editedItem.effective_date);
                            const { response, result } = await createInsuranceBreakdown(this.editedItem);
                            console.log("add/result: ", result);
                            console.log("add/response: ", response);
                            responseBreakdown = response;
                            /* if (responseBreakdown.ok) {
                                const response = await this.updateVehicle([this.Item]);
                                if (!response.ok){
                                    return;
                                }                                
                            } */
                        } else {
                            alert("You must register at least one type of coverage");
                            return;
                        }

                    } else {
                        if (this.editedItem.coverage.type.length > 0) {
                            /* if (this.files.insuranceFile != null) {
                                this.editedItem.file_name = this.files.insuranceFile.name;
                            } */
                            //this.editedItem.last_user = infoEmployee.employee_number;
                            console.log("Edit insurance breakdown: ", this.editedItem);
                            responseBreakdown = await updateInsuranceBreakdown(this.editedItem.id, this.editedItem);
                        } else {
                            alert("You must register at least one type of coverage");
                            return;
                        }
                    }
                    console.log("<<<<<<<<<<<<<<<<<<<<<<<< responseBreakdown: ", responseBreakdown);
                    if (responseBreakdown.ok) {
                        console.log("this.Item: ", this.Item);
                        console.log("this.editedItem: ", this.editedItem);
                        this.updateVehicle([this.objItemInsurance]);
                        console.log("this.vehicle: ", this.vehicle);
                        /* if (!response.ok) {
                            return;
                        } */
                    }
                    if (responseBreakdown.status !== 409) {
                        const res = await checkFiles(this.files, this.editedItem);
                        //console.log("After checkFiles......");
                        //console.log("res: ", res);
                        if (res) {
                            this.vehicle.insurance_file = true;
                            /* this.showLoader = false;
                            this.$emit('sent-info', false); */
                        }


                        /* //console.log("response ok....");
                        this.$emit('sent-info', false); */
                    } else {
                        // Manejo de errores HTTP, como 409
                        if (responseBreakdown.status === 409) {
                            alert("The vehicle you want to register already has insurance assigned!"); // Muestra el mensaje de error del backend
                        } else {
                            alert("There was an issue with your request. Please try again.");
                        }
                        this.showLoader = false; // Detener el loader en caso de error
                    }
                    if (responseBreakdown.ok) {
                        const { response, result } = await editVehicle(this.editedItem.vin, this.vehicle);
                        console.log("<<<<<<<<<<<<<<<<< updateVehicle/response: ", response);
                        if (!response.ok) {
                            return;
                        } else {
                            this.showLoader = false;
                            this.$emit('sent-info', false);
                        }
                    }

                }
            }
            catch (error) {
                console.log("Error: ", error);
                this.showLoader = false;
            }
        },
        setValues(item) {
            //console.log("Item: ", item);
            this.editedItem.company = item.insurance_name;
        },
    },
    watch: {
        coverageSelected(val) {
            //console.log("coverageSelected: ", val);
            if (val != null) {
                this.type.type = val.coverage;
                //console.log("this.type.type: ", this.type.type);
            }
        },
        carSelected(item) {
            //console.log("itemCar: ", item);
            if (item != null) {
                this.editedItem.car_assigned = item.name;
                //this.editedItem.car_assigned = item;
                this.editedItem.vin = item.vin;
                this.driver_name = item.driver_name;
                this.year = item.year;
                //console.log("this.editedItem: ", this.editedItem);
                //console.log("this.editedItem.car_assigned: ", this.editedItem.car_assigned);
                //console.log("this.editedItem.vin: ", this.editedItem.vin);
            }
        },
        /* "editedItem.car_assigned"(val){
            //console.log("car_assigned: ", val);
 
        } */
    },
    computed: {
        formattedTotalCost() {
            // Formatea el total_cost con dos decimales
            // Verifica y convierte el total_cost a número
            const cost = parseFloat(this.editedItem.total_cost);
            return !isNaN(cost) ? cost.toFixed(2) : "0.00";
        },
        buttonClasses() {
            return {
                'light-green-background': this.isFormComplete,
                'disabled-background': !this.isFormComplete,
            };
        },
        isFormComplete() {
            return (
                this.type.type &&
                this.type.description &&
                this.type.price
            );
        },
    },
    async mounted() {
        //console.log("++++++++++++++++++++++++++ Mounted/InsuranceBreakdownCard - this.ItemInsurance: ", this.ItemInsurance);
        console.log("++++++++++++++++++++++++++ Mounted/InsuranceBreakdownCard - this.ItemInsurance: ", this.ItemInsurance);
        console.log("++++++++++++++++++++++++++ Mounted/InsuranceBreakdownCard - this.ItemVehicle: ", this.ItemVehicle);
        //this.editedIndex = this.ItemVehicle.id;
        console.log("this.TypeAction: ", this.TypeAction);
        //console.log("<<<<<<<<<<<< this.editedIndex: ", this.editedIndex);
        this.$nextTick(() => {
            this.$refs.form.resetValidation();
        });
        this.setValues(this.ItemInsurance);
        this.objItemInsurance = _.cloneDeep(this.ItemInsurance); // Insurance INFO
        this.itemVehicles = await fetchVehicles();
        this.typeOfCoverages = await getCoverages();
        if (this.TypeAction === 'update') {
            this.editedIndex = this.ItemVehicle.id;
            this.editedItem = _.cloneDeep(this.ItemVehicle); // Vehicle INFO
            //this.objItemInsurance = _.cloneDeep(this.ItemInsurance); // Insurance INFO
            this.carSelected = this.itemVehicles.find(vehicle => vehicle.name === this.editedItem.car_assigned);//car_assigned
            //console.log("this.carSelected: ", this.carSelected);
            //this.carSelected = this.editedItem.car_assigned
        } else {
            this.objItemInsurance = _.cloneDeep(this.ItemInsurance); // Insurance INFO
        }
        //console.log("this.typeOfCoverages: ", this.typeOfCoverages);
        //console.log("++++++++++++++++++++++++ this.itemVehicles: ", this.itemVehicles);
    },
}
</script>

<style scoped>
.purple-text-field .v-input__control .v-input__slot input {
    color: rgb(223, 25, 223) !important;
    /* Cambia el color del texto ingresado a morado */
    font-weight: bold !important;
}

.light-green-background {
    background-color: #8BC34A !important;
    /* Verde claro cuando habilitado */
}

.disabled-background {
    background-color: #D3D3D3 !important;
    /* Gris claro cuando deshabilitado */
}

.icon-thick {
    font-weight: 700;
    /* Aumenta el grosor del ícono */
}
</style>
