<template>
    <div>
        <v-container class="fill-height d-flex align-center justify-center ">
            <v-card class="rounded custom-card" width="1200"><!-- width="1000" -->
                <v-card-title class="headline orange-bg">DRIVING INSTRUCTOR TRAINING LOG</v-card-title>
                <v-card-text>
                    <div id="pdf-content">
                        <v-divider class="mt-4 mb-6"></v-divider>
                        <h3>TRAINEE`S INFORMATION</h3>
                        <v-row>
                            <v-col cols="12" sm="6" md="4">
                                <v-text-field :disabled="editedIndex !== -1" v-model="formData.trainee_name"
                                    label="Trainee name">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                                <v-text-field :disabled="editedIndex !== -1" v-model="formData.trainee_lastName"
                                    label="Trainee last name">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-divider class="mt-4 mb-6"></v-divider>
                    </div>

                    <v-expansion-panels v-model="panel" multiple> <!-- :disabled="disabled" -->
                        <v-expansion-panel>
                            <!-- BEGINNING OF FIRST LESSON -->
                            <v-expansion-panel-header class="font-weight-bold">BEGINNING OF FIRST
                                LESSON</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-container>
                                    <v-row>
                                        <v-col cols="8">
                                            <v-row>
                                                <h4 style="margin-bottom: 10px;" class="bold-black-text">STUDENT MUST
                                                    WEAR PPE FOR THE FULL LESSON (IF APPLICABLE)</h4>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="4">
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <!-- Contenedor para las filas y respuestas -->
                                        <v-col cols="12">
                                            <v-sheet max-width="100%" overflow-x="auto">
                                                <!-- Contenido que deseas hacer scroll horizontalmente -->
                                                <div>
                                                    <!-- Itera sobre cada fila de datos -->
                                                    <v-row dense v-for="(rowName, rowIndex) in rowsBeginningFirstLesson"
                                                        :key="`row_${rowIndex}`" style="margin-top: -20px;">
                                                        <v-col cols="12">
                                                            <v-divider></v-divider>
                                                        </v-col>
                                                        <!-- Nombre de la fila -->
                                                        <v-col cols="8">
                                                            <span>{{ rowName }}</span>
                                                        </v-col>
                                                        <!-- Contenedor de respuestas, desplazable horizontalmente -->
                                                        <v-col cols="4">
                                                            <v-row style="margin-top: -30px;">
                                                                <v-col
                                                                    v-for="(answer, colIndex) in formData.beginningFirstLessonAnswers[rowIndex]"
                                                                    :key="`col_${colIndex}`" class="center-checkbox">
                                                                    <v-checkbox v-model="answer.checked"
                                                                        @change="handleCheckboxChange(rowIndex, colIndex, 'beginningFirstLessonAnswers', answer.checked)"
                                                                        :disabled="answer.initiallySelected || formData.status === true">
                                                                    </v-checkbox>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                            </v-sheet>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <!-- BEGINNING OF EVERY LESSON -->
                        <v-expansion-panel>
                            <v-expansion-panel-header class="font-weight-bold">BEGINNING OF EVERY
                                LESSON</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-container>
                                    <v-row>
                                        <v-col cols="8">
                                            <v-row>
                                                <h4 style="margin-bottom: 10px;" class="bold-black-text">STUDENT MUST
                                                    WEAR PERSONAL PROTECTION EQUIPEMENT (PPE) -IF APPLICABLE- </h4>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="4">
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <!-- Contenedor para las filas y respuestas -->
                                        <v-col cols="12">
                                            <v-sheet max-width="100%" overflow-x="auto">
                                                <!-- Contenido que deseas hacer scroll horizontalmente -->
                                                <div class="scrollable-content">
                                                    <!-- Itera sobre cada fila de datos -->
                                                    <v-row dense v-for="(rowName, rowIndex) in rowsBeginningEveryLesson"
                                                        :key="`row_${rowIndex}`" style="margin-top: -20px;">
                                                        <v-col cols="12" class="p-0">
                                                            <v-divider class="my-0"></v-divider>
                                                        </v-col>
                                                        <!-- Nombre de la fila -->
                                                        <v-col cols="8">
                                                            <span>{{ rowName }}</span>
                                                        </v-col>
                                                        <!-- Contenedor de respuestas, desplazable horizontalmente -->
                                                        <v-col cols="4">
                                                            <v-row style="margin-top: -20px;">
                                                                <v-col
                                                                    v-for="(answer, colIndex) in formData.beginningEveryLessonAnswers[rowIndex]"
                                                                    :key="`col_${colIndex}`" class="center-checkbox">
                                                                    <v-checkbox v-model="answer.checked"
                                                                        @change="handleCheckboxChange(rowIndex, colIndex, 'beginningEveryLessonAnswers', answer.checked)"
                                                                        :disabled="answer.initiallySelected || formData.status === true">
                                                                    </v-checkbox>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                            </v-sheet>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <!-- END OF EVERY LESSON -->
                        <v-expansion-panel>
                            <v-expansion-panel-header class="font-weight-bold">END OF EVERY
                                LESSON</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-container>
                                    <v-row>
                                        <v-col cols="8">
                                            <v-row>
                                                <h4 style="margin-bottom: 10px;" class="bold-black-text">EVALUATION
                                                    (evaluation goes into our records & is emailed to student/parent)
                                                </h4>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="4">
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <!-- Contenedor para las filas y respuestas -->
                                        <v-col cols="12">
                                            <v-sheet max-width="100%" overflow-x="auto">
                                                <!-- Contenido que deseas hacer scroll horizontalmente -->
                                                <div class="scrollable-content">
                                                    <!-- Itera sobre cada fila de datos -->
                                                    <v-row dense v-for="(rowName, rowIndex) in rowsEndEveryLesson"
                                                        :key="`row_${rowIndex}`" style="margin-top: -15px;">
                                                        <v-col cols="12" class="p-0">
                                                            <v-divider class="my-0"></v-divider>
                                                        </v-col>
                                                        <!-- Nombre de la fila -->
                                                        <v-col cols="8">
                                                            <span>{{ rowName }}</span>
                                                        </v-col>
                                                        <!-- Contenedor de respuestas, desplazable horizontalmente -->
                                                        <v-col cols="4">
                                                            <v-row style="margin-top: -35px;">
                                                                <v-col
                                                                    v-for="(answer, colIndex) in formData.endEveryLessonAnswers[rowIndex]"
                                                                    :key="`col_${colIndex}`" class="center-checkbox">
                                                                    <v-checkbox v-model="answer.checked"
                                                                        @change="handleCheckboxChange(rowIndex, colIndex, 'endEveryLessonAnswers', answer.checked)"
                                                                        :disabled="answer.initiallySelected || formData.status === true">
                                                                    </v-checkbox>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                            </v-sheet>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <v-divider class="mt-4 mb-6"></v-divider>
                    <!-- Pestañas -->
                    <v-tabs v-model="tab">
                        <v-tab v-for="(item, index) in skillTabs" :key="index">{{ item }}</v-tab>
                    </v-tabs>
                    <!-- Contenido de las pestañas -->
                    <v-tabs-items v-model="tab">
                        <v-tab-item v-for="(item, index) in skillTabs" :key="index">
                            <!-- SKILL SET ONE -->
                            <div v-if="index === 0" id="docsForm">
                                <!-- SKILL SET ONE -->
                                <div><!-- v-if="formData.skillsetOne" -->
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <h4>SKILLSET ONE</h4>
                                    <p>New Driving Instructor demonstrates knowledge and understanding of the following,
                                        must be
                                        able to
                                        consistently cover all requirements for each topic.</p>
                                    <v-divider class="mt-4 mb-6"></v-divider>

                                    <!-- TRAINER`S INFORMATION -->
                                    <h4>TRAINER`S INFORMATION</h4>
                                    <v-row v-if="formData.trainer.length > 0" v-for="(item, index) in formData.trainer"
                                        :key="index">
                                        <v-col v-for="(value, key) in item" :key="key" cols="3" sm="6" md="3">
                                            <v-text-field disabled :value="item[key]"
                                                @input="updateField(item, key, $event)"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <!-- Trainer`s info -->
                                    <v-row v-if="pageRoute === '/training-log'" v-show="formData.statusss1 === false">
                                        <v-col cols="3" sm="6" md="3">
                                            <v-text-field disabled v-model="formData.employee_number"
                                                label="Employee number">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="3" sm="6" md="3">
                                            <v-text-field disabled v-model="formData.trainer_name"
                                                label="Trainer`s name">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="3" sm="6" md="3">
                                            <v-text-field disabled v-model="formData.trainer_lastName"
                                                label="Trainer`s last name">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="3" sm="6" md="3">
                                            <v-text-field disabled v-model="formData.dateTimeCreated" label="">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <!-- <h4>BEFORE LEAVING TO THE PARKING LOT, INSIDE THE CAR</h4> -->
                                    <v-expansion-panels v-model="panel2" multiple>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header class="font-weight-bold">BEFORE LEAVING TO THE
                                                PARKING LOT,
                                                INSIDE
                                                THE CAR</v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-container>
                                                    <v-row>
                                                        <v-col cols="4">
                                                        </v-col>
                                                        <v-col v-for="(columnsName, columnIndex) in columnsSetOne"
                                                            :key="`column_${columnIndex}`" cols="1.6">
                                                            <span>{{ columnsName }}</span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <!-- Contenedor para las filas y respuestas -->
                                                        <v-col cols="12">
                                                            <v-sheet max-width="100%" overflow-x="auto">
                                                                <!-- Contenido que deseas hacer scroll horizontalmente -->
                                                                <div class="scrollable-content">
                                                                    <!-- Itera sobre cada fila de datos -->
                                                                    <v-row dense
                                                                        v-for="(rowName, rowIndex) in rowsSetOne"
                                                                        :key="`row_${rowIndex}`"
                                                                        style="margin-top: 5px;">
                                                                        <v-col cols="12" class="p-0">
                                                                            <v-divider class="my-0"></v-divider>
                                                                        </v-col>
                                                                        <!-- Nombre de la fila -->
                                                                        <v-col cols="4">
                                                                            <span>{{ rowName }}</span>
                                                                        </v-col>
                                                                        <!-- Contenedor de respuestas, desplazable horizontalmente -->
                                                                        <v-col cols="8">
                                                                            <v-row style="margin-top: -30px;">
                                                                                <v-col
                                                                                    v-for="(answer, colIndex) in formData.skillSetOneAnswers[rowIndex]"
                                                                                    :key="`col_${colIndex}`" cols="1.6"
                                                                                    class="center-checkbox">
                                                                                    <v-checkbox v-model="answer.checked"
                                                                                        @change="handleCheckboxChange(rowIndex, colIndex, 'skillSetOneAnswers', answer.checked)"
                                                                                        :disabled="answer.initiallySelected || formData.status === true">
                                                                                    </v-checkbox>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-col>
                                                                    </v-row>
                                                                </div>
                                                            </v-sheet>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>

                                        <v-expansion-panel>
                                            <v-expansion-panel-header class="font-weight-bold">ON THE WAY TO THE PARKING
                                                LOT</v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-container>
                                                    <v-row>
                                                        <v-col cols="4">
                                                        </v-col>
                                                        <v-col v-for="(columnsName, columnIndex) in columnsSetOne"
                                                            :key="`column_${columnIndex}`" cols="1.6">
                                                            <span>{{ columnsName }}</span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <!-- Contenedor para las filas y respuestas -->
                                                        <v-col cols="12">
                                                            <v-sheet max-width="100%" overflow-x="auto">
                                                                <!-- Contenido que deseas hacer scroll horizontalmente -->
                                                                <div class="scrollable-content">
                                                                    <!-- Itera sobre cada fila de datos -->
                                                                    <v-row dense
                                                                        v-for="(rowName, rowIndex) in rowsSetOne_2"
                                                                        :key="`row_${rowIndex}`"
                                                                        style="margin-top: 5px;">
                                                                        <v-col cols="12" class="p-0">
                                                                            <v-divider class="my-0"></v-divider>
                                                                        </v-col>
                                                                        <!-- Nombre de la fila -->
                                                                        <v-col cols="4">
                                                                            <span>{{ rowName }}</span>
                                                                        </v-col>
                                                                        <!-- Contenedor de respuestas, desplazable horizontalmente -->
                                                                        <v-col cols="8">
                                                                            <v-row style="margin-top: -30px;">
                                                                                <v-col
                                                                                    v-for="(answer, colIndex) in formData.skillSetOneAnswers_2[rowIndex]"
                                                                                    :key="`col_${colIndex}`" cols="1.6"
                                                                                    class="center-checkbox">
                                                                                    <v-checkbox v-model="answer.checked"
                                                                                        @change="handleCheckboxChange(rowIndex, colIndex, 'skillSetOneAnswers_2', answer.checked)"
                                                                                        :disabled="answer.initiallySelected || formData.status === true">
                                                                                    </v-checkbox>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-col>
                                                                    </v-row>
                                                                </div>
                                                            </v-sheet>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>

                                        <v-expansion-panel>
                                            <v-expansion-panel-header class="font-weight-bold">AT THE PARKING LOT,
                                                STUDENT AND
                                                DRIVING
                                                INSTRUCTOR OUTSIDE OF THE CAR</v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-container>
                                                    <v-row>
                                                        <v-col cols="4">
                                                        </v-col>
                                                        <v-col v-for="(columnsName, columnIndex) in columnsSetOne"
                                                            :key="`column_${columnIndex}`" cols="1.6">
                                                            <span>{{ columnsName }}</span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <!-- Contenedor para las filas y respuestas -->
                                                        <v-col cols="12">
                                                            <v-sheet max-width="100%" overflow-x="auto">
                                                                <!-- Contenido que deseas hacer scroll horizontalmente -->
                                                                <div class="scrollable-content">
                                                                    <!-- Itera sobre cada fila de datos -->
                                                                    <v-row dense
                                                                        v-for="(rowName, rowIndex) in rowsSetOne_3"
                                                                        :key="`row_${rowIndex}`"
                                                                        style="margin-top: 5px;">
                                                                        <v-col cols="12" class="p-0">
                                                                            <v-divider class="my-0"></v-divider>
                                                                        </v-col>
                                                                        <!-- Nombre de la fila -->
                                                                        <v-col cols="4">
                                                                            <span>{{ rowName }}</span>
                                                                        </v-col>
                                                                        <!-- Contenedor de respuestas, desplazable horizontalmente -->
                                                                        <v-col cols="8">
                                                                            <v-row style="margin-top: -35px;">
                                                                                <v-col
                                                                                    v-for="(answer, colIndex) in formData.skillSetOneAnswers_3[rowIndex]"
                                                                                    :key="`col_${colIndex}`" cols="1.6"
                                                                                    class="center-checkbox">
                                                                                    <v-checkbox v-model="answer.checked"
                                                                                        @change="handleCheckboxChange(rowIndex, colIndex, 'skillSetOneAnswers_3', answer.checked)"
                                                                                        :disabled="answer.initiallySelected || formData.status === true">
                                                                                    </v-checkbox>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-col>
                                                                    </v-row>
                                                                </div>
                                                            </v-sheet>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>

                                        <v-expansion-panel>
                                            <v-expansion-panel-header class="font-weight-bold">AT THE PARKING LOT:
                                                DRIVING
                                                INSTRUCTOR
                                                OUTSIDE OF CAR, STUDENT IN DRIVER’S SEAT</v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-container>
                                                    <v-row>
                                                        <v-col cols="4">
                                                        </v-col>
                                                        <v-col v-for="(columnsName, columnIndex) in columnsSetOne"
                                                            :key="`column_${columnIndex}`" cols="1.6">
                                                            <span>{{ columnsName }}</span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <!-- Contenedor para las filas y respuestas -->
                                                        <v-col cols="12">
                                                            <v-sheet max-width="100%" overflow-x="auto">
                                                                <!-- Contenido que deseas hacer scroll horizontalmente -->
                                                                <div class="scrollable-content">
                                                                    <!-- Itera sobre cada fila de datos -->
                                                                    <v-row dense
                                                                        v-for="(rowName, rowIndex) in rowsSetOne_4"
                                                                        :key="`row_${rowIndex}`"
                                                                        style="margin-top: 5px;">
                                                                        <v-col cols="12" class="p-0">
                                                                            <v-divider class="my-0"></v-divider>
                                                                        </v-col>
                                                                        <!-- Nombre de la fila -->
                                                                        <v-col cols="4">
                                                                            <span>{{ rowName }}</span>
                                                                        </v-col>
                                                                        <!-- Contenedor de respuestas, desplazable horizontalmente -->
                                                                        <v-col cols="8">
                                                                            <v-row style="margin-top: -35px;">
                                                                                <v-col
                                                                                    v-for="(answer, colIndex) in formData.skillSetOneAnswers_4[rowIndex]"
                                                                                    :key="`col_${colIndex}`" cols="1.6"
                                                                                    class="center-checkbox">
                                                                                    <v-checkbox v-model="answer.checked"
                                                                                        @change="handleCheckboxChange(rowIndex, colIndex, 'skillSetOneAnswers_4', answer.checked)"
                                                                                        :disabled="answer.initiallySelected || formData.status === true">
                                                                                    </v-checkbox>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-col>
                                                                    </v-row>
                                                                </div>
                                                            </v-sheet>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>

                                        <v-expansion-panel>
                                            <v-expansion-panel-header class="font-weight-bold">AT THE PARKING LOT:
                                                INSIDE THE
                                                CAR</v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-container>
                                                    <v-row>
                                                        <v-col cols="4">
                                                        </v-col>
                                                        <v-col v-for="(columnsName, columnIndex) in columnsSetOne"
                                                            :key="`column_${columnIndex}`" cols="1.6">
                                                            <span>{{ columnsName }}</span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <!-- Contenedor para las filas y respuestas -->
                                                        <v-col cols="12">
                                                            <v-sheet max-width="100%" overflow-x="auto">
                                                                <!-- Contenido que deseas hacer scroll horizontalmente -->
                                                                <div class="scrollable-content">
                                                                    <!-- Itera sobre cada fila de datos -->
                                                                    <v-row dense
                                                                        v-for="(rowName, rowIndex) in rowsSetOne_5"
                                                                        :key="`row_${rowIndex}`"
                                                                        style="margin-top: 5px;">
                                                                        <v-col cols="12" class="p-0">
                                                                            <v-divider class="my-0"></v-divider>
                                                                        </v-col>
                                                                        <!-- Nombre de la fila -->
                                                                        <v-col cols="4">
                                                                            <span>{{ rowName }}</span>
                                                                        </v-col>
                                                                        <!-- Contenedor de respuestas, desplazable horizontalmente -->
                                                                        <v-col cols="8">
                                                                            <v-row style="margin-top: -35px;">
                                                                                <v-col
                                                                                    v-for="(answer, colIndex) in formData.skillSetOneAnswers_5[rowIndex]"
                                                                                    :key="`col_${colIndex}`" cols="1.6"
                                                                                    class="center-checkbox">
                                                                                    <v-checkbox v-model="answer.checked"
                                                                                        @change="handleCheckboxChange(rowIndex, colIndex, 'skillSetOneAnswers_5', answer.checked)"
                                                                                        :disabled="answer.initiallySelected || formData.status === true">
                                                                                    </v-checkbox>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-col>
                                                                    </v-row>
                                                                </div>
                                                            </v-sheet>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <h4>SURVEY SKILLSET ONE</h4>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <v-row>
                                        <v-col>
                                            <h4>ATTENDANCE</h4>
                                            <v-radio-group v-model="formData.attendanceOptionSS1"
                                                :disabled="formData.statusss1 === true">
                                                <v-radio label="1- Satisfactory" value="option1"></v-radio>
                                                <v-radio label="2 - Almost Satisfactory" value="option2"></v-radio>
                                                <v-radio label="3 - Did not Cover" value="option3"></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                        <v-col>
                                            <h4>TRAINING PARTICIPATION:</h4>
                                            <v-row>
                                                <v-col>
                                                    <v-radio-group v-model="formData.trainingParticipationOptionSS1"
                                                        :disabled="formData.statusss1 === true">
                                                        <v-radio label="1- Satisfactory" value="option1"></v-radio>
                                                        <v-radio label="2 - Almost Satisfactory"
                                                            value="option2"></v-radio>
                                                        <v-radio label="3 - Did not Cover" value="option3"></v-radio>
                                                    </v-radio-group>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col>
                                            <h4>KNOWLEDGE CHECK:</h4>
                                            <v-radio-group v-model="formData.knowledgeOptionSS1"
                                                :disabled="formData.statusss1 === true">
                                                <v-radio label="1- Satisfactory" value="option1"></v-radio>
                                                <v-radio label="2 - Almost Satisfactory" value="option2"></v-radio>
                                                <v-radio label="3 - Did not Cover" value="option3"></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                    </v-row>
                                    <v-btn class="mr-4 red-light" @click="deselectOption('surveyss1')"
                                        :disabled="formData.statusss1 === true">Clear options</v-btn>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                </div>
                            </div>
                            <!-- SKILL SET TWO -->
                            <div v-if="index === 1" id="docsForm">
                                <!-- SKILL SET TWO -->
                                <div><!-- v-if="formData.skillsetTwo" -->
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <h4>SKILLSET TWO</h4>
                                    <p>New Driving Instructor demonstrates knowledge and understanding of the following,
                                        must be
                                        able to
                                        consistently cover all requirements for each topic.</p>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <!-- TRAINER`S INFORMATION -->
                                    <h4>TRAINER`S INFORMATION</h4>
                                    <v-row v-if="formData.trainerSS2.length > 0"
                                        v-for="(item, index) in formData.trainerSS2" :key="index">
                                        <v-col v-for="(value, key) in item" :key="key" cols="3" sm="6" md="3">
                                            <v-text-field disabled :value="item[key]"
                                                @input="updateField(item, key, $event)"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="pageRoute === '/training-log'" v-show="formData.statusss2 === false">
                                        <v-col cols="3" sm="6" md="3">
                                            <v-text-field disabled v-model="formData.employee_numberSS2"
                                                label="Employee number">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="3" sm="6" md="3">
                                            <v-text-field disabled v-model="formData.trainer_nameSS2"
                                                label="Trainer`s name">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="3" sm="6" md="3">
                                            <v-text-field disabled v-model="formData.trainer_lastNameSS2"
                                                label="Trainer`s last name">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="3" sm="6" md="3">
                                            <v-text-field disabled v-model="formData.dateTimeCreated" label="">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <!-- TRAINER`S INFORMATION -->
                                    <v-container>
                                        <v-row>
                                            <v-col cols="4">
                                            </v-col>
                                            <v-col v-for="(columnsName, columnIndex) in columnsSetOne"
                                                :key="`column_${columnIndex}`" cols="1.6">
                                                <span>{{ columnsName }}</span>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <!-- Contenedor para las filas y respuestas -->
                                            <v-col cols="12">
                                                <v-sheet max-width="100%" overflow-x="auto">
                                                    <!-- Contenido que deseas hacer scroll horizontalmente -->
                                                    <div class="scrollable-content">
                                                        <!-- Itera sobre cada fila de datos -->
                                                        <v-row dense v-for="(rowName, rowIndex) in rowsSetTwo"
                                                            :key="`row_${rowIndex}`" style="margin-top: 5px;">
                                                            <v-col cols="12" class="p-0">
                                                                <v-divider class="my-0"></v-divider>
                                                            </v-col>
                                                            <!-- Nombre de la fila -->
                                                            <v-col cols="4">
                                                                <span>{{ rowName }}</span>
                                                            </v-col>
                                                            <!-- Contenedor de respuestas, desplazable horizontalmente -->
                                                            <v-col cols="8">
                                                                <v-row style="margin-top: -35px;">
                                                                    <v-col
                                                                        v-for="(answer, colIndex) in formData.skillSetTwoAnswers[rowIndex]"
                                                                        :key="`col_${colIndex}`" cols="1.6"
                                                                        class="center-checkbox">
                                                                        <v-checkbox v-model="answer.checked"
                                                                            @change="handleCheckboxChange(rowIndex, colIndex, 'skillSetTwoAnswers', answer.checked)"
                                                                            :disabled="answer.initiallySelected || formData.status === true">
                                                                        </v-checkbox>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-col>
                                                        </v-row>
                                                    </div>
                                                </v-sheet>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <h4>SURVEY SKILLSET TWO</h4>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <!-- SURVEY SS2 -->
                                    <v-row>
                                        <v-col>
                                            <h4>ATTENDANCE</h4>
                                            <v-radio-group v-model="formData.attendanceOptionSS2"
                                                :disabled="formData.statusss2 === true">
                                                <v-radio label="1- Satisfactory" value="option1"></v-radio>
                                                <v-radio label="2 - Almost Satisfactory" value="option2"></v-radio>
                                                <v-radio label="3 - Did not Cover" value="option3"></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                        <v-col>
                                            <h4>TRAINING PARTICIPATION:</h4>
                                            <v-row>
                                                <v-col>
                                                    <v-radio-group v-model="formData.trainingParticipationOptionSS2"
                                                        :disabled="formData.statusss2 === true">
                                                        <v-radio label="1- Satisfactory" value="option1"></v-radio>
                                                        <v-radio label="2 - Almost Satisfactory"
                                                            value="option2"></v-radio>
                                                        <v-radio label="3 - Did not Cover" value="option3"></v-radio>
                                                    </v-radio-group>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col>
                                            <h4>KNOWLEDGE CHECK:</h4>
                                            <v-radio-group v-model="formData.knowledgeOptionSS2"
                                                :disabled="formData.statusss2 === true">
                                                <v-radio label="1- Satisfactory" value="option1"></v-radio>
                                                <v-radio label="2 - Almost Satisfactory" value="option2"></v-radio>
                                                <v-radio label="3 - Did not Cover" value="option3"></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                    </v-row>
                                    <v-btn class="mr-4 red-light" @click="deselectOption('surveyss2')"
                                        :disabled="formData.statusss2 === true">Clear options</v-btn>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <h2 class="bold-red-text centered-text">
                                        YOU MUST NOT PUT A STUDENT ON THE ROAD UNTIL THEY CAN SUCCESFULLY MAKE MULTIPLE
                                        SAFE,
                                        CONTROLLED, PRECISE RIGHT AND LEFT TURNS IN THE PARKING LOT WITHOUT ANY
                                        INSTRUCTION OR
                                        ASSISTANCE. NO EXCEPTIONS.
                                    </h2>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                </div>
                            </div>
                            <!-- SKILL SET THREE -->
                            <div v-if="index === 2" id="docsForm">
                                <!-- SKILL SET THREE -->
                                <div><!-- v-if="formData.skillsetThree" -->
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <h2 class="bold-red-text centered-text">
                                        YOU MUST NOT PUT A STUDENT ON THE ROAD UNTIL THEY CAN SUCCESFULLY MAKE MULTIPLE
                                        SAFE,
                                        CONTROLLED, PRECISE RIGHT AND LEFT TURNS IN THE PARKING LOT WITHOUT ANY
                                        INSTRUCTION OR
                                        ASSISTANCE. NO EXCEPTIONS.
                                    </h2>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <h4>SKILLSET THREE</h4>
                                    <p>New Driving Instructor demonstrates knowledge and understanding of the following,
                                        must be
                                        able to
                                        consistently cover all requirements for each topic.</p>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <h4>TRAINER`S INFORMATION</h4>
                                    <v-row v-if="formData.trainerSS3.length > 0"
                                        v-for="(item, index) in formData.trainerSS3" :key="index">
                                        <v-col v-for="(value, key) in item" :key="key" cols="3" sm="6" md="3">
                                            <v-text-field disabled :value="item[key]"
                                                @input="updateField(item, key, $event)"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="pageRoute === '/training-log'" v-show="formData.statusss3 === false">
                                        <v-col cols="3" sm="6" md="3">
                                            <v-text-field disabled v-model="formData.employee_numberSS3"
                                                label="Employee number">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="3" sm="6" md="3">
                                            <v-text-field disabled v-model="formData.trainer_nameSS3"
                                                label="Trainer`s name">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="3" sm="6" md="3">
                                            <v-text-field disabled v-model="formData.trainer_lastNameSS3"
                                                label="Trainer`s last name">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="3" sm="6" md="3">
                                            <v-text-field disabled v-model="formData.dateTimeCreated" label="">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="4">
                                            </v-col>
                                            <v-col v-for="(columnsName, columnIndex) in columnsSetOne"
                                                :key="`column_${columnIndex}`" cols="1.6">
                                                <span>{{ columnsName }}</span>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <!-- Contenedor para las filas y respuestas -->
                                            <v-col cols="12">
                                                <v-sheet max-width="100%" overflow-x="auto">
                                                    <!-- Contenido que deseas hacer scroll horizontalmente -->
                                                    <div class="scrollable-content">
                                                        <!-- Itera sobre cada fila de datos -->
                                                        <v-row dense v-for="(rowName, rowIndex) in rowsSetThree"
                                                            :key="`row_${rowIndex}`" style="margin-top: 5px;">
                                                            <!-- Nombre de la fila -->
                                                            <v-col cols="12" class="p-0">
                                                                <v-divider class="my-0"></v-divider>
                                                            </v-col>
                                                            <v-col cols="4">
                                                                <span>{{ rowName }}</span>
                                                            </v-col>
                                                            <!-- Contenedor de respuestas, desplazable horizontalmente -->
                                                            <v-col cols="8">
                                                                <v-row style="margin-top: -35px;">
                                                                    <v-col
                                                                        v-for="(answer, colIndex) in formData.skillSetThreeAnswers[rowIndex]"
                                                                        :key="`col_${colIndex}`" cols="1.6"
                                                                        class="center-checkbox">
                                                                        <v-checkbox v-model="answer.checked"
                                                                            @change="handleCheckboxChange(rowIndex, colIndex, 'skillSetThreeAnswers', answer.checked)"
                                                                            :disabled="answer.initiallySelected || formData.status === true">
                                                                        </v-checkbox>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-col>

                                                        </v-row>
                                                    </div>
                                                </v-sheet>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <h4>SURVEY SKILLSET THREE</h4>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <!-- SURVEY SS3 -->
                                    <v-row>
                                        <v-col>
                                            <h4>ATTENDANCE</h4>
                                            <v-radio-group v-model="formData.attendanceOptionSS3"
                                                :disabled="formData.statusss3 === true">
                                                <v-radio label="1- Satisfactory" value="option1"></v-radio>
                                                <v-radio label="2 - Almost Satisfactory" value="option2"></v-radio>
                                                <v-radio label="3 - Did not Cover" value="option3"></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                        <v-col>
                                            <h4>TRAINING PARTICIPATION:</h4>
                                            <v-row>
                                                <v-col>
                                                    <v-radio-group v-model="formData.trainingParticipationOptionSS3"
                                                        :disabled="formData.statusss3 === true">
                                                        <v-radio label="1- Satisfactory" value="option1"></v-radio>
                                                        <v-radio label="2 - Almost Satisfactory"
                                                            value="option2"></v-radio>
                                                        <v-radio label="3 - Did not Cover" value="option3"></v-radio>
                                                    </v-radio-group>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col>
                                            <h4>KNOWLEDGE CHECK:</h4>
                                            <v-radio-group v-model="formData.knowledgeOptionSS3"
                                                :disabled="formData.statusss3 === true">
                                                <v-radio label="1- Satisfactory" value="option1"></v-radio>
                                                <v-radio label="2 - Almost Satisfactory" value="option2"></v-radio>
                                                <v-radio label="3 - Did not Cover" value="option3"></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                    </v-row>
                                    <v-btn class="mr-4 red-light" @click="deselectOption('surveyss3')"
                                        :disabled="formData.statusss3 === true">Clear options</v-btn>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                </div>
                            </div>
                            <!-- SKILL SET FOUR -->
                            <div v-if="index === 3" id="docsForm">
                                <!-- SKILL SET FOUR -->
                                <div><!-- v-if="formData.skillsetFour" -->
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <h4>SKILLSET FOUR</h4>
                                    <p>New Driving Instructor demonstrates knowledge and understanding of the following,
                                        must be
                                        able to
                                        consistently cover all requirements for each topic.</p>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <h4>TRAINER`S INFORMATION</h4>
                                    <v-row v-if="formData.trainerSS4.length > 0"
                                        v-for="(item, index) in formData.trainerSS4" :key="index">
                                        <v-col v-for="(value, key) in item" :key="key" cols="3" sm="6" md="3">
                                            <v-text-field disabled :value="item[key]"
                                                @input="updateField(item, key, $event)"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="pageRoute === '/training-log'" v-show="formData.statusss4 === false">
                                        <v-col cols="3" sm="6" md="3">
                                            <v-text-field disabled v-model="formData.employee_numberSS4"
                                                label="Employee number">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="3" sm="6" md="3">
                                            <v-text-field disabled v-model="formData.trainer_nameSS4"
                                                label="Trainer`s name">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="3" sm="6" md="3">
                                            <v-text-field disabled v-model="formData.trainer_lastNameSS4"
                                                label="Trainer`s last name">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="3" sm="6" md="3">
                                            <v-text-field disabled v-model="formData.dateTimeCreated" label="">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <!-- BOXES SS4 -->
                                    <v-container>
                                        <v-row>
                                            <v-col cols="4">
                                            </v-col>
                                            <v-col v-for="(columnsName, columnIndex) in columnsSetOne"
                                                :key="`column_${columnIndex}`" cols="1.6">
                                                <!-- Columna para el nombre de la fila -->
                                                <span>{{ columnsName }}</span>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <!-- Contenedor para las filas y respuestas -->
                                            <v-col cols="12">
                                                <v-sheet max-width="100%" overflow-x="auto">
                                                    <!-- Contenido que deseas hacer scroll horizontalmente -->
                                                    <div class="scrollable-content">
                                                        <!-- Itera sobre cada fila de datos -->
                                                        <v-row dense v-for="(rowName, rowIndex) in rowsSetFour"
                                                            :key="`row_${rowIndex}`" style="margin-top: 5px;">
                                                            <!-- Nombre de la fila -->
                                                            <v-col cols="12" class="p-0">
                                                                <v-divider class="my-0"></v-divider>
                                                            </v-col>
                                                            <v-col cols="4">
                                                                <span>{{ rowName }}</span>
                                                            </v-col>
                                                            <!-- Contenedor de respuestas, desplazable horizontalmente -->
                                                            <v-col cols="8">
                                                                <v-row style="margin-top: -35px;">
                                                                    <v-col
                                                                        v-for="(answer, colIndex) in formData.skillSetFourAnswers[rowIndex]"
                                                                        :key="`col_${colIndex}`" cols="1.6"
                                                                        class="center-checkbox">
                                                                        <v-checkbox v-model="answer.checked"
                                                                            @change="handleCheckboxChange(rowIndex, colIndex, 'skillSetFourAnswers', answer.checked)"
                                                                            :disabled="answer.initiallySelected || formData.status === true">
                                                                        </v-checkbox>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-col>

                                                        </v-row>
                                                    </div>
                                                </v-sheet>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <h4>SURVEY SKILLSET FOUR</h4>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <!-- SURVEY SS4 -->
                                    <v-row>
                                        <v-col>
                                            <h4>ATTENDANCE</h4>
                                            <v-radio-group v-model="formData.attendanceOptionSS4"
                                                :disabled="formData.statusss4 === true">
                                                <v-radio label="1- Satisfactory" value="option1"></v-radio>
                                                <v-radio label="2 - Almost Satisfactory" value="option2"></v-radio>
                                                <v-radio label="3 - Did not Cover" value="option3"></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                        <v-col>
                                            <h4>TRAINING PARTICIPATION:</h4>
                                            <v-row>
                                                <v-col>
                                                    <v-radio-group v-model="formData.trainingParticipationOptionSS4"
                                                        :disabled="formData.statusss4 === true">
                                                        <v-radio label="1- Satisfactory" value="option1"></v-radio>
                                                        <v-radio label="2 - Almost Satisfactory"
                                                            value="option2"></v-radio>
                                                        <v-radio label="3 - Did not Cover" value="option3"></v-radio>
                                                    </v-radio-group>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col>
                                            <h4>KNOWLEDGE CHECK:</h4>
                                            <v-radio-group v-model="formData.knowledgeOptionSS4"
                                                :disabled="formData.statusss4 === true">
                                                <v-radio label="1- Satisfactory" value="option1"></v-radio>
                                                <v-radio label="2 - Almost Satisfactory" value="option2"></v-radio>
                                                <v-radio label="3 - Did not Cover" value="option3"></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                    </v-row>
                                    <v-btn class="mr-4 red-light" @click="deselectOption('surveyss4')"
                                        :disabled="formData.statusss4 === true">Clear options</v-btn>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                </div>
                            </div>
                            <!-- ADVANCED MANEUVERS -->
                            <div v-if="index === 4" id="docsForm">
                                <!-- ADVANCED MANEUVERS -->
                                <div> <!-- v-if="formData.maneuvers" -->
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <h4>ADVANCED MANEUVERS</h4>
                                    <p>New Driving Instructor demonstrates knowledge and understanding of the following,
                                        must be
                                        able to
                                        consistently cover all requirements for each topic.</p>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <h4>TRAINER`S INFORMATION</h4>
                                    <v-row v-if="formData.trainerM.length > 0"
                                        v-for="(item, index) in formData.trainerM" :key="index">
                                        <v-col v-for="(value, key) in item" :key="key" cols="3" sm="6" md="3">
                                            <v-text-field disabled :value="item[key]"
                                                @input="updateField(item, key, $event)"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="pageRoute === '/training-log'" v-show="formData.statusm === false">
                                        <v-col cols="3" sm="6" md="3">
                                            <v-text-field disabled v-model="formData.employee_numberM"
                                                label="Employee number">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="3" sm="6" md="3">
                                            <v-text-field disabled v-model="formData.trainer_nameM"
                                                label="Trainer`s name">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="3" sm="6" md="3">
                                            <v-text-field disabled v-model="formData.trainer_lastNameM"
                                                label="Trainer`s last name">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="3" sm="6" md="3">
                                            <v-text-field disabled v-model="formData.dateTimeCreated" label="">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <!-- BOXES SS4 -->
                                    <v-container>
                                        <v-row>
                                            <v-col cols="4">
                                            </v-col>
                                            <v-col v-for="(columnsName, columnIndex) in columnsSetOne"
                                                :key="`column_${columnIndex}`" cols="1.6">
                                                <!-- Columna para el nombre de la fila -->
                                                <span>{{ columnsName }}</span>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <!-- Contenedor para las filas y respuestas -->
                                            <v-col cols="12">
                                                <v-sheet max-width="100%" overflow-x="auto">
                                                    <!-- Contenido que deseas hacer scroll horizontalmente -->
                                                    <div class="scrollable-content">
                                                        <!-- Itera sobre cada fila de datos -->
                                                        <v-row dense v-for="(rowName, rowIndex) in rowsMeneuvers"
                                                            :key="`row_${rowIndex}`" style="margin-top: 5px;">
                                                            <!-- Nombre de la fila -->
                                                            <v-col cols="12" class="p-0">
                                                                <v-divider class="my-0"></v-divider>
                                                            </v-col>
                                                            <v-col cols="4">
                                                                <span>{{ rowName }}</span>
                                                            </v-col>
                                                            <!-- Contenedor de respuestas, desplazable horizontalmente -->
                                                            <v-col cols="8">
                                                                <v-row style="margin-top: -35px;">
                                                                    <v-col
                                                                        v-for="(answer, colIndex) in formData.maneuversAnswers[rowIndex]"
                                                                        :key="`col_${colIndex}`" cols="1.6"
                                                                        class="center-checkbox">
                                                                        <v-checkbox v-model="answer.checked"
                                                                            @change="handleCheckboxChange(rowIndex, colIndex, 'maneuversAnswers', answer.checked)"
                                                                            :disabled="answer.initiallySelected || formData.status === true">
                                                                        </v-checkbox>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-col>

                                                        </v-row>
                                                    </div>
                                                </v-sheet>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <h4>SURVEY SKILLSET ADVANCED MANEUVERS</h4>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <!-- SURVEY M -->
                                    <v-row>
                                        <v-col>
                                            <h4>ATTENDANCE</h4>
                                            <v-radio-group v-model="formData.attendanceOptionM"
                                                :disabled="formData.statusm === true">
                                                <v-radio label="1- Satisfactory" value="option1"></v-radio>
                                                <v-radio label="2 - Almost Satisfactory" value="option2"></v-radio>
                                                <v-radio label="3 - Did not Cover" value="option3"></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                        <v-col>
                                            <h4>TRAINING PARTICIPATION:</h4>
                                            <v-row>
                                                <v-col>
                                                    <v-radio-group v-model="formData.trainingParticipationOptionM"
                                                        :disabled="formData.statusm === true">
                                                        <v-radio label="1- Satisfactory" value="option1"></v-radio>
                                                        <v-radio label="2 - Almost Satisfactory"
                                                            value="option2"></v-radio>
                                                        <v-radio label="3 - Did not Cover" value="option3"></v-radio>
                                                    </v-radio-group>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col>
                                            <h4>KNOWLEDGE CHECK:</h4>
                                            <v-radio-group v-model="formData.knowledgeOptionM"
                                                :disabled="formData.statusm === true">
                                                <v-radio label="1- Satisfactory" value="option1"></v-radio>
                                                <v-radio label="2 - Almost Satisfactory" value="option2"></v-radio>
                                                <v-radio label="3 - Did not Cover" value="option3"></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                    </v-row>
                                    <v-btn class="mr-4 red-light" @click="deselectOption('surveym')"
                                        :disabled="formData.statusm === true">Clear options</v-btn>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                </div>
                            </div>
                        </v-tab-item>
                    </v-tabs-items>
                    <v-btn v-if="pageRoute === '/training-log'" class="mr-4 red-light" @click="back">Back</v-btn>
                    <v-btn v-if="pageRoute === '/training-log'" class="success mr-4 green-bright" @click="save"
                        :disabled="formData.status === true">Save</v-btn>
                    <div v-if="pageRoute === '/admin-trainer-interface'">
                        <v-btn class="success mr-4 green-bright"
                            @click="openModalFile('#traininglog')">Complete</v-btn><!-- :disabled="formData.status === true" -->
                        <v-btn class="success mr-4 green-bright" @click="openModalFile('#skillsetone')">SSONE</v-btn>
                        <v-btn class="success mr-4 green-bright" @click="openModalFile('#skillsettwo')">SSTWO</v-btn>
                        <v-btn class="success mr-4 green-bright"
                            @click="openModalFile('#skillsetthree')">SSTHREE</v-btn>
                        <v-btn class="success mr-4 green-bright" @click="openModalFile('#skillsetfour')">SSFOUR</v-btn>
                        <v-btn class="success mr-4 green-bright" @click="openModalFile('#maneuvers')">ADV.
                            MANEUVERS</v-btn>
                    </div>
                </v-card-text>
                <file-modal :modalOpen.sync="modalOpen" :title="modalTitle" :dataFileUrl="fileUrl"
                    :formStatus="formStatus"></file-modal>
            </v-card>
        </v-container>
        <v-container v-if="createPdf" class="fill-height d-flex align-center justify-center" id="traininglog">
            <v-card class="rounded custom-card" width="1000">
                <v-card-text>
                    <div id="headform">
                        <v-card-title class="headline orange-bg">DRIVING INSTRUCTOR TRAINING LOG</v-card-title>
                        <v-divider class="mt-4 mb-6"></v-divider>
                        <!-- TRAINEE`S INFORMATION -->
                        <h3>TRAINEE`S INFORMATION</h3>
                        <v-row>
                            <v-col cols="12" sm="6" md="4">
                                <v-text-field :disabled="editedIndex !== -1" v-model="formData.trainee_name"
                                    label="Trainee name">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                                <v-text-field :disabled="editedIndex !== -1" v-model="formData.trainee_lastName"
                                    label="Trainee last name">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-divider class="mt-4 mb-6"></v-divider>
                        <!-- BEGINNING OF FIRST LESSON -->
                        <div>
                            <h3>BEGINNING OF FIRST LESSON</h3>
                            <v-divider class="mt-4 mb-6"></v-divider>
                            <v-container>
                                <v-row>
                                    <v-col cols="8">
                                        <v-row style="margin-bottom: 10px;">
                                            <h4 class="bold-black-text">STUDENT MUST WEAR PPE FOR THE FULL LESSON (IF
                                                APPLICABLE)</h4>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="4">
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-sheet max-width="100%" overflow-x="auto">
                                            <div class="scrollable-content">
                                                <v-row dense v-for="(rowName, rowIndex) in rowsBeginningFirstLesson"
                                                    :key="`row_${rowIndex}`" style="margin-top: -20px;">
                                                    <v-col cols="12" class="p-0">
                                                        <v-divider class="my-0"></v-divider>
                                                    </v-col>
                                                    <v-col cols="8">
                                                        <span>{{ rowName }}</span>
                                                    </v-col>
                                                    <v-col cols="4">
                                                        <v-row style="margin-top: -30px;">
                                                            <v-col
                                                                v-for="(answer, colIndex) in formData.beginningFirstLessonAnswers[rowIndex]"
                                                                :key="`col_${colIndex}`" class="center-checkbox">
                                                                <v-checkbox v-model="answer.checked"
                                                                    @change="handleCheckboxChange(rowIndex, colIndex, 'beginningFirstLessonAnswers', answer.checked)"
                                                                    :disabled="answer.initiallySelected || formData.status === true">
                                                                </v-checkbox>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </v-sheet>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </div>
                        <!-- BEGINNING OF EVERY LESSON -->
                        <div>
                            <v-divider class="mt-4 mb-6"></v-divider>
                            <h3>BEGINNING OF EVERY LESSON</h3>
                            <v-divider class="mt-4 mb-6"></v-divider>
                            <v-container>
                                <v-row>
                                    <v-col cols="8">
                                        <v-row style="margin-bottom: 10px;">
                                            <h4 class="bold-black-text">STUDENT MUST WEAR PERSONAL PROTECTION
                                                EQUIPEMENT (PPE) -IF
                                                APPLICABLE-</h4>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="4">
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-sheet max-width="100%" overflow-x="auto">
                                            <div class="scrollable-content">
                                                <v-row dense v-for="(rowName, rowIndex) in rowsBeginningEveryLesson"
                                                    :key="`row_${rowIndex}`" style="margin-top: -20px;">
                                                    <v-col cols="12" class="p-0">
                                                        <v-divider class="my-0"></v-divider>
                                                    </v-col>
                                                    <v-col cols="8">
                                                        <span>{{ rowName }}</span>
                                                    </v-col>
                                                    <v-col cols="4">
                                                        <v-row style="margin-top: -20px;">
                                                            <v-col
                                                                v-for="(answer, colIndex) in formData.beginningEveryLessonAnswers[rowIndex]"
                                                                :key="`col_${colIndex}`" class="center-checkbox">
                                                                <v-checkbox v-model="answer.checked"
                                                                    @change="handleCheckboxChange(rowIndex, colIndex, 'beginningEveryLessonAnswers', answer.checked)"
                                                                    :disabled="answer.initiallySelected || formData.status === true">
                                                                </v-checkbox>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </v-sheet>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </div>
                        <!-- END OF EVERY LESSON -->
                        <div>
                            <v-divider class="mt-4 mb-6"></v-divider>
                            <h3>END OF EVERY LESSON</h3>
                            <v-divider class="mt-4 mb-6"></v-divider>
                            <v-container>
                                <v-row>
                                    <v-col cols="8">
                                        <v-row style="margin-bottom: 10px;">
                                            <h4 class="bold-black-text">EVALUATION (evaluation goes into our records &
                                                is emailed
                                                to student/parent)</h4>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="4">
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-sheet max-width="100%" overflow-x="auto">
                                            <div class="scrollable-content">
                                                <v-row dense v-for="(rowName, rowIndex) in rowsEndEveryLesson"
                                                    :key="`row_${rowIndex}`" style="margin-top: -15px;">
                                                    <v-col cols="12" class="p-0">
                                                        <v-divider class="my-0"></v-divider>
                                                    </v-col>
                                                    <v-col cols="8">
                                                        <span>{{ rowName }}</span>
                                                    </v-col>
                                                    <v-col cols="4">
                                                        <v-row style="margin-top: -35px;">
                                                            <v-col
                                                                v-for="(answer, colIndex) in formData.endEveryLessonAnswers[rowIndex]"
                                                                :key="`col_${colIndex}`" class="center-checkbox">
                                                                <v-checkbox v-model="answer.checked"
                                                                    @change="handleCheckboxChange(rowIndex, colIndex, 'endEveryLessonAnswers', answer.checked)"
                                                                    :disabled="answer.initiallySelected || formData.status === true">
                                                                </v-checkbox>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </v-sheet>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </div>
                    </div>
                    <!-- SKILL SET ONE -->
                    <div id="skillsetone">
                        <v-divider class="mt-4 mb-6"></v-divider>
                        <h4>SKILLSET ONE</h4>
                        <p>New Driving Instructor demonstrates knowledge and understanding of the following, must be
                            able to
                            consistently cover all requirements for each topic.</p>
                        <v-divider class="mt-4 mb-6"></v-divider>

                        <!-- TRAINER`S INFORMATION -->
                        <h4>TRAINER`S INFORMATION</h4>
                        <v-row v-if="formData.trainer.length > 0" v-for="(item, index) in formData.trainer"
                            :key="index">
                            <v-col v-for="(value, key) in item" :key="key" cols="3" sm="6" md="3">
                                <v-text-field disabled :value="item[key]"
                                    @input="updateField(item, key, $event)"></v-text-field>
                            </v-col>
                        </v-row>
                        <!-- Trainer`s info -->
                        <v-row v-if="pageRoute === '/training-log'" v-show="formData.statusss1 === false">
                            <v-col cols="3" sm="6" md="3">
                                <v-text-field disabled v-model="formData.employee_number" label="Employee number">
                                </v-text-field>
                            </v-col>
                            <v-col cols="3" sm="6" md="3">
                                <v-text-field disabled v-model="formData.trainer_name" label="Trainer`s name">
                                </v-text-field>
                            </v-col>
                            <v-col cols="3" sm="6" md="3">
                                <v-text-field disabled v-model="formData.trainer_lastName" label="Trainer`s last name">
                                </v-text-field>
                            </v-col>
                            <v-col cols="3" sm="6" md="3">
                                <v-text-field disabled v-model="formData.dateTimeCreated" label="">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <!-- TRAINER`S INFORMATION -->
                        <v-divider class="mt-4 mb-6"></v-divider>
                        <!-- BEFORE LEAVING TO THE PARKING LOT, INSIDE
                        THE CAR -->
                        <div>
                            <v-divider class="mt-4 mb-6"></v-divider>
                            <h4>BEFORE LEAVING TO THE PARKING LOT, INSIDE
                                THE CAR</h4>
                            <v-container>
                                <v-row>
                                    <v-col cols="4">
                                    </v-col>
                                    <v-col v-for="(columnsName, columnIndex) in columnsSetOne"
                                        :key="`column_${columnIndex}`" cols="1.6">
                                        <span>{{ columnsName }}</span>
                                    </v-col>
                                </v-row>
                                <v-row>

                                    <v-col cols="12">
                                        <v-sheet max-width="100%" overflow-x="auto">

                                            <div class="scrollable-content">

                                                <v-row dense v-for="(rowName, rowIndex) in rowsSetOne"
                                                    :key="`row_${rowIndex}`" style="margin-top: 5px;">
                                                    <v-col cols="12" class="p-0">
                                                        <v-divider class="my-0"></v-divider>
                                                    </v-col>

                                                    <v-col cols="4">
                                                        <span>{{ rowName }}</span>
                                                    </v-col>

                                                    <v-col cols="8">
                                                        <v-row style="margin-top: -30px;">
                                                            <v-col
                                                                v-for="(answer, colIndex) in formData.skillSetOneAnswers[rowIndex]"
                                                                :key="`col_${colIndex}`" cols="1.6"
                                                                class="center-checkbox">
                                                                <v-checkbox v-model="answer.checked"
                                                                    @change="handleCheckboxChange(rowIndex, colIndex, 'skillSetOneAnswers', answer.checked)"
                                                                    :disabled="answer.initiallySelected || formData.status === true">
                                                                </v-checkbox>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </v-sheet>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </div>

                        <!-- ON THE WAY TO THE PARKING LOT -->
                        <div>
                            <v-divider class="mt-4 mb-6"></v-divider>
                            <h4>ON THE WAY TO THE PARKING LOT</h4>
                            <v-container>
                                <v-row>
                                    <v-col cols="4">
                                    </v-col>
                                    <v-col v-for="(columnsName, columnIndex) in columnsSetOne"
                                        :key="`column_${columnIndex}`" cols="1.6">
                                        <span>{{ columnsName }}</span>
                                    </v-col>
                                </v-row>
                                <v-row>

                                    <v-col cols="12">
                                        <v-sheet max-width="100%" overflow-x="auto">

                                            <div class="scrollable-content">

                                                <v-row dense v-for="(rowName, rowIndex) in rowsSetOne"
                                                    :key="`row_${rowIndex}`" style="margin-top: 5px;">
                                                    <v-col cols="12" class="p-0">
                                                        <v-divider class="my-0"></v-divider>
                                                    </v-col>

                                                    <v-col cols="4">
                                                        <span>{{ rowName }}</span>
                                                    </v-col>

                                                    <v-col cols="8">
                                                        <v-row style="margin-top: -30px;">
                                                            <v-col
                                                                v-for="(answer, colIndex) in formData.skillSetOneAnswers[rowIndex]"
                                                                :key="`col_${colIndex}`" cols="1.6"
                                                                class="center-checkbox">
                                                                <v-checkbox v-model="answer.checked"
                                                                    @change="handleCheckboxChange(rowIndex, colIndex, 'skillSetOneAnswers', answer.checked)"
                                                                    :disabled="answer.initiallySelected || formData.status === true">
                                                                </v-checkbox>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </v-sheet>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </div>

                        <!-- AT THE PARKING LOT, STUDENT AND DRIVING INSTRUCTOR OUTSIDE OF THE CAR -->
                        <div>
                            <v-divider class="mt-4 mb-6"></v-divider>
                            <h4>AT THE PARKING LOT, STUDENT AND DRIVING INSTRUCTOR OUTSIDE OF THE CAR</h4>
                            <v-container>
                                <v-row>
                                    <v-col cols="4">
                                    </v-col>
                                    <v-col v-for="(columnsName, columnIndex) in columnsSetOne"
                                        :key="`column_${columnIndex}`" cols="1.6">
                                        <span>{{ columnsName }}</span>
                                    </v-col>
                                </v-row>
                                <v-row>

                                    <v-col cols="12">
                                        <v-sheet max-width="100%" overflow-x="auto">

                                            <div class="scrollable-content">

                                                <v-row dense v-for="(rowName, rowIndex) in rowsSetOne_2"
                                                    :key="`row_${rowIndex}`" style="margin-top: 5px;">
                                                    <v-col cols="12" class="p-0">
                                                        <v-divider class="my-0"></v-divider>
                                                    </v-col>

                                                    <v-col cols="4">
                                                        <span>{{ rowName }}</span>
                                                    </v-col>

                                                    <v-col cols="8">
                                                        <v-row style="margin-top: -30px;">
                                                            <v-col
                                                                v-for="(answer, colIndex) in formData.skillSetOneAnswers_2[rowIndex]"
                                                                :key="`col_${colIndex}`" cols="1.6"
                                                                class="center-checkbox">
                                                                <v-checkbox v-model="answer.checked"
                                                                    @change="handleCheckboxChange(rowIndex, colIndex, 'skillSetOneAnswers_2', answer.checked)"
                                                                    :disabled="answer.initiallySelected || formData.status === true">
                                                                </v-checkbox>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </v-sheet>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </div>

                        <!-- AT THE PARKING LOT: DRIVING INSTRUCTOR OUTSIDE OF CAR, STUDENT IN DRIVER’S SEAT -->
                        <div>
                            <v-divider class="mt-4 mb-6"></v-divider>
                            <h4>AT THE PARKING LOT: DRIVING INSTRUCTOR OUTSIDE OF CAR, STUDENT IN DRIVER’S SEAT</h4>
                            <v-container>
                                <v-row>
                                    <v-col cols="4">
                                    </v-col>
                                    <v-col v-for="(columnsName, columnIndex) in columnsSetOne"
                                        :key="`column_${columnIndex}`" cols="1.6">
                                        <span>{{ columnsName }}</span>
                                    </v-col>
                                </v-row>
                                <v-row>

                                    <v-col cols="12">
                                        <v-sheet max-width="100%" overflow-x="auto">

                                            <div class="scrollable-content">

                                                <v-row dense v-for="(rowName, rowIndex) in rowsSetOne_2"
                                                    :key="`row_${rowIndex}`" style="margin-top: 5px;">
                                                    <v-col cols="12" class="p-0">
                                                        <v-divider class="my-0"></v-divider>
                                                    </v-col>

                                                    <v-col cols="4">
                                                        <span>{{ rowName }}</span>
                                                    </v-col>

                                                    <v-col cols="8">
                                                        <v-row style="margin-top: -30px;">
                                                            <v-col
                                                                v-for="(answer, colIndex) in formData.skillSetOneAnswers_2[rowIndex]"
                                                                :key="`col_${colIndex}`" cols="1.6"
                                                                class="center-checkbox">
                                                                <v-checkbox v-model="answer.checked"
                                                                    @change="handleCheckboxChange(rowIndex, colIndex, 'skillSetOneAnswers_2', answer.checked)"
                                                                    :disabled="answer.initiallySelected || formData.status === true">
                                                                </v-checkbox>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </v-sheet>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </div>

                        <!-- AT THE PARKING LOT: INSIDE THE CAR -->
                        <div>
                            <v-divider class="mt-4 mb-6"></v-divider>
                            <h4>AT THE PARKING LOT: INSIDE THE CAR</h4>
                            <v-container>
                                <v-row>
                                    <v-col cols="4">
                                    </v-col>
                                    <v-col v-for="(columnsName, columnIndex) in columnsSetOne"
                                        :key="`column_${columnIndex}`" cols="1.6">
                                        <span>{{ columnsName }}</span>
                                    </v-col>
                                </v-row>
                                <v-row>

                                    <v-col cols="12">
                                        <v-sheet max-width="100%" overflow-x="auto">

                                            <div class="scrollable-content">

                                                <v-row dense v-for="(rowName, rowIndex) in rowsSetOne_5"
                                                    :key="`row_${rowIndex}`" style="margin-top: 5px;">
                                                    <v-col cols="12" class="p-0">
                                                        <v-divider class="my-0"></v-divider>
                                                    </v-col>

                                                    <v-col cols="4">
                                                        <span>{{ rowName }}</span>
                                                    </v-col>

                                                    <v-col cols="8">
                                                        <v-row style="margin-top: -35px;">
                                                            <v-col
                                                                v-for="(answer, colIndex) in formData.skillSetOneAnswers_5[rowIndex]"
                                                                :key="`col_${colIndex}`" cols="1.6"
                                                                class="center-checkbox">
                                                                <v-checkbox v-model="answer.checked"
                                                                    @change="handleCheckboxChange(rowIndex, colIndex, 'skillSetOneAnswers_5', answer.checked)"
                                                                    :disabled="answer.initiallySelected || formData.status === true">
                                                                </v-checkbox>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </v-sheet>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </div>

                        <v-divider class="mt-4 mb-6"></v-divider>
                        <h4>SURVEY SKILLSET ONE</h4>
                        <v-divider class="mt-4 mb-6"></v-divider>
                        <v-row>
                            <v-col>
                                <h4>ATTENDANCE</h4>
                                <v-radio-group v-model="formData.attendanceOptionSS1"
                                    :disabled="formData.statusss1 === true">
                                    <v-radio label="1- Satisfactory" value="option1"></v-radio>
                                    <v-radio label="2 - Almost Satisfactory" value="option2"></v-radio>
                                    <v-radio label="3 - Did not Cover" value="option3"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col>
                                <h4>TRAINING PARTICIPATION:</h4>
                                <v-row>
                                    <v-col>
                                        <v-radio-group v-model="formData.trainingParticipationOptionSS1"
                                            :disabled="formData.statusss1 === true">
                                            <v-radio label="1- Satisfactory" value="option1"></v-radio>
                                            <v-radio label="2 - Almost Satisfactory" value="option2"></v-radio>
                                            <v-radio label="3 - Did not Cover" value="option3"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col>
                                <h4>KNOWLEDGE CHECK:</h4>
                                <v-radio-group v-model="formData.knowledgeOptionSS1"
                                    :disabled="formData.statusss1 === true">
                                    <v-radio label="1- Satisfactory" value="option1"></v-radio>
                                    <v-radio label="2 - Almost Satisfactory" value="option2"></v-radio>
                                    <v-radio label="3 - Did not Cover" value="option3"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-divider class="mt-4 mb-6"></v-divider>
                    </div>
                    <!-- SKILL SET TWO -->
                    <div id="skillsettwo">
                        <v-divider class="mt-4 mb-6"></v-divider>
                        <h4>SKILLSET TWO</h4>
                        <p>New Driving Instructor demonstrates knowledge and understanding of the following, must be
                            able to
                            consistently cover all requirements for each topic.</p>
                        <v-divider class="mt-4 mb-6"></v-divider>
                        <!-- TRAINER`S INFORMATION -->
                        <h4>TRAINER`S INFORMATION</h4>
                        <v-row v-if="formData.trainerSS2.length > 0" v-for="(item, index) in formData.trainerSS2"
                            :key="index">
                            <v-col v-for="(value, key) in item" :key="key" cols="3" sm="6" md="3">
                                <v-text-field disabled :value="item[key]"
                                    @input="updateField(item, key, $event)"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row v-if="pageRoute === '/training-log'" v-show="formData.statusss2 === false">
                            <v-col cols="3" sm="6" md="3">
                                <v-text-field disabled v-model="formData.employee_numberSS2" label="Employee number">
                                </v-text-field>
                            </v-col>
                            <v-col cols="3" sm="6" md="3">
                                <v-text-field disabled v-model="formData.trainer_nameSS2" label="Trainer`s name">
                                </v-text-field>
                            </v-col>
                            <v-col cols="3" sm="6" md="3">
                                <v-text-field disabled v-model="formData.trainer_lastNameSS2"
                                    label="Trainer`s last name">
                                </v-text-field>
                            </v-col>
                            <v-col cols="3" sm="6" md="3">
                                <v-text-field disabled v-model="formData.dateTimeCreated" label="">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <!-- TRAINER`S INFORMATION -->
                        <v-container>
                            <v-row>
                                <v-col cols="4">
                                </v-col>
                                <v-col v-for="(columnsName, columnIndex) in columnsSetOne"
                                    :key="`column_${columnIndex}`" cols="1.6">
                                    <span>{{ columnsName }}</span>
                                </v-col>
                            </v-row>
                            <v-row>

                                <v-col cols="12">
                                    <v-sheet max-width="100%" overflow-x="auto">

                                        <div class="scrollable-content">

                                            <v-row dense v-for="(rowName, rowIndex) in rowsSetTwo"
                                                :key="`row_${rowIndex}`" style="margin-top: 5px;">
                                                <v-col cols="12" class="p-0">
                                                    <v-divider class="my-0"></v-divider>
                                                </v-col>

                                                <v-col cols="4">
                                                    <span>{{ rowName }}</span>
                                                </v-col>

                                                <v-col cols="8">
                                                    <v-row style="margin-top: -35px;">
                                                        <v-col
                                                            v-for="(answer, colIndex) in formData.skillSetTwoAnswers[rowIndex]"
                                                            :key="`col_${colIndex}`" cols="1.6" class="center-checkbox">
                                                            <v-checkbox v-model="answer.checked"
                                                                @change="handleCheckboxChange(rowIndex, colIndex, 'skillSetTwoAnswers', answer.checked)"
                                                                :disabled="answer.initiallySelected || formData.status === true">
                                                            </v-checkbox>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-sheet>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-divider class="mt-4 mb-6"></v-divider>
                        <h4>SURVEY SKILLSET TWO</h4>
                        <v-divider class="mt-4 mb-6"></v-divider>
                        <!-- SURVEY SS2 -->
                        <v-row>
                            <v-col>
                                <h4>ATTENDANCE</h4>
                                <v-radio-group v-model="formData.attendanceOptionSS2"
                                    :disabled="formData.statusss2 === true">
                                    <v-radio label="1- Satisfactory" value="option1"></v-radio>
                                    <v-radio label="2 - Almost Satisfactory" value="option2"></v-radio>
                                    <v-radio label="3 - Did not Cover" value="option3"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col>
                                <h4>TRAINING PARTICIPATION:</h4>
                                <v-row>
                                    <v-col>
                                        <v-radio-group v-model="formData.trainingParticipationOptionSS2"
                                            :disabled="formData.statusss2 === true">
                                            <v-radio label="1- Satisfactory" value="option1"></v-radio>
                                            <v-radio label="2 - Almost Satisfactory" value="option2"></v-radio>
                                            <v-radio label="3 - Did not Cover" value="option3"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col>
                                <h4>KNOWLEDGE CHECK:</h4>
                                <v-radio-group v-model="formData.knowledgeOptionSS2"
                                    :disabled="formData.statusss2 === true">
                                    <v-radio label="1- Satisfactory" value="option1"></v-radio>
                                    <v-radio label="2 - Almost Satisfactory" value="option2"></v-radio>
                                    <v-radio label="3 - Did not Cover" value="option3"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-divider class="mt-4 mb-6"></v-divider>
                        <h2 class="bold-red-text centered-text">
                            YOU MUST NOT PUT A STUDENT ON THE ROAD UNTIL THEY CAN SUCCESFULLY MAKE MULTIPLE SAFE,
                            CONTROLLED, PRECISE RIGHT AND LEFT TURNS IN THE PARKING LOT WITHOUT ANY INSTRUCTION OR
                            ASSISTANCE. NO EXCEPTIONS.
                        </h2>
                        <v-divider class="mt-4 mb-6"></v-divider>
                    </div>
                    <!-- SKILL SET THREE -->
                    <div id="skillsetthree">
                        <v-divider class="mt-4 mb-6"></v-divider>
                        <h4>SKILLSET THREE</h4>
                        <p>New Driving Instructor demonstrates knowledge and understanding of the following, must be
                            able to
                            consistently cover all requirements for each topic.</p>
                        <v-divider class="mt-4 mb-6"></v-divider>
                        <h4>TRAINER`S INFORMATION</h4>
                        <v-row v-if="formData.trainerSS3.length > 0" v-for="(item, index) in formData.trainerSS3"
                            :key="index">
                            <v-col v-for="(value, key) in item" :key="key" cols="3" sm="6" md="3">
                                <v-text-field disabled :value="item[key]"
                                    @input="updateField(item, key, $event)"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row v-if="pageRoute === '/training-log'" v-show="formData.statusss3 === false">
                            <v-col cols="3" sm="6" md="3">
                                <v-text-field disabled v-model="formData.employee_numberSS3" label="Employee number">
                                </v-text-field>
                            </v-col>
                            <v-col cols="3" sm="6" md="3">
                                <v-text-field disabled v-model="formData.trainer_nameSS3" label="Trainer`s name">
                                </v-text-field>
                            </v-col>
                            <v-col cols="3" sm="6" md="3">
                                <v-text-field disabled v-model="formData.trainer_lastNameSS3"
                                    label="Trainer`s last name">
                                </v-text-field>
                            </v-col>
                            <v-col cols="3" sm="6" md="3">
                                <v-text-field disabled v-model="formData.dateTimeCreated" label="">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-container>
                            <v-row>
                                <v-col cols="4">
                                </v-col>
                                <v-col v-for="(columnsName, columnIndex) in columnsSetOne"
                                    :key="`column_${columnIndex}`" cols="1.6">
                                    <span>{{ columnsName }}</span>
                                </v-col>
                            </v-row>
                            <v-row>

                                <v-col cols="12">
                                    <v-sheet max-width="100%" overflow-x="auto">

                                        <div class="scrollable-content">

                                            <v-row dense v-for="(rowName, rowIndex) in rowsSetThree"
                                                :key="`row_${rowIndex}`" style="margin-top: 5px;">

                                                <v-col cols="12" class="p-0">
                                                    <v-divider class="my-0"></v-divider>
                                                </v-col>
                                                <v-col cols="4">
                                                    <span>{{ rowName }}</span>
                                                </v-col>

                                                <v-col cols="8">
                                                    <v-row style="margin-top: -35px;">
                                                        <v-col
                                                            v-for="(answer, colIndex) in formData.skillSetThreeAnswers[rowIndex]"
                                                            :key="`col_${colIndex}`" cols="1.6" class="center-checkbox">
                                                            <v-checkbox v-model="answer.checked"
                                                                @change="handleCheckboxChange(rowIndex, colIndex, 'skillSetThreeAnswers', answer.checked)"
                                                                :disabled="answer.initiallySelected || formData.status === true">
                                                            </v-checkbox>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>

                                            </v-row>
                                        </div>
                                    </v-sheet>
                                </v-col>
                            </v-row>
                        </v-container>

                        <v-divider class="mt-4 mb-6"></v-divider>
                        <h4>SURVEY SKILLSET THREE</h4>
                        <v-divider class="mt-4 mb-6"></v-divider>
                        <!-- SURVEY SS3 -->
                        <v-row>
                            <v-col>
                                <h4>ATTENDANCE</h4>
                                <v-radio-group v-model="formData.attendanceOptionSS3"
                                    :disabled="formData.statusss3 === true">
                                    <v-radio label="1- Satisfactory" value="option1"></v-radio>
                                    <v-radio label="2 - Almost Satisfactory" value="option2"></v-radio>
                                    <v-radio label="3 - Did not Cover" value="option3"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col>
                                <h4>TRAINING PARTICIPATION:</h4>
                                <v-row>
                                    <v-col>
                                        <v-radio-group v-model="formData.trainingParticipationOptionSS3"
                                            :disabled="formData.statusss3 === true">
                                            <v-radio label="1- Satisfactory" value="option1"></v-radio>
                                            <v-radio label="2 - Almost Satisfactory" value="option2"></v-radio>
                                            <v-radio label="3 - Did not Cover" value="option3"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col>
                                <h4>KNOWLEDGE CHECK:</h4>
                                <v-radio-group v-model="formData.knowledgeOptionSS3"
                                    :disabled="formData.statusss3 === true">
                                    <v-radio label="1- Satisfactory" value="option1"></v-radio>
                                    <v-radio label="2 - Almost Satisfactory" value="option2"></v-radio>
                                    <v-radio label="3 - Did not Cover" value="option3"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-divider class="mt-4 mb-6"></v-divider>
                    </div>
                    <!-- SKILL SET FOUR -->
                    <div id="skillsetfour">
                        <v-divider class="mt-4 mb-6"></v-divider>
                        <h4>SKILLSET FOUR</h4>
                        <p>New Driving Instructor demonstrates knowledge and understanding of the following, must be
                            able to
                            consistently cover all requirements for each topic.</p>
                        <v-divider class="mt-4 mb-6"></v-divider>
                        <h4>TRAINER`S INFORMATION</h4>
                        <v-row v-if="formData.trainerSS4.length > 0" v-for="(item, index) in formData.trainerSS4"
                            :key="index">
                            <v-col v-for="(value, key) in item" :key="key" cols="3" sm="6" md="3">
                                <v-text-field disabled :value="item[key]"
                                    @input="updateField(item, key, $event)"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row v-if="pageRoute === '/training-log'" v-show="formData.statusss4 === false">
                            <v-col cols="3" sm="6" md="3">
                                <v-text-field disabled v-model="formData.employee_numberSS4" label="Employee number">
                                </v-text-field>
                            </v-col>
                            <v-col cols="3" sm="6" md="3">
                                <v-text-field disabled v-model="formData.trainer_nameSS4" label="Trainer`s name">
                                </v-text-field>
                            </v-col>
                            <v-col cols="3" sm="6" md="3">
                                <v-text-field disabled v-model="formData.trainer_lastNameSS4"
                                    label="Trainer`s last name">
                                </v-text-field>
                            </v-col>
                            <v-col cols="3" sm="6" md="3">
                                <v-text-field disabled v-model="formData.dateTimeCreated" label="">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <!-- BOXES SS4 -->
                        <v-container>
                            <v-row>
                                <v-col cols="4">
                                </v-col>
                                <v-col v-for="(columnsName, columnIndex) in columnsSetOne"
                                    :key="`column_${columnIndex}`" cols="1.6">
                                    <!-- Columna para el nombre de la fila -->
                                    <span>{{ columnsName }}</span>
                                </v-col>
                            </v-row>
                            <v-row>

                                <v-col cols="12">
                                    <v-sheet max-width="100%" overflow-x="auto">

                                        <div class="scrollable-content">

                                            <v-row dense v-for="(rowName, rowIndex) in rowsSetFour"
                                                :key="`row_${rowIndex}`" style="margin-top: 5px;">

                                                <v-col cols="12" class="p-0">
                                                    <v-divider class="my-0"></v-divider>
                                                </v-col>
                                                <v-col cols="4">
                                                    <span>{{ rowName }}</span>
                                                </v-col>

                                                <v-col cols="8">
                                                    <v-row style="margin-top: -35px;">
                                                        <v-col
                                                            v-for="(answer, colIndex) in formData.skillSetFourAnswers[rowIndex]"
                                                            :key="`col_${colIndex}`" cols="1.6" class="center-checkbox">
                                                            <v-checkbox v-model="answer.checked"
                                                                @change="handleCheckboxChange(rowIndex, colIndex, 'skillSetFourAnswers', answer.checked)"
                                                                :disabled="answer.initiallySelected || formData.status === true">
                                                            </v-checkbox>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>

                                            </v-row>
                                        </div>
                                    </v-sheet>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-divider class="mt-4 mb-6"></v-divider>
                        <h4>SURVEY SKILLSET FOUR</h4>
                        <v-divider class="mt-4 mb-6"></v-divider>
                        <!-- SURVEY SS4 -->
                        <v-row>
                            <v-col>
                                <h4>ATTENDANCE</h4>
                                <v-radio-group v-model="formData.attendanceOptionSS4"
                                    :disabled="formData.statusss4 === true">
                                    <v-radio label="1- Satisfactory" value="option1"></v-radio>
                                    <v-radio label="2 - Almost Satisfactory" value="option2"></v-radio>
                                    <v-radio label="3 - Did not Cover" value="option3"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col>
                                <h4>TRAINING PARTICIPATION:</h4>
                                <v-row>
                                    <v-col>
                                        <v-radio-group v-model="formData.trainingParticipationOptionSS4"
                                            :disabled="formData.statusss4 === true">
                                            <v-radio label="1- Satisfactory" value="option1"></v-radio>
                                            <v-radio label="2 - Almost Satisfactory" value="option2"></v-radio>
                                            <v-radio label="3 - Did not Cover" value="option3"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col>
                                <h4>KNOWLEDGE CHECK:</h4>
                                <v-radio-group v-model="formData.knowledgeOptionSS4"
                                    :disabled="formData.statusss4 === true">
                                    <v-radio label="1- Satisfactory" value="option1"></v-radio>
                                    <v-radio label="2 - Almost Satisfactory" value="option2"></v-radio>
                                    <v-radio label="3 - Did not Cover" value="option3"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-divider class="mt-4 mb-6"></v-divider>
                    </div>
                    <!-- SKILL SET MANEUVERS -->
                    <div id="maneuvers">
                        <v-divider class="mt-4 mb-6"></v-divider>
                        <h4>ADVANCED MANEUVERS</h4>
                        <p>New Driving Instructor demonstrates knowledge and understanding of the following, must be
                            able to
                            consistently cover all requirements for each topic.</p>
                        <v-divider class="mt-4 mb-6"></v-divider>
                        <h4>TRAINER`S INFORMATION</h4>
                        <v-row v-if="formData.trainerM.length > 0" v-for="(item, index) in formData.trainerM"
                            :key="index">
                            <v-col v-for="(value, key) in item" :key="key" cols="3" sm="6" md="3">
                                <v-text-field disabled :value="item[key]"
                                    @input="updateField(item, key, $event)"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row v-if="pageRoute === '/training-log'" v-show="formData.statusm === false">
                            <v-col cols="3" sm="6" md="3">
                                <v-text-field disabled v-model="formData.employee_numberM" label="Employee number">
                                </v-text-field>
                            </v-col>
                            <v-col cols="3" sm="6" md="3">
                                <v-text-field disabled v-model="formData.trainer_nameM" label="Trainer`s name">
                                </v-text-field>
                            </v-col>
                            <v-col cols="3" sm="6" md="3">
                                <v-text-field disabled v-model="formData.trainer_lastNameM" label="Trainer`s last name">
                                </v-text-field>
                            </v-col>
                            <v-col cols="3" sm="6" md="3">
                                <v-text-field disabled v-model="formData.dateTimeCreated" label="">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <!-- BOXES SS4 -->
                        <v-container>
                            <v-row>
                                <v-col cols="4">
                                </v-col>
                                <v-col v-for="(columnsName, columnIndex) in columnsSetOne"
                                    :key="`column_${columnIndex}`" cols="1.6">
                                    <!-- Columna para el nombre de la fila -->
                                    <span>{{ columnsName }}</span>
                                </v-col>
                            </v-row>
                            <v-row>

                                <v-col cols="12">
                                    <v-sheet max-width="100%" overflow-x="auto">

                                        <div class="scrollable-content">

                                            <v-row dense v-for="(rowName, rowIndex) in rowsMeneuvers"
                                                :key="`row_${rowIndex}`" style="margin-top: 5px;">

                                                <v-col cols="12" class="p-0">
                                                    <v-divider class="my-0"></v-divider>
                                                </v-col>
                                                <v-col cols="4">
                                                    <span>{{ rowName }}</span>
                                                </v-col>

                                                <v-col cols="8">
                                                    <v-row style="margin-top: -35px;">
                                                        <v-col
                                                            v-for="(answer, colIndex) in formData.maneuversAnswers[rowIndex]"
                                                            :key="`col_${colIndex}`" cols="1.6" class="center-checkbox">
                                                            <v-checkbox v-model="answer.checked"
                                                                @change="handleCheckboxChange(rowIndex, colIndex, 'maneuversAnswers', answer.checked)"
                                                                :disabled="answer.initiallySelected || formData.status === true">
                                                            </v-checkbox>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>

                                            </v-row>
                                        </div>
                                    </v-sheet>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-divider class="mt-4 mb-6"></v-divider>
                        <h4>SURVEY SKILLSET ADVANCED MANEUVERS</h4>
                        <v-divider class="mt-4 mb-6"></v-divider>
                        <!-- SURVEY M -->
                        <v-row>
                            <v-col>
                                <h4>ATTENDANCE</h4>
                                <v-radio-group v-model="formData.attendanceOptionM"
                                    :disabled="formData.statusm === true">
                                    <v-radio label="1- Satisfactory" value="option1"></v-radio>
                                    <v-radio label="2 - Almost Satisfactory" value="option2"></v-radio>
                                    <v-radio label="3 - Did not Cover" value="option3"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col>
                                <h4>TRAINING PARTICIPATION:</h4>
                                <v-row>
                                    <v-col>
                                        <v-radio-group v-model="formData.trainingParticipationOptionM"
                                            :disabled="formData.statusm === true">
                                            <v-radio label="1- Satisfactory" value="option1"></v-radio>
                                            <v-radio label="2 - Almost Satisfactory" value="option2"></v-radio>
                                            <v-radio label="3 - Did not Cover" value="option3"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col>
                                <h4>KNOWLEDGE CHECK:</h4>
                                <v-radio-group v-model="formData.knowledgeOptionM"
                                    :disabled="formData.statusm === true">
                                    <v-radio label="1- Satisfactory" value="option1"></v-radio>
                                    <v-radio label="2 - Almost Satisfactory" value="option2"></v-radio>
                                    <v-radio label="3 - Did not Cover" value="option3"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-divider class="mt-4 mb-6"></v-divider>
                    </div>
                </v-card-text>
            </v-card>
        </v-container>

    </div>
</template>


<script>
import { bus } from '../../main.js';
import { get_employee_number } from '../../utils/get_employee_number.js'
import { GET_EMPLOYEE_BY_EMPLOYEE_NUMBER, ADD_TRAINING_LOG, ADD_TRAINING_LOG_EMPLOYEE, GET_TRAINING_LOG_BY_EMPLOYEE_NUMBER, UPDATE_TRAINING_LOG } from '../../utils/endpoints';
import html2pdf from 'html2pdf.js';
import FileModal from '../../components/Modals/FileModal.vue'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { PDFDocument, arrayUnion } from 'pdf-lib';


export default {
    components: {
        FileModal,
    },
    props: {
        itemTrainingLog: Object,
        itemEditedIndex: Number
    },
    data() {
        return {
            // Tabs
            tab: null,
            skillTabs: ['SKILL SET ONE', 'SKILL SET TWO', 'SKILL SET THREE', 'SKILL SET FOUR', 'ADVANCED MANEUVERS'],
            // Modal
            modalOpen: false,
            modalTitle: '',
            fileUrl: '',
            formStatus: false,
            // PDF
            createPdf: false,
            //////
            pageRoute: window.location.pathname,
            panel: [],
            panel2: [],
            //disabled: false,
            //readonly: false,
            editedIndex: -1,
            watchedValue: '',
            watcherIsActive: true,
            objDataForm: {},
            columnsSetOne: [
                'Learning Nova`s Standards.',
                'Be the Student.',
                'Be the Instructor.',
                'Observation with Actual Student.',
                'Being Observed teaching an actual student'
            ],
            rowsSetOne: [
                'Show how to move the foot from brake to gas.',
                'Explain the fundamental rule to all of driving: to look far at the center of your intended path of travel and use your peripheral view to keep track of the edges of your path.',
                'Explain pedestrians always have the right of way except on a green traffic light.',
                'Explain the right of way rules for traffic.',
                'Locations to stop the car: stop line, crosswalk, intersection, behind another vehicle should see the rear tires meet the road.',
                'What is a full stop'
            ],
            rowsSetOne_2: [
                'Explain to watch how instructor drives (Instructor must drive according to Nova standards).',
                'Explain stop signs and traffic signals, how to brake gently and how to start moving gradually.',
                'Explain where you are looking and how to keep the car straight using your peripheral view (you can make a lane change to show how where you look is where you go).',
                'Student listens to rising tone of the engine when Driving Instructor accelerates from a stop sign or red light so they can recognize the sound of acceleration when doing the RPM exercise and turns',
                'Explain how to use the three second following distance rule.',
                'Explain how to coordinate eyes, hands and feet.',
                'Student watches hand-over-hand steering and instructor explains how to turn the steering wheel (when, how much, and what speed to turn the steering wheel). Explain how do you let the steering wheel sliding through your fingers while you gently accelerate.'
            ],
            rowsSetOne_3: [
                'Width of the car (hugging the back of Nova´s car).',
                'Explain all lights on the rear of the car (see above; also include brakes and reverse).',
                'Explain all lights on the front of the car (color/shape/meaning/use/light-lever position).',
                'Length of the car (tire location, mirror location, driver seat location)',
                'Explain the location of seat adjustments before entering the car.'
            ],
            rowsSetOne_4: [
                'Adjust: seat (up/down, back/forth); steering wheel, seat-back angle, headrest, seatbelt (student does ALL of this on their own while Driving Instructor explains how).',
                'Explain the door panel controls and the adjustments.',
                'BLIND SPOTS (where to stand and move to explain how to check/why to check).'
            ],
            rowsSetOne_5: [
                'Once in the parking lot, show the route the student will follow. Give reference points where the center point is. Also how to use your hands and foot while explaining the route.',
                'Driver’s-side; turn signal lever and head light switch; all dashboard gauges, indicators, and warning lights; wipers lever; hazard-light button; climate control (including center buttons) & vents.',
                'Explain ignition, gearshift lever (explain full use of Neutral), parking brake.',
                'Explain the correct adjustment for the inside rearview mirror, including what the lever underneath the mirror is for.',
                'Explain the correct adjustments for the outside mirrors.',
                'Brake positions (5 4 3 2 1 0-but not to the floor)',
                'Hold RPM under 2 (as many times as necessary: look at gauge and listen for tone; with eyes open first and then, eyes closed; ears only),',
                'Drive across parking lot at 5MPH / 10MPH by using a visual reference point straight ahead.',
                'Instructor must back the car up, not the student'
            ],

            rowsSetTwo: [
                'HANDS AT 9 AND 3.',
                'Explain reference/turning point (the center of your intended path of travel).',
                'Explain the three basic Hand over hand turns, but practice only the 3 quarter turn.',
                'Wide Turns: forward/backward/left & right. Makes Y shape. Explain trajectory. Replace hands.',
                'Regular Turns: forward/backward/left & right. Makes T shape. Explain trajectory. Replace hands.',
                'Sharp Turns: forward/backward/left & right. Makes U shape. Explain trajectory. Replace hands.',
                'RIGHT TURN EXERCISES',
                'LEFT TURN EXERCISES',
                'Repeat all four exercises above, this time to the left. Continue until satisfactory.'
            ],
            rowsSetThree: [
                'At the beginning of any lesson check to make sure the student makes all the adjustments to de seat first, then mirrors before moving the car.',
                'Pull away from the curb (can explain each step in order).',
                'Center in lane (describe how to teach).',
                'Stop sign/Traffic lights (legal stop, “check left, check front, check right, check left again”, use pedestrian countdown to gauge yellow light approach speed).',
                'Turning on red.',
                'Pedestrian right-of-way.',
                'Perception/identification of intersection information (signs, right-of-way, traffic).',
                'Perception of the shape of a turn/trajectory/how the student is turning.',
                'Speed bumps, Pot holes, and Speed humps.',
                'Bike lane awareness / Bicycle green boxes.',
                'Right-of-way rules at all-way stops',
                'Right turns on small streets.',
                'Stopping behind another vehicle.',
                'Maintain a steady speed through a turn/perception of speed.',
                'Following distance/3-second rule',
                'Scan pattern (visual sweep two blocks ahead/look for potential problems on current block).',
                'One-second rule (glances at mirrors/dashboard during scan pattern).',
                'Left turns on small streets.',
                'Pull up to the curb & park “uphill” or “downhill”.',
                'Rules of the road (Unprotected left turn, rigth of way, etc.).'
            ],
            rowsSetFour: [
                'Smith System',
                'SIPDE Process',
                'Lane changing: student must describe all steps in order before attempting. Practice in no traffic first.',
                'When turning left move 1/3 into the intersection before turning',
                'Protected left (explain the rules and the order of the lights).',
                'Turns at major intersections (timing, speed, trajectory).',
                'Turn into the closest lane (explain traffic patterns).',
                'Reverse Straight Maneuver.',
                'Parking lot parking (angled & perpendicular).',
                '3 Types of Turnabouts using driveway to the left, backing around the corner, three-point turn.',
                'Wide & Sharp turns.',
                'If it´s the last lesson before the Road Test prep lesson, first make sure the student is prepared for the actual Road Test before allowing them to move on to Road Test prep.'
            ],
            rowsMeneuvers: [
                'Before teaching any advanced maneuver, be absolutely sure that the student is perfectly capable of passing the Road Test.',
                'Parallel parking to the right.',
                'Parallel parking to the left.',
                'Express-way Driving (Entering, exiting and changing lanes. Give detailed step-by-step instructions).',
                'Pump gas if possible (student may not put the nozzle in the fuel line, but should do everything else).',
                'Check tire pressure if possible (gauge, what should it be, where is that info, how to inflate, etc.)',
                'Go over necessary supplies/what everyone should have in a car and why.',
                'Under the hood: battery, fluid receptacles/lines.',
                'Check oil (when car is off and cool): how to read, how to refill, check windshield washer fluid.',
                'Rules of the road (Unprotected left turn, right of way, etc.).',
                'Pictures of Coolant and oil levels must be sent to Fleet weekly. And weekly carwashes must be made.',
                'Winter driving if applicable.'
            ],
            rowsBeginningFirstLesson: [
                'Use our Parking Lot Map to find the nearest parking lot before lesson/plug into GPS.',
                'Introduce yourself.',
                'Ask the student if the name on the permit is the name they prefer to be called.',
                'Explain 5-10 minute eval will take place at end of each lesson.',
                'Ask if student has any driving experience (when, where and how much time).'
            ],
            rowsBeginningEveryLesson: [
                'Check permit/photo ID (must be original permit –not photocopy, not picture-, not expired, correct student name, check if needs eyewear as restriction B).',
                'Correct shoes (no open heel, no platform/high heel, no flipflops, no slip-on`s).',
                'Have student turn off cell phone. Instructor must keep their cellphone on a holder, not in their hands.'
            ],
            rowsEndEveryLesson: [
                'Skills covered, and what portions of the skills were covered.',
                'Describe what were the student deficiencies.',
                'Clear, detailed notes describing what needs improvement and explain why.',
                'What needs to be covered on the next lesson.',
                'Suggest upgrade/more lessons if necessary and explain why.',
                'Can dictate notes with voice rather than typing them out.',
                'If it´s the last lesson before the Road Test prep lesson, first make sure the student is prepared for the actual Road Test before allowing them to move on to Road Test prep.',
                'Submit student signature.',
                'Submit instructor signature.',
                'Submit lesson/make sure evaluation is marked “Complete”.',
                'Reviews: must receive minimum one review per week. Text the link after the lesson.'
            ],
            formData: {
                dateTimeCreated: '',
                uuid: '',
                status: false,
                trainee_name: '',
                trainee_lastName: '',
                skillsetOne: false,
                skillsetTwo: false,
                skillsetThree: false,
                skillsetFour: false,
                maneuvers: false,
                // Skill Set 1
                statusss1: false,
                trainer_name: '',
                trainer_lastName: '',
                employee_number: '',
                trainer: [],
                // Skill Set 2
                statusss2: false,
                trainer_nameSS2: '',
                trainer_lastNameSS2: '',
                employee_numberSS2: '',
                trainerSS2: [],
                // Skill Set 3
                statusss3: false,
                trainer_nameSS3: '',
                trainer_lastNameSS3: '',
                employee_numberSS3: '',
                trainerSS3: [],
                // Skill Set 4
                statusss4: false,
                trainer_nameSS4: '',
                trainer_lastNameSS4: '',
                employee_numberSS4: '',
                trainerSS4: [],
                // Maneuvers
                statusm: false,
                trainer_nameM: '',
                trainer_lastNameM: '',
                employee_numberM: '',
                trainerM: [],

                skillSetOneAnswers: [
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }]
                ],
                skillSetOneAnswers_2: [
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }]
                ],
                skillSetOneAnswers_3: [
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }]
                ],
                skillSetOneAnswers_4: [
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }]
                ],
                skillSetOneAnswers_5: [
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }]
                ],
                skillSetTwoAnswers: [
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }]
                ],
                skillSetThreeAnswers: [
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }]
                ],
                skillSetFourAnswers: [
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }]
                ],
                maneuversAnswers: [
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }]
                ],
                beginningFirstLessonAnswers: [
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }]
                ],
                beginningEveryLessonAnswers: [
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }]
                ],
                endEveryLessonAnswers: [
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }]
                ],

                attendanceOptionSS1: '',
                trainingParticipationOptionSS1: '',
                knowledgeOptionSS1: '',

                attendanceOptionSS2: '',
                trainingParticipationOptionSS2: '',
                knowledgeOptionSS2: '',

                attendanceOptionSS3: '',
                trainingParticipationOptionSS3: '',
                knowledgeOptionSS3: '',

                attendanceOptionSS4: '',
                trainingParticipationOptionSS4: '',
                knowledgeOptionSS4: '',

                attendanceOptionM: '',
                trainingParticipationOptionM: '',
                knowledgeOptionM: '',

                currentDateTime: null,
                trainer_email: '',
            },

            formDataSave: {
                dateTimeCreated: '',
                uuid: '',
                trainee_name: '',
                trainee_lastName: '',
                skillsetOne: false,
                skillsetTwo: false,
                skillsetThree: false,
                skillsetFour: false,
                maneuvers: false,
                // Skill Set 1
                trainer_name: '',
                trainer_lastName: '',
                employee_number: '',
                trainer: [],
                // Skill Set 2
                statusss2: false,
                trainer_nameSS2: '',
                trainer_lastNameSS2: '',
                employee_numberSS2: '',
                trainerSS2: [],
                // Skill Set 3
                statusss3: false,
                trainer_nameSS3: '',
                trainer_lastNameSS3: '',
                employee_numberSS3: '',
                trainerSS3: [],
                // Skill Set 4
                statusss4: false,
                trainer_nameSS4: '',
                trainer_lastNameSS4: '',
                employee_numberSS4: '',
                trainerSS4: [],
                // Maneuvers
                statusm: false,
                trainer_nameM: '',
                trainer_lastNameM: '',
                employee_numberM: '',
                trainerM: [],
                skillSetOneAnswers: [
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }]
                ],
                skillSetOneAnswers_2: [
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }]
                ],
                skillSetOneAnswers_3: [
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }]
                ],
                skillSetOneAnswers_4: [
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }]
                ],
                skillSetOneAnswers_5: [
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }]
                ],
                skillSetTwoAnswers: [
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }]
                ],
                skillSetThreeAnswers: [
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }]
                ],
                skillSetFourAnswers: [
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }]
                ],
                maneuversAnswers: [
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }]
                ],
                beginningFirstLessonAnswers: [
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }]
                ],
                beginningEveryLessonAnswers: [
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }]
                ],
                endEveryLessonAnswers: [
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }]
                ],
                attendanceOptionSS1: '',
                trainingParticipationOptionSS1: '',
                knowledgeOptionSS1: '',

                attendanceOptionSS2: '',
                trainingParticipationOptionSS2: '',
                knowledgeOptionSS2: '',

                attendanceOptionSS3: '',
                trainingParticipationOptionSS3: '',
                knowledgeOptionSS3: '',

                attendanceOptionSS4: '',
                trainingParticipationOptionSS4: '',
                knowledgeOptionSS4: '',

                attendanceOptionM: '',
                trainingParticipationOptionM: '',
                knowledgeOptionM: '',

                currentDateTime: null,
                trainer_email: '',
            },
            formDataDefault: {
                uuid: '',
                trainee_name: '',
                trainee_lastName: '',
                skillsetOne: false,
                skillsetTwo: false,
                skillsetThree: false,
                skillsetFour: false,
                maneuvers: false,
                // Skill Set 1
                trainer_name: '',
                trainer_lastName: '',
                employee_number: '',
                trainer: [],
                // Skill Set 2
                statusss2: false,
                trainer_nameSS2: '',
                trainer_lastNameSS2: '',
                employee_numberSS2: '',
                trainerSS2: [],
                // Skill Set 3
                statusss3: false,
                trainer_nameSS3: '',
                trainer_lastNameSS3: '',
                employee_numberSS3: '',
                trainerSS3: [],
                // Skill Set 4
                statusss4: false,
                trainer_nameSS4: '',
                trainer_lastNameSS4: '',
                employee_numberSS4: '',
                trainerSS4: [],
                // Maneuvers
                statusm: false,
                trainer_nameM: '',
                trainer_lastNameM: '',
                employee_numberM: '',
                trainerM: [],
                skillSetOneAnswers: [
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }]
                ],
                skillSetOneAnswers_2: [
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }]
                ],
                skillSetOneAnswers_3: [
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }]
                ],
                skillSetOneAnswers_4: [
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }]
                ],
                skillSetOneAnswers_5: [
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }]
                ],
                skillSetTwoAnswers: [
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }]
                ],
                skillSetThreeAnswers: [
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }]
                ],
                skillSetFourAnswers: [
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }]
                ],
                maneuversAnswers: [
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }, { checked: false, initiallySelected: false }]
                ],
                beginningFirstLessonAnswers: [
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }]
                ],
                beginningEveryLessonAnswers: [
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }]
                ],
                endEveryLessonAnswers: [
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }],
                    [{ checked: false, initiallySelected: false }]
                ],
                attendanceOptionSS1: '',
                trainingParticipationOptionSS1: '',
                knowledgeOptionSS1: '',

                attendanceOptionSS2: '',
                trainingParticipationOptionSS2: '',
                knowledgeOptionSS2: '',

                attendanceOptionSS3: '',
                trainingParticipationOptionSS3: '',
                knowledgeOptionSS3: '',

                attendanceOptionSS4: '',
                trainingParticipationOptionSS4: '',
                knowledgeOptionSS4: '',

                attendanceOptionM: '',
                trainingParticipationOptionM: '',
                knowledgeOptionM: '',

                currentDateTime: null,
                trainer_email: '',
            }
        }
    },
    mounted() {
        console.log("------------------TrainingLog Form MOUNTED------------------");
        bus.$emit('login-success');
        bus.$emit('permissions');

        if (this.pageRoute === '/training-log') {
            this.get_EmployeeData();
        } else {
            //console.log("this.itemTrainingLog: ", this.itemTrainingLog);
            this.editedIndex = this.itemEditedIndex;
            this.fillFormData(this.itemTrainingLog);
        }
        console.log("formData: ", this.formData);
        console.log("------------------/MOUNTED------------------");
    },
    methods: {
        async openModalFile(idSkillSet) {
            //console.log("Opening modal.....");
            //console.log("idSkillSet: ", idSkillSet);
            try {
                if (idSkillSet === '#traininglog') {
                    this.fileUrl = await this.generateCompletePDF(idSkillSet);
                    //console.log("this.fileUrl: ", this.fileUrl);
                    this.modalTitle = "Training log"
                    this.modalOpen = true; // Abrir el modal
                } else {
                    let pdfs = [];
                    let pdfUrl = null;
                    const ids = ['#headform', idSkillSet];
                    for (const id of ids) {
                        if (id === '#headform') {
                            pdfUrl = await this.generatePDFHeadForm(id);
                        } else if (id === '#skillsetone') {
                            pdfUrl = await this.generatePDFSkillsetOne(id);
                        } else {
                            pdfUrl = await this.generatePDF(id);
                        }
                        pdfs.push(pdfUrl);
                    }
                    //console.log("pdfs: ", pdfs);
                    const mergedPdfBlob = await this.mergePDFs(pdfs);
                    //console.log("mergedPdfBlob: ", mergedPdfBlob);
                    this.fileUrl = URL.createObjectURL(mergedPdfBlob);
                    //console.log("this.fileUrl: ", this.fileUrl);
                    this.modalTitle = "Training log";
                    this.modalOpen = true;
                }
            } catch (error) {
                console.error("Error opening modal: ", error);
            }
        },
        async mergePDFs(pdfs) {
            try {
                const mergedPdf = await PDFDocument.create();

                for (const pdfBlob of pdfs) {
                    const pdfBytes = await pdfBlob.arrayBuffer();
                    const pdfDoc = await PDFDocument.load(pdfBytes);
                    const copiedPages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
                    copiedPages.forEach((page) => {
                        mergedPdf.addPage(page);
                    });
                }

                const mergedPdfBytes = await mergedPdf.save();
                return new Blob([mergedPdfBytes], { type: 'application/pdf' });
            } catch (error) {
                console.error("Error merging PDFs: ", error);
                throw error; // Re-lanza el error para que pueda ser manejado por quien llame a esta función
            }
        },
        generatePDFSkillsetOne(idSkillSet) {
            return new Promise(async (resolve, reject) => {
                try {
                    this.createPdf = true;
                    requestAnimationFrame(async () => {
                        const element = document.querySelector(idSkillSet);
                        //const { offsetWidth: contentWidth, offsetHeight: contentHeight } = element;
                        //console.log("element: ", element);
                        const opt = {
                            margin: 30,
                            filename: 'training_log.pdf',
                            image: { type: 'jpeg', quality: 0.98 },
                            html2canvas: { scale: 2 },
                            jsPDF: { unit: 'px', format: [1000, 4901], orientation: 'portrait' }
                            //jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
                        };

                        // Ajustar el alto del documento jsPDF según el contenido dinámico
                        //opt.jsPDF.height = contentHeight / 72; // Convertir a pulgadas (1 pulgada = 72 puntos)

                        this.createPdf = false;
                        const pdfBlob = await html2pdf().from(element).set(opt).outputPdf('blob');
                        resolve(pdfBlob);
                    });
                } catch (error) {
                    console.error("Error generating PDF: ", error);
                    this.createPdf = false;
                    reject(error);
                }
            });
        },
        generatePDFHeadForm(idSkillSet) {
            return new Promise(async (resolve, reject) => {
                try {
                    this.createPdf = true;
                    requestAnimationFrame(async () => {
                        const element = document.querySelector(idSkillSet);
                        //const { offsetWidth: contentWidth, offsetHeight: contentHeight } = element;
                        //console.log("element: ", element);
                        const opt = {
                            margin: 30,
                            filename: 'training_log.pdf',
                            image: { type: 'jpeg', quality: 0.98 },
                            html2canvas: { scale: 2 },
                            jsPDF: { unit: 'px', format: [1000, 1600], orientation: 'portrait' }
                            //jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
                        };
                        // Ajustar el alto del documento jsPDF según el contenido dinámico
                        //opt.jsPDF.height = contentHeight / 72; // Convertir a pulgadas (1 pulgada = 72 puntos)

                        this.createPdf = false;
                        const pdfBlob = await html2pdf().from(element).set(opt).outputPdf('blob');
                        resolve(pdfBlob);
                    });
                } catch (error) {
                    console.error("Error generating PDF: ", error);
                    this.createPdf = false;
                    reject(error);
                }
            });
        },
        generatePDF(idSkillSet) {
            return new Promise(async (resolve, reject) => {
                try {
                    this.createPdf = true;
                    requestAnimationFrame(async () => {
                        const element = document.querySelector(idSkillSet);
                        //console.log("element: ", element);
                        const opt = {
                            margin: 30,
                            filename: 'training_log.pdf',
                            image: { type: 'jpeg', quality: 0.98 },
                            html2canvas: { scale: 2 },
                            //jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
                            jsPDF: { unit: 'px', format: [1000, 2000], orientation: 'portrait' }
                        };
                        // Configurar márgenes al crear el documento jsPDF
                        const { unit, format, orientation } = opt.jsPDF;
                        const marginLeft = 72 * 1; // 1 pulgada de margen izquierdo
                        const marginTop = 72 * 1; // 1 pulgada de margen superior

                        const doc = new jsPDF({
                            orientation,
                            unit,
                            format,
                            marginLeft,
                            marginTop
                        });

                        this.createPdf = false;
                        const pdfBlob = await html2pdf().from(element).set(opt).outputPdf('blob');
                        resolve(pdfBlob);
                    });
                } catch (error) {
                    console.error("Error generating PDF: ", error);
                    this.createPdf = false;
                    reject(error);
                }
            });
        },
        async generateCompletePDF(idSkillSet) {
            //console.log("idSkillSet: ", idSkillSet);
            try {
                this.createPdf = true;
                const pdfUrl = await new Promise((resolve, reject) => {
                    requestAnimationFrame(async () => {
                        try {
                            //console.log("Training log PDF.........");
                            //const element = document.querySelector('#traininglog');
                            const element = document.querySelector(idSkillSet);

                            const opt = {
                                margin: 1,
                                filename: 'skillset-one.pdf',
                                image: { type: 'jpeg', quality: 0.98 },
                                html2canvas: { scale: 2 },
                                jsPDF: { unit: 'px', format: [1000, 12000], orientation: 'portrait' }
                            };
                            this.createPdf = false;
                            const pdfBlob = await html2pdf().from(element).set(opt).outputPdf('blob');
                            const pdfUrl = URL.createObjectURL(pdfBlob);
                            //console.log("pdfUrl: ", pdfUrl);
                            resolve(pdfUrl);
                        } catch (error) {
                            reject(error);
                            this.createPdf = false;
                        }
                    });
                });
                return pdfUrl;
            } catch (error) {
                console.error("Error: ", error);
            }
        },

        getDateTime() {
            // Obtener la fecha y hora actual
            var fechaHoraActual = new Date();

            // Obtener las partes de la fecha y hora
            var hora = fechaHoraActual.getHours();
            var minutos = fechaHoraActual.getMinutes();
            var segundos = fechaHoraActual.getSeconds();
            var dia = fechaHoraActual.getDate();
            var mes = fechaHoraActual.getMonth() + 1; // ¡Ojo! Los meses comienzan en 0
            var anio = fechaHoraActual.getFullYear();

            // Agregar un cero delante si el valor es menor que 10
            hora = hora < 10 ? '0' + hora : hora;
            minutos = minutos < 10 ? '0' + minutos : minutos;
            segundos = segundos < 10 ? '0' + segundos : segundos;
            dia = dia < 10 ? '0' + dia : dia;
            mes = mes < 10 ? '0' + mes : mes;

            // Crear la cadena con el formato deseado
            var fechaHoraFormateada = hora + ':' + minutos + ':' + segundos + ' ' + dia + '/' + mes + '/' + anio;

            //console.log("Fecha y hora actual:", fechaHoraFormateada);
            return fechaHoraFormateada;
        },
        back() {
            this.$router.push({ name: 'trainer-interface' });
        },
        deselectOption(survey) {
            if (survey === 'surveyss1') {
                this.formData.attendanceOptionSS1 = '';
                this.formData.trainingParticipationOptionSS1 = '';
                this.formData.knowledgeOptionSS1 = '';
            }
            if (survey === 'surveyss2') {
                this.formData.attendanceOptionSS2 = '';
                this.formData.trainingParticipationOptionSS2 = '';
                this.formData.knowledgeOptionSS2 = '';
            }
            if (survey === 'surveyss3') {
                this.formData.attendanceOptionSS3 = '';
                this.formData.trainingParticipationOptionSS3 = '';
                this.formData.knowledgeOptionSS3 = '';
            }
            if (survey === 'surveyss4') {
                this.formData.attendanceOptionSS4 = '';
                this.formData.trainingParticipationOptionSS4 = '';
                this.formData.knowledgeOptionSS4 = '';
            }
            if (survey === 'surveym') {
                this.formData.attendanceOptionM = '';
                this.formData.trainingParticipationOptionM = '';
                this.formData.knowledgeOptionM = '';
            }

        },
        async updateTrainingLogHistory(data) {
            //console.log("UPDATING - Training Log");
            //console.log("<data: ", data);
            const url = UPDATE_TRAINING_LOG;
            ////console.log("url: ", url);
            ////console.log("data: ", data);

            try {
                const response = await fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });

                if (response.ok) {
                    const responseData = await response.json();
                    //return responseData;
                    return "ok"
                } else {
                    console.error('Error:', response.status);
                    return null;
                }
            } catch (error) {
                console.error('Request error:', error);
                return null;
            }
        },
        handleCheckboxChange(rowIndex, colIndex, answers, state) {
            try {
                //console.log("state: ", state);
                //console.log("rowIndex: ", rowIndex);
                //console.log("colIndex: ", colIndex);
                //console.log("answers: ", answers);
                if (this.editedIndex != -1) {
                    if (state === true) {
                        this.$set(this.formDataSave[answers][rowIndex][colIndex], 'checked', true);
                        this.$set(this.formDataSave[answers][rowIndex][colIndex], 'initiallySelected', true);
                    } else {
                        this.$set(this.formDataSave[answers][rowIndex][colIndex], 'checked', false);
                        this.$set(this.formDataSave[answers][rowIndex][colIndex], 'initiallySelected', false);
                    }
                }
            } catch (error) {
                console.error("Error-handleCheckboxChange: ", error);
            }
        },
        deactivateWatcher() {
            this.watcherIsActive = false;
        },
        generateHistoryJson() {
            this.formData.dateTimeCreated = this.getDateTime();
            const newHistory = {
                "uuid": this.formData.uuid,
                "status": this.formData.status,
                "statusss1": this.formData.statusss1,
                "statusss2": this.formData.statusss2,
                "statusss3": this.formData.statusss3,
                "statusss4": this.formData.statusss4,
                "statusm": this.formData.statusm,
                "history": [
                    {
                        "beginningFirstLessonAnswers": this.formDataSave.beginningFirstLessonAnswers,
                        "beginningEveryLessonAnswers": this.formDataSave.beginningEveryLessonAnswers,
                        "endEveryLessonAnswers": this.formDataSave.endEveryLessonAnswers,
                        "skillSetOneAnswers_2": this.formDataSave.skillSetOneAnswers_2,
                        "skillSetOneAnswers_3": this.formDataSave.skillSetOneAnswers_3,
                        "skillSetOneAnswers_4": this.formDataSave.skillSetOneAnswers_4,
                        "skillSetOneAnswers_5": this.formDataSave.skillSetOneAnswers_5,
                        /*  "trainer": trainerSS1, */
                        "trainer": {
                            "employee_number": this.formData.employee_number,
                            "trainer_name": this.formData.trainer_name,
                            "trainer_lastName": this.formData.trainer_lastName,
                            "dateTimeCreated": this.formData.dateTimeCreated
                        },
                        "trainerSS2": {
                            "employee_number": this.formData.employee_number,
                            "trainer_name": this.formData.trainer_name,
                            "trainer_lastName": this.formData.trainer_lastName,
                            "dateTimeCreated": this.formData.dateTimeCreated
                        },
                        "trainerSS3": {
                            "employee_number": this.formData.employee_number,
                            "trainer_name": this.formData.trainer_name,
                            "trainer_lastName": this.formData.trainer_lastName,
                            "dateTimeCreated": this.formData.dateTimeCreated
                        },
                        "trainerSS4": {
                            "employee_number": this.formData.employee_number,
                            "trainer_name": this.formData.trainer_name,
                            "trainer_lastName": this.formData.trainer_lastName,
                            "dateTimeCreated": this.formData.dateTimeCreated
                        },
                        "trainerM": {
                            "employee_number": this.formData.employee_number,
                            "trainer_name": this.formData.trainer_name,
                            "trainer_lastName": this.formData.trainer_lastName,
                            "dateTimeCreated": this.formData.dateTimeCreated
                        },
                        "trainer_email": this.formDataSave.trainer_email,
                        "attendanceOptionSS1": this.formDataSave.attendanceOptionSS1,
                        "trainingParticipationOptionSS1": this.formDataSave.trainingParticipationOptionSS1,
                        "knowledgeOptionSS1": this.formDataSave.knowledgeOptionSS1,
                        "skillSetOneAnswers": this.formDataSave.skillSetOneAnswers,

                        "attendanceOptionSS2": this.formDataSave.attendanceOptionSS2,
                        "trainingParticipationOptionSS2": this.formDataSave.trainingParticipationOptionSS2,
                        "knowledgeOptionSS2": this.formDataSave.knowledgeOptionSS2,
                        "skillSetTwoAnswers": this.formDataSave.skillSetTwoAnswers,

                        "attendanceOptionSS3": this.formDataSave.attendanceOptionSS3,
                        "trainingParticipationOptionSS3": this.formDataSave.trainingParticipationOptionSS3,
                        "knowledgeOptionSS3": this.formDataSave.knowledgeOptionSS3,
                        "skillSetThreeAnswers": this.formDataSave.skillSetThreeAnswers,

                        "attendanceOptionSS4": this.formDataSave.attendanceOptionSS4,
                        "trainingParticipationOptionSS4": this.formDataSave.trainingParticipationOptionSS4,
                        "knowledgeOptionSS4": this.formDataSave.knowledgeOptionSS4,
                        "skillSetFourAnswers": this.formDataSave.skillSetFourAnswers,

                        "attendanceOptionM": this.formDataSave.attendanceOptionM,
                        "trainingParticipationOptionM": this.formDataSave.trainingParticipationOptionM,
                        "knowledgeOptionM": this.formDataSave.knowledgeOptionM,
                        "maneuversAnswers": this.formDataSave.maneuversAnswers

                    }
                ]
            }
            return newHistory;
        },
        async save() {
            ////console.log("UUID: ", this.formData.uuid);
            ////console.log("this.editedIndex: ", this.editedIndex);
            if (this.editedIndex != -1) {
                //console.log("Editing.....")
                const newHistory = this.generateHistoryJson();
                //console.log("newHistory: ", newHistory);

                const res = await this.updateTrainingLogHistory(newHistory);

                //console.log("res Editing ---> ", res);
                if (res === 'ok') {
                    this.$router.push({ name: 'trainer-interface' });
                }
                //////console.log("formData: ", formData);
            } else {
                //console.log("NEW TRAINING LOG.....");
                if (this.formData.trainee_name === '' || this.formData.trainee_name === '') {
                    alert("Trainee fields are empty!");
                } else {
                    //console.log("Saving.......")
                    const res = await this.add_training_log();
                    //console.log("res add_training_log: ", res);
                    if (res === 'ok') {
                        this.$router.push({ name: 'trainer-interface' });
                    }
                }
            }
        },
        /**
         * Author: [Javier Daniel González Torres]
         * 
         * Description: This function fills the formData object with values from formDataTest, excluding false, null, and empty string values.
         * 
         * Input: None
         * 
         * Output: None (Updates the formData object)
         */
        fillFormData(objDataForm) {
            //console.log("<------------------- FillFormData......");
            //console.log("objDataForm: ", objDataForm);
            ////console.log("formData: ", this.formData);
            // Iterate over each key in formDataTest
            for (const prop in objDataForm) {

                // Check if the key is a direct property of formDataTest
                //if (objDataForm.hasOwnProperty(key)) {
                if (prop == 'history') {
                    ////console.log("<---------------- PROPERTY == history.......")
                    ////console.log("history: ", objDataForm[prop])
                    const history = objDataForm[prop];
                    for (const obj of history) {
                        //console.log("<HISTORY----> ", obj);
                        for (const prop2 in obj) {
                            //console.log("prop: ", prop2);

                            try {
                                if (this.formData[prop2] !== undefined) {
                                    ////console.log("SI existe la propiedad en formData......", prop2);
                                    if (prop2 == "trainer") {
                                        //console.log("----------- trainer")
                                        //console.log("<trainer: ", obj[prop2]);
                                        const trainerObj = {
                                            "employee_number": obj[prop2]['employee_number'],
                                            "trainer_name": obj[prop2]['trainer_name'],
                                            "trainer_lastName": obj[prop2]['trainer_lastName'],
                                            "dateTimeCreated": obj[prop2]['dateTimeCreated'],

                                        }
                                        this.formData.trainer.push(trainerObj);
                                        ////console.log("this.formData.trainer: ", this.formData.trainer);
                                    } else if (prop2 == "trainerSS2") {
                                        ////console.log("----------- trainerSS2")
                                        ////console.log("<trainerSS2: ", obj[prop2]);

                                        const todasLasPropiedadesTienenValor = Object.values(obj[prop2]).every(valor => valor !== '');

                                        // Verificar el resultado
                                        if (todasLasPropiedadesTienenValor) {
                                            ////console.log("Todas las propiedades tienen valores");
                                            const trainerObj = {
                                                "employee_number": obj[prop2]['employee_number'],
                                                "trainer_name": obj[prop2]['trainer_name'],
                                                "trainer_lastName": obj[prop2]['trainer_lastName'],
                                                "dateTimeCreated": obj[prop2]['dateTimeCreated'],
                                            }
                                            this.formData.trainerSS2.push(trainerObj);
                                            ////console.log("this.formData.trainerSS2: ", this.formData.trainerSS2);
                                        } else {
                                            ////console.log("Al menos una propiedad no tiene valor");
                                            delete obj['trainerSS2'];
                                        }
                                    } else if (prop2 == "trainerSS3") {
                                        ////console.log("----------- trainerSS3")
                                        ////console.log("<trainerSS3: ", obj[prop2]);

                                        const todasLasPropiedadesTienenValor = Object.values(obj[prop2]).every(valor => valor !== '');

                                        // Verificar el resultado
                                        if (todasLasPropiedadesTienenValor) {
                                            ////console.log("Todas las propiedades tienen valores");
                                            const trainerObj = {
                                                "employee_number": obj[prop2]['employee_number'],
                                                "trainer_name": obj[prop2]['trainer_name'],
                                                "trainer_lastName": obj[prop2]['trainer_lastName'],
                                                "dateTimeCreated": obj[prop2]['dateTimeCreated'],
                                            }
                                            this.formData.trainerSS3.push(trainerObj);
                                            ////console.log("this.formData.trainerSS3: ", this.formData.trainerSS3);
                                        } else {
                                            ////console.log("Al menos una propiedad no tiene valor");
                                            delete obj['trainerSS3'];
                                        }
                                    } else if (prop2 == "trainerSS4") {
                                        ////console.log("----------- trainerSS4")
                                        ////console.log("<trainerSS4: ", obj[prop2]);

                                        const todasLasPropiedadesTienenValor = Object.values(obj[prop2]).every(valor => valor !== '');

                                        // Verificar el resultado
                                        if (todasLasPropiedadesTienenValor) {
                                            ////console.log("Todas las propiedades tienen valores");
                                            const trainerObj = {
                                                "employee_number": obj[prop2]['employee_number'],
                                                "trainer_name": obj[prop2]['trainer_name'],
                                                "trainer_lastName": obj[prop2]['trainer_lastName'],
                                                "dateTimeCreated": obj[prop2]['dateTimeCreated'],
                                            }
                                            this.formData.trainerSS4.push(trainerObj);
                                            ////console.log("this.formData.trainerSS4: ", this.formData.trainerSS4);
                                        } else {
                                            ////console.log("Al menos una propiedad no tiene valor");
                                            delete obj['trainerSS4'];
                                        }
                                    } else if (prop2 == "trainerM") {
                                        ////console.log("----------- trainerM")
                                        ////console.log("<trainerM: ", obj[prop2]);

                                        const todasLasPropiedadesTienenValor = Object.values(obj[prop2]).every(valor => valor !== '');

                                        // Verificar el resultado
                                        if (todasLasPropiedadesTienenValor) {
                                            ////console.log("Todas las propiedades tienen valores");
                                            const trainerObj = {
                                                "employee_number": obj[prop2]['employee_number'],
                                                "trainer_name": obj[prop2]['trainer_name'],
                                                "trainer_lastName": obj[prop2]['trainer_lastName'],
                                                "dateTimeCreated": obj[prop2]['dateTimeCreated'],
                                            }
                                            this.formData.trainerM.push(trainerObj);
                                            ////console.log("this.formData.trainerM: ", this.formData.trainerM);
                                        } else {
                                            ////console.log("Al menos una propiedad no tiene valor");
                                            delete obj['trainerM'];
                                        }
                                    } else if (prop2 == "skillSetOneAnswers" ||
                                        prop2 == "skillSetTwoAnswers" ||
                                        prop2 == "skillSetThreeAnswers" ||
                                        prop2 == "skillSetFourAnswers" ||
                                        prop2 == "maneuversAnswers" ||
                                        prop2 == "beginningFirstLessonAnswers" ||
                                        prop2 == "beginningEveryLessonAnswers" ||
                                        prop2 == "endEveryLessonAnswers" ||
                                        prop2 == "skillSetOneAnswers_2" ||
                                        prop2 == "skillSetOneAnswers_3" ||
                                        prop2 == "skillSetOneAnswers_4" ||
                                        prop2 == "skillSetOneAnswers_5") {
                                        ////console.log("----------- skillSetOneAnswers")
                                        ////console.log("skillSetOneAnswers: ", obj[prop2]);
                                        if (obj[prop2].length !== this.formData[prop2].length) {
                                            console.error("Matrices must have the same length");
                                            return;
                                        } else {
                                            ////console.log("Same length");
                                        }

                                        // Iterate through each row of the matrices
                                        for (let i = 0; i < this.formData[prop2].length; i++) {
                                            // Check if the row has the same length
                                            if (this.formData[prop2][i].length !== obj[prop2][i].length) {
                                                console.error(`Row ${i} of the matrices must have the same length`);
                                                return;
                                            }

                                            // Iterate through each element of the row
                                            for (let j = 0; j < this.formData[prop2][i].length; j++) {
                                                // If the value in the source is true, copy it to the destination
                                                if (obj[prop2][i][j].checked === true) {
                                                    this.formData[prop2][i][j].checked = true;
                                                    this.formData[prop2][i][j].initiallySelected = true;
                                                }
                                            }
                                        }
                                    } else {
                                        const value = obj[prop2];
                                        if (value !== false && value !== null && value !== '') {
                                            ////console.log("<<<<<<<<<<< PROPIEDAD: ", prop2);
                                            ////console.log("Value: ", value)
                                            ////console.log("Assigning.......")
                                            this.formData[prop2] = value;
                                        } else {
                                            ////console.log("Not assigning........")
                                        }
                                        ////console.log("---------------------------------------------------------------");
                                    }
                                } else {
                                    //console.log("NO existe la propiedad en formData......");
                                }
                            } catch (error) {
                                console.error("ERROR: ", error)
                            }

                        }
                    }
                    /* for (const key in prop) {
                        ////console.log("key: ", key);
                        const value = this.dataForm[key][prop];
                        if (value !== false && value !== null && value !== '') {
                            // Assign the value to the corresponding key in formData
                            this.formData[key][prop] = value;
                        }
                    } */

                    ////console.log("</---------------- PROPERTY == history.......")
                } else {
                    if (prop == 'uuid') {
                        ////console.log("uuid: ", objDataForm[prop]);
                    }
                    // Get the value associated with the current key
                    const value = objDataForm[prop];
                    // Check if the value is not false, not null, and not an empty string
                    if (value !== false && value !== null && value !== '') {
                        // Assign the value to the corresponding key in formData
                        this.formData[prop] = value;
                    }
                }
                //}
            }
            // Log the updated formData
            ////console.log("this.formData: ", this.formData);
            ////console.log("</------------------- FillFormData......");
            return "ok";
        },
        clearFormDataSave() {
            for (let prop in this.formDataDefault) {
                // Verifica si la propiedad existe en formDataSave y si es un objeto
                if (prop !== "employee_number" && prop !== "trainer_lastName" && prop !== "trainer_name" && prop !== "trainer_email" && prop !== "uuid") {
                    if (this.formDataSave.hasOwnProperty(prop) && typeof this.formDataSave[prop] === 'object' && this.formDataDefault[prop] !== null) {
                        // Si es un array, copia cada elemento
                        if (Array.isArray(this.formDataDefault[prop])) {
                            this.formDataSave[prop] = this.formDataDefault[prop].map(arr => arr.slice());
                        } else {
                            // Si es un objeto, copia cada propiedad
                            for (let subProp in this.formDataDefault[prop]) {
                                this.formDataSave[prop][subProp] = this.formDataDefault[prop][subProp];
                            }
                        }
                    } else {
                        // Si no es un objeto, simplemente asigna el valor
                        this.formDataSave[prop] = this.formDataDefault[prop];
                    }
                }
            }
        },
        clearFormData() {
            ////console.log("Clearing formData....")
            for (const key in this.formDataDefault) {
                if ((key != 'employee_number') && (key != 'trainer_name') && (key != 'trainer_lastName') && (key != 'trainer_email')) {
                    if (this.formDataDefault.hasOwnProperty(key)) {
                        this.formData[key] = this.formDataDefault[key];
                    }
                }
            }
            ////console.log("this.formData: ", this.formData);
        },
        async get_EmployeeData() {
            try {

                this.formData.employee_number = get_employee_number();
                //console.log("employee_number: ", this.formData.employee_number);
                const url = `${GET_EMPLOYEE_BY_EMPLOYEE_NUMBER}?employee_number=${this.formData.employee_number}`;
                ////console.log("url: ", url);

                const response = await fetch(url); // Replace "your_endpoint" with the URL of your endpoint
                if (!response.ok) {
                    throw new Error('Failed to fetch record');
                }
                const data = await response.json();
                ////console.log("data: ", data);
                ////console.log("name: ", data.name)
                ////console.log("last_name: ", data.last_name)
                /* this.formData.trainer_name = data.name;
                this.formData.trainer_lastName = data.last_name;
                this.formData.trainer_email = data.nova_email; */
                this.initializeTrainer(data);
                ////console.log("trainer_email: ", this.formData.trainer_email);

                return data; // Returns the object retrieved from the endpoint
            } catch (error) {
                console.error('Error fetching record:', error);
                throw error;
            }
        },
        initializeTrainer(data) {
            // SSONE
            this.formData.trainer_name = data.name;
            this.formData.trainer_lastName = data.last_name;
            this.formData.trainer_email = data.nova_email;

            //SSTWO
            this.formData.trainer_nameSS2 = data.name;
            this.formData.trainer_lastNameSS2 = data.last_name;
            this.formData.employee_numberSS2 = this.formData.employee_number;

            //SSTHREE
            this.formData.trainer_nameSS3 = data.name;
            this.formData.trainer_lastNameSS3 = data.last_name;
            this.formData.employee_numberSS3 = this.formData.employee_number;

            //SSFOUR
            this.formData.trainer_nameSS4 = data.name;
            this.formData.trainer_lastNameSS4 = data.last_name;
            this.formData.employee_numberSS4 = this.formData.employee_number;

            //SSM
            this.formData.trainer_nameM = data.name;
            this.formData.trainer_lastNameM = data.last_name;
            this.formData.employee_numberM = this.formData.employee_number;
        },
        copyFormData() {
            ////console.log("copiando form data......")
        },
        generateJson() {
            try {
                //console.log("GENERATING JSON................");
                //console.log('this.formData.beginningFirstLessonAnswers: ', this.formData.beginningFirstLessonAnswers);
                if (this.formData.attendanceOptionSS1 !== '' && this.formData.trainingParticipationOptionSS1 !== '' && this.formData.knowledgeOptionSS1 !== '') {
                    this.formData.statusss1 = true;
                }
                if (this.formData.attendanceOptionSS2 !== '' && this.formData.trainingParticipationOptionSS2 !== '' && this.formData.knowledgeOptionSS2 !== '') {
                    this.formData.statusss2 = true;
                }
                if (this.formData.attendanceOptionSS3 !== '' && this.formData.trainingParticipationOptionSS3 !== '' && this.formData.knowledgeOptionSS3 !== '') {
                    this.formData.statusss3 = true;
                }
                if (this.formData.attendanceOptionSS4 !== '' && this.formData.trainingParticipationOptionSS4 !== '' && this.formData.knowledgeOptionSS4 !== '') {
                    this.formData.statusss4 = true;
                }
                if (this.formData.attendanceOptionM !== '' && this.formData.trainingParticipationOptionM !== '' && this.formData.knowledgeOptionM !== '') {
                    this.formData.statusm = true;
                }
                if (this.formData.statusss1 === true && this.formData.statusss2 === true && this.formData.statusss3 === true && this.formData.statusss4 === true && this.formData.statusm === true) {
                    this.formData.status === true;
                }
                this.formData.dateTimeCreated = this.getDateTime();
                const formData = {
                    "status": this.formData.status,
                    "statusss1": this.formData.statusss1,
                    "statusss2": this.formData.statusss2,
                    "statusss3": this.formData.statusss3,
                    "statusss4": this.formData.statusss4,
                    "statusm": this.formData.statusm,
                    "trainee_name": this.formData.trainee_name,
                    "trainee_lastName": this.formData.trainee_lastName,
                    "employee_number": this.formData.employee_number,
                    "trainer_name": this.formData.trainer_name,
                    "trainer_lastName": this.formData.trainer_lastName,
                    "history": [
                        {
                            "beginningFirstLessonAnswers": this.formData.beginningFirstLessonAnswers,
                            "beginningEveryLessonAnswers": this.formData.beginningEveryLessonAnswers,
                            "endEveryLessonAnswers": this.formData.endEveryLessonAnswers,
                            "skillSetOneAnswers_2": this.formData.skillSetOneAnswers_2,
                            "skillSetOneAnswers_3": this.formData.skillSetOneAnswers_3,
                            "skillSetOneAnswers_4": this.formData.skillSetOneAnswers_4,
                            "skillSetOneAnswers_5": this.formData.skillSetOneAnswers_5,
                            "trainer": {
                                "employee_number": this.formData.employee_number,
                                "trainer_name": this.formData.trainer_name,
                                "trainer_lastName": this.formData.trainer_lastName,
                                "dateTimeCreated": this.formData.dateTimeCreated
                            },
                            "trainerSS2": {
                                "employee_number": this.formData.employee_number,
                                "trainer_name": this.formData.trainer_name,
                                "trainer_lastName": this.formData.trainer_lastName,
                                "dateTimeCreated": this.formData.dateTimeCreated
                            },
                            "trainerSS3": {
                                "employee_number": this.formData.employee_number,
                                "trainer_name": this.formData.trainer_name,
                                "trainer_lastName": this.formData.trainer_lastName,
                                "dateTimeCreated": this.formData.dateTimeCreated
                            },
                            "trainerSS4": {
                                "employee_number": this.formData.employee_number,
                                "trainer_name": this.formData.trainer_name,
                                "trainer_lastName": this.formData.trainer_lastName,
                                "dateTimeCreated": this.formData.dateTimeCreated
                            },
                            "trainerM": {
                                "employee_number": this.formData.employee_number,
                                "trainer_name": this.formData.trainer_name,
                                "trainer_lastName": this.formData.trainer_lastName,
                                "dateTimeCreated": this.formData.dateTimeCreated
                            },
                            "trainer_email": this.formData.trainer_email,
                            "attendanceOptionSS1": this.formData.attendanceOptionSS1,
                            "trainingParticipationOptionSS1": this.formData.trainingParticipationOptionSS1,
                            "knowledgeOptionSS1": this.formData.knowledgeOptionSS1,
                            "skillSetOneAnswers": this.formData.skillSetOneAnswers,

                            "attendanceOptionSS2": this.formData.attendanceOptionSS2,
                            "trainingParticipationOptionSS2": this.formData.trainingParticipationOptionSS2,
                            "knowledgeOptionSS2": this.formData.knowledgeOptionSS2,
                            "skillSetTwoAnswers": this.formData.skillSetTwoAnswers,

                            "attendanceOptionSS3": this.formData.attendanceOptionSS3,
                            "trainingParticipationOptionSS3": this.formData.trainingParticipationOptionSS3,
                            "knowledgeOptionSS3": this.formData.knowledgeOptionSS3,
                            "skillSetThreeAnswers": this.formData.skillSetThreeAnswers,

                            "attendanceOptionSS4": this.formData.attendanceOptionSS4,
                            "trainingParticipationOptionSS4": this.formData.trainingParticipationOptionSS4,
                            "knowledgeOptionSS4": this.formData.knowledgeOptionSS4,
                            "skillSetFourAnswers": this.formData.skillSetFourAnswers,

                            "attendanceOptionM": this.formData.attendanceOptionM,
                            "trainingParticipationOptionM": this.formData.trainingParticipationOptionM,
                            "knowledgeOptionM": this.formData.knowledgeOptionM,
                            "maneuversAnswers": this.formData.maneuversAnswers,
                        }
                    ]
                };
                //console.log("formData: ", formData);
                return formData;
            } catch (error) {
                console.error("Error generateJson: ", error);
            }
        },
        async add_training_log() {
            //console.log("add_training_log........");
            const formData = this.generateJson();
            //console.log("formData: ", formData);

            try {
                const response = await fetch(ADD_TRAINING_LOG, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formData)
                });

                if (!response.ok) {
                    throw new Error('Hubo un problema al realizar la solicitud: ' + response.status);
                }

                // Si la solicitud fue exitosa, devolver "ok"
                return "ok";
            } catch (error) {
                console.error('Error al consumir el endpoint:', error);
                // En caso de error, podrías lanzar una excepción o devolver null, dependiendo de cómo quieras manejarlo
                throw error;
            }
        },
    },
    watch: {
        tab(val) {
            //console.log("val: ", val);

        },
        watchedValue(newValue, oldValue) {
            if (this.watcherIsActive) {
                //console.log("Watching.....")
                // Realiza las acciones necesarias cuando el watcher observa un cambio
                ////console.log('El watcher está observando cambios:', newValue);
            } else {
                // Realiza acciones adicionales cuando el watcher deja de observar cambios
                ////console.log('El watcher dejó de observar cambios');
                ////console.log("BEFORE -- this.formDataSave: ", this.formDataSave);
                this.clearFormDataSave();
                ////console.log("AFTER -- this.formDataSave: ", this.formDataSave);
            }
        },
        'formData.skillsetOne': function (newVal) {
            console.log("watching - skillsetOne:", newVal);
            this.formDataSave.skillsetOne = newVal;
            this.formData.trainer_name = this.formData.trainer_name;
            this.formData.trainer_lastName = this.formData.trainer_lastName;
            this.formData.employee_number = this.formData.employee_number;            
        },
        'formData.skillsetTwo': function (newVal) {
            ////console.log("watching - skillsetTwo:", newVal);
            this.formDataSave.skillsetTwo = newVal;
            ////console.log("----> this.formData: ", this.formData);
            this.formData.trainer_nameSS2 = this.formData.trainer_name;
            this.formData.trainer_lastNameSS2 = this.formData.trainer_lastName;
            this.formData.employee_numberSS2 = this.formData.employee_number;            
        },
        'formData.skillsetThree': function (newVal) {
            ////console.log("watching - skillsetThree:", newVal);
            this.formDataSave.skillsetThree = newVal;
            this.formData.trainer_nameSS3 = this.formData.trainer_name;
            this.formData.trainer_lastNameSS3 = this.formData.trainer_lastName;
            this.formData.employee_numberSS3 = this.formData.employee_number;            
        },
        'formData.skillsetFour': function (newVal) {
            ////console.log("watching - skillsetFour:", newVal);
            this.formDataSave.skillsetFour = newVal;
            this.formData.trainer_nameSS4 = this.formData.trainer_name;
            this.formData.trainer_lastNameSS4 = this.formData.trainer_lastName;
            this.formData.employee_numberSS4 = this.formData.employee_number;            
        },
        'formData.maneuvers': function (newVal) {
            ////console.log("watching - maneuvers:", newVal);
            this.formDataSave.maneuvers = newVal;
            this.formData.trainer_nameM = this.formData.trainer_name;
            this.formData.trainer_lastNameM = this.formData.trainer_lastName;
            this.formData.employee_numberM = this.formData.employee_number;            
        },
        'formData.uuid': function (newVal) {
            ////console.log("watching - uuid:", newVal);
            this.formDataSave.uuid = newVal;
        },
        'formData.employee_number': function (newVal) {
            //console.log("watching - employee_number:", newVal);
            this.formDataSave.employee_number = newVal;
        },
        'formData.trainer_name': function (newVal) {
            ////console.log("watching - trainer_name:", newVal);
            this.formDataSave.trainer_name = newVal;
        },
        'formData.trainer_lastName': function (newVal) {
            ////console.log("watching - trainer_lastName:", newVal);
            this.formDataSave.trainer_lastName = newVal;
        },
        'formData.trainer_email': function (newVal) {
            ////console.log("watching - trainer_email:", newVal);
            this.formDataSave.trainer_email = newVal;
        },
        'formData.attendanceOptionSS1': function (newVal) {
            ////console.log("watching - attendanceOptionSS1:", newVal);
            this.formDataSave.attendanceOptionSS1 = newVal;
            ////console.log("this.formDataSave.attendanceOptionSS1: ", this.formDataSave.attendanceOptionSS1);
        },
        'formData.trainingParticipationOptionSS1': function (newVal) {
            ////console.log("watching - trainingParticipationOptionSS1:", newVal);
            this.formDataSave.trainingParticipationOptionSS1 = newVal;
            ////console.log("this.formDataSave.trainingParticipationOptionSS1: ", this.formDataSave.trainingParticipationOptionSS1);
        },
        'formData.knowledgeOptionSS1': function (newVal) {
            ////console.log("watching - knowledgeOptionSS1:", newVal);
            this.formDataSave.knowledgeOptionSS1 = newVal;
            ////console.log("this.formDataSave.knowledgeOptionSS1: ", this.formDataSave.knowledgeOptionSS1);
        },
        'formData.attendanceOptionSS2': function (newVal) {
            ////console.log("watching - attendanceOptionSS2:", newVal);
            this.formDataSave.attendanceOptionSS2 = newVal;
            ////console.log("this.formDataSave.attendanceOptionSS2: ", this.formDataSave.attendanceOptionSS2);
        },
        'formData.trainingParticipationOptionSS2': function (newVal) {
            ////console.log("watching - trainingParticipationOptionSS2:", newVal);
            this.formDataSave.trainingParticipationOptionSS2 = newVal;
            ////console.log("this.formDataSave.trainingParticipationOptionSS2: ", this.formDataSave.trainingParticipationOptionSS2);
        },
        'formData.knowledgeOptionSS2': function (newVal) {
            ////console.log("watching - knowledgeOptionSS2:", newVal);
            this.formDataSave.knowledgeOptionSS2 = newVal;
            ////console.log("this.formDataSave.knowledgeOptionSS2: ", this.formDataSave.knowledgeOptionSS2);
        },
        'formData.attendanceOptionSS3': function (newVal) {
            ////console.log("watching - attendanceOptionSS3:", newVal);
            this.formDataSave.attendanceOptionSS3 = newVal;
            ////console.log("this.formDataSave.attendanceOptionSS3: ", this.formDataSave.attendanceOptionSS3);
        },
        'formData.trainingParticipationOptionSS3': function (newVal) {
            ////console.log("watching - trainingParticipationOptionSS3:", newVal);
            this.formDataSave.trainingParticipationOptionSS3 = newVal;
            ////console.log("this.formDataSave.trainingParticipationOptionSS3: ", this.formDataSave.trainingParticipationOptionSS3);
        },
        'formData.knowledgeOptionSS3': function (newVal) {
            ////console.log("watching - knowledgeOptionSS3:", newVal);
            this.formDataSave.knowledgeOptionSS3 = newVal;
            ////console.log("this.formDataSave.knowledgeOptionSS3: ", this.formDataSave.knowledgeOptionSS3);
        },
        'formData.attendanceOptionSS4': function (newVal) {
            ////console.log("watching - attendanceOptionSS4:", newVal);
            this.formDataSave.attendanceOptionSS4 = newVal;
            ////console.log("this.formDataSave.attendanceOptionSS4: ", this.formDataSave.attendanceOptionSS4);
        },
        'formData.trainingParticipationOptionSS4': function (newVal) {
            ////console.log("watching - trainingParticipationOptionSS4:", newVal);
            this.formDataSave.trainingParticipationOptionSS4 = newVal;
            ////console.log("this.formDataSave.trainingParticipationOptionSS4: ", this.formDataSave.trainingParticipationOptionSS4);
        },
        'formData.knowledgeOptionSS4': function (newVal) {
            ////console.log("watching - knowledgeOptionSS4:", newVal);
            this.formDataSave.knowledgeOptionSS4 = newVal;
            ////console.log("this.formDataSave.knowledgeOptionSS4: ", this.formDataSave.knowledgeOptionSS4);
        },
        'formData.attendanceOptionM': function (newVal) {
            ////console.log("watching - attendanceOptionM:", newVal);
            this.formDataSave.attendanceOptionM = newVal;
            ////console.log("this.formDataSave.attendanceOptionM: ", this.formDataSave.attendanceOptionM);
        },
        'formData.trainingParticipationOptionM': function (newVal) {
            ////console.log("watching - trainingParticipationOptionM:", newVal);
            this.formDataSave.trainingParticipationOptionM = newVal;
            ////console.log("this.formDataSave.trainingParticipationOptionM: ", this.formDataSave.trainingParticipationOptionM);
        },
        'formData.knowledgeOptionM': function (newVal) {
            ////console.log("watching - knowledgeOptionM:", newVal);
            this.formDataSave.knowledgeOptionM = newVal;
            ////console.log("this.formDataSave.knowledgeOptionM: ", this.formDataSave.knowledgeOptionM);
        }
    },
    created() {
        //console.log("CREATED--Training Log Form.........")
        if (this.$route.params.dataForm) {
            this.objDataForm = this.$route.params.dataForm;
            //console.log("this.objDataForm: ", this.objDataForm);
            this.editedIndex = this.$route.params.editedIndex;
            //console.log("this.editedIndex: ", this.editedIndex);
            const res = this.fillFormData(this.objDataForm);
            setTimeout(() => {
                this.watchedValue = 'Nuevo valor'; // Cambia el valor después de 5 segundos
                this.deactivateWatcher(); // Desactiva el watcher
            }, 1500);
            ////console.log("/CREATED.........")
        }

    },
}
</script>


<style scoped>
.orange-bg {
    background-color: rgb(255, 71, 11);
}

.scrollable-content {
    width: 100%;
    /* Ajusta el ancho según sea necesario */
}

.center-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
}

.tight-row {
    margin: -10px;
}

.red-light {
    background-color: #ffcccc !important;
    /* Cambia este valor según el tono de rojo que desees */
    color: #000000;
    /* Color de texto para asegurar la legibilidad */
}

.green-bright {
    background-color: #7fff7f !important;
    /* Cambia este valor según el tono de verde que desees */
    color: #000000;
    /* Color de texto para asegurar la legibilidad */
}

.bold-black-text {
    color: black;
    font-weight: bold;
}

.bold-red-text {
    color: red;
    font-weight: bold;
}

.centered-text {
    text-align: center;
}
</style>
