<template>
    <div>
        <v-data-table :headers="headers" :items="filteredVehicles" sort-by="name" class="elevation-1"
            @click:row="updateItem"><!-- :item-class="itemClass" -->
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title class="font-weight-bold">Vehicles</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-text-field v-model="search" label="Search" solo-inverted prepend-icon="mdi-magnify"
                        class="mr-3 mt-5"></v-text-field>
                    <v-btn color="blue" dark class="mb-2 mr-2" @click="refreshTable">
                        <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                </v-toolbar>
            </template>
            <template v-slot:item.expiration_date="{ item }">
                <td :class="expirationDateClass(item.expiration_date)">
                    {{ item.expiration_date }}
                </td>
            </template>
            <template v-slot:item.plate_sticker_expiration_date="{ item }">
                <td :class="expirationDateClass(item.plate_sticker_expiration_date)">
                    {{ item.plate_sticker_expiration_date }}
                </td>
            </template>
            <template v-slot:item.city_sticker_expiration_date="{ item }">
                <td :class="expirationDateClass(item.city_sticker_expiration_date)">
                    {{ item.city_sticker_expiration_date }}
                </td>
            </template>
            <template v-slot:item.safety_inspection_expiration_date="{ item }">
                <td :class="expirationDateClass(item.safety_inspection_expiration_date)">
                    {{ item.safety_inspection_expiration_date }}
                </td>
            </template>
            <template v-slot:item.emission_test_expiration_date="{ item }">
                <td :class="expirationDateClass(item.emission_test_expiration_date)">
                    {{ item.emission_test_expiration_date }}
                </td>
            </template>
            <template v-slot:item.triangle_expiration_date="{ item }">
                <td :class="expirationDateClass(item.triangle_expiration_date)">
                    {{ item.triangle_expiration_date }}
                </td>
            </template>
            <template v-slot:item.insurance_expiration_date="{ item }">
                <td :class="expirationDateClass(item.insurance_expiration_date)">
                    {{ item.insurance_expiration_date }}
                </td>
            </template>


            <!-- <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" :style="{ color: 'blue' }" @click="updateItem(item)">
                    mdi-pencil
                </v-icon>
            </template> -->
        </v-data-table>
        <v-dialog v-model="dialogOpen" max-width="1100" content-class="dialog-height">            
            <template v-if="dialogOpen">                
                <VehiclesCard :Index="editedIndex" :Item="editedItem" :History="history"
                    @sent-info="handleInfoFromChild" :key="dialogOpen.toString()" />
            </template>
        </v-dialog>
        <div class="loader-container" v-if="showLoader">
            <div class="loader"></div>
        </div>
    </div>
</template>

<script>
import { bus } from '../../main.js';
import VehiclesCard from './VehiclesCard.vue';
import { feedTableVehicles, fetchVehicles } from '../../utils/vehicles_CRUD.js';
import { get_role } from '../../utils/role.js'

export default {
    components: {
        VehiclesCard
    },
    data: () => ({
        history: {
            employee_number: '',
            last_employee: '',
            last_date: ''
        },
        name: '',
        showLoader: false,
        dialogOpen: false,
        search: '',
        items: [],
        editedIndex: -1,
        headers: [
            /* { text: 'Actions', value: 'actions', sortable: false }, */
            { text: 'Vin', value: 'vin' },
            { text: 'ID SAMSARA', value: 'id_samsara' },
            { text: 'Name', value: 'name' },
            { text: 'Driver`s name', value: 'driver_name' },
            { text: 'License Plate', value: 'licensePlate' },
            { text: 'Make', value: 'make' },
            { text: 'Model', value: 'model' },
            { text: 'Year', value: 'year' },
            { text: 'Purchase Date', value: 'purchase_date' },
            { text: 'Next Oil Change', value: 'next_oil_change' },
            { text: 'Expiration Date', value: 'expiration_date' },
            //{ text: 'Registered Under', value: 'registered_under' },
            //{ text: 'Registration Address', value: 'registration_address' },
            { text: 'Plate Sticker Expiration Date', value: 'plate_sticker_expiration_date' },
            //{ text: 'Registration File', value: 'registration_file' },
            { text: 'City Sticker Expiration Date', value: 'city_sticker_expiration_date' },
            { text: 'Safety Inspection Expiration Date', value: 'safety_inspection_expiration_date' },
            //{ text: 'Safety Inspection File', value: 'safety_inspection_file' },
            { text: 'Emission Test Expiration Date', value: 'emission_test_expiration_date' },
            //{ text: 'Emission Test File', value: 'emission_test_file' },
            { text: 'SOS Triangle', value: 'sos_triangle' },
            { text: 'Triangle Expiration Date', value: 'triangle_expiration_date' },
            //{ text: 'Triangle File', value: 'triangle_file' },
            { text: 'Insurance Company', value: 'insurance_company' },
            { text: 'Insurance Expiration Date', value: 'insurance_expiration_date' },
            //{ text: 'Insurance File', value: 'insurance_file' },
            //{ text: 'Inspection Record', value: 'inspection_record' },
            //{ text: 'Last Modified Date', value: 'modified_date' },
            //{ text: 'Last Modified User', value: 'modified_user' }
        ],
        vehicles: [],
        editedItem: {
            driver_name: '',
            id_samsara: '',
            licensePlate: '',
            make: '',
            model: '',
            name: '',
            vin: '',
            year: '',
            purchase_date: null,
            next_oil_change: '',
            expiration_date: null,
            registered_under: '',
            registration_address: '',
            plate_sticker_expiration_date: null,
            registration_file: false,
            city_sticker_expiration_date: null,
            safety_inspection_expiration_date: null,
            safety_inspection_file: false,
            emission_test_expiration_date: null,
            emission_test_file: false,
            sos_triangle: false, // true = YES, false = NO
            triangle_expiration_date: null,
            triangle_file: false,
            insurance_company: '',
            insurance_expiration_date: null,
            insurance_file: false,
            inspection_record: '',
            //last_modified_date: null,
            //last_modified_user: '',
            history_json: {
                history: []
            },
        },
        defaultEditedItem: {
            driver_name: '',
            id_samsara: '',
            licensePlate: '',
            make: '',
            model: '',
            name: '',
            vin: '',
            year: '',
            purchase_date: null,
            next_oil_change: '',
            expiration_date: null,
            registered_under: '',
            registration_address: '',
            plate_sticker_expiration_date: null,
            registration_file: false,
            city_sticker_expiration_date: null,
            safety_inspection_expiration_date: null,
            safety_inspection_file: false,
            emission_test_expiration_date: null,
            emission_test_file: false,
            sos_triangle: false, // true = YES, false = NO
            triangle_expiration_date: null,
            triangle_file: false,
            insurance_company: '',
            insurance_expiration_date: null,
            insurance_file: false,
            inspection_record: '',
            //last_modified_date: null,
            //last_modified_user: '',
            history_json: {
                history: []
            },
        },
    }),
    computed: {
        filteredVehicles() {
            if (this.vehicles) {
                return this.vehicles.filter(vehicle =>
                    vehicle.name.toLowerCase().includes(this.search.toLowerCase())
                );
            }
            else {
                return [];
            }
        },
    },
    async mounted() {
        bus.$emit('login-success');
        bus.$emit('permissions');
        //this.showLoader = true;
        //this.vehicles = await fetchVehicles();
        this.refreshTable();
        console.log("this.vehicles: ", this.vehicles);
        //this.showLoader = false;
    },
    methods: {
        get_name() {
            try {
                const data = get_role();
                const employee_number = data.employee_number;
                const name = data.name;
                const last_name = data.last_name;
                this.history.last_employee = name + ' ' + last_name;
                this.history.employee_number = employee_number;
            } catch (error) {
                console.error("error-get_name: ", error);
            }
        },
        expirationDateClass(expirationDate) {
            const currentDate = new Date();
            const expiration = new Date(expirationDate);
            const diffTime = expiration - currentDate;
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            if (diffDays <= 0) {
                return 'red-cell';
            } else if (diffDays <= 31) {
                return 'yellow-cell';
            } else {
                return 'green-cell';
            }
        },
        updateItem(item) {
            console.log("item: ", item)

            /* this.editedIndex = item.id;
            this.editedItem = item; */
            this.editedIndex = this.vehicles.indexOf(item);
            console.log(this.editedIndex);
            this.editedItem = Object.assign({}, item);
            console.log("this.editedItem: ", this.editedItem);
            this.get_name();
            this.dialogOpen = true;
        },
        async handleInfoFromChild(info) {
            // Manejar la información recibida desde el hijo
            console.log('Información recibida desde el hijo:', info);
            this.dialogOpen = info; //Close dialog            
            this.refreshTable();
        },
        openDialog() {
            console.log("Opening dialog.....");
            this.dialogOpen = true;
        },
        async refreshTable() {
            this.showLoader = true;
            const response = await feedTableVehicles();
            if (!response.ok) {
                this.showLoader = false;
                throw new Error(`HTTP error! status: ${response.status}`);
            } else {
                this.vehicles = await fetchVehicles();
                console.log("this.vehicles: ", this.vehicles);
                console.log("Updating table....")
                this.showLoader = false;
            }
        },
        /* close() {
            console.log("Closing from Vehicles.vue......");
            //this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
            //this.editedIndex = -1;
            this.dialogOpen = false;
            console.log("this.dialogOpen: ", this.dialogOpen);
        }, */
    },
    watch: {
        dialogOpen(val) {
            console.log(">>>>>>>>>>>>>>>>>> val: ", val);
            if (!val) {
                console.log("val-false: ", val);
                this.dialogOpen = false;
                //this.editedItem = this.defaultEditedItem;
                this.editedIndex = -1;
            }
            //val || this.close();
        },
    }
}
</script>

<style scoped>
.dialog-height {
    max-height: 718px !important;
    min-height: 718px !important;
}

/* Cell Style */
.red-cell {
    background-color: #ff4c4c !important;
    /* Bright red */
    color: white !important;
    font-weight: bold !important;
}

.yellow-cell {
    background-color: #ffff66 !important;
    /* Bright yellow */
    color: black !important;
    font-weight: bold !important;
}

.green-cell {
    background-color: #66ff66 !important;
    /* Bright green */
    color: black !important;
    font-weight: bold !important;
}

/* Loader */
.loader-container {
    position: fixed;
    /* Para que el loader esté por encima de todo el contenido */
    top: 0;
    left: 0;
    width: 100%;
    /* Ocupa todo el ancho del viewport */
    height: 100%;
    /* Ocupa todo el alto del viewport */
    display: flex;
    justify-content: center;
    /* Centra horizontalmente */
    align-items: center;
    /* Centra verticalmente */
    background-color: rgba(255, 255, 255, 0.5);
    /* Fondo semitransparente */
    z-index: 9999;
    /* Coloca el loader por encima de todo el contenido */
}

.loader {
    width: 120px;
    height: 60px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    background: #fff;
    filter: blur(5px) contrast(10) hue-rotate(60deg);
    mix-blend-mode: darken;
}

.loader:before,
.loader:after {
    content: "";
    width: 40px;
    border-radius: 50%;
    background: #ff00ff;
    animation: l3 1s infinite alternate;
}

.loader:after {
    --s: -1;
}

@keyframes l3 {

    90%,
    100% {
        transform: translate(calc(var(--s, 1)*30px))
    }
}
</style>