<template>
    <div>
        <v-row class="pt-4 px-4">
            <v-col cols="12" sm="12" md="12" class="d-flex justify-center">
                <v-card height="auto"><!-- width="900" -->
                    <v-card-text>
                        <v-data-table :headers="headers" :items="filteredItems" sort-by="coverage"
                            class="elevation-1"><!-- @click:row="openDialog" -->
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-toolbar-title class="font-weight-bold">Computer Equipment
                                        Assignation</v-toolbar-title>
                                    <v-divider class="mx-4" inset vertical></v-divider>
                                    <v-select v-model="statusSelected" :items="status"
                                        style="max-width: 220px; margin-top: 30px; margin-right: 10px;"
                                        label="Filter Active/Inactive" @input="getItemsStatus"></v-select>
                                    <v-text-field v-model="search" label="Search" solo-inverted
                                        prepend-icon="mdi-magnify" class="mr-3 mt-5"></v-text-field>
                                    <v-btn color="blue" dark class="mb-2 mr-2" @click="refreshButton">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                    <v-btn color="success" dark class="mb-2 mr-2" @click="openDialog(null)">
                                        <v-icon>mdi-plus-thick</v-icon>
                                    </v-btn>
                                </v-toolbar>
                            </template>
                            <template v-slot:item.is_active="{ item }">
                                <v-icon :color="item.is_active ? 'green' : 'red'">
                                    {{ item.is_active ? 'mdi-check-bold' : 'mdi-close-thick' }}
                                </v-icon>
                            </template>

                            <template v-slot:item.assigned="{ item }">
                                <v-icon :color="item.assigned ? 'green' : 'red'">
                                    {{ item.assigned ? 'mdi-check-bold' : 'mdi-close-thick' }}
                                </v-icon>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-icon small class="mr-2" :style="{ color: 'orange' }"
                                    @click="openHistoryDialog(item, 'equipment')">
                                    mdi-desktop-classic
                                </v-icon>
                                <v-icon small class="mr-2" :style="{ color: 'purple' }"
                                    @click="openHistoryDialog(item, 'history')">
                                    mdi-history
                                </v-icon>
                                <v-icon small class="mr-2" :style="{ color: 'blue' }" @click="openDialog(item)">
                                    mdi-pencil
                                </v-icon>
                                <v-icon small :style="{ color: 'red' }" @click="deleteItem(item)">
                                    mdi-delete
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-dialog v-model="dialogOpen" max-width="1100"><!--  -->
                <v-card class="dialog-card" outlined>
                    <ComputerEquipmentAssignationCard :Index="editedIndex" :Item="editedItem"
                        :key="dialogOpen.toString()" @sent-info="handleInfoFromChild" />
                </v-card>
            </v-dialog>
            <!-- Modal de Historial -->
            <v-dialog v-model="historyDialog" max-width="1200px">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ title_dialog }}</span>
                    </v-card-title>
                    <v-card-subtitle class="title"
                        style="font-size: 1.2rem; font-weight: bold; color: #3f51b5; margin-top: -10px;">
                        {{ employee.employee_number }} -
                        {{ employee.name }} {{ employee.last_name }}
                    </v-card-subtitle>
                    <v-card-text>
                        <v-data-table :headers="historyHeaders" :items="selectedItemHistory" item-key="id"
                            class="elevation-1" dense>
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td>{{ item.uuid }}</td>
                                    <td>{{ item.assignment_date }}</td>
                                    <td style="white-space: normal;">{{ item.equipment_name }}</td>
                                    <td style="white-space: normal;">{{ item.brand }}</td>
                                    <td>{{ item.serial_number }}</td>
                                    <td style="white-space: normal;">{{ item.condition }}</td>
                                    <td>{{ item.code }}</td>
                                    <td style="white-space: normal;">{{ item.observations }}</td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>
        <div class="loader-container" v-if="showloader">
            <!-- <l-bouncy size="150" speed="0.8" color="#FF00F7"></l-bouncy> -->
            <l-grid size="75" speed="1.5" color="red"></l-grid>
        </div>
    </div>
</template>

<script>
import { bus } from '../../main.js';
import { updateAssignedEquipment } from '../../utils/It/computerEquipmentAssignation_CRUD.js'
import { getAllEquipmentAssigned } from '../../utils/It/computerEquipmentAssignation_CRUD.js'
import ComputerEquipmentAssignationCard from '../../components/It/ComputerEquipmentAssignationCard.vue'
import _ from 'lodash';
import { grid } from 'ldrs'

grid.register()

export default {
    components: {
        /* TypeOfCoverageCard */
        ComputerEquipmentAssignationCard
    },
    data: () => ({
        title_dialog: '',
        employee: {},
        // Status
        status: 'active',
        statusSelected: 'Active',
        status: ['Active', 'Inactive', 'All'],
        // History
        historyDialog: false,
        selectedItemHistory: [], // Para almacenar el historial del equipo seleccionado
        //////////////////////////////
        showloader: false,
        dialogInfoInsurance: false,
        // Headers para la tabla del historial
        historyHeaders: [
            { text: 'UUID', value: 'uuid' },
            { text: 'Assigment Date', value: 'assignment_date' },
            { text: 'Equipment Name', value: 'equipment_name' },
            { text: 'Brand', value: 'brand' },
            { text: 'Serial Number', value: 'serial_number' },
            { text: 'Condition', value: 'condition' },
            { text: 'Code', value: 'code' },
            { text: 'Observations', value: 'observations' },
        ],
        headers: [
            { text: 'Actions', value: 'actions', sortable: false },
            { text: 'UUID', value: 'uuid' },
            { text: 'Employee Number', value: 'employee.employee_number' },
            { text: 'Name', value: 'employee.name' },
            { text: 'Last Name', value: 'employee.last_name' },
            /* { text: 'Equipment Name', value: 'equipment_name' },
            { text: 'Brand', value: 'brand' },
            { text: 'Serial Number', value: 'serial_number' },
            { text: 'Condition', value: 'condition' },
            { text: 'Code', value: 'code' },
            { text: 'Observations', value: 'observations' },
            { text: 'Is Active', value: 'is_active' },
            { text: 'Assigned', value: 'assigned' }, */
            /* { text: 'History', value: 'history' }, */
            /* { text: 'Created At', value: 'created_at' } */
        ],
        dialogOpen: false,
        search: '',
        items: [],
        editedIndex: '',
        editedItem: {
            equipment_name: '',
            brand: '',
            serial_number: 'N/A', // default value
            condition: '',
            code: '',
            observations: ''
        },
        defaultItem: {
            equipment_name: '',
            brand: '',
            serial_number: 'N/A', // default value
            condition: '',
            code: '',
            observations: ''
        },

    }),
    computed: {
        filteredItems() {
            if (!this.search.trim()) {
                return this.items;
            } else {
                return this.items.filter(item =>
                    item.name.toLowerCase().includes(this.search.toLowerCase())
                );
            }
        }
    },
    async mounted() {
        ////console.log("-------------------MOUNTED.......................")
        bus.$emit('login-success');
        bus.$emit('permissions');
        await this.fetchItems('active');
    },
    methods: {
        async refreshButton() {
            console.log("<<<<<<<<<<< refreshButton");
            const response = await this.fetchItems('active');
            console.log("response: ", response);
            if (response) {
                this.statusSelected = 'Active';
                console.log("this.statusSelected : ", this.statusSelected);
            }
        },
        async getItemsStatus(item) {
            console.log("Filtering......", item);
            this.logsFilter = item;
            if (item === 'Active') {
                //this.trainingLogs = await this.get_TrainingLog_Forms();
                await this.fetchItems('active');
            } else if (item === 'Inactive') {
                //this.trainingLogs = await this.get_TrainingLog_FormsComplete();
                await this.fetchItems('inactive');
            } else {
                await this.fetchItems('all');
            }
        },
        openHistoryDialog(item, type) {
            console.log("item: ", item);
            //console.log("item.equipment_history: ", item.equipment_history);
            /* if (Array.isArray(item.equipment_history)) {
                console.error("Error: El valor de 'item' es un array pero debería ser un objeto.");
                return;
            } */

            console.log("item: ", item);
            //console.log("item.equipment_history: ", item.equipment_history);
            // Almacenar el historial del equipo seleccionado
            this.employee = item.employee;
            if (type === 'history') {
                this.selectedItemHistory = item.equipment_history.equipment;
                this.title_dialog = "Equipment History"
            } else {
                this.selectedItemHistory = item.equipment;
                this.title_dialog = "Assigned Equipment"
            }
            //this.selectedItemHistory = item;
            // Abrir el modal de historial
            this.historyDialog = true;
            // Almacenar el historial del equipo seleccionado
            /* this.selectedItemHistory = item.equipment_history;
            // Abrir el modal de historial
            this.historyDialog = true; */
        },
        /* viewHistory(item) {
            // Lógica para ver el historial del equipo seleccionado
            //console.log('Viewing history for:', item);
            // Aquí puedes abrir un diálogo o redirigir a una vista detallada
        }, */
        /* deleteItem(item) {
            this.editedIndex = this.employees.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        }, */
        /* openInfo(item) {
            //console.log("Openning info....", item);
            this.editedItem = item;
            //this.dialogInfoInsurance = true;
        }, */
        handleInfoFromChild(info) {
            // Manejar la información recibida desde el hijo
            //console.log('Información recibida desde el hijo:', info);
            this.dialogOpen = info; //Close dialog
            this.fetchItems('active'); //Refresh employees table
        },
        updateItem(item) {
            console.log("item: ", item)
            /* this.editedIndex = item.id;
            this.editedItem = item; */
            //this.editedIndex = this.items.indexOf(item);            
            //console.log("this.editedIndex: ", this.editedIndex);
            this.editedItem = Object.assign({}, item);
            this.editedIndex = this.editedItem.uuid;
            console.log("this.editedIndex: ", this.editedIndex);
            //console.log("<<<<<<<<<<<<<<<<<<<< this.editedItem: ", this.editedItem);
            this.dialogOpen = true;
        },
        async deleteItem(item) {
            try {
                this.showloader = true;
                console.log("item: ", item)
                const response = await updateAssignedEquipment(item);
                console.log("response: ", response);
                if (response.ok) {
                    this.showloader = false;
                    await this.fetchItems('active');
                }else{
                    this.showloader = false;
                }
            } catch (error) {
                this.showloader = false;
                console.error("deleteItem/error: ", error);
            }

        },
        openDialog(item) {
            console.log("Opening dialog.....", item)
            //this.editedItem = Object.assign({}, item);
            if (item != null) {
                this.editedIndex = item.uuid;
                if (this.editedIndex != '') {
                    //this.editedIndex = item.uuid;
                    console.log("this.editedIndex: ", this.editedIndex);
                    this.editedItem = _.cloneDeep(item);
                    console.log("this.editedItem: ", this.editedItem);
                    /* this.editedItem = Object.assign({}, item); */
                }
            } else {
                this.editedIndex = '';
            }
            this.dialogOpen = true;
        },
        async fetchItems(status) {
            try {
                this.showloader = true;
                this.items = await getAllEquipmentAssigned(status);
                console.log("this.items: ", this.items);
                this.showloader = false;
                return true;
            } catch (error) {
                console.error("Error fetching: ", error);
            }
        }
    },
    watch: {
        dialogOpen(val) {
            //console.log(">>>>>>>>>>>>>>>>>> val: ", val);
            if (!val) {
                this.editedItem = this.defaultEditedItem;
                this.editedIndex = '';
                this.fetchItems('active');
            }
        },
        /* dialogInfoInsurance(val) {
            //console.log(">>>>>>>>>>>>>>>>>>dialogInfoInsurance/val: ", val);
            if (!val) {
                this.editedItem = this.defaultEditedItem;
                this.editedIndex = '';
                this.fetchItems();
            }
        }, */
    }
}
</script>

<style>
.loader-container {
    position: fixed;
    /* Para que el loader esté por encima de todo el contenido */
    top: 0;
    left: 0;
    width: 100%;
    /* Ocupa todo el ancho del viewport */
    height: 100%;
    /* Ocupa todo el alto del viewport */
    display: flex;
    justify-content: center;
    /* Centra horizontalmente */
    align-items: center;
    /* Centra verticalmente */
    background-color: rgba(255, 255, 255, 0.5);
    /* Fondo semitransparente */
    z-index: 9999;
    /* Coloca el loader por encima de todo el contenido */
}

.dialog-height {
    max-height: auto !important;
    min-height: auto !important;
}

.dialog-card {
    padding: 0;
    /* Elimina el relleno interno */
    margin: 0;
    /* Elimina los márgenes */
}
</style>