<template>
    <employees-form />
</template>

<script>
import EmployeesForm from '../../components/Employees/EmployeesForm.vue'

export default {
    name: 'employeesFormView',
    components: {
        EmployeesForm,
    },
}
</script>