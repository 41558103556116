import { uploadFileToFleetS3 } from '../../utils/Insurance/insuranceBreakdownCRUD.js'

export function validateExtension(file) {
    const allowedExtensions = ['pdf', 'mp4'];
    //const file = this.recentPhotoFile;    

    // Obtener la extensión del archivo
    const extension = file.name.split('.').pop().toLowerCase();
    console.log("extension: ", extension);

    if (!allowedExtensions.includes(extension)) {
        // Si la extensión del archivo no está permitida, mostrar una alerta
        alert("Formato incorrecto");
        // También puedes mostrar un mensaje en la consola para fines de depuración
        console.log("El archivo debe ser un PDF.");
        // Limpiar el campo de archivo seleccionado
        //this.recentPhotoFile = null;                
        return false;
    } else {
        return true;
    }
}

export function renamedFile(file, newNameData) {
    //console.log("Rename File...")
    if (file === null) {
        console.log("Archivo vacío.................");
    } else {
        console.log("Archivo NO vacío.......");
    }
    const originalFileName = file.name;
    console.log("originalFileName: ", originalFileName);
    const fileExtension = originalFileName.slice((originalFileName.lastIndexOf(".") - 1 >>> 0) + 2);
    //console.log("fileExtension: ", fileExtension);
    // Create a new file with the desired name and the same file type

    // Obtener la fecha y hora actual
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().slice(0, 10); // yyyy-mm-dd
    const formattedTime = currentDate.toTimeString().slice(0, 8).replace(/:/g, '-'); // hh-mm-ss

    // Concatenar fecha y hora al nombre del archivo
    const newName = `${newNameData}_${formattedDate}_${formattedTime}`;
    console.log("newName: ", newName);

    const renamedFile = new File([file], newName.toLowerCase() + '.' + fileExtension, { type: file.type });
    console.log("renamedFile: ", renamedFile);
    return renamedFile;
}

export async function checkFiles(files, editedItem) {
    console.log("Checking files....");
    console.log("editedItem: ", editedItem);
    let existFile = false;
    try {
        let response;
        for (const key in files) {
            if (files[key] === null) {
                console.log(`${key} es vacío`);
            } else {
                existFile = true;
                console.log(`${key} está lleno`);
                response = await uploadFileToFleetS3(editedItem.vin, files[key]);
            }
        }
        console.log("For finished......");
        if (!existFile) {
            /* return true */
            return false
        } else {
            if (response.ok) {
                console.log(">>>>>>>>>>>>>>>>>>>>>>>> Uploading files finished.......");
                return true
            }
        }
    } catch (error) {
        console.error("Error uploading fleet files.....");
        return false;
    }
}

