<template>
    <div>
        <v-row class="pt-4 px-4">
            <v-col cols="12" sm="12" md="12">
                <v-card height="520">
                    <v-card-text>
                        <v-data-table :headers="headers" :items="filteredItems" sort-by="last_4_digits"
                            class="elevation-1">
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-toolbar-title class="font-weight-bold">Credit Card Car</v-toolbar-title>
                                    <v-divider class="mx-4" inset vertical></v-divider>
                                    <v-text-field v-model="search" label="Search" solo-inverted
                                        prepend-icon="mdi-magnify" class="mr-3 mt-5"></v-text-field>
                                    <v-btn color="blue" dark class="mb-2 mr-2" @click="fetchItems">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                    <v-btn color="success" dark class="mb-2 mr-2" @click="openDialog">
                                        <v-icon>mdi-plus-thick</v-icon>
                                    </v-btn>
                                </v-toolbar>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-icon small class="mr-2" :style="{ color: 'blue' }" @click="updateItem(item)">
                                    mdi-pencil
                                </v-icon>
                                <v-icon small :style="{ color: 'red' }" @click="deleteItem(item)">
                                    mdi-delete
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-dialog v-model="dialogOpen" max-width="700">
                <v-card class="dialog-card" outlined>
                    <CreditCardCarCard :Index="editedIndex" :Item="editedItem" :key="dialogOpen.toString()"
                        @sent-info="handleInfoFromChild" />
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import { bus } from '../../main.js';
import { getAllCreditCardManagements, deleteCreditCardManagement } from '../../utils/credit_card_managements_CRUD.js'
import CreditCardCarCard from '../../components/Accounting/CreditCardCarCard.vue'

export default {
    components: {        
        CreditCardCarCard
    },
    data: () => ({
        headers: [            
            { text: 'Actions', value: 'actions', sortable: false },
            { text: 'Last 4 Digits', value: 'last_4_digits' },
            { text: 'Samsara Vehicle', value: 'samsara_vehicle' },            
            { text: 'Card Type', value: 'card_type' },
            { text: 'Company', value: 'company' },
            { text: 'Link Date', value: 'link_date' },
            { text: 'Due Date', value: 'due_date' },
            { text: 'Ledger Account', value: 'ledger_account' }
        ],
        dialogOpen: false,
        search: '',
        items: [],
        editedIndex: -1,
        editedItem: {
            samsara_vehicle: '',
            credit_type: '',        
            last_4_digits: '',            
            company: '',            
            link_date: '',            
            due_date: '',            
            ledger_account: ''
        },
        defaultEditedItem: {
            samsara_vehicle: '',
            credit_type: '',
            last_4_digits: '',
            company: '',
            link_date: '',            
            due_date: '',            
            ledger_account: ''             
        }
    }),
    computed: {
        filteredItems() {
            if (!this.search.trim()) {
                return this.items;
            } else {
                return this.items.filter(item =>
                    item.last_4_digits.toLowerCase().includes(this.search.toLowerCase())
                );
            }
        }
    },
    async mounted() {
        //console.log("-------------------MOUNTED.......................")
        bus.$emit('login-success');
        bus.$emit('permissions');
        await this.fetchItems();
        
    },
    methods: {
        handleInfoFromChild(info) {
            // Manejar la información recibida desde el hijo
            console.log('Información recibida desde el hijo:', info);
            this.dialogOpen = info; //Close dialog
            this.fetchItems(); //Refresh employees table
        },
        updateItem(item) {
            console.log("item: ", item)
            /* this.editedIndex = item.id;
            this.editedItem = item; */
            this.editedIndex = this.items.indexOf(item);
            console.log(this.editedIndex);
            this.editedItem = Object.assign({}, item);
            this.dialogOpen = true;
        },
        async deleteItem(item) {
            console.log("item: ", item)
            const response = await deleteCreditCardManagement(item.id);
            if (response.ok) {
                this.fetchItems();
            }
        },
        openDialog() {
            console.log("Opening dialog.....")
            this.dialogOpen = true;
        },
        async fetchItems() {
            try {
                this.items = await getAllCreditCardManagements();
                console.log("this.items: ", this.items);
            } catch (error) {
                console.error("Error fetching Banks: ", error);
            }
        }
    },
    watch: {
        dialogOpen(val) {
            console.log("val: ", val);
            if (!val) {
                this.editedItem = this.defaultEditedItem;
                this.editedIndex = -1;
            }
        }
    }
}
</script>

<style scoped>
.dialog-card {
    padding: 0;
    /* Elimina el relleno interno */
    margin: 0;
    /* Elimina los márgenes */
}
</style>