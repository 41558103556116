import { GET_ALL_EMPLOYEES, UPDATE_EMPLOYEE, GET_ALL_EMPLOYEES_ACTIVE, GET_ALL_EMPLOYEES_BY_STATUS, EMPLOYEES_FILTER, EMPLOYEE, UPDATE_END_DATE_STATUS_EMPLOYEE, GET_EMPLOYEE_BY_EMPLOYEE_NUMBER, JOB_OFFER, SEND_JOB_OFFER, ACTIVATE_EMPLOYEE } from '../../src/utils/endpoints.js'
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { searchByEmployeeNumber } from '../utils/It/computerEquipmentAssignation_CRUD.js'

/**
 * Fetches all employees from the server.
 * @returns {Promise<data.employees>}
 * @throws {Error} Throws an error if the fetch operation fails.
 * @author Javier Daniel González Torres
 */
export async function getEmployees() {
    try {
        console.log("employees_CRUD - getEmployees");
        const response = await fetch(GET_ALL_EMPLOYEES);
        if (!response.ok) throw new Error('Failed to fetch employees');
        const data = await response.json();
        console.log('data-getEmployees: ', data);
        return data.employees;
    } catch (error) {
        console.error('Error fetching employees:', error);
    }
}

/**
 * Fetches employees by their status from the server.
 * @param {string} status - The status of the employees to fetch ('active' or 'inactive').
 * @returns {Promise<Array>} A promise that resolves to an array of employees.
 * @throws {Error} Throws an error if the fetch operation fails.
 * @author Javier Daniel González Torres
 */
export async function getEmployeesByStatus(status) {
    try {
        console.log("getEmployeesByStatus/status: ", status);
        // Valida que el status sea 'active' o 'inactive'
        if (!['Active', 'Inactive'].includes(status)) {
            throw new Error('Invalid status. Please use "active" or "inactive".');
        }

        // Construye la URL con el parámetro de estado
        const response = await fetch(`${GET_ALL_EMPLOYEES_BY_STATUS}${status}`);

        if (!response.ok) throw new Error('Failed to fetch employees by status');

        const data = await response.json();
        console.log(data.employees);
        return data.employees;
    } catch (error) {
        console.error('Error fetching employees by status:', error);
        throw error;
    }
    /* try {
        const response = await fetch(GET_ALL_EMPLOYEES_ACTIVE);
        if (!response.ok) throw new Error('Failed to fetch employees by status');
        const data = await response.json();
        console.log(data.employees);
        return  data.employees;
    } catch (error) {
        console.error('Error fetching employees by status:', error);
    } */
}

/**
 * Fetches the role of an employee by their employee number.
 * @param {string} employeeNumber - The employee number.
 * @returns {Promise<void>}
 * @throws {Error} Throws an error if the fetch operation fails.
 * @author Javier Daniel González Torres
 */
export async function getRole(employeeNumber) {
    try {
        const response = await fetch(`/role?employee_number=${employeeNumber}`);
        if (!response.ok) throw new Error('Failed to fetch role');
        const data = await response.json();
        console.log(data);
    } catch (error) {
        console.error('Error fetching role:', error);
    }
}

/**
 * Adds a new employee to the server.
 * @param {Object} employeeData - The employee data to add.
 * @returns {Promise<response>}
 * @throws {Error} Throws an error if the fetch operation fails.
 * @author Javier Daniel González Torres
 */
export async function addEmployee(employeeData) {
    try {
        const response = await fetch('/addEmployee', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(employeeData)
        });
        if (!response.ok) throw new Error('Failed to add employee');
        const data = await response.json();
        console.log(data);
        return response;
    } catch (error) {
        console.error('Error adding employee:', error);
    }
}

/**
 * Updates the status of an employee.
 * @param {Object} employeeData - The employee data with the new status.
 * @returns {Promise<response>}
 * @throws {Error} Throws an error if the fetch operation fails.
 * @author Javier Daniel González Torres
 */
export async function updateEmployeeStatus(employeeData) {
    try {
        const response = await fetch('/updateStatus', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(employeeData)
        });
        if (!response.ok) throw new Error('Failed to update employee status');
        const data = await response.json();
        console.log(data);
        return response;
    } catch (error) {
        console.error('Error updating employee status:', error);
    }
}

/**
 * Updates an employee's data.
 * @param {Object} employeeData - The employee data to update.
 * @returns {Promise<response>}
 * @throws {Error} Throws an error if the fetch operation fails.
 * @author Javier Daniel González Torres
 */
export async function updateEmployee(employeeData) {
    try {
        const response = await fetch(UPDATE_EMPLOYEE, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(employeeData)
        });
        if (!response.ok) throw new Error('Failed to update employee');
        const data = await response.json();
        console.log(data);
        return response;
    } catch (error) {
        console.error('Error updating employee:', error);
    }
}

/**
 * Fetches an employee by their employee number and their schedule.
 * @param {string} employeeNumber - The employee number.
 * @returns {Promise<void>}
 * @throws {Error} Throws an error if the fetch operation fails.
 * @author Javier Daniel González Torres
 */
export async function getEmployeeByNumber(employeeNumber) {
    try {
        const response = await fetch(`${GET_EMPLOYEE_BY_EMPLOYEE_NUMBER}?employee_number=${employeeNumber}`);
        console.log("response: ", response);
        if (!response.ok) throw new Error('Failed to fetch employee by number');
        const data = await response.json();
        console.log("data: ", data);
        return data;
    } catch (error) {
        console.error('Error fetching employee by number:', error);
    }
}

/**
 * Updates the document URLs of an employee.
 * @param {string} employeeNumber - The employee number.
 * @param {Object} urlData - The new URLs to update.
 * @returns {Promise<void>}
 * @throws {Error} Throws an error if the fetch operation fails.
 * @author Javier Daniel González Torres
 */
export async function updateEmployeeDocsUrls(employeeNumber, urlData) {
    try {
        const response = await fetch(`/employees/update_docs_urls?employee_number=${employeeNumber}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(urlData)
        });
        if (!response.ok) throw new Error('Failed to update employee document URLs');
        const data = await response.json();
        console.log(data);
    } catch (error) {
        console.error('Error updating employee document URLs:', error);
    }
}

export async function filterEmployees(bodyData) {
    console.log("bodyData: ", bodyData)

    try {
        // Hacemos la petición POST usando fetch
        const response = await fetch(EMPLOYEES_FILTER, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',  // Indicamos que enviamos JSON
            },
            body: JSON.stringify(bodyData)  // Convertimos el body a JSON
        });

        // Revisamos si la respuesta fue exitosa
        if (!response.ok) {
            throw new Error('Error en la petición: ' + response.statusText);
        }

        // Parseamos la respuesta como JSON
        const data = await response.json();

        // Mostramos los resultados de la consulta
        console.log('Resultados de la búsqueda:', data);

        // Aquí puedes manipular los resultados de la manera que desees
        return data;

    } catch (error) {
        console.error('Error al consumir el endpoint:', error);
    }
}

export async function exportEmployeesToExcel(employees) {
    if (employees.length > 0) {
        const columnOrder = [
            'EMPLOYEE_NUMBER', 'NAME', 'LAST_NAME', 'POSITION', 'STATUS', 'WORKER_CATEGORY', 'DEPARTMENT', 'REPORT',
            'ROLE', 'PERMISSIONS', 'HIRE_DATE', 'END_DATE', 'BIRTHDATE', 'NOVA_EMAIL', 'PERSONAL_PHONE', 'ADDRESS',
            'CITY', 'STATE', 'COUNTRY', 'POSTAL_CODE', 'NAME_EMERGENCY', 'LAST_NAME_EMERGENCY', 'PHONE_EMERGENCY',
            'ADDRESS_EMERGENCY', 'RELATIONSHIP_EMERGENCY', 'FORM_STATUS', 'OBSERVATIONS', 'MARITAL_STATUS', 'ETHNICITY',
            'GENDER', 'PAYMENT_METHOD', 'PAY_FREQUENCY', 'ID_TYPE', 'ID_NUMBER', 'ASSIGNED_VEHICLE', 'LOCATION',
            'ADDRESS_LOCATION', 'RATE_OFFICE_STAFF', 'TRAINING_RATE', 'TRAVELING_TIME_RATE', 'COMMISSIONS', 'BONUS_TYPE',
            'AMOUNT_BONUS', 'TYPE_OF_COMISSIONS', 'RECEIVES_COMISSIONS', 'SINCE', 'WALK_INS', 'TRAINING_LOGS',
            'PERMIT', 'PERMIT_EXPIRATION', 'HAS_ASSIGNED_EQUIPMENT', 'HAD_ASSIGNED_EQUIPMENT'
        ];

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Employees');

        worksheet.addRow(columnOrder);
        worksheet.getRow(1).eachCell((cell) => {
            cell.font = { bold: true };
        });

        for (const employee of employees) {
            let rowData = [];
            for (const column of columnOrder) {
                let value;
                if (column === 'REPORT' && employee['report']) {
                    value = employee['report']
                        .map((rep) => `${rep.name} ${rep.last_name}`)
                        .join(', ');
                } else {
                    value = employee[column.toLowerCase()];
                }
                rowData.push(value);
            }
            worksheet.addRow(rowData);

            let assignedEquipment = null;
            if (employee.has_assigned_equipment) {
                assignedEquipment = await searchByEmployeeNumber(employee.employee_number);
                console.log("assignedEquipment: ", assignedEquipment);
                if (assignedEquipment && Array.isArray(assignedEquipment.equipment)) {
                    addEquipmentSheet(workbook, `${employee.employee_number}_has`, assignedEquipment.equipment);
                } else {
                    console.warn(`No equipment found for employee: ${employee.employee_number}`);
                }
            }

            /* if (employee.had_assigned_equipment) {
                assignedEquipment = await searchByEmployeeNumber(employee.employee_number);
                console.log("assignedEquipment: ", assignedEquipment);
                addEquipmentSheet(workbook, `${employee.employee_number}_had`, assignedEquipment.equipment_history.equipment);
            } */
           // Manejo de historial de equipos
           if (employee.had_assigned_equipment) {
            const assignedEquipment = await searchByEmployeeNumber(employee.employee_number);
            if (assignedEquipment?.equipment_history?.equipment) {
                addEquipmentHistorySheet(workbook, `${employee.employee_number}_had`, assignedEquipment.equipment_history.equipment);
            } else {
                console.warn(`No equipment history found for employee: ${employee.employee_number}`);
            }
        }
        }

        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), 'employees_filtered.xlsx');
    } else {
        console.log('No hay empleados para exportar');
    }
}

// Función para historial de equipos
function addEquipmentHistorySheet(workbook, sheetName, equipmentList) {
    if (!Array.isArray(equipmentList)) {
        console.error(`Invalid equipmentList for sheet: ${sheetName}`);
        return;
    }

    const historyColumns = ['CODE', 'UUID', 'BRAND', 'ASSIGNED', 'CONDITION', 'OBSERVATIONS', 'SERIAL_NUMBER', 'EQUIPMENT_NAME', 'ASSIGNMENT_DATE'];
    const historySheet = workbook.addWorksheet(sheetName);

    historySheet.addRow(historyColumns);
    historySheet.getRow(1).eachCell((cell) => {
        cell.font = { bold: true };
    });

    for (const equipment of equipmentList) {
        const rowData = historyColumns.map((col) => equipment[col.toLowerCase()] || '');
        historySheet.addRow(rowData);
    }
}

function addEquipmentSheet(workbook, sheetName, equipmentList) {
    if (!Array.isArray(equipmentList)) {
        console.error(`Invalid equipmentList for sheet: ${sheetName}`);
        return;
    }

    const equipmentColumns = ['CODE', 'UUID', 'BRAND', 'ASSIGNED', 'CONDITION', 'OBSERVATIONS', 'SERIAL_NUMBER', 'EQUIPMENT_NAME', 'ASSIGNMENT_DATE'];
    const equipmentSheet = workbook.addWorksheet(sheetName);

    equipmentSheet.addRow(equipmentColumns);
    equipmentSheet.getRow(1).eachCell((cell) => {
        cell.font = { bold: true };
    });

    for (const equipment of equipmentList) {
        const rowData = equipmentColumns.map((col) => equipment[col.toLowerCase()] || '');
        equipmentSheet.addRow(rowData);
    }
}

export async function fetchActiveEmployeesWithVehicle() {
    const url = `${EMPLOYEE}/active_with_vehicle`; // La URL de tu endpoint

    try {
        const response = await fetch(url, {
            method: 'GET', // Método HTTP
            headers: {
                'Content-Type': 'application/json', // Indicamos que esperamos JSON
            },
        });

        // Verificar si la respuesta fue exitosa
        if (response.ok) {
            const data = await response.json(); // Convertir la respuesta a JSON
            console.log('Active employees with assigned vehicle:', data);
            return data; // Retornamos los datos
        } else {
            console.error('Error fetching data:', response.statusText);
        }
    } catch (error) {
        console.error('Fetch error:', error); // Manejo de errores
    }
}

export async function updateEndDate(employee_number, endDate = null) {
    console.log("employeeId: ", employee_number);
    console.log("endDate: ", endDate);
    // Define el cuerpo de la solicitud
    const data = endDate ? { end_date: endDate } : {};

    try {
        // Realiza la solicitud PUT al endpoint
        const response = await fetch(`${UPDATE_END_DATE_STATUS_EMPLOYEE}${employee_number}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        // Verifica si la solicitud fue exitosa
        if (response.ok) {
            const result = await response.json();
            console.log(result.message); // Mensaje exitoso
        } else {
            const errorData = await response.json();
            console.error('Error:', errorData.error); // Mensaje de error
        }
    } catch (error) {
        console.error('Request failed', error); // Error en la solicitud
    }
}

export async function activateEmployee(employeeNumber) {
    const url = `${ACTIVATE_EMPLOYEE}${employeeNumber}`;

    try {
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.ok) {
            const data = await response.json();
            console.log("Employee updated successfully:", data.message);
            /* Swal.fire({
                icon: 'success',
                title: 'Updated!',
                text: 'Employee data updated successfully.'
            }); */
            return response;
        } else {
            const errorData = await response.json();
            console.error("Error updating employee:", errorData.error);
            /* Swal.fire({
                icon: 'error',
                title: 'Error',
                text: `Error: ${errorData.error}`
            }); */
        }
    } catch (error) {
        console.error("Network error:", error);
        /* Swal.fire({
            icon: 'error',
            title: 'Network Error',
            text: 'An unexpected error occurred while updating the employee.'
        }); */
    }
}

export async function fetchEmployeeJobOffer(employeeNumber) {
    try {
        const response = await fetch(`${JOB_OFFER}${employeeNumber}/full_report`, {
            method: 'GET',
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error("Error fetching the report:", errorData.error);
            alert(`Error: ${errorData.error}`);
            return null;
        }

        const contentType = response.headers.get("content-type");
        if (contentType !== "application/pdf") {
            const errorText = await response.text();
            console.error("Unexpected content type:", contentType);
            console.error("Response body:", errorText);
            alert("Received an unexpected file format. Expected PDF.");
            return null;
        }

        return await response.blob();
    } catch (error) {
        console.error("An error occurred while fetching the report:", error);
        alert("An unexpected error occurred. Please try again.");
        return null;
    }
}

export async function sendJobOfferEmail(employeeNumber) {
    const url = `${SEND_JOB_OFFER}${employeeNumber}`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.ok) {
            const data = await response.json();
            console.log("Email sent successfully:", data.message);
            //alert("Job offer email sent successfully!");
        } else {
            const errorData = await response.json();
            console.error("Error sending email:", errorData.error);
            //alert(`Error: ${errorData.error}`);
        }
    } catch (error) {
        console.error("Network error:", error);
        //alert("An unexpected error occurred while sending the email.");
    }
}

export async function fetchEmployeesByLocationAndStatus(location, status) {
    const url = `${EMPLOYEE}/filter?location=${encodeURIComponent(location)}&status=${encodeURIComponent(status)}`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            // Manejo de errores basado en el código de respuesta
            const errorData = await response.json();
            throw new Error(errorData.error || errorData.message || 'Unknown error');
        }

        // Procesar los datos de la respuesta
        const data = await response.json();
        console.log('Employees:', data);
        return data;
    } catch (error) {
        // Manejo de errores generales
        console.error('Error fetching employees:', error.message);
        return { error: error.message };
    }
}
