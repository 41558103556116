<template>
    <new-training-log-form />
</template>

<script>
import NewTrainingLogForm from '../../components/Training_log/NewTrainingLogForm.vue'

export default {
    name: 'NewTrainingLogView',
    components: {
        NewTrainingLogForm,
    },
}
</script>