<template>
    <payroll />
</template>

<script>
import Payroll from '../../components/Payroll/Payroll.vue'

export default {
    name: 'PayrollView',
    components: {
        Payroll,
    },
}
</script>