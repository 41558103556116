<template>
    <insurance />
</template>

<script>
import Insurance from '../../components/Fleet/Insurance.vue'

export default {
    name: 'InsuranceView',
    components: {
        Insurance,
    },
}
</script>