<template>
    <div>
        <v-row class="pt-4 px-4" >
            <v-col cols="12" sm="12" md="12" class="d-flex justify-center">
                <v-card height="520" width="1800">
                    <v-card-text>
                        <v-data-table :headers="headers" :items="filteredItems" sort-by="detail"
                            class="elevation-1">
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-toolbar-title class="font-weight-bold">Payroll</v-toolbar-title>
                                    <v-divider class="mx-4" inset vertical></v-divider>
                                    <v-text-field v-model="search" label="Search" solo-inverted
                                        prepend-icon="mdi-magnify" class="mr-3 mt-5"></v-text-field>
                                    <!-- <v-btn color="blue" dark class="mb-2 mr-2" @click="fetchItems">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                    <v-btn color="success" dark class="mb-2 mr-2" @click="openDialog">
                                        <v-icon>mdi-plus-thick</v-icon>
                                    </v-btn> -->
                                </v-toolbar>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-icon small class="mr-2" :style="{ color: 'blue' }" @click="updateItem(item)">
                                    mdi-pencil
                                </v-icon>
                                <v-icon small :style="{ color: 'red' }" @click="deleteItem(item)">
                                    mdi-delete
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
            <!-- <v-dialog v-model="dialogOpen" max-width="700">
                <v-card class="dialog-card" outlined>
                    <CreditCardCarCard :Index="editedIndex" :Item="editedItem" :key="dialogOpen.toString()"
                        @sent-info="handleInfoFromChild" />
                </v-card>
            </v-dialog> -->
        </v-row>
    </div>
</template>

<script>
import { bus } from '../../main.js';
import {getPayrolls} from '../../utils/Payroll/payroll_CRUD.js'
import {get_employee_number} from '../../utils/get_employee_number.js'

export default {
    components: {        
        
    },
    data: () => ({
        headers: [            
            { text: 'Employee Number', value: 'employee_number' },
            { text: 'Instructor', value: 'instructor' },
            { text: 'Student Name', value: 'student_name' },
            { text: 'Date', value: 'date' },
            { text: 'Type', value: 'type' },
            { text: 'Start time', value: 'start_time' },
            { text: 'End time', value: 'end_time' },
            { text: 'Number of hours', value: 'number_of_hours' },
            { text: 'Status', value: 'status' },
            { text: 'Deduction tickets', value: 'deduction_tickets' },
            { text: 'Loans', value: 'loans' },
            { text: 'Bonuses', value: 'bonuses' },
            { text: 'Details', value: 'detail' },            
        ],
        dialogOpen: false,
        search: '',
        items: [],
        editedIndex: -1,
        editedItem: {
            employee_number: '',
            detail: '',        
            type: '',            
            date: '',            
            start_time: '',            
            end_time: '',            
            number_of_hours: '',
            instructor: '',
            status: '',
            deduction_tickets: '',
            loans: '',
            bonuses: ''
        },
        defaultEditedItem: {
            employee_number: '',
            detail: '',        
            type: '',            
            date: '',            
            start_time: '',            
            end_time: '',            
            number_of_hours: '',
            instructor: '',
            status: '',
            deduction_tickets: '',
            loans: '',
            bonuses: ''             
        }
    }),
    computed: {
        filteredItems() {
            if (!this.search.trim()) {
                return this.items;
            } else {
                return this.items.filter(item =>
                    item.details.toLowerCase().includes(this.search.toLowerCase())
                );
            }
        }
    },
    async mounted() {
        //console.log("-------------------MOUNTED.......................")
        bus.$emit('login-success');
        bus.$emit('permissions');
        this.editedItem.employee_number = get_employee_number();
        await this.fetchItems();
        
    },
    methods: {
        handleInfoFromChild(info) {
            // Manejar la información recibida desde el hijo
            /* console.log('Información recibida desde el hijo:', info);
            this.dialogOpen = info; //Close dialog
            this.fetchItems(); //Refresh employees table */
        },
        updateItem(item) {
            /* console.log("item: ", item)
            this.editedIndex = this.items.indexOf(item);
            console.log(this.editedIndex);
            this.editedItem = Object.assign({}, item);
            this.dialogOpen = true; */
        },
        async deleteItem(item) {
            /* console.log("item: ", item)
            const response = await deleteCreditCardManagement(item.id);
            if (response.ok) {
                this.fetchItems();
            } */
        },
        openDialog() {
            /* console.log("Opening dialog.....")
            this.dialogOpen = true; */
        },
        async fetchItems() {
            try {
                console.log("this.editedItem.employee_number: ", this.editedItem.employee_number);
                this.items = await getPayrolls(this.editedItem.employee_number);
                console.log("this.items: ", this.items);
            } catch (error) {
                console.error("Error fetching Banks: ", error);
            }
        }
    },
    watch: {
        /* dialogOpen(val) {
            console.log("val: ", val);
            if (!val) {
                this.editedItem = this.defaultEditedItem;
                this.editedIndex = -1;
            }
        } */
    }
}
</script>

<style scoped>
.dialog-card {
    padding: 0;
    /* Elimina el relleno interno */
    margin: 0;
    /* Elimina los márgenes */
}
</style>