export const formStructure = {
  instructor: '',
  vehicle_name: '',
  location: '',
  department: "Fleet",
  expense_date: "",
  payment_method: "",
  card_number: "",
  card_account: {
    account_id: '',
    account_code: '',
    account_name: ''
  },
  expense_type: {
    type: [
      {
        type: {
          account_code: '',
          account_id: '',
          account_name: '',
          account_type: '',
          description: '',
        },
        amount: null,
        discount: null,
        total: null,
        description: '',
        files_names: [],
        files: [],
        supplier: null,
        tags: [],
        location: '',
      }
    ]
  },
  ticket_name_s3: [],
  supplier: null,
  description: "",
  amount: null,
  management_name: null,
  driver_name: '',
  status: false,
  created_by: '',
  prorated: false,
};

export const typeOfExpenseStructure = {
  type: {
    account_code: '',
    account_id: '',
    account_name: '',
    account_type: '',
    description: '',
  },
  amount: null,
  discount: null,
  total: null,
  description: '',
  files_names: [],
  files: [],
  supplier: null,
  porcentage: null,
  tags: [],
  location: '',
}