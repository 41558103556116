<template>
    <v-col cols="12" sm="12" md="12">
        <v-card>
            <v-card-title>
                <span class="headline">Computer Equipment Assignation</span>
            </v-card-title>

            <v-col>
                <v-form ref='form'>

                    <v-card-text>
                        <v-divider class="mt-4 mb-6"></v-divider>
                        <h4>Employee</h4>
                        <v-divider class="mt-4 mb-6"></v-divider>
                        <v-row>
                            <v-col cols="6">
                                <v-combobox v-model="employeeSelected" :items="formattedEmployeeItems"
                                    item-text="displayText" item-value="employee_number" label="Select Employee"
                                    return-object :rules="[rules.validObject]" required></v-combobox>
                            </v-col>
                        </v-row>
                        <v-divider class="mt-4 mb-6"></v-divider>
                        <h4>Certificates</h4>
                        <v-row>
                            <v-col cols="6" class="d-flex align-center">
                                <v-file-input v-model="handover_file" label="Upload Equipment Handover Certificate"
                                    accept="/*"
                                    @change="renameFile('handover_file', 'handover_certificate')"></v-file-input>
                                <v-icon class="mr-2" :color="handover_certificate ? 'green' : 'red'"
                                    @click="openModal('Equipment Handover Certificate', 'handover_certificate.pdf', handover_file)">mdi-file-document-outline</v-icon>
                            </v-col><!-- @click="openCertificate('handover')" -->
                            <v-col cols="6" class="d-flex align-center">
                                <v-file-input v-model="receipt_file" label="Upload Equipment Receipt Certificate"
                                    accept="/*"
                                    @change="renameFile('receipt_file', 'receipt_certificate')"></v-file-input>
                                <v-icon class="mr-2" :color="receipt_certificate ? 'green' : 'red'"
                                    @click="openModal('Equipment Receipt Certificate', 'receipt_certificate.pdf', receipt_file)">mdi-file-document-outline</v-icon><!-- @click="openCertificate('receipt')" -->
                            </v-col>
                        </v-row>
                        <div v-if="Item && Item.uuid">
                            <v-divider class="mt-4 mb-6"></v-divider>
                            <h4>Generate Certificates</h4>
                            <v-row>
                                <v-col cols="4" md="4">
                                    <!-- Assignation Date -->
                                    <v-menu v-model="assignation_dateMenu" :close-on-content-click="false"
                                        :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="data_certificate.assignation_date"
                                                label="Assignation Date" prepend-icon="mdi-calendar" readonly
                                                v-bind="attrs" v-on="on" :rules="[rules.required]"
                                                required></v-text-field>
                                        </template>
                                        <v-date-picker v-model="data_certificate.assignation_date"
                                            @input="assignation_dateMenu = false" :rules="[rules.required]"
                                            required></v-date-picker>
                                    </v-menu><!-- ref="assignation_date" -->
                                </v-col>
                                <v-col cols="4" md="4">
                                    <!-- Delivery Date -->
                                    <v-menu v-model="delivery_dateMenu" :close-on-content-click="false"
                                        :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="data_certificate.delivery_date" label="Delivery Date"
                                                prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                                                :rules="[rules.required]" required></v-text-field>
                                        </template>
                                        <v-date-picker v-model="data_certificate.delivery_date"
                                            @input="delivery_dateMenu = false" :rules="[rules.required]"
                                            required></v-date-picker>
                                    </v-menu><!-- ref="assignation_date"  -->
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-textarea v-model="data_certificate.observations" label="Observations">
                                    </v-textarea>
                                </v-col>
                            </v-row>
                            <v-checkbox v-model="data_certificate.signature"
                                label="Include Digital Signature"></v-checkbox>
                            <v-btn color="success" class="mx-2" @click="generateHandover">
                                HANDOVER
                            </v-btn>
                            <v-btn color="info" class="mx-2" @click="generateReceipt">
                                RECEIPT
                            </v-btn>
                        </div>


                        <v-divider class="mt-4 mb-6"></v-divider>
                        <h4>Computer Equipment</h4>
                        <v-divider class="mt-4 mb-6"></v-divider>
                        <v-row>
                            <v-col cols="4" md="4">
                                <!-- Assignation Date -->
                                <v-menu ref="assignation_date" v-model="dateMenu" :close-on-content-click="false"
                                    :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="assignation_date" label="Assignation Date"
                                            prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                                            :rules="[rules.required]" required></v-text-field>
                                    </template>
                                    <v-date-picker v-model="assignation_date" @input="dateMenu = false"
                                        :rules="[rules.required]" required></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="5">
                                <v-combobox v-model="equipmentSelected" :items="formattedComputerEquipmentItems"
                                    item-text="displayText" item-value="uuid" label="Select Computer Equipment"
                                    return-object></v-combobox>
                            </v-col>
                            <v-col cols="1" class="mt-3">
                                <v-btn :disabled="!isFormComplete" @click="addItem" :class="buttonClasses" icon>
                                    <v-icon class="white--text icon-thick">mdi-plus</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <!-- <v-form ref='form'> -->
                        <v-divider class="mt-4 mb-6"></v-divider>
                        <h4>Computer Equipment INFO</h4>
                        <v-divider class="mt-4 mb-6"></v-divider>
                        <v-row>
                            <v-col><!-- cols="6" -->
                                <v-text-field v-model="editedItem.equipment_name" label="Equipment Name" readonly>
                                </v-text-field>
                            </v-col>
                            <v-col><!-- cols="6" -->
                                <v-text-field v-model="editedItem.brand" label="Brand" readonly>
                                </v-text-field>
                            </v-col>
                            <v-col><!-- cols="6" -->
                                <v-text-field v-model="editedItem.serial_number" label="Serial Number" readonly>
                                </v-text-field>
                            </v-col>
                            <v-col><!-- cols="6" -->
                                <v-text-field v-model="editedItem.condition" label="Condition" readonly>
                                </v-text-field>
                            </v-col>
                            <v-col><!-- cols="6" -->
                                <v-text-field v-model="editedItem.code" label="Code">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea v-model="editedItem.observations" label="Observations" readonly>
                                </v-textarea>
                            </v-col>
                        </v-row>
                        <v-divider class="mt-4 mb-6"></v-divider>
                        <!-- </v-form> -->
                    </v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <template>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left"
                                                    style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                    Name
                                                </th>
                                                <th class="text-left"
                                                    style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                    Serial Number
                                                </th>
                                                <th class="text-left"
                                                    style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                    Assigment Date
                                                </th>
                                                <th class="text-center"
                                                    style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                    Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in assignedEquipment" :key="index">
                                                <td style="white-space: normal; word-wrap: break-word;">{{
                                                    item.equipment_name }}</td>
                                                <td style="white-space: normal; word-wrap: break-word;">{{
                                                    item.serial_number }}</td>
                                                <td style="white-space: normal; word-wrap: break-word;">{{
                                                    item.assignment_date }}</td>
                                                <td class="text-center">
                                                    <v-icon color="red" @click="deleteItem(index)">
                                                        mdi-delete
                                                    </v-icon>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </template>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="submitForm">
                    SAVE
                </v-btn>
            </v-card-actions>
        </v-card>
        <div class="loader-container" v-if="showloader">
            <l-grid size="75" speed="1.5" color="red"></l-grid>
        </div>
        <file-modal :modalOpen.sync="modalOpen" :title="modalTitle" :dataFileUrl="dataFileUrl"></file-modal>
    </v-col>
</template>

<script>
import { getUnassignedComputerEquipment } from '../../utils/It/computerEquipment_CRUD.js'
import { createEquipmentAssigned, updateEquipmentAssigned, uploadCertificate, generateCertificate } from '../../utils/It/computerEquipmentAssignation_CRUD.js'
import _ from 'lodash';
import { getEmployeesByStatus } from '../../utils/employees_CRUD.js'
import { grid } from 'ldrs'
import { generatePublicURL } from '../../utils/presignedUrl.js'
import FileModal from '../../components/Modals/FileModal.vue'
grid.register()

export default {
    components: {
        FileModal
    },
    name: 'ComputerEquipmentCard',
    props: {
        Index: String,
        Item: Object
    },
    data: () => ({
        assignation_dateMenu: false,
        delivery_dateMenu: false,
        data_certificate: {
            signature: false,
            assignation_date: null,
            observations: '',
            delivery_date: null,
            handover: false,
            receipt: false,
        },
        // File modal
        modalOpen: false,
        modalTitle: '',
        dataFileUrl: '',
        handover_certificate: false,
        receipt_certificate: false,
        handover_file: null,
        receipt_file: null,
        showloader: false,
        dateMenu: false,
        assignation_date: null,
        // Certificate
        certificate_file: null,
        // Employee
        employeeSelected: null,
        employeesItems: [],
        employee: {
            employee_number: '',
            name: '',
            last_name: ''
        },
        // Equipment
        computerEquipmentItems: [],
        equipmentSelected: null,
        assignedEquipment: [],
        ////////////////////
        editedItem: {
            equipment_name: '',
            brand: '',
            serial_number: 'N/A', // default value
            condition: '',
            code: '',
            observations: '',
            uuid: '',
            is_active: null,
            history: null,
            assigned: null,
        },
        defaultItem: {
            equipment_name: '',
            brand: '',
            serial_number: 'N/A', // default value
            condition: '',
            code: '',
            observations: '',
            uuid: '',
            is_active: null,
            history: null,
            assigned: null,
        },
        rules: {
            required: value => !!value || 'Required.',
            validObject: value => {
                // Verifica si el objeto tiene la propiedad 'employee_number' 
                return (value && value.employee_number) ? true : 'Please select a valid employee.';
            },
        },
        editedIndex: '',
    }),

    computed: {
        formattedEmployeeItems() {
            return this.employeesItems.map(item => ({
                ...item,
                displayText: `${item.employee_number} - ${item.name} ${item.last_name}`  // Texto concatenado
            }));
        },
        buttonClasses() {
            return {
                'light-green-background': this.equipmentSelected,
                'disabled-background': !this.equipmentSelected,
            };
        },
        isFormComplete() {
            return (
                this.equipmentSelected
                /* this.type.type &&
                this.type.description &&
                this.type.price */
            );
        },
        formattedComputerEquipmentItems() {
            return this.computerEquipmentItems.map(item => ({
                ...item,
                displayText: `${item.serial_number} - ${item.equipment_name}`  // Texto concatenado
            }));
        }
    },
    watch: {
        employeeSelected(val) {
            console.log("employeeSelected: ", val);
            if (val != null) {
                this.employee.employee_number = val.employee_number;
                this.employee.name = val.name;
                this.employee.last_name = val.last_name;
                console.log("this.employee: ", this.employee);
            }
        },
        equipmentSelected(val, oldVal) {
            console.log("this.equipmentSelected: ", this.equipmentSelected);
            if (val !== oldVal && typeof val === 'object') {
                console.log("Equipment Selected (Object): ", val);
                this.equipmentSelected = val;
                // Crear una copia profunda del objeto seleccionado
                this.editedItem = JSON.parse(JSON.stringify(val));
            }
            if (val === null) {
                this.editedItem = Object.assign({}, this.defaultItem);
            }
        }
    },
    async mounted() {
        try {
            this.showloader = true;
            console.log("-------------------MOUNTED.......................")
            console.log("this.Index: ", this.Index);
            // Asigna la fecha de hoy en el formato adecuado
            const today = new Date().toISOString().substr(0, 10); // Obtiene la fecha en formato YYYY-MM-DD
            this.assignation_date = today;
            this.$nextTick(() => {
                this.$refs.form.resetValidation();
            });
            console.log("this.Index: ", this.Index);

            this.computerEquipmentItems = await getUnassignedComputerEquipment();
            console.log("this.computerEquipmentItems: ", this.computerEquipmentItems);

            /* const { employee_number, name, last_name } = get_role();
            this.employee.employee_number = employee_number;
            this.employee.name = name;
            this.employee.last_name = last_name;
            console.log("this.employee: ", this.employee); */
            this.employeesItems = await getEmployeesByStatus('Active');

            if (this.Index !== '') {
                console.log("-------------- UPDATING.......");
                console.log("+++++++++ this.item: ", this.Item);
                /* this.editedItem = _.cloneDeep(this.Item); */
                //this.editedItem = this.Item;
                this.editedIndex = this.Index;

                this.assignedEquipment = this.Item.equipment;
                //this.employee = this.Item.employee;
                //this.employeeSelected = this.Item.employee.employee_number + ' - ' + this.Item.employee.name + ' ' + this.Item.employee.last_name;
                this.employeeSelected = {
                    employee_number: this.Item.employee.employee_number,
                    name: this.Item.employee.name,
                    last_name: this.Item.employee.last_name,
                    displayText: `${this.Item.employee.employee_number} - ${this.Item.employee.name} ${this.Item.employee.last_name}`
                };
                this.handover_certificate = this.Item.handover_certificate;
                this.receipt_certificate = this.Item.receipt_certificate;
                //this.editedItem.id = this.Index;
                //console.log("this.editedIndex: ", this.editedIndex);
                //console.log("this.editedItem: ", this.editedItem);

            } else {
                console.log("CREATING.......", this.editedItem);
                this.editedItem = this.defaultItem;
                this.editedIndex = '';
                this.editDates = true;
            }
            this.showloader = false;
        } catch (error) {
            console.error("MOUNTED: ", error);
        }
    },
    methods: {
        generateHandover() {
            this.data_certificate.handover = true;
            this.data_certificate.receipt = false;
            console.log("Generating handover: ", this.data_certificate);
            const recordId = this.Item.uuid;
            generateCertificate(recordId, this.data_certificate);
        },
        generateReceipt() {
            this.data_certificate.handover = false;
            this.data_certificate.receipt = true;
            console.log("Generating receipt: ", this.data_certificate);
            const recordId = this.Item.uuid;
            generateCertificate(recordId, this.data_certificate);
        },
        async openModal(title, fileName, file) {
            console.log("Opening modal....", file);
            this.modalTitle = title;
            if (file === null) {
                const presignedUrl = await generatePublicURL(`it/${this.Item.employee.employee_number}`, fileName);
                console.log("presignedUrl: ", presignedUrl);
                if (presignedUrl.hasOwnProperty('url')) {
                    this.dataFileUrl = presignedUrl.url;
                    this.modalOpen = true;
                }
            }
            else {
                console.log("File loaded.....");
                if (file != null) {
                    console.log("Diferente a null");
                    const blob = new Blob([file], { type: file.type });
                    // Crear una URL para el Blob
                    this.dataFileUrl = URL.createObjectURL(blob);
                    //console.log("this.fileUrl: ", this.fileUrl);
                    //this.fileUrl = this.editedItem['file'];
                    if (this.dataFileUrl) {
                        this.modalOpen = true;
                    }
                    //return;
                }
            }
        },
        renameFile(fileKey, newName) {
            const file = this[fileKey];
            if (file) {
                const extension = file.name.split('.').pop(); // Obtener la extensión del archivo
                const renamedFile = new File([file], `${newName}.${extension}`, {
                    type: file.type,
                });
                this[fileKey] = renamedFile; // Actualizar el archivo con el nombre renombrado
                console.log(`File renamed to: ${renamedFile.name}`);
            } else {
                console.error('No file selected to rename.');
            }
        },
        openCertificate(type) {
            if (type === 'handover') {
                console.log('Opening certificate: Handover');
            } else if (type === 'receipt') {
                console.log('Opening certificate: Receipt');
            }
        },
        deleteItem(index) {
            // Confirmación antes de eliminar
            this.assignedEquipment.splice(index, 1);
        },
        removeProperties(equipment) {
            // Creamos una copia del objeto para no mutar el original
            const modifiedEquipment = { ...equipment };

            // Eliminamos las propiedades no deseadas
            delete modifiedEquipment.history;
            delete modifiedEquipment.is_active;
            delete modifiedEquipment.created_at;
            delete modifiedEquipment.displayText;

            return modifiedEquipment;
        },
        addItem() {
            //console.log("this.type.type: ", this.type.type);
            try {
                //console.log("this.type: ", this.type);
                ////console.log("type: ", typeof this.editedItem.coverage);
                ////console.log("Is drivers an array? ", Array.isArray(this.editedItem.coverage.type));
                if (this.equipmentSelected) {
                    let newItem = null;
                    //newItem = Object.assign({}, this.equipmentSelected);
                    newItem = _.cloneDeep(this.equipmentSelected);

                    this.computerEquipmentItems = this.computerEquipmentItems.filter(
                        item => item.uuid !== newItem.uuid
                    );
                    //console.log("newItem: ", newItem);
                    /* if (!Array.isArray(this.editedItem.drivers)) {
                        //console.log("ARRAY DRIVERS ES DIFERENTE DE ARRAY[......]");
                        this.editedItem.drivers.drivers = [];
                    } */
                    const modifiedNewItem = this.removeProperties(newItem);
                    modifiedNewItem.assignment_date = this.assignation_date;
                    console.log(">>>>>>>>>>>>>> modifiedNewItem: ", modifiedNewItem);
                    console.log(">>>>>>>>>>>>>> this.assignedEquipment: ", this.assignedEquipment);
                    this.assignedEquipment.push(modifiedNewItem);
                    //this.calculateTotalCost();
                    this.equipmentSelected = null;
                    //this.editedItem = Object.assign({}, this.defaultItem);
                    //this.coverageSelected = null;
                    this.$refs.form.resetValidation();
                    /*  this.type.type = '';
                     this.type.description = '';
                     this.type.price = ''; */
                    console.log("Added equipment:", this.assignedEquipment);
                }
                //console.log("----------- editedItem: ", this.editedItem);
                //console.log("Item added!");
            } catch (error) {
                console.error("Error adding coverage: ", error);
            }
        },
        async submitForm() {
            try {
                if (this.$refs.form.validate()) {
                    if (this.assignedEquipment.length > 0) {
                        this.showloader = true;
                        console.log('Form submitted:', this.editedItem);
                        console.log("this.editedIndex: ", this.editedIndex);
                        let response = null;
                        /* CREATE */
                        if (this.editedIndex === '') {
                            const data = {
                                "equipment": this.assignedEquipment,
                                "employee": this.employee
                            }
                            response = await createEquipmentAssigned(data);
                            console.log("response: ", response);
                        } else {
                            // Updating record
                            console.log("UPDATING/this.Item: ", this.Item);
                            console.log("this.editedItem.uuid: ", this.Item.uuid);
                            response = await updateEquipmentAssigned(this.Item.uuid, this.Item);
                            console.log("response: ", response);
                        }
                        if (response.ok) {
                            if (this.handover_file) {
                                console.log("UPLOADING HANDOVER FILE.................");
                                await uploadCertificate(this.employee.employee_number, this.handover_file);
                            }
                            if (this.receipt_file) {
                                console.log("UPLOADING RECEIPT FILE.................");
                                await uploadCertificate(this.employee.employee_number, this.receipt_file);
                            }
                            this.showloader = false;
                            this.$emit('sent-info', false);
                        }
                    } else {
                        alert("Assign computer equipment");
                    }
                }

            } catch (error) {
                console.error("Error: ", error);
                this.showloader = false;
            }
        },
    },
}
</script>

<style scoped>
.loader-container {
    position: fixed;
    /* Para que el loader esté por encima de todo el contenido */
    top: 0;
    left: 0;
    width: 100%;
    /* Ocupa todo el ancho del viewport */
    height: 100%;
    /* Ocupa todo el alto del viewport */
    display: flex;
    justify-content: center;
    /* Centra horizontalmente */
    align-items: center;
    /* Centra verticalmente */
    background-color: rgba(255, 255, 255, 0.5);
    /* Fondo semitransparente */
    z-index: 9999;
    /* Coloca el loader por encima de todo el contenido */
}

.light-green-background {
    background-color: #8BC34A !important;
    /* Verde claro cuando habilitado */
}

.disabled-background {
    background-color: #D3D3D3 !important;
    /* Gris claro cuando deshabilitado */
}
</style>