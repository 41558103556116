<template>
    <new-trainer-interface />
</template>

<script>
import NewTrainerInterface from '../../components/Training_log/NewTrainerInterface'

export default {
    name: 'NewTrainerInterfaceView',
    components: {
        NewTrainerInterface,
    },
}
</script>