<template>
    <v-col cols="12" sm="12" md="12">
        <v-card>
            <v-card-title>
                <span class="headline">Chart of Account</span>
            </v-card-title>
            <v-col>
                <v-card-text>
                    <v-divider class="mt-4 mb-6"></v-divider>
                    <v-row>
                        <v-col>
                            <v-row>
                                <v-col cols="12">
                                    <div class="d-flex align-center">
                                        <v-file-input label="Chart Of Account file" v-model="chart_of_account_file"
                                            accept=".xlsx,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel">
                                        </v-file-input>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-btn color="blue darken-1" text @click="bulk_data">
                        Import
                    </v-btn>
                    <v-divider class="mt-4 mb-6"></v-divider>
                    <v-form ref='form'>
                        <v-text-field v-model="editedItem.account_type" label="Account Type"></v-text-field>

                        <v-text-field v-model="editedItem.account_name" :rules="[rules.required]" label="Account Name"
                            required></v-text-field>

                        <v-text-field v-model="editedItem.account_code" :rules="[rules.required]" label="Account Code"
                            required></v-text-field>

                        <v-textarea v-model="editedItem.description" label="Description"></v-textarea>

                        <v-text-field v-model="editedItem.mileage_rate" label="Mileage Rate"
                            type="number"></v-text-field>

                        <v-text-field v-model="editedItem.mileage_unit" label="Mileage Unit"></v-text-field>

                        <v-checkbox v-model="editedItem.is_mileage" label="Is Mileage"></v-checkbox>

                        <v-text-field v-model="editedItem.account_number" :rules="[rules.required]"
                            label="Account Number" required></v-text-field>

                        <v-text-field v-model="editedItem.currency" label="Currency"></v-text-field>

                        <v-text-field v-model="editedItem.parent_account" label="Parent Account"></v-text-field>
                    </v-form>
                </v-card-text>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="submitForm">
                    SAVE
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
</template>

<script>
import * as XLSX from 'xlsx';
import { bus } from '../../main.js';
import { GET_CHART_OF_ACCOUNT, POST_CHART_OF_ACCOUNT_BULK } from '../../utils/endpoints';
import { createChartOfAccount, updateChartOfAccount, createChartOfAccountsInBulk } from '../../utils/chart_of_accounts_CRUD.js'

export default {
    name: 'ChartOfAccountCard',
    props: {
        Index: Number,
        Item: Object
    },
    data: () => ({
        chart_of_account_file: null,
        editedItem: {
            id: '',
            account_code: '',
            account_name: '',
            description: '',
            account_type: '',
            mileage_rate: 0.0,
            mileage_unit: '',
            is_mileage: false,
            account_number: '',
            currency: '',
            parent_account: '',
        },
        rules: {
            required: value => !!value || 'Required.',
        },
        defaultEditedItem: {
            id: '',
            account_code: '',
            account_name: '',
            description: '',
            account_type: '',
            mileage_rate: 0.0,
            mileage_unit: '',
            is_mileage: false,
            account_number: '',
            currency: '',
            parent_account: '',
        },
        editedIndex: -1,
    }),
    async mounted() {
        console.log("-------------------MOUNTED.......................")
        console.log("this.Index: ", this.Index);
        this.$nextTick(() => {
            this.$refs.form.resetValidation();
        });
        if (this.Index != -1) {
            this.editedItem = this.Item;
            this.editedIndex = this.Index
            console.log("this.editedIndex: ", this.editedIndex);
            console.log("this.editedItem: ", this.editedItem);
        }
    },
    methods: {
        async submitForm() {
            if (this.chart_of_account_file === null) {
                if (this.$refs.form.validate()) {
                    console.log('Form submitted:', this.editedItem);
                    let response = null;
                    // ADD new record
                    if (this.editedIndex === -1) {
                        response = await createChartOfAccount(this.editedItem);
                        console.log("response: ", response);
                    } else {
                        // Updating record
                        response = await updateChartOfAccount(this.editedIndex, this.editedItem);
                        console.log("Updating - response: ", response);
                    }
                    if (response.ok) {
                        this.$emit('sent-info', false);
                    }
                } else {
                    console.log('Form validation failed.');
                }
            }

        },
        async bulk_data() {
            try {
                const data = await this.handleFile();
                console.log("data: ", data);
                const response = await createChartOfAccountsInBulk(data);
                if (response.ok) {
                    this.chart_of_account_file = null;
                    this.$emit('sent-info', false);
                }
            } catch (error) {
                console.log("error: ", error);
            }
        },
        handleFile() {
            const file = this.chart_of_account_file;
            if (!file) {
                console.error('No file selected');
                return;
            }
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = function (event) {
                    try {
                        const data = new Uint8Array(event.target.result);
                        const workbook = XLSX.read(data, { type: 'array' });
                        const sheetName = workbook.SheetNames[0];
                        const worksheet = workbook.Sheets[sheetName];
                        const jsonData = XLSX.utils.sheet_to_json(worksheet);
                        const formattedData = jsonData.map(row => ({
                            account_code: row['Account Code'] || '',  // Cadena vacía por defecto
                            account_name: row['Account Name'] || '',  // Cadena vacía por defecto
                            description: row['Description'] || '',  // Cadena vacía por defecto
                            account_type: row['Account Type'] || '',  // Cadena vacía por defecto
                            mileage_rate: parseFloat(row['Mileage Rate']) || 0.0,  // Número flotante por defecto
                            mileage_unit: row['Mileage Unit'] || '',  // Cadena vacía por defecto
                            is_mileage: row['IsMileage']?.toString().toLowerCase() === 'true',  // Valor booleano, falso por defecto
                            account_number: row['Account #'] || '',  // Cadena vacía por defecto
                            currency: row['Currency'] || '',  // Cadena vacía por defecto
                            parent_account: row['Parent Account'] || '',  // Cadena vacía por defecto
                            /* account_code: row['account_code'],
                            account_name: row['account_name'],
                            description: row['description'],
                            account_type: row['account_type'],
                            mileage_rate: row['mileage_rate'],
                            mileage_unit: row['mileage_unit'],
                            is_mileage: row['is_mileage']?.toString().toLowerCase() === 'true',
                            account_number: row['account_#'],
                            currency: row['currency'],
                            parent_account: row['parent_account'] */
                        }));
                        console.log("formattedData: ", formattedData);

                        resolve(formattedData);
                    } catch (error) {
                        reject(error);
                    }
                };

                reader.onerror = function (error) {
                    reject(error);
                };

                reader.readAsArrayBuffer(file);
            });
        }
    },
    computed: {
        filteredCharts() {
            if (!this.search.trim()) {
                return this.charts.data;
            } else {
                return this.charts.data.filter(chart =>
                    chart.account_name.toLowerCase().includes(this.search.toLowerCase())
                );
            }
        }
    },
}
</script>