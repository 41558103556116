<template>
    <div>
        <v-data-table :headers="headers" :items="filteredTrainingLogs" sort-by="trainee_name"
            class="elevation-1"><!-- @click:row="redirect" @click:row="openModal"-->
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title class="font-weight-bold">Admin Training Log Console</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-select v-model="logsFilter" :items="status" @input="getLogs"
                        style="max-width: 220px; margin-top: 30px; margin-right: 10px;"
                        label="Filter Complete/Incomplete"></v-select>
                    <v-text-field v-model="search" label="Search" solo-inverted prepend-icon="mdi-magnify"
                        class="mr-3 mt-5"></v-text-field>
                    <v-btn color="blue" dark class="mb-2 mr-2" @click="refreshForms">
                        <v-icon left>mdi-refresh</v-icon>
                        Refresh
                    </v-btn>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item, index }">
                <v-icon small class="mr-2" :style="{ color: 'blue' }" @click="preview(item, index)">
                    mdi-eye
                </v-icon>
                <v-icon v-if="logsFilter === 'Incomplete' || logsFilter === ''" small class="mr-2" color="blue" @click="openModal(item)">
                    mdi-account-plus
                </v-icon>
            </template>
        </v-data-table>
        <training-log-modal v-if="modalOpen" :modalOpen.sync="modalOpen" :title="modalTitle"
            :itemEditedIndex="itemEditedIndex" :itemTrainingLog="itemTrainingLog"
            :logsFilter="logsFilter"></training-log-modal><!--:fileUrl="fileUrl" @save="save" -->
        <v-dialog v-model="trainingPreview" max-width="900"><!-- max-width="900" content-class="dialog-height" -->
            <v-card class="dialog-card" outlined>
                <TrainingLogForm :editedIndex="editedIndex" :itemTrainingLog="itemTrainingLog"
                    :key="trainingPreview.toString()" />
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { get_employee_number } from '../../utils/get_employee_number.js'
import TrainingLogForm from '../../components/Training_log/TrainingLogForm.vue'
import { GET_ALL_TRAINING_LOGS, GET_ALL_TRAINING_LOGS_COMPLETE } from '../../utils/endpoints';
import { bus } from '../../main.js';
import TrainingLogModal from '../../components/Modals/TrainingLogModal'

export default {

    components: {
        TrainingLogModal,
        TrainingLogForm
    },
    data: () => ({
        trainingPreview: false,
        logsFilter: 'Incomplete',
        status: ['Complete', 'Incomplete'],
        //Modal
        modalOpen: false,
        modalTitle: 'Assigned to:',
        itemTrainingLog: null,
        itemEditedIndex: -1,
        //fileUrl: '',
        /////////
        formIndex: -1,
        editedIndex: -1,
        search: '',
        headers: [
            { text: 'Trainee`s name', value: 'trainee_name' },
            { text: 'Trainee`s last name', value: 'trainee_lastName' },
            { text: 'UUID', value: 'uuid' },
            { text: 'Actions', value: 'actions', sortable: false }
        ],
        trainingLogs: [],

        employee_number: '',


        editedItem: {
            history: '',
            id: null,
            status: false,
            trainee_lastName: '',
            trainee_name: '',
            uuid: '',
        }
    }),
    computed: {
        filteredTrainingLogs() {
            if (this.trainingLogs) {
                return this.trainingLogs.filter(trainingLog =>
                    trainingLog.trainee_name.toLowerCase().includes(this.search.toLowerCase())
                );
            }
            else {
                return [];
            }
        },
    },
    async mounted() {
        //console.log("-------------------MOUNTED.......................")
        bus.$emit('login-success');
        bus.$emit('permissions');
        this.employee_number = get_employee_number();
        let forms = await this.get_TrainingLog_Forms();
        console.log("forms: ", forms);
        this.trainingLogs = forms;
    },
    methods: {
        preview(item, index) {
            console.log("########## Preview......");
            console.log("item: ", item);
            console.log("index: ", index);
            this.itemTrainingLog = item;
            this.trainingPreview = true;
            this.itemEditedIndex = index;
            console.log("index: ", this.itemEditedIndex );
        },
        async getLogs(item) {
            console.log("Filtering......", item);
            this.logsFilter = item;
            if (item === 'Incomplete') {
                this.trainingLogs = await this.get_TrainingLog_Forms();
            } else {
                this.trainingLogs = await this.get_TrainingLog_FormsComplete();
            }
        },
        async openModal(item) {
            console.log("item: ", item);
            this.editedIndex = this.trainingLogs.indexOf(item);
            console.log("------this.editedIndex: ", this.editedIndex);
            //this.editedItem = Object.assign({}, item);
            this.itemTrainingLog = item;
            console.log("this.itemTrainingLog: ", this.itemTrainingLog);
            console.log("Opening modal....")
            this.modalOpen = true;
        },
        async refreshForms() {

            if (this.logsFilter === 'Incomplete' || this.logsFilter === '') {
                console.log("refreshing incomplete......")
                this.trainingLogs = await this.get_TrainingLog_Forms();
            } else if (this.logsFilter === 'Complete') {
                console.log("refreshing Complete......")
                this.trainingLogs = await this.get_TrainingLog_FormsComplete();
            }
            //this.trainingLogs = await this.get_TrainingLog_Forms();
        },
        async get_TrainingLog_FormsComplete() {
            try {
                const response = await fetch(GET_ALL_TRAINING_LOGS_COMPLETE); // Replace "your_endpoint" with the URL of your endpoint
                if (!response.ok) {
                    throw new Error('Failed to fetch record');
                }
                const res = await response.json();
                //return res;
                //this.trainingLogs = res;
                console.log("res get_TrainingLog_Forms: ", res);

                return res;
            } catch (error) {
                console.error('Error fetching record:', error);
                //throw error;
            }
        },
        async get_TrainingLog_Forms() {
            try {
                const response = await fetch(GET_ALL_TRAINING_LOGS); // Replace "your_endpoint" with the URL of your endpoint
                if (!response.ok) {
                    throw new Error('Failed to fetch record');
                }
                const res = await response.json();
                //return res;
                //this.trainingLogs = res;
                console.log("res get_TrainingLog_Forms: ", res);

                return res;
            } catch (error) {
                console.error('Error fetching record:', error);
                //throw error;
            }
        },
    },
    watch: {
        trainingPreview(item) {
            console.log("Dialog opened.....");
        }
    }
}
</script>

<style scoped>
/* .dialog-height{
  max-height: 600px !important;
  min-height: 300px !important;
} */

.dialog-card {
    padding: 0;
    /* Elimina el relleno interno */
    margin: 0;
    /* Elimina los márgenes */
}

.v-dialog__content {
    padding: 0;
    /* Elimina el relleno interno */
    margin: 0;
    /* Elimina los márgenes */
}

.custom-btn {
    margin: 20px auto;
    /* Centra el botón verticalmente */
    width: 70px;
    /* Tamaño cuadrado del botón */
    height: 70px !important;
    padding-top: 10px;
    /* Añade separación superior */
}

.align-center {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    height: 100%;
}
</style>