<template>
    <div>
        <v-card>
            <v-toolbar flat color="primary" dark>
                <v-toolbar-title>Vehicle INFO</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="content-container">
                <v-tabs vertical>
                    <v-tab class="justify-start info-tab" :class="{ 'active-tab': activeTab === 'info' }"
                        @click="activeTab = 'info'">
                        <v-icon left>
                            mdi-information
                        </v-icon>
                        Samsara Info
                    </v-tab>
                    <v-tab class="justify-start insurance-tab" :class="{ 'active-tab': activeTab === 'insurance' }"
                        @click="activeTab = 'insurance'">
                        <v-icon left>
                            mdi-shield-check
                        </v-icon>
                        Insurance
                    </v-tab>
                    <v-tab class="justify-start legal-tab" :class="{ 'active-tab': activeTab === 'legal' }"
                        @click="activeTab = 'legal'">
                        <v-icon left>
                            mdi-file-document
                        </v-icon>
                        Legal Documents
                    </v-tab>
                    <v-tab class="justify-start insurance_company_history-tab"
                        :class="{ 'active-tab': activeTab === 'insurance_company_history' }"
                        @click="activeTab = 'insurance_company_history'">
                        <v-icon left>
                            mdi-timeline
                        </v-icon>
                        Insurance Company History
                    </v-tab>
                    <v-tab class="justify-start insurance_file_history-tab"
                        :class="{ 'active-tab': activeTab === 'insurance_file_history' }"
                        @click="activeTab = 'insurance_file_history'">
                        <v-icon left>
                            mdi-archive
                        </v-icon>
                        Insurance File History
                    </v-tab>
                    <!-- SAMSARA INFO -->
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <v-form ref='form'>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <h4>Samsara INFO</h4>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <v-text-field v-model="editedItem.id_samsara" label="ID Samsara"
                                                readonly></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field v-model="editedItem.licensePlate" label="License Plate"
                                                readonly></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field v-model="editedItem.make" label="Make"
                                                readonly></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field v-model="editedItem.model" label="Model"
                                                readonly></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field v-model="editedItem.year" label="Year"
                                                readonly></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field v-model="editedItem.name" label="Name"
                                                readonly></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field v-model="editedItem.vin" label="VIN" readonly></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field v-model="editedItem.driver_name" label="Driver Name"
                                                readonly></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field v-model="editedItem.purchase_date" label="Purchase Date"
                                                readonly></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field v-model="editedItem.next_oil_change" label="Next Oil Change"
                                                readonly></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <h4>History</h4>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <v-row>
                                        <v-col>
                                            <v-data-table :headers="headers"
                                                :items="this.editedItem.history_json.history" class="elevation-1">
                                                <template v-slot:top>
                                                    <v-toolbar flat>
                                                        <v-toolbar-title>History</v-toolbar-title>
                                                    </v-toolbar>
                                                </template>
                                            </v-data-table>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <!-- INSURANCE -->
                    <v-tab-item>
                        <v-card flat height="620">
                            <v-card-text>
                                <v-form ref='form'>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <h4>Insurance</h4>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <v-text-field v-model="editedItem.insurance_company"
                                                label="Insurance Company" readonly></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <div class="d-flex align-center">
                                                <v-text-field v-model="editedItem.insurance_expiration_date"
                                                    label="Insurance Expiration Date" readonly
                                                    :class="getDateClass(editedItem.insurance_expiration_date)"></v-text-field>
                                                <v-btn icon
                                                    :class="{ 'green--text': editedItem.insurance_file === true, 'red--text': !editedItem.insurance_file }">
                                                    <v-icon
                                                        @click="openModal('Insurance', editedItem.insurance_file_name, 'insurance_file', files.insuranceFile)">mdi-file</v-icon>
                                                </v-btn>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <h4>Triangle</h4>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <v-select v-model="decissionValue" :items="itemDecision"
                                                label="SOS Triangle" :rules="[rules.required]"></v-select>
                                        </v-col>
                                        <v-col v-if="decissionValue === 'Yes'" cols="12" sm="4">
                                            <v-menu v-model="menuTriangleExpirationDate" :close-on-content-click="false"
                                                transition="scale-transition" offset-y>
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field v-model="editedItem.triangle_expiration_date"
                                                        label="Triangle Expiration Date" v-on="on"
                                                        :rules="[rules.required]" required
                                                        :class="getDateClass(editedItem.triangle_expiration_date)"></v-text-field>
                                                </template>
                                                <v-date-picker v-model="editedItem.triangle_expiration_date"
                                                    @input="menuTriangleExpirationDate = false"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col v-if="decissionValue === 'Yes'" cols="12" sm="4">
                                            <div class="d-flex align-center">
                                                <v-file-input label="Triangle File" v-model="files.triangleFile"
                                                    @change="onFileSelected(files.triangleFile, 'triangle_file', 'triangleFile')"
                                                    accept="application/pdf">
                                                </v-file-input>
                                                <v-btn icon
                                                    :class="{ 'green--text': editedItem.triangle_file === true, 'red--text': !editedItem.triangle_file }">
                                                    <v-icon
                                                        @click="openModal('Triangle', 'triangle_file.pdf', 'triangle_file', files.triangleFile)">mdi-file</v-icon>
                                                </v-btn>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <!-- LEGAL DOCUMENTS -->
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <v-form ref='form'>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <h4>Registration</h4>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <v-menu v-model="menuExpirationDate" :close-on-content-click="false"
                                                transition="scale-transition" offset-y>
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field v-model="editedItem.expiration_date"
                                                        label="Expiration Date" v-on="on" :rules="[rules.required]"
                                                        required :class="getDateClass(editedItem.expiration_date)">
                                                    </v-text-field>
                                                </template>
                                                <v-date-picker v-model="editedItem.expiration_date"
                                                    @input="menuExpirationDate = false"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field v-model="editedItem.registered_under"
                                                :rules="[rules.required]" label="Registered Under"
                                                required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field v-model="editedItem.registration_address"
                                                :rules="[rules.required]" label="Registration Address"
                                                required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-menu v-model="menuPlateStickerExpirationDate"
                                                :close-on-content-click="false" transition="scale-transition" offset-y>
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field v-model="editedItem.plate_sticker_expiration_date"
                                                        label="Plate Sticker Expiration Date" v-on="on"
                                                        :rules="[rules.required]" required
                                                        :class="getDateClass(editedItem.plate_sticker_expiration_date)"></v-text-field>
                                                </template>
                                                <v-date-picker v-model="editedItem.plate_sticker_expiration_date"
                                                    @input="menuPlateStickerExpirationDate = false"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <div class="d-flex align-center">
                                                <v-file-input label="Registration File" v-model="files.registrationFile"
                                                    @change="onFileSelected(files.registrationFile, 'registration_file', 'registrationFile')"
                                                    accept="application/pdf">
                                                </v-file-input>
                                                <v-btn icon
                                                    :class="{ 'green--text': editedItem.registration_file === true, 'red--text': !editedItem.registration_file }">
                                                    <v-icon
                                                        @click="openModal('Registration File', 'registration_file.pdf', 'registration_file', files.registrationFile)">mdi-file</v-icon>
                                                </v-btn>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-menu v-model="menuCityStickerExpirationDate"
                                                :close-on-content-click="false" transition="scale-transition" offset-y>
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field v-model="editedItem.city_sticker_expiration_date"
                                                        label="City Sticker Expiration Date" v-on="on"
                                                        :rules="[rules.required]" required
                                                        :class="getDateClass(editedItem.city_sticker_expiration_date)"></v-text-field>
                                                </template>
                                                <v-date-picker v-model="editedItem.city_sticker_expiration_date"
                                                    @input="menuCityStickerExpirationDate = false"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <h4>Safety Inspection</h4>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-menu v-model="menuSafetyInspectionExpirationDate"
                                                :close-on-content-click="false" transition="scale-transition" offset-y>
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field v-model="editedItem.safety_inspection_expiration_date"
                                                        label="Safety Inspection Expiration Date" v-on="on"
                                                        :rules="[rules.required]" required
                                                        :class="getDateClass(editedItem.safety_inspection_expiration_date)"></v-text-field>
                                                </template>
                                                <v-date-picker v-model="editedItem.safety_inspection_expiration_date"
                                                    @input="menuSafetyInspectionExpirationDate = false"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <div class="d-flex align-center">
                                                <v-file-input label="Safety Inspection File"
                                                    v-model="files.safetyInspectionFile"
                                                    @change="onFileSelected(files.safetyInspectionFile, 'safety_inspection_file', 'safetyInspectionFile')"
                                                    accept="application/pdf">
                                                </v-file-input>
                                                <v-btn icon
                                                    :class="{ 'green--text': editedItem.safety_inspection_file === true, 'red--text': !editedItem.safety_inspection_file }">
                                                    <v-icon
                                                        @click="openModal('Safety Inspection File', 'safety_inspection_file.pdf', 'safety_inspection_file', files.safetyInspectionFile)">mdi-file</v-icon>
                                                </v-btn>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <h4>Emission Test</h4>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-menu v-model="menuEmissionTestExpirationDate"
                                                :close-on-content-click="false" transition="scale-transition" offset-y>
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field v-model="editedItem.emission_test_expiration_date"
                                                        label="Emission Test Expiration Date" v-on="on"
                                                        :rules="[rules.required]" required
                                                        :class="getDateClass(editedItem.emission_test_expiration_date)"></v-text-field>
                                                </template>
                                                <v-date-picker v-model="editedItem.emission_test_expiration_date"
                                                    @input="menuEmissionTestExpirationDate = false"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <div class="d-flex align-center">
                                                <v-file-input label="Emission Test File"
                                                    v-model="files.emissionTestFile"
                                                    @change="onFileSelected(files.emissionTestFile, 'emission_test_file', 'emissionTestFile')"
                                                    accept="application/pdf">
                                                </v-file-input>
                                                <v-btn icon
                                                    :class="{ 'green--text': editedItem.emission_test_file === true, 'red--text': !editedItem.emission_test_file }">
                                                    <v-icon
                                                        @click="openModal('Emission Test File', 'emission_test_file.pdf', 'emission_test_file', files.emissionTestFile)">mdi-file</v-icon>
                                                </v-btn>
                                            </div>
                                        </v-col>
                                    </v-row>

                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <h4>Inspection</h4>
                                    <v-divider class="mt-4 mb-6"></v-divider>
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <!-- <v-text-field v-model="inspectionVideo" :rules="[rules.required]"
                                    label="Inspection Record" required></v-text-field> -->
                                            <div class="d-flex align-center">
                                                <v-file-input label="Inspection Record" v-model="files.inspectionRecord"
                                                    @change="onFileSelected(files.inspectionRecord, 'inspection_record', 'inspectionRecord')"
                                                    accept="video/mp4">
                                                </v-file-input>
                                                <v-btn icon
                                                    :class="{ 'green--text': editedItem.inspection_record === true, 'red--text': !editedItem.inspection_record }">
                                                    <v-icon
                                                        @click="openModal('Inspection Record', 'inspection_record.mp4', 'inspection_record', files.inspectionRecord)">mdi-video</v-icon>
                                                </v-btn>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <!-- INSURANCE COMPANY HISTORY -->
                    <v-tab-item>
                        <v-card flat height="620">
                            <v-card-text>
                                <v-divider class="mt-4 mb-6"></v-divider>
                                <h4 class="orange-text">Insurance Company History</h4>
                                <v-divider class="mt-4 mb-6"></v-divider>
                                <v-row>
                                    <v-col cols="12">
                                        <v-row>
                                            <v-col>
                                                <v-data-table :headers="headersInsuranceCompany"
                                                    :items="insurance_company_history" class="elevation-1"
                                                    @click:row="showTypeOfCoverage">
                                                    <template v-slot:top>
                                                        <v-toolbar flat>
                                                            <v-toolbar-title>History</v-toolbar-title>
                                                        </v-toolbar>
                                                    </template>
                                                    <template v-slot:item.effective_date="{ item }">
                                                        {{ formatDate(item.effective_date) }}
                                                    </template>
                                                    <template v-slot:item.termination_date="{ item }">
                                                        {{ formatDate(item.termination_date) }}
                                                    </template>
                                                    <template v-slot:item.total_cost="{ item }">
                                                        {{ formatCurrency(item.total_cost) }}
                                                    </template>
                                                </v-data-table>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <!-- INSURANCE FILE HISTORY -->
                    <v-tab-item>
                        <v-card flat height="620">
                            <v-card-text>
                                <v-divider class="mt-4 mb-6"></v-divider>
                                <h4 class="marron-text">Insurance File History</h4>
                                <v-divider class="mt-4 mb-6"></v-divider>
                                <v-row>
                                    <v-col cols="12">
                                        <v-row>
                                            <v-col>
                                                <v-data-table :headers="headersInsuranceFiles" :items="insurance_files"
                                                    class="elevation-1" @click:row="showFile">
                                                    <template v-slot:top>
                                                        <v-toolbar flat>
                                                            <v-toolbar-title>History</v-toolbar-title>
                                                        </v-toolbar>
                                                    </template>
                                                </v-data-table>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <!-- <file-modal :modalOpen.sync="modalOpen" :title="modalTitle" :dataFileUrl="dataFileUrl"></file-modal> -->
                </v-tabs>
                <v-dialog v-model="dialogTypeOfCoverage" max-width="800">
                    <v-card class="dialog-card" outlined>
                        <DialogTypeOfCoverage :ItemTypeOfCoverage="typeOfCoverageItem" :InsuranceInfo="InsuranceInfo"
                            :key="dialogTypeOfCoverage.toString()" />
                    </v-card>
                </v-dialog>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="submitForm">
                    SAVE
                </v-btn>
            </v-card-actions>
            <file-modal :modalOpen.sync="modalOpen" :title="modalTitle" :dataFileUrl="dataFileUrl"></file-modal>
        </v-card>
        <div v-if="showLoader" class="loader-container">
            <div class="loading">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </div>
    <!-- </v-col> -->
</template>

<script>
//import { createCreditCardManagement, updateCreditCardManagement } from '../../utils/credit_card_managements_CRUD.js'
import { editVehicle, uploadFileToFleetS3 } from '../../utils/vehicles_CRUD.js'
import { getInsurances } from '../../utils/insurance_CRUD.js'
import { generatePublicURL } from '../../utils/presignedUrl.js'
import FileModal from '../../components/Modals/FileModal.vue'
import moment from 'moment-timezone';
import { fetchAllInsuranceBreakdownsByVin } from '../../utils/Insurance/insuranceBreakdownCRUD.js'
//import { getInsuranceByName } from '../../utils/insurance_CRUD.js'
import { format } from 'date-fns';
import DialogTypeOfCoverage from '../../components/Fleet/Insurance/DialogTypeOfCoverage.vue'
import { getListOfFiles } from '../../utils/S3/s3_CRUD.js'


export default {
    name: 'VehiclesCard',
    components: {
        FileModal,
        DialogTypeOfCoverage,
    },
    props: {
        Index: Number,
        Item: Object,
        History: Object,
    },
    data: () => ({
        // Insurance File History
        insurance_files: [],
        headersInsuranceFiles: [
            { text: 'File Name', value: 'fileName' } // Coincide con la propiedad que creamos en el array de objetos
        ],
        // Type of Coverage
        InsuranceInfo: null,
        dialogTypeOfCoverage: false,
        typeOfCoverageItem: [],
        // Insurance Company History
        insurance_company_history: [],
        headersInsuranceCompany: [
            { text: 'Company', value: 'company' },
            { text: 'Effective Date', value: 'effective_date' },
            { text: 'Termination Date', value: 'termination_date' },
            { text: 'Total Cost', value: 'total_cost' },
        ],
        insurance_file: false,
        activeTab: 'info',
        tab: 0,
        headers: [
            { text: 'Last Employee', value: 'last_employee' },
            { text: 'Last Employee Number', value: 'employee_number' },
            { text: 'Last Modification Date', value: 'last_date' },
        ],
        // Loader
        showLoader: false,
        // File modal
        modalOpen: false,
        modalTitle: '',
        dataFileUrl: '',
        itemDecision: [
            "Yes",
            "No"
        ],
        // Date Picker
        menuExpirationDate: false,
        menuPlateStickerExpirationDate: false,
        menuCityStickerExpirationDate: false,
        menuSafetyInspectionExpirationDate: false,
        menuEmissionTestExpirationDate: false,
        menuTriangleExpirationDate: false,
        menuInsuranceExpirationDate: false,
        // Files
        files: {
            registrationFile: null,
            safetyInspectionFile: null,
            emissionTestFile: null,
            triangleFile: null,
            insuranceFile: null,
            inspectionRecord: null,
        },
        // v-select
        decissionValue: 'No',
        insuranceItems: [],
        editedItem: {
            id: '',
            driver_name: '',
            id_samsara: '',
            licensePlate: '',
            make: '',
            model: '',
            name: '',
            vin: '',
            year: '',
            purchase_date: null,
            next_oil_change: '',
            expiration_date: null,
            registered_under: '',
            registration_address: '',
            plate_sticker_expiration_date: null,
            registration_file: false,
            city_sticker_expiration_date: null,
            safety_inspection_expiration_date: null,
            safety_inspection_file: false,
            emission_test_expiration_date: null,
            emission_test_file: false,
            sos_triangle: null, // true = YES, false = NO
            triangle_expiration_date: null,
            triangle_file: false,
            insurance_company: '',
            insurance_expiration_date: null,
            insurance_file: false,
            inspection_record: '',
            //last_modified_date: '',
            //last_modified_user: '',
            //history_modified_date: '',
            //history_modified_user: '',
            //history: '',
            history_json: {
                history: []
            },
            insurance_file_name: '',
        },
        insurance_company: '',
        insurance_expiration_date: null,
        rules: {
            required: value => !!value || 'Required.',
        },
        defaultEditedItem: {
            id: '',
            driver_name: '',
            id_samsara: '',
            licensePlate: '',
            make: '',
            model: '',
            name: '',
            vin: '',
            year: '',
            purchase_date: null,
            next_oil_change: '',
            expiration_date: null,
            registered_under: '',
            registration_address: '',
            plate_sticker_expiration_date: null,
            registration_file: false,
            city_sticker_expiration_date: null,
            safety_inspection_expiration_date: null,
            safety_inspection_file: false,
            emission_test_expiration_date: null,
            emission_test_file: false,
            sos_triangle: false, // true = YES, false = NO
            triangle_expiration_date: null,
            triangle_file: false,
            insurance_company: '',
            insurance_expiration_date: null,
            insurance_file: false,
            inspection_record: '',
            inspection_record: '',
            history_json: {
                history: []
            },
            insurance_file_name: '',
        },
        editedIndex: -1,
    }),
    async mounted() {
        console.log("-------------------MOUNTED.......................")
        console.log("this.Index: ", this.Index);
        if (this.Index != -1) {
            const insurance_array = await getInsurances();

            console.log("insurance_array: ", insurance_array);
            this.insuranceItems = insurance_array.map(item => item.insurance_name);
            this.$nextTick(() => {
                this.$refs.form.resetValidation();
            });
            this.editedItem = this.Item;
            this.editedIndex = this.Index
            console.log("this.editedIndex: ", this.editedIndex);
            console.log("this.editedItem: ", this.editedItem);
            this.getInsuranceHistory(this.editedItem.vin);
            this.get_insurance_file_list();
            //this.loadInfoInsurance(this.editedItem);
        }
    },
    methods: {
        showFile(item){
            console.log("Item: ", item);
            console.log("fileName: ", item.fileName);
            this.openModal('Insurance', item.fileName, 'insurance_file', null)
        },
        async get_insurance_file_list() {
            const route = `Fleet/Insurance/${this.editedItem.vin}`;
            /* {
                "folder_name": `Fleet/Insurance/${this.editedItem.vin}`,
            }; */

            console.log("route: ", route);
            this.insurance_files = await getListOfFiles(route);

            // Convertir el array de strings en un array de objetos
            this.insurance_files = this.insurance_files.map(file => {
                return { fileName: file };  // Mapea cada string a un objeto con la propiedad "fileName"
            });
            console.log("this.insurance_files: ", this.insurance_files);
        },
        showTypeOfCoverage(item) {
            console.log("showTypeOfCoverage/item: ", item);
            console.log("coverage: ", item.coverage.type);
            this.typeOfCoverageItem = null;
            this.typeOfCoverageItem = item.coverage.type;
            this.InsuranceInfo = item;
            this.dialogTypeOfCoverage = true;
        },
        formatCurrency(value) {
            if (typeof value !== "number") {
                return '';
            }
            return `$ ${value.toFixed(2)}`;
        },
        formatDate(dateString) {
            if (!dateString) return '';

            return format(new Date(dateString), 'yyyy-MM-dd');
        },
        async getInsuranceHistory() {
            console.log("getInsuranceHistory......");
            this.insurance_company_history = await fetchAllInsuranceBreakdownsByVin(this.editedItem.vin);
            console.log("this.insurance_company_history: ", this.insurance_company_history);

        },
        getLastEndDate(insuranceArray) {
            return insuranceArray.map(insurance => {
                const periods = insurance.policy_period.period;
                if (periods.length > 0) {
                    const lastPeriod = periods[periods.length - 1]; // Acceder al último objeto en el array
                    return lastPeriod.endDate; // Obtener el valor de endDate del último objeto
                }
                return null; // Devuelve null si no hay periodos
            });
        },
        getDateClass(date) {
            console.log("date: ", date);
            if (!date) return '';

            const expirationDate = new Date(date);
            const today = new Date();
            const differenceInDays = Math.ceil((expirationDate - today) / (1000 * 60 * 60 * 24));

            if (differenceInDays <= 0) {
                return 'red-text';
            } else if (differenceInDays <= 31) {
                return 'yellow-text';
            } else {
                return 'green-text';
            }
        },

        async checkFiles(files) {
            let existFile = false;
            try {
                let response;
                for (const key in files) {
                    if (files[key] === null) {
                        console.log(`${key} es vacío`);
                    } else {
                        existFile = true;
                        console.log(`${key} está lleno`);
                        response = await uploadFileToFleetS3(this.editedItem.vin, files[key]);
                    }
                }
                console.log("For finished......");
                if (!existFile) {
                    return true
                } else {
                    if (response.ok) {
                        console.log(">>>>>>>>>>>>>>>>>>>>>>>> Uploading files finished.......");
                        return true
                    }
                }
            } catch (error) {
                console.error("Error uploading fleet files.....");
                return false;
            }
        },
        async openModal(title, fileName, property, file) {
            console.log("Opening modal....");
            console.log("file: ", file);
            console.log("title: ", title);
            console.log("fileName: ", fileName);
            console.log("property: ", property);
            this.modalTitle = title;
            //console.log("this.editedItem[property]: ", this.editedItem[property]);
            //if (this[property] === true) {
            if (this.editedItem[property] === true) {
                const presignedUrl = await generatePublicURL(`Fleet/${this.editedItem.vin}`, fileName);
                console.log("presignedUrl: ", presignedUrl);
                if (presignedUrl.hasOwnProperty('url')) {
                    this.dataFileUrl = presignedUrl.url;
                    this.modalOpen = true;
                } else {
                    console.log("No se generó ninguna , debido a que no existe el archivo");
                    return;
                }
            } /* else {
                if (file != null) {
                    console.log("File diferente a null");
                    const blob = new Blob([file], { type: file.type });
                    // Crear una URL para el Blob
                    this.dataFileUrl = URL.createObjectURL(blob);
                    console.log(">>>>>>>>>>>>>>>> this.dataFileUrl: ", this.dataFileUrl);
                    if (this.dataFileUrl) {
                        this.modalOpen = true;
                    }
                } else {
                    alert("⚠️ No file loaded! Please load a file before proceeding.");
                }
            } */
        },
        onFileSelected(file, fileName, property_File) {
            console.log("file: ", file);
            console.log("fileName: ", fileName);
            console.log("property_File: ", property_File);
            if (this.validateExtension(file)) {
                console.log("Renaming file....");
                this.files[property_File] = this.renamedFile(file, fileName);
                //this.editedItem[fileName] = true;
            } else {
                this.files[property_File] = null;
            }
        },
        validateExtension(file) {
            const allowedExtensions = ['pdf', 'mp4'];
            //const file = this.recentPhotoFile;    

            // Obtener la extensión del archivo
            const extension = file.name.split('.').pop().toLowerCase();
            console.log("extension: ", extension);

            if (!allowedExtensions.includes(extension)) {
                // Si la extensión del archivo no está permitida, mostrar una alerta
                alert("Formato incorrecto");
                // También puedes mostrar un mensaje en la consola para fines de depuración
                console.log("El archivo debe ser un PDF.");
                // Limpiar el campo de archivo seleccionado
                //this.recentPhotoFile = null;                
                return false;
            } else {
                return true;
            }
        },
        renamedFile(file, newName) {
            //console.log("Rename File...")
            if (file === null) {
                console.log("Archivo vacío.................");
            } else {
                console.log("Archivo NO vacío.......");
            }
            const originalFileName = file.name;
            console.log("originalFileName: ", originalFileName);
            const fileExtension = originalFileName.slice((originalFileName.lastIndexOf(".") - 1 >>> 0) + 2);
            //console.log("fileExtension: ", fileExtension);
            // Create a new file with the desired name and the same file type
            const renamedFile = new File([file], newName.toLowerCase() + '.' + fileExtension, { type: file.type });
            console.log("renamedFile: ", renamedFile);
            return renamedFile;
        },
        getChicagoDateTime() {
            // Obtén la fecha y hora actual en la zona horaria de Chicago
            const chicagoTime = moment.tz("America/Chicago");

            // Formatea la fecha y hora en el formato deseado
            const formattedDateTime = chicagoTime.format("YYYY-MM-DD HH:mm:ss");

            return formattedDateTime;
        },
        async submitForm() {

            if (this.$refs.form.validate()) {
                this.showLoader = true;
                console.log("this.history: ", this.history);
                this.History.last_date = this.getChicagoDateTime();
                console.log("this.editedItem: ", this.editedItem);
                //console.log("this.editedItem.history_json: ", this.editedItem.history_json);
                this.editedItem.history_json.history.push(this.History);
                console.log("this.editedItem.history_json: ", this.editedItem.history_json);
                console.log('Form submitted:', this.editedItem.vin);
                //let response = null;
                // ADD new record
                const { response, result } = await editVehicle(this.editedItem.vin, this.editedItem);
                console.log("Updating - response: ", response);
                if (response.ok) {
                    const res = await this.checkFiles(this.files);
                    console.log("After checkFiles......");
                    console.log("res: ", res);
                    if (res) {
                        this.showLoader = false;
                        this.$emit('sent-info', false);
                    }
                }
            } else {
                console.log('Form validation failed.');
            }
        },
    },
    watch: {
        decissionValue(newValue) {
            console.log("-------- newValue: ", newValue);
            if (newValue === 'Yes') {
                this.editedItem.sos_triangle = true;
                console.log("this.editedItem.sos_triangle: ", this.editedItem.sos_triangle);
            } else if (newValue === 'No') {
                this.editedItem.sos_triangle = false;
                console.log("this.editedItem.sos_triangle: ", this.editedItem.sos_triangle);
            }
        },
        'editedItem.sos_triangle'(val) {
            console.log("sos_triangle-val: ", val);
            if (val) {
                this.decissionValue = 'Yes';
            } else {
                this.decissionValue = 'No';
                this.editedItem.triangle_expiration_date = null;
                this.files.triangleFile = null;
            }
        }
    }
}
</script>

<style scoped>
.marron-text {
    color: #8B4513;
}

.orange-text {
    color: orange;
}

.dialog-card {
    padding: 0;
    /* Elimina el relleno interno */
    margin: 0;
    /* Elimina los márgenes */
}

.insurance_file_history-tab.active-tab {
    background-color: #BBDEFB;
    color: #8B4513;
}

.insurance_company_history-tab.active-tab {
    background-color: #BBDEFB;
    color: #FFA500;
}

.active-tab {
    background-color: inherit;
    color: inherit;
}

.info-tab.active-tab {
    background-color: #BBDEFB;
    color: #2196F3;
}

.insurance-tab.active-tab {
    background-color: #C8E6C9;
    color: #4CAF50;
}

.legal-tab.active-tab {
    background-color: #D1C4E9 !important;
    /* Morado para Legal Documents */
    color: #673AB7 !important;
}

.content-container {
    max-height: 600px;
    overflow-y: auto;
    padding-top: 56px;
    /* height of toolbar */
}

.red-text>>>.v-input__control .v-input__slot input {
    color: red !important;
    font-weight: bold !important;
}

.yellow-text>>>.v-input__control .v-input__slot input {
    color: gold !important;
    font-weight: bold !important;
}

.green-text>>>.v-input__control .v-input__slot input {
    color: green !important;
    font-weight: bold !important;
}

/* Estilos del contenedor del loader */
.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    /* Fondo blanco opaco */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    /* Asegura que el loader esté por encima de otros elementos */
}

.loading {
    --speed-of-animation: 0.9s;
    --gap: 6px;
    --first-color: #ff0000;
    --second-color: #000000;
    --third-color: #f6bb02;
    --fourth-color: #000000;
    --fifth-color: #ff0000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    gap: 6px;
    height: 100px;
}

.loading span {
    width: 4px;
    height: 50px;
    background: var(--first-color);
    animation: scale var(--speed-of-animation) ease-in-out infinite;
}

.loading span:nth-child(2) {
    background: var(--second-color);
    animation-delay: -0.8s;
}

.loading span:nth-child(3) {
    background: var(--third-color);
    animation-delay: -0.7s;
}

.loading span:nth-child(4) {
    background: var(--fourth-color);
    animation-delay: -0.6s;
}

.loading span:nth-child(5) {
    background: var(--fifth-color);
    animation-delay: -0.5s;
}

@keyframes scale {

    0%,
    40%,
    100% {
        transform: scaleY(0.05);
    }

    20% {
        transform: scaleY(1);
    }
}
</style>