<template>
    <v-col cols="12" sm="12" md="12">
        <v-card>
            <v-card-title>
                <span class="headline">Paid Through Account</span>
            </v-card-title>
            <v-col>
                <v-card-text>
                    <v-form ref='form'>
                        <v-text-field v-model="editedItem.paid_through_account_name" :rules="[rules.required]"
                            label="Paid Through Account Name" required></v-text-field>
                        <!-- <v-text-field v-model="editedItem.bank_name" :rules="[rules.required]"
                            label="Bank Name" required></v-text-field> -->
                        <v-select v-model="editedItem.bank_name" :items="itemsBank" label="Bank" required></v-select>
                    </v-form>
                </v-card-text>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="submitForm">
                    SAVE
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
</template>

<script>
import { createPaidThroughAccount, updatePaidThroughAccount } from '../../utils/paid_through_account_CRUD'
import { getAllBanks } from '../../utils/banks_CRUD'

export default {
    name: 'PaidThroughAccountCard',
    props: {
        Index: Number,
        Item: Object
    },
    data: () => ({
        itemsBank: [],
        editedItem: {
            id: '',
            paid_through_account_name: '',
            bank_name: '',
        },
        rules: {
            required: value => !!value || 'Required.',
        },
        defaultEditedItem: {
            id: '',
            paid_through_account_name: '',
            bank_name: '',
        },
        editedIndex: -1,
    }),
    async mounted() {
        console.log("-------------------MOUNTED.......................")
        console.log("this.Index: ", this.Index);
        this.$nextTick(() => {
            this.$refs.form.resetValidation();
        });
        this.getAllItemsBank();
        if (this.Index != -1) {
            this.editedItem = this.Item;
            this.editedIndex = this.Index
            console.log("this.editedIndex: ", this.editedIndex);
            console.log("this.editedItem: ", this.editedItem);
        }
    },
    methods: {
        async submitForm() {
            if (this.$refs.form.validate()) {
                console.log('Form submitted:', this.editedItem);
                let response = null;
                // ADD new record
                if (this.editedIndex === -1) {
                    response = await createPaidThroughAccount(this.editedItem);
                    console.log("response: ", response);
                } else {
                    // Updating record
                    response = await updatePaidThroughAccount(this.editedIndex, this.editedItem);
                    console.log("Updating - response: ", response);
                }
                if (response.ok) {
                    this.$emit('sent-info', false);
                }
            } else {
                console.log('Form validation failed.');
            }
        },

        async getAllItemsBank(){
            const banks_array = await getAllBanks();
            console.log("banks_array: ", banks_array);
            this.itemsBank = banks_array.map(item => item.bank_name);
            console.log("this.itemsBank: ", this.itemsBank);
        },
    },
}
</script>