import { ZOHO_VENDORS } from '../../../utils/endpoints'


/* export async function fetchZohoVendors() {
    try {
        // Hacer la solicitud al endpoint
        const response = await fetch(ZOHO_VENDORS, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        // Verificar si la respuesta es exitosa
        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }

        // Convertir la respuesta a JSON
        const data = await response.json();

        // Verificar si la solicitud fue exitosa
        if (data.success) {
            console.log('Vendors:', data.data);  // Aquí puedes manejar los datos obtenidos
            return data.data;
        } else {
            console.error('Error fetching vendors:', data.error);
        }

    } catch (error) {
        console.error('Fetch error:', error);
    }
} */

export async function bulkCreateVendors(vendors) {
    try {
        console.log("vendors: ", vendors);
        // Realizar la solicitud al endpoint de vendors/bulk
        const response = await fetch(`${ZOHO_VENDORS}/bulk`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(vendors),  // Enviar el array de vendors como JSON
        });

        // Verificar si la respuesta fue exitosa
        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }

        // Convertir la respuesta a JSON
        const data = await response.json();

        // Verificar el estado de la respuesta
        if (data.status === 'success') {
            console.log('Vendors created successfully:', data.message);
            return response;
        } else {
            console.error('Failed to create vendors:', data.message);
            return response;
        }
    } catch (error) {
        console.error('Error during bulk creation of vendors:', error);
    }
}


export async function fetchZohoVendors() {
    try {
        let page = 1;
        let perPage = 100;

        // Hacemos la primera solicitud para obtener el número total de páginas
        const initialUrl = `${ZOHO_VENDORS}?page=${page}&per_page=${perPage}`;
        const initialResponse = await fetch(initialUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!initialResponse.ok) {
            throw new Error(`Error: ${initialResponse.status}`);
        }

        const initialData = await initialResponse.json();
        const totalPages = initialData.pages;

        // Creamos un array de promesas para todas las páginas
        const requests = [];
        for (let i = 1; i <= totalPages; i++) {
            const url = `${ZOHO_VENDORS}?page=${i}&per_page=${perPage}`;
            requests.push(fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }));
        }

        // Hacemos todas las solicitudes en paralelo
        const responses = await Promise.all(requests);

        // Procesamos todas las respuestas
        const allVendors = [];
        for (const response of responses) {
            if (!response.ok) {
                throw new Error(`Error en la solicitud: ${response.status}`);
            }
            const data = await response.json();
            allVendors.push(...data.data);
        }

        // Mostrar todos los datos en la consola (o procesarlos como necesites)
        console.log('All vendors:', allVendors);

        // Retornamos todos los datos
        return allVendors;

    } catch (error) {
        // Manejo de errores
        console.error('Error al obtener todos los datos:', error);
    }
    /* try {
        let page = 1;
        let perPage = 100;

        // Hacemos la primera solicitud para obtener el número total de páginas
        const initialUrl = `${ZOHO_VENDORS}?page=${page}&per_page=${perPage}`;
        const initialResponse = await fetch(initialUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!initialResponse.ok) {
            throw new Error(`Error: ${initialResponse.status}`);
        }

        const initialData = await initialResponse.json();
        const totalPages = initialData.pages;

        // Creamos un array de promesas para todas las páginas
        const requests = [];
        for (let i = 1; i <= totalPages; i++) {
            const url = `${ZOHO_VENDORS}?page=${i}&per_page=${perPage}`;
            requests.push(fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }));
        }

        // Hacemos todas las solicitudes en paralelo
        const responses = await Promise.all(requests);

        // Procesamos todas las respuestas
        const allVendors = [];
        for (const response of responses) {
            if (!response.ok) {
                throw new Error(`Error en la solicitud: ${response.status}`);
            }
            const data = await response.json();
            allVendors.push(...data.data);
        }

        // Mostramos todos los datos en la consola (o puedes procesarlos como necesites)
        console.log('All vendors:', allVendors);

        // Retornamos todos los datos
        return allVendors;

    } catch (error) {
        // Manejo de errores
        console.error('Error al obtener todos los datos:', error);
    } */
    /* try {
        let page = 1;
        let perPage = 100;
        let allVendors = [];
        let morePages = true;

        while (morePages) {
            // Construimos la URL con los parámetros de paginación
            const url = `${ZOHO_VENDORS}?page=${page}&per_page=${perPage}`;

            // Hacemos la solicitud GET al endpoint
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            // Verificamos si la respuesta es exitosa
            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }

            // Convertimos la respuesta a formato JSON
            const data = await response.json();

            // Agregamos los resultados de esta página al array de todos los vendors
            allVendors = allVendors.concat(data.data);

            // Determinamos si hay más páginas por cargar
            morePages = page < data.pages;
            page++; // Avanzamos a la siguiente página
        }

        // Mostramos todos los datos en la consola (o puedes procesarlos como necesites)
        console.log('All vendors:', allVendors);

        // Retorna todos los datos
        return allVendors;

    } catch (error) {
        // Manejo de errores
        console.error('Error al obtener todos los datos:', error);
    } */
}
