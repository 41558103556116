<template>
    <employees />
</template>

<script>
import Employees from '../../components/Employees/Employees.vue'

export default {
    name: 'EmployeesView',
    components: {
        Employees,
    },
}
</script>