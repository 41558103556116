<template>
    <v-col cols="12" sm="12" md="12">
        <v-card>
            <v-card-title>
                <span class="headline">Computer Equipment</span>
            </v-card-title>
            <v-col>
                <v-card-text>
                    <v-form ref='form'>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field v-model="editedItem.equipment_name" :rules="[rules.required]"
                                    label="Equipment Name" required>
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-model="editedItem.brand" :rules="[rules.required]" label="Brand"
                                    required>
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-model="editedItem.serial_number" :rules="[rules.required]"
                                    label="Serial Number" required>
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-model="editedItem.condition" :rules="[rules.required]" label="Condition"
                                    required>
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-model="editedItem.code" :rules="[rules.required]" label="Code" required>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea v-model="editedItem.observations" :rules="[rules.required]"
                                    label="Observations" required>
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="submitForm">
                    SAVE
                </v-btn>
            </v-card-actions>
        </v-card>
        <div class="loader-container" v-if="showloader">
            <l-grid size="75" speed="1.5" color="red"></l-grid>
        </div>
    </v-col>
</template>

<script>
import { createComputerEquipment, updateEquipmentHistory, getComputerEquipmentByUuid, updateComputerEquipment } from '../../utils/It/computerEquipment_CRUD.js'
import _ from 'lodash';
import { grid } from 'ldrs'
grid.register()


export default {
    name: 'ComputerEquipmentCard',
    props: {
        Index: String,
        Item: Object
    },
    data: () => ({
        showloader: false,
        editedItem: {
            equipment_name: '',
            brand: '',
            serial_number: 'N/A', // default value
            condition: '',
            code: '',
            observations: ''
        },
        defaultItem: {
            equipment_name: '',
            brand: '',
            serial_number: 'N/A', // default value
            condition: '',
            code: '',
            observations: ''
        },
        rules: {
            required: value => !!value || 'Required.',
        },
        editedIndex: '',
        
    }),
    async mounted() {
        console.log("this.Index: ", this.Index);
        console.log("-------------------MOUNTED.......................")
        this.$nextTick(() => {
            this.$refs.form.resetValidation();
        });
        
        console.log("this.Index: ", this.Index);
        if (this.Index != '') {
            console.log("-------------- UPDATING.......");
            console.log("+++++++++ this.item: ", this.Item);
            this.editedItem = _.cloneDeep(this.Item);
            this.editedIndex = this.Index;
            //this.editedItem.id = this.Index;
            //console.log("this.editedIndex: ", this.editedIndex);
            //console.log("this.editedItem: ", this.editedItem);

        } else {
            console.log("CREATING.......");
            this.editedItem = this.defaultItem;
            //this.editedIndex = '';
            this.editDates = true;

        }
        
    },

    methods: {
        async submitForm() {
            try {
                if (this.$refs.form.validate()) {
                    console.log('Form submitted:', this.editedItem);
                    console.log("this.editedIndex: ", this.editedIndex);
                    let response = null;
                    this.showloader = true;
                    if (this.editedIndex === '' || this.editedIndex === undefined) {
                        response = await createComputerEquipment(this.editedItem);
                        console.log("response: ", response);
                    } else {
                        // Updating record
                        response = await updateComputerEquipment(this.editedItem.uuid, this.editedItem);
                        console.log("response: ", response);
                    }
                    if (response.ok) {
                        this.$emit('sent-info', false);
                        this.showloader = false;
                    }
                } else {
                    console.log('Form validation failed.');
                }
            } catch (error) {
                console.error("Error: ", error);
            }
        },
    },
}
</script>

<style>
.loader-container {
    position: fixed;
    /* Para que el loader esté por encima de todo el contenido */
    top: 0;
    left: 0;
    width: 100%;
    /* Ocupa todo el ancho del viewport */
    height: 100%;
    /* Ocupa todo el alto del viewport */
    display: flex;
    justify-content: center;
    /* Centra horizontalmente */
    align-items: center;
    /* Centra verticalmente */
    background-color: rgba(255, 255, 255, 0.5);
    /* Fondo semitransparente */
    z-index: 9999;
    /* Coloca el loader por encima de todo el contenido */
}
</style>