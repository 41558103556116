<template>
  <div>
    <v-container>
      <!-- Switch para mostrar/ocultar el formulario de filtrado -->
      <!-- <v-switch v-model="showFilters" label="Filtrar"></v-switch> -->

      <!-- Formulario de filtros, se muestra solo si el switch está activo -->
      <v-form v-show="showFilters" ref="filterForm" @submit.prevent="search"
        style="background-color: #e0f7fa; padding: 16px; border-radius: 8px;">
        <v-row>
          <!-- Status -->
          <v-col cols="12" sm="6" md="2">
            <v-select v-model="filters.status" :items="['Active', 'Inactive']" label="Status"></v-select>
          </v-col>

          <!-- Company -->
          <v-col cols="12" sm="6" md="2">
            <v-select v-model="filters.company" :items="companies" label="Company" clearable></v-select>
          </v-col>

          <!-- Fecha de Ingreso (Entre dos fechas) -->
          <v-col cols="12" sm="6" md="2">
            <v-menu v-model="datePickers.hireDateMenu" :close-on-content-click="false" transition="scale-transition"
              offset-y min-width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="filters.hireDate" label="Hire Date" readonly v-bind="attrs"
                  v-on="on" clearable></v-text-field>
              </template>
              <v-date-picker v-model="filters.hireDate" range
                @change="datePickers.hireDateMenu = false"></v-date-picker>
            </v-menu>
          </v-col>

          <!-- Fecha de Baja o Salida (Entre dos fechas) -->
          <v-col cols="12" sm="6" md="2">
            <v-menu v-model="datePickers.endDateMenu" :close-on-content-click="false" transition="scale-transition"
              offset-y min-width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="filters.endDate" label="Termination date" readonly v-bind="attrs"
                  v-on="on" clearable></v-text-field>
              </template>
              <v-date-picker v-model="filters.endDate" range @change="datePickers.endDateMenu = false"></v-date-picker>
            </v-menu>
          </v-col>

          <!-- País -->
          <v-col cols="12" sm="6" md="2">
            <!-- <v-select v-model="filters.country" :items="countries" label="Country"></v-select> -->
            <v-combobox v-model="locationSelected" :items="countries" item-text="location" label="Location"
              return-object clearable></v-combobox>
          </v-col>

          <!-- Fecha de Cumpleaños (Entre dos fechas) -->
          <v-col cols="12" sm="6" md="2">
            <v-menu v-model="datePickers.birthDateMenu" :close-on-content-click="false" transition="scale-transition"
              offset-y min-width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="filters.birthdate" label="Birthdate" readonly v-bind="attrs"
                  v-on="on" clearable></v-text-field>
              </template>
              <v-date-picker v-model="filters.birthdate" range
                @change="datePickers.birthDateMenu = false"></v-date-picker>
            </v-menu>
          </v-col>

          <!-- Department -->
          <v-col cols="12" sm="6" md="2">
            <v-select v-model="filters.department" :items="departments" label="Department" clearable></v-select>
          </v-col>

          <!-- Tipo de Horario -->
          <v-col cols="12" sm="6" md="2">
            <v-select v-model="filters.typeOfSchedule" :items="['Full-time', 'Part-time']"
              label="Type of Schedule" clearable></v-select>
          </v-col>

          <!-- Position -->
          <v-col cols="12" sm="6" md="2">
            <v-select v-model="filters.position" :items="positions" label="Position" clearable></v-select>
          </v-col>

          <!-- Género -->
          <v-col cols="12" sm="6" md="2">
            <v-select v-model="filters.gender" :items="['Male', 'Female']" label="Gender" clearable></v-select>
          </v-col>

          <!-- Worker Category -->
          <v-col cols="12" sm="6" md="2">
            <v-select v-model="filters.worker_category"
              :items="['Independent Contract', 'Professional Service', 'Employee']" label="Worker Category" clearable></v-select>
          </v-col>

          <!-- Ethnicity -->
          <v-col cols="12" sm="6" md="2">
            <v-select v-model="filters.ethnicity" :items="ethnicities" label="Ethnicity" clearable></v-select>
          </v-col>

          <!-- Marital Status -->
          <v-col cols="12" sm="6" md="2">
            <v-select v-model="filters.maritalStatus" :items="itemMaritalStatus" label="Marital Status" clearable></v-select>
          </v-col>

          <!-- Tabaco User -->
          <v-col cols="12" sm="6" md="2">
            <v-select v-model="filters.tabacoUser" :items="['Yes', 'No']" label="Tabaco User" clearable></v-select>
          </v-col>

          <!--Has Assigned Equipment -->
          <v-col v-if="filters.hadAssignedEquipment != 'Yes'" cols="12" sm="6" md="2">
            <v-select v-model="filters.assignedEquipment" :items="['Yes', 'No']" label="Has Assigned Equipment" clearable></v-select>
          </v-col>

          <!--Had Assigned Equipment -->
          <v-col v-if="filters.assignedEquipment != 'Yes'" cols="12" sm="6" md="2">
            <v-select v-model="filters.hadAssignedEquipment" :items="['Yes', 'No']"
              label="Had Assigned Equipment" clearable></v-select>
          </v-col>

          <!-- Search Button -->
          <v-col cols="12" sm="12" md="12" class="d-flex justify-space-between">
            <!-- Contenedor para el botón "Search" del lado izquierdo -->
            <div>
              <v-btn @click="searchFilters" class="mr-4" style="background-color: #1565C0; color: white;">
                Search
              </v-btn>
            </div>

            <!-- Contenedor para los botones del lado derecho ("Reset Filters" y "Export to Excel") -->
            <div>
              <v-btn @click="resetFilters" color="grey lighten-1" class="mr-4">
                Reset Filters
              </v-btn>
              <v-btn @click="exportReport" class="ml-4" style="background-color: #28a745; color: white;">
                Export to Excel
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <v-data-table :headers="headers" :items="filteredEmployees" sort-by="name" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>HR - Employees</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-switch v-model="showFilters" label="Filters"></v-switch>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-select v-model="employeeFilter" :items="status" @input="getEmployees"
            style="max-width: 220px; margin-top: 30px; margin-right: 10px;" label="Filter Active/Inactive"></v-select>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field v-model="search" label="Search" solo-inverted prepend-icon="mdi-magnify"
            class="mr-3 mt-5"></v-text-field>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">
                Are you sure you want to deactivate <br>
                {{ editedItem.name }} {{ editedItem.last_name }}?</v-card-title>
              <v-card-text>
                <v-col cols="12" sm="6" md="6">
                  <v-menu v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-text-field v-model="dialog_end_date" label="Position End Date" readonly
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="dialog_end_date" @input="menuEndDate = false"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <!-- <v-btn color="blue darken-1" text @click="close">Cancel</v-btn> -->
                <v-btn color="red darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogActivate" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">
                Are you sure you want to activate <br>
                {{ editedItem.name }} {{ editedItem.last_name }}?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                <v-btn color="red darken-1" text @click="activateItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog content-class="dialog-height" v-model="dialogTabs" max-width="1000">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="success" dark class="mb-2 mr-2" v-bind="attrs" v-on="on">
                <v-icon>mdi-account-plus</v-icon>
              </v-btn>
              <v-btn color="blue" dark class="mb-2 mr-2" @click="getAllEmployees(employeeFilter)">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <EmployeesCard :message="messageFromParent" :newEmployee="newEmployeeValue" :index="employeeIndex"
              @sent-info="handleInfoFromChild" :department="department" :key="dialogTabs.toString()" />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-for="(header, index) in headers" v-slot:[`item.${header.value}`]="{ item }">
        <td :key="index" :class="{ 'green-background': shouldApplyGreenBackground(header.value, item) }">
          {{ item[header.value] }}
        </td>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" :style="{ color: 'blue' }" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon v-if="item.status === 'Active'" small :style="{ color: 'red' }" @click="deleteItem(item)">
          mdi-delete
        </v-icon>
        <v-icon v-if="item.status === 'Inactive'" small :style="{ color: 'green' }" @click="activateEmployee(item)">
          mdi-restore
        </v-icon>
      </template>
      <template v-slot:item.job_offerurl="{ item }">
        <div class="d-flex justify-center align-center">
          <v-icon :style="{ color: item.form_status ? 'green' : 'red' }"
            @click="item.form_status && previewJobOffer(item)">
            mdi-file-document-outline
          </v-icon>
        </div>
      </template>
      <template v-slot:item.has_assigned_equipment="{ item }">
        <div class="d-flex justify-center align-center" v-if="item.has_assigned_equipment">
          <v-icon class="mr-2" :style="{ color: 'orange' }" @click="openHistoryDialog(item, 'equipment')">
            mdi-desktop-classic
          </v-icon>
        </div>
      </template>
    </v-data-table>
    <file-modal :modalOpen.sync="modalOpen" :title="modalTitle" :dataFileUrl="fileUrl"></file-modal>
    <div class="loader-container" v-if="showLoader">
      <div class="loader"></div>
    </div>
    <!-- Modal de Historial -->
    <v-dialog v-model="historyDialog" max-width="1200px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ title_dialog }}</span>
        </v-card-title>
        <v-card-subtitle class="title" style="font-size: 1.2rem; font-weight: bold; color: #3f51b5; margin-top: -10px;">
          {{ employee.employee_number }} -
          {{ employee.name }} {{ employee.last_name }}
        </v-card-subtitle>
        <v-card-text>
          <v-data-table :headers="historyHeaders" :items="selectedItemHistory" item-key="id" class="elevation-1" dense>
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.uuid }}</td>
                <td>{{ item.assignment_date }}</td>
                <td style="white-space: normal;">{{ item.equipment_name }}</td>
                <td style="white-space: normal;">{{ item.brand }}</td>
                <td>{{ item.serial_number }}</td>
                <td style="white-space: normal;">{{ item.condition }}</td>
                <td>{{ item.code }}</td>
                <td style="white-space: normal;">{{ item.observations }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EmployeesCard from '../../components/Employees/EmployeesCard.vue';
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { CHANGE_STATUS_EMPLOYEE, GET_ALL_EMPLOYEES_BY_STATUS } from '../../utils/endpoints'
import { bus } from '../../main.js'
import FileModal from '../../components/Modals/FileModal.vue'
import { get_permissions } from '../../utils/permission.js'
import { getAllReportTo } from '../../utils/Employees/report_toCRUD'
import { filterEmployees, exportEmployeesToExcel, updateEndDate, getEmployeesByStatus, activateEmployee, fetchEmployeeJobOffer, fetchEmployeesByLocationAndStatus } from '../../utils/employees_CRUD'
import { getLocations } from '../../utils/locations'
import { searchByEmployeeNumber } from '../../utils/It/computerEquipmentAssignation_CRUD'

export default {

  name: 'Employees',
  components: {
    EmployeesCard,
    FileModal
  },
  mixins: [validationMixin],
  data: () => ({
    hr_philipinas_permissions: false,
    employee: {},
    title_dialog: '',
    historyHeaders: [
      { text: 'UUID', value: 'uuid' },
      { text: 'Assigment Date', value: 'assignment_date' },
      { text: 'Equipment Name', value: 'equipment_name' },
      { text: 'Brand', value: 'brand' },
      { text: 'Serial Number', value: 'serial_number' },
      { text: 'Condition', value: 'condition' },
      { text: 'Code', value: 'code' },
      { text: 'Observations', value: 'observations' },
    ],
    selectedItemHistory: [], // Para almacenar el historial del equipo seleccionado
    historyDialog: false,
    employeeFilter: 'Active',
    status: ['Active', 'Inactive'],
    dialog_end_date: null,
    menuEndDate: null,
    /* FILTERS */
    reportToSelected: null,
    locationSelected: null,
    reportToItem: [],
    showFilters: false,
    filters: {
      status: 'Active',
      company: null,
      hireDate: null,
      endDate: null,
      country: null,
      birthdate: null,
      department: null,
      typeOfSchedule: null,
      position: null,
      gender: null,
      reportTo: null,
      ethnicity: null,
      maritalStatus: null,
      tabacoUser: null,
      worker_category: null,
      assignedEquipment: null,
      hadAssignedEquipment: null,
    },
    datePickers: {
      hireDateMenu: false,
      endDateMenu: false,
      birthDateMenu: false,
    },
    itemMaritalStatus: [
      "Single",
      "Civil PartnerShip",
      "Married",
      "Divorced",
      "Separated",
      "Legally Separated",
      "Domestic Partner",
      "Widowed"
    ],
    companies: [
      'Nova Driving School',
      'V-Outsourcing',
      'V-Outsourcing/Gusto'
    ], // Lista de compañías
    countries: null, // Lista de países
    departments: ["Sales Department",
      "Retentions Department",
      "Calendar Department",
      "Human Resources Department",
      "Accounting Department",
      "Education Department",
      "Corporate Department",
      "Marketing Department",
      "Learning and Development Department",
      "Information Technology Department",
      "Fleet Department"], // Lista de departamentos
    positions: ["Director",
      "Manager",
      "Coordinator",
      "Supervisor",
      "Specialist",
      "Analyst",
      "Assistant",
      "Support",
      "Operator",
      "Instructor",
      "Teacher"], // Lista de posiciones
    ethnicities: [
      "Hispanic or Latino",
      "Other"
    ], // Lista de etnicidades
    ///////////////////////////////////////////////////////////////////////////
    statusFilter: 'Active',
    status: ['Active', 'Inactive'],
    showLoader: false,
    department: '',
    //Modal    
    modalOpen: false,
    modalTitle: '',
    fileUrl: '',
    dialogKey: 0, // Inicializar la clave del componente
    messageFromParent: '',
    newEmployeeValue: null,
    employeeIndex: -1,
    dialogTabs: false,
    dialog: false,
    dialogDelete: false,
    dialogActivate: false,
    search: '',
    menu: false,
    greenColumns: ['mondaystr', 'tuesdaystr', 'wednesdaystr', 'thursdaystr', 'fridaystr', 'saturdaystr', 'sundaystr'],
    headers: [
      { text: 'Actions', value: 'actions', sortable: false },
      {
        text: 'Employee number',
        align: 'start',
        sortable: false,
        value: 'employee_number',
      },
      { text: 'Name(s)', value: 'name' },
      { text: 'Last name(s)', value: 'last_name' },
      { text: 'Job Offer File', value: 'job_offerurl' },
      { text: 'Equipment', value: 'has_assigned_equipment' },
      { text: 'Company', value: 'company' },
      { text: 'Department', value: 'department' },
      { text: 'Position', value: 'position' },
      { text: 'Nova Email', value: 'nova_email' },
      { text: 'Birthdate', value: 'birthdate' },
      { text: 'Gender', value: 'gender' },
      { text: 'Country', value: 'country' },
      { text: 'City', value: 'city' },
      { text: "Hire Date", value: "hire_date" },
      { text: "End Date", value: "end_date" },
      { text: "Years With Nova", value: "years_with_nova" },
      { text: "Worker Category", value: "worker_category" },
      { text: "Marital Status", value: "marital_status" },
      { text: "Ethnicity", value: "ethnicity" },
      { text: "Tobacco User", value: "tabacco_user" },
      { text: "Monday Schedule", value: "mondaystr" },
      { text: "Tuesday Schedule", value: "tuesdaystr" },
      { text: "Wednesday Schedule", value: "wednesdaystr" },
      { text: "Thursday Schedule", value: "thursdaystr" },
      { text: "Friday Schedule", value: "fridaystr" },
      { text: "Saturday Schedule", value: "saturdaystr" },
      { text: "Sunday Schedule", value: "sundaystr" },
    ],
    editedIndex: -1,
    editedItem: {
      employee_number: '',
      company: '',
      name: '',
      last_name: '',
      gender: '',
      permissions: 'Employee',
      department: '',
      position: '',
      nova_email: 'example@novadriving.com',
      personal_phone: '',
      address: '',
      postal_code: '',
      country: '',
      state: '',
      city: '',
      birthdate: null,
      id_type: '',
      id_number: '',
      report_to: false,
      dn_name: '',
      hire_date: null,
      end_date: null,
      years_with_nova: 0,
      type_of_income: '',
      rate_office_staff: 0.0,
      btw_rate: 0.0,
      class_c_rate: 0.0,
      cr_rate: 0.0,
      ss_rate: 0.0,
      training_rate: 0.0,
      assignment_rate: 0.0,
      commissions: 0.0,
      personal_email: 'example@gmail.com',
      phone_8x8: '',
      extension_phone_8x8: '',
      location: '',
      address_location: '',
      preferences: '',
      type_of_schedule: 'N/A',
      porcentage_of_docs: 0.0,
      payment_method: 'N/A',
      activity_details: '',
      job_change_reason: '',
      worker_category: '',
      receives_comissions: 'N/A',
      pay_frequency: 'N/A',
      marital_status: '',
      ethnicity: '',
      race: '',
      medicare: '',
      medicaid: '',
      tabacco_user: '',
      name_emergency: '',
      last_name_emergency: '',
      phone_emergency: '',
      address_emergency: '',
      relationship_emergency: '',
      since: null,
      days: [
        { day_of_week: 'Monday', checked: false, start_time: null, end_time: null, startTimeMenu: false, endTimeMenu: false, employee_number: '' },
        { day_of_week: 'Tuesday', checked: false, start_time: null, end_time: null, startTimeMenu: false, endTimeMenu: false, employee_number: '' },
        { day_of_week: 'Wednesday', checked: false, start_time: null, end_time: null, startTimeMenu: false, endTimeMenu: false, employee_number: '' },
        { day_of_week: 'Thursday', checked: false, start_time: null, end_time: null, startTimeMenu: false, endTimeMenu: false, employee_number: '' },
        { day_of_week: 'Friday', checked: false, start_time: null, end_time: null, startTimeMenu: false, endTimeMenu: false, employee_number: '' },
        { day_of_week: 'Saturday', checked: false, start_time: null, end_time: null, startTimeMenu: false, endTimeMenu: false, employee_number: '' },
        { day_of_week: 'Sunday', checked: false, start_time: null, end_time: null, startTimeMenu: false, endTimeMenu: false, employee_number: '' },
      ],
      // Schedule string
      mondaystr: '',
      tuesdaystr: '',
      wednesdaystr: '',
      thursdaystr: '',
      fridaystr: '',
      saturdaystr: '',
      sundaystr: '',
      veteran: 'N/A'
    },
    defaultItem: {
      employee_number: '',
      company: '',
      name: '',
      last_name: '',
      gender: '',
      permissions: 'Employee',
      department: '',
      position: '',
      nova_email: 'example@novadriving.com',
      personal_phone: '',
      address: '',
      postal_code: '',
      country: '',
      state: '',
      city: '',
      birthdate: null,
      id_type: '',
      id_number: '',
      report_to: false,
      dn_name: '',
      hire_date: null,
      end_date: null,
      years_with_nova: 0,
      type_of_income: '',
      rate_office_staff: 0.0,
      btw_rate: 0.0,
      class_c_rate: 0.0,
      cr_rate: 0.0,
      ss_rate: 0.0,
      training_rate: 0.0,
      assignment_rate: 0.0,
      commissions: 0.0,
      personal_email: 'example@gmail.com',
      phone_8x8: '',
      extension_phone_8x8: '',
      location: '',
      address_location: '',
      preferences: '',
      type_of_schedule: 'N/A',
      porcentage_of_docs: 0.0,
      payment_method: 'N/A',
      activity_details: '',
      job_change_reason: '',
      worker_category: '',
      receives_comissions: 'N/A',
      pay_frequency: 'N/A',
      marital_status: '',
      ethnicity: '',
      race: '',
      medicare: '',
      medicaid: '',
      tabacco_user: '',
      name_emergency: '',
      last_name_emergency: '',
      phone_emergency: '',
      address_emergency: '',
      relationship_emergency: '',
      since: null,
      days: [
        { day_of_week: 'Monday', checked: false, start_time: null, end_time: null, startTimeMenu: false, endTimeMenu: false, employee_number: '' },
        { day_of_week: 'Tuesday', checked: false, start_time: null, end_time: null, startTimeMenu: false, endTimeMenu: false, employee_number: '' },
        { day_of_week: 'Wednesday', checked: false, start_time: null, end_time: null, startTimeMenu: false, endTimeMenu: false, employee_number: '' },
        { day_of_week: 'Thursday', checked: false, start_time: null, end_time: null, startTimeMenu: false, endTimeMenu: false, employee_number: '' },
        { day_of_week: 'Friday', checked: false, start_time: null, end_time: null, startTimeMenu: false, endTimeMenu: false, employee_number: '' },
        { day_of_week: 'Saturday', checked: false, start_time: null, end_time: null, startTimeMenu: false, endTimeMenu: false, employee_number: '' },
        { day_of_week: 'Sunday', checked: false, start_time: null, end_time: null, startTimeMenu: false, endTimeMenu: false, employee_number: '' },
      ],
      // Schedule string
      mondaystr: '',
      tuesdaystr: '',
      wednesdaystr: '',
      thursdaystr: '',
      fridaystr: '',
      saturdaystr: '',
      sundaystr: '',
      veteran: 'N/A',
    },

    employees: [],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Employee' : 'Edit Employee'
    },
    filteredEmployees() {
      if (this.employees) {
        return this.employees.filter(employee => {
          const nameMatch = employee.name.toLowerCase().includes(this.search.toLowerCase());
          const lastNameMatch = employee.last_name.toLowerCase().includes(this.search.toLowerCase());
          return nameMatch || lastNameMatch;
        });
      } else {
        return [];
      }
      /* return this.employees.filter(employee =>
        employee.name.toLowerCase().includes(this.search.toLowerCase())
      ); */
    },
  },
  async mounted() {
    //console.log("MOUNTED -- Employees Interface");
    bus.$emit('login-success');
    bus.$emit('permissions');
    const { department, hr_philipinas } = get_permissions();
    this.hr_philipinas_permissions = hr_philipinas;
    this.department = department;
    this.reportToItem = await getAllReportTo();
    this.countries = await getLocations();
    if(this.hr_philipinas_permissions){
      this.employees = await fetchEmployeesByLocationAndStatus('Philipinas', 'Active');
    }else{
      this.getAllEmployees('Active');
    }
    //console.log("this.department: ", this.department);
  },

  watch: {
    showFilters(val) {
      console.log("showFilters/val: ", val);
      if (!val) {
        this.resetFilters();
      }
    },
    reportToSelected(item) {
      if (item) {
        this.filters.reportTo = `${item.name} ${item.last_name}`;
        console.log("this.filters.reportTo: ", this.filters.reportTo)
      }
    },
    locationSelected(item) {
      if (item) {
        this.filters.country = item.location;
      }
    },
    dialogTabs(val) {
      //console.log("<--------------------WATCHING dialogTabs from Employees.js: ", val);
      this.newEmployeeValue = this.editedItem; //Employee data
      this.employeeIndex = this.editedIndex; //index number
      val || this.close();
    },
    dialogDelete(val) {
      val || this.close();
    },
  },
  methods: {
    async openHistoryDialog(item, type) {
      try {
        console.log("item: ", item);
        let itemAssignedEquipment = await searchByEmployeeNumber(item.employee_number);
        // Almacenar el historial del equipo seleccionado
        this.employee = itemAssignedEquipment.employee;
        /* if (type === 'history') {
          this.selectedItemHistory = item.equipment_history.equipment;
          this.title_dialog = "Equipment History"
        } else { */
        this.selectedItemHistory = itemAssignedEquipment.equipment;
        this.title_dialog = "Assigned Equipment"
        /* } */
        // Abrir el modal de historial
        this.historyDialog = true;
      } catch (error) {
        console.error("Error/openHistoryDialog: ", error);
      }
    },
    async getEmployees() {
      this.showLoader = true;
      console.log("Get employees by status: ", this.employeeFilter);

      
      //this.employees = await getEmployeesByStatus(this.employeeFilter)
      if(this.hr_philipinas_permissions){
        this.employees = await fetchEmployeesByLocationAndStatus('Philipinas', this.employeeFilter);
      }else{
        this.employees = await getEmployeesByStatus(this.employeeFilter);
      }

      this.showLoader = false;
    },
    exportReport() {
      exportEmployeesToExcel(this.employees);
    },
    resetFilters() {
      this.locationSelected = null;
      this.filters = {
        status: 'Active',
        company: null,
        hireDate: null,
        endDate: null,
        country: null,
        birthdate: null,
        department: null,
        typeOfSchedule: null,
        position: null,
        gender: null,
        reportTo: null,
        ethnicity: null,
        maritalStatus: null,
        tabacoUser: null,
        worker_category: null,
        assignedEquipment: null,
        hadAssignedEquipment: null,
      };
    },
    concatName(item) {
      return `${item.name} ${item.last_name}`;
    },
    async searchFilters() {
      // Aquí se realiza la búsqueda filtrada
      console.log(this.filters);
      this.employees = await filterEmployees(this.filters);
    },
    convertToBoolean(value) {
      // Convierte la cadena '1' en verdadero (true) y cualquier otra cosa en falso (false)
      return value === '1';
    },
    async previewJobOffer(item) {
      if (item.form_status) {
        this.showLoader = true;
        console.log("employee_number: ", item.employee_number);
        this.jobOfferFile = await fetchEmployeeJobOffer(item.employee_number);
        console.log("this.jobOfferFile ", this.jobOfferFile);
        //this.jobOfferFile = new File([combinedPdfBlob], "job_offer.pdf", { type: 'application/pdf' });
        this.modalTitle = "The form has been successfully filled out";
        //this.formStatus = item.form_status;
        this.fileUrl = URL.createObjectURL(this.jobOfferFile);
        this.modalOpen = true;
        this.showLoader = false;
      }
    },
    handleInfoFromChild(info) {
      // Manejar la información recibida desde el hijo
      //console.log('Información recibida desde el hijo:', info);
      this.dialogTabs = info; //Close dialog
      this.getAllEmployees('Active'); //Refresh employees table
    },
    sendInfoToChild() {
      //console.log("**********************************");
      //console.log("Sending info to child from FATHER.....");
      //console.log("**********************************");
      this.messageFromParent = '¡Hola desde el padre!';
      this.newEmployeeValue = this.editedItem; //Employee data
      this.employeeIndex = this.editedIndex; //index number
      //console.log("**********************************")
    },
    shouldApplyGreenBackground(column, item) {
      // Verificar si la columna actual está en la lista de columnas verdes
      return this.greenColumns.includes(column);
    },
    async getAllEmployees(status) {
      console.log("status: ", status);
      if (status === 'Active') {
        this.statusFilter = 'Active';
      }
      this.showLoader = true;
      //this.employees = await getEmployees();
      if(this.hr_philipinas_permissions){
        this.employees = await fetchEmployeesByLocationAndStatus('Philipinas', status);
      }else{
        this.employees = await getEmployeesByStatus(status);
      }

      console.log("this.employees: ", this.employees);
      this.showLoader = false;
    },
    editItem(item) {
      this.editedIndex = this.employees.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogTabs = true;
      this.sendInfoToChild();
    },
    activateEmployee(item) {
      console.log("activateEmployee/item: ", item);
      this.editedIndex = this.employees.indexOf(item);
      this.editedItem = Object.assign({}, item);
      console.log("this.editedItem: ", this.editedItem);
      this.dialogActivate = true;
    },
    deleteItem(item) {
      console.log("item: ", item);
      this.editedIndex = this.employees.indexOf(item);
      this.editedItem = Object.assign({}, item);
      console.log("this.editedItem: ", this.editedItem);
      this.dialogDelete = true;
    },

    async activateItemConfirm() {
      this.showLoader = true;
      await activateEmployee(this.editedItem.employee_number);
      //this.employees.splice(this.editedIndex, 1)
      await this.getEmployees();
      this.showLoader = false;
      this.close();
    },

    async deleteItemConfirm() {
      this.showLoader = true;
      await updateEndDate(this.editedItem.employee_number, this.dialog_end_date);
      //this.update_StatusEmployee();
      //this.employees.splice(this.editedIndex, 1)
      await this.getEmployees();
      this.showLoader = false;
      this.close();
    },

    close() {
      //console.log("Closing from Employees.vue......");
      this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
      this.editedIndex = -1;
      this.dialogTabs = false;
      this.dialogDelete = false;
      this.dialogActivate = false;
    },
    update_StatusEmployee() {
      const data = {
        employee_number: this.editedItem.employee_number,
        status: 'Inactive'
      };

      const requestOptions = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      };

      fetch(CHANGE_STATUS_EMPLOYEE, requestOptions)
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to update employee status');
          }
          return response.json();
        })
        .catch(error => {
          alert('An error occurred while updating employee status. Please try again later.');
        });
    },
  },
}
</script>

<style scoped>
/* 
Style adjustments for the data table:
- Table headers will not wrap text and will display all content in a single line.
- Table cells will wrap text if it's too long but will maintain a minimum width of 170px.
*/
.v-data-table th {
  white-space: nowrap;
}

.v-data-table td {
  /*word-wrap: break-word;*/
  white-space: nowrap;
  /* min-width: 170px; */
}

.custom-row {
  margin-bottom: -8px;
  /* Puedes ajustar este valor según sea necesario */
}

h4 {
  font-weight: bold;
  color: black;
}

h1 {
  color: orange;
}

.green-background {
  background-color: #C8E6C9;
  /* Puedes cambiar este color si es necesario */
}

.dialog-height {
  max-height: 1000px !important;
  min-height: 1000px !important;
}

/*Styles for loader*/
/* HTML: <div class="loader"></div> */
.loader-container {
  position: fixed;
  /* Para que el loader esté por encima de todo el contenido */
  top: 0;
  left: 0;
  width: 100%;
  /* Ocupa todo el ancho del viewport */
  height: 100%;
  /* Ocupa todo el alto del viewport */
  display: flex;
  justify-content: center;
  /* Centra horizontalmente */
  align-items: center;
  /* Centra verticalmente */
  background-color: rgba(255, 255, 255, 0.5);
  /* Fondo semitransparente */
  z-index: 9999;
  /* Coloca el loader por encima de todo el contenido */
}

.loader {
  width: 120px;
  height: 60px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  background: #fff;
  filter: blur(5px) contrast(10) hue-rotate(60deg);
  mix-blend-mode: darken;
}

.loader:before,
.loader:after {
  content: "";
  width: 40px;
  border-radius: 50%;
  background: #ff00ff;
  animation: l3 1s infinite alternate;
}

.loader:after {
  --s: -1;
}

@keyframes l3 {

  90%,
  100% {
    transform: translate(calc(var(--s, 1)*30px))
  }
}
</style>
