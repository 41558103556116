<template>
    <training-log-form />
</template>

<script>
import TrainingLogForm from '../../components/Training_log/TrainingLogForm.vue'

export default {
    name: 'TrainingLogView',
    components: {
        TrainingLogForm,
    },
}
</script>