import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { WALKINS_ENDPOINT, GET_ALL_EMPLOYEES_WALKINS, GET_EXCLUDED_LOCATION, GET_ALL_WALKINS_TODAY, GET_ALL_WALKINS_TODAY_AND_EMPLOYEE_NUMBER } from '../../utils/endpoints'

export async function exportWalkInsToExcel(walkins) {
    if (walkins.length > 0) {
        // Definir el orden de las columnas basado en las propiedades del modelo WalkIns
        const columnOrder = [
            'uuid', 'date_chicago', 'time_chicago', 'employee_number', 'staff_name', 'appointment_dmv',
            'btw_lessons', 'cr_cr_online', 'certificate', 'complaint', 'copies', 'hr', 'information',
            'payment', 'practice_we', 'schedule_reschedule_btw', 'sold', 'teen_rt', 
            'documents_teens_adults_chauffeurs', 'test_chauffeurs_taxi_livery', 'location', 
            'account_zoho_danubanet'
        ];

        // Crear un nuevo workbook
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Walk Ins');

        // Añadir los encabezados de las columnas
        const headerRow = columnOrder.map((col) => col.toUpperCase());
        worksheet.addRow(headerRow);

        // Aplicar estilo de negrita a los encabezados
        worksheet.getRow(1).eachCell((cell) => {
            cell.font = { bold: true };
        });

        // Formatear los datos de walkins
        walkins.forEach((walkin) => {
            let rowData = [];
            columnOrder.forEach((column) => {
                let value = walkin[column];
                rowData.push(value);
            });
            worksheet.addRow(rowData);
        });

        // Exportar el archivo Excel
        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), 'walk_ins.xlsx');
    } else {
        console.log('No hay walkins para exportar');
    }
}

export async function filterWalkIns(filters) {
    try {        
        console.log("<<<<< filters: ", filters);
        const response = await fetch(`${WALKINS_ENDPOINT}/filter`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(filters)
        });
        console.log("response: ", response);

        if (!response.ok) {
            throw new Error('Error en la solicitud');
        }

        const data = await response.json();
        console.log("data: ", data);
        return data; // Devolver los resultados filtrados
    } catch (error) {
        console.error('Error al filtrar los walk-ins:', error);
        return []; // Devolver un array vacío en caso de error
    }
}

export async function getEmployeesWithWalkIns() {
    try {
        const response = await fetch(GET_ALL_EMPLOYEES_WALKINS, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Error en la solicitud');
        }

        const data = await response.json();
        return data;  // Devolver los datos obtenidos
    } catch (error) {
        console.error('Error al obtener empleados con walk_ins:', error);
        return [];  // Devolver un array vacío en caso de error
    }
}

export async function getLocations() {
    try {
        const response = await fetch(GET_EXCLUDED_LOCATION, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Error en la solicitud');
        }

        const data = await response.json();
        return data;  // Devolver los datos obtenidos (las ubicaciones)
    } catch (error) {
        console.error('Error al obtener las ubicaciones:', error);
        return [];  // Devolver un array vacío en caso de error
    }
}

export async function deleteWalkInByUUID(uuid) {
    const url = `${WALKINS_ENDPOINT}/${uuid}`;
    
    try {
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.ok) {
            const result = await response.json();
            console.log('Walk-in successfully deleted:', result);
            return response;
        } else {
            const errorData = await response.json();
            console.error('Error deleting walk-in:', errorData);
            return errorData;
        }
    } catch (error) {
        console.error('Network or server error:', error);
        return { error: 'Network or server error' };
    }
}

export async function fetchTodayWalkIns() {
    try {
        const response = await fetch(GET_ALL_WALKINS_TODAY, {
            method: 'GET', // Método HTTP
            headers: {
                'Content-Type': 'application/json' // Tipo de contenido esperado
            }
        });

        if (!response.ok) {
            // Maneja errores si la respuesta no es 200 OK
            throw new Error(`Error: ${response.status}`);
        }

        //const data = await response.json(); // Convierte la respuesta a JSON

        //console.log('Walk-ins for today:', data.data); // Procesa la respuesta como sea necesario
        // Aquí puedes manipular los datos recibidos, por ejemplo, actualizar el DOM
        return response;

    } catch (error) {
        console.error('There was an error fetching the walk-ins:', error);
    }
}

/**
 * Function to fetch walk-ins filtered by today's date and optionally by employee number.
 * 
 * @param {string|null} employeeNumber - Optional parameter. If provided, it filters the walk-ins by the employee number. 
 *                                       If null or not provided, it only filters by today's date.
 * 
 * @returns {Promise<void>} - Fetches the data from the server and logs the walk-ins for today. 
 *                            If an error occurs, it logs the error in the console.
 * 
 * This function constructs the URL dynamically based on whether the employeeNumber is provided.
 * If the employee number is given, it will be included in the query string (`?employee_number=...`).
 * The function sends a GET request to the server and expects a JSON response. 
 * It handles successful responses by logging the data or handles errors by catching them and displaying the error message in the console.
 */
export async function fetchTodayWalkIns_ByEmployee(employeeNumber = null) {
    try {
        // Construir la URL del endpoint, añadiendo el parámetro employee_number si está presente
        let url = GET_ALL_WALKINS_TODAY_AND_EMPLOYEE_NUMBER; //'/walk_ins/records/today';
        if (employeeNumber) {
            url += `?employee_number=${encodeURIComponent(employeeNumber)}`;
        }
        console.log("Url: ", url);

        // Realizar la solicitud GET al endpoint
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        console.log("response: ", response);
        // Manejar posibles errores en la respuesta
        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }

        // Convertir la respuesta a JSON
        const data = await response.json();

        console.log('Walk-ins for today:', data); // Aquí puedes manipular los datos recibidos
        return data; // Devuelve los datos si se necesitan en otra parte del código

    } catch (error) {
        console.error('There was an error fetching the walk-ins:', error);
    }
}

export async function updateWalkIn(uuid, updatedData) {
    try {
        const response = await fetch(`${WALKINS_ENDPOINT}/${uuid}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedData),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Error: ${errorData.error}`);
        }

        const result = await response.json();
        console.log('Walk-in updated successfully:', result);
        //return result;
        return response;

    } catch (error) {
        console.error('Error updating walk-in:', error);
        return null;
    }
}