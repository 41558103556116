<template>
    <zoho-vendors />
</template>

<script>
import ZohoVendors from '../../../components/Accounting/Zoho/ZohoVendors.vue'

export default {
    name: 'ZohoVendorsView',
    components: {
        ZohoVendors,
    },
}
</script>