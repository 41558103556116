<template>
    <!-- <v-col cols="12" sm="12" md="12"> -->
        <v-card>
            <v-card-title>
                <span class="headline">Type Of Coverage</span>
            </v-card-title>
            <v-col>
                <v-card-text>
                    <v-form ref='form'>
                        <!-- Insurance Name -->
                        <v-row>
                            <!-- Insurance Name -->
                            <v-col cols="6">
                                <v-text-field v-model="editedItem.coverage" :rules="[rules.required]"
                                    label="Type of coverage" required></v-text-field>
                            </v-col>                            
                        </v-row>                        
                    </v-form>
                </v-card-text>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="submitForm">
                    SAVE
                </v-btn>
            </v-card-actions>
        </v-card>
    <!-- </v-col> -->
</template>

<script>
/* import { createCoverage } from '../../utils/insurance_CRUD.js' */
import { createCoverage, updateCoverage } from '../../../utils/Insurance/typeOfCoverageCRUD.js'
import _ from 'lodash';

export default {
    name: 'TypeOfCoverageCard',
    props: {
        Index: Number,
        Item: Object
    },
    data: () => ({
        /* showEditDates: false,
        editDates: false,
        startMenu: false,
        endMenu: false,
        driver: '',
        period: {
            startDate: null,
            endDate: null,
        }, */
        editedItem: {
            /* id: '', */
            coverage: '',
        },
        defaultItem: {
            /* id: '', */
            coverage: '',
        },
        rules: {
            required: value => !!value || 'Required.',
        },
        editedIndex: -1,
    }),
    async mounted() {
        console.log("-------------------MOUNTED.......................")
        console.log("this.Index: ", this.Index);

        //this.setPeriod();
        this.$nextTick(() => {
            this.$refs.form.resetValidation();
        });
        console.log("this.Index: ", this.Index);
        if (this.Index != -1) {
            console.log("+++++++++ this.item: ", this.Item)
            //this.editedItem = this.Item;
            this.editedItem = _.cloneDeep(this.Item);
            this.editedIndex = this.Index;
            this.editedItem.id = this.Index;
            console.log("this.editedIndex: ", this.editedIndex);
            console.log("this.editedItem: ", this.editedItem);

        } else {
            this.editedItem = this.defaultItem;
            this.editedIndex = -1;
            this.editDates = true;
        }
    },

    methods: {
        changeIconColor(color) {
            this.iconColor = color; // Cambia el color del ícono
        },
        async submitForm() {
            try {
                if (this.$refs.form.validate()) {
                    console.log('Form submitted:', this.editedItem);
                    console.log("this.editedIndex: ", this.editedIndex);
                    let response = null;
                    // ADD new record
                    if (this.editedIndex === -1) {
                        response = await createCoverage(this.editedItem);
                        console.log("response: ", response);
                    } else {
                        // Updating record
                        response = await updateCoverage(this.editedIndex, this.editedItem);
                        console.log("response: ", response);
                    }
                    if (response.ok) {
                        this.$emit('sent-info', false);
                    }
                } else {
                    console.log('Form validation failed.');
                }
            } catch (error) {
                console.error("Error: ", error);
            }
        },
    },
}
</script>

