<template>
    <expense-record-admin-panel />
</template>

<script>
import ExpenseRecordAdminPanel from '../../components/Accounting/ExpenseRecordAdminPanel.vue'

export default {
    name: 'ExpenseRecordAdminPanelView',
    components: {
        ExpenseRecordAdminPanel,
    },
}
</script>