<template>
    <permissions-console />
</template>

<script>
import PermissionsConsole from '../../components/Permissions/permissionsConsole.vue'


export default {
    name: 'PermissionsConsoleView',
    components: {
        PermissionsConsole,
    },
}
</script>