export function isAuthenticated() {
  const token = localStorage.getItem('token');

  if (token != '') {
    try {
      const tokenParts = token.split('.');
      const payload = JSON.parse(atob(tokenParts[1]));
      const exp = payload.exp;      
      const currentTime = Math.floor(Date.now() / 1000);
      if (exp < currentTime) {
        return false;
      }
      return true;
    } catch (error) {
      return false;
    }
  } else {
    return false;
  }
}
