<template>
    <div>
        <v-row class="pt-4 px-4">
            <v-col cols="12" sm="12" md="12">
                <v-card height="520">
                    <v-card-text>
                        <v-data-table :headers="headers" :items="filteredExpenseTypes" sort-by="expense_type"
                            class="elevation-1">
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-toolbar-title class="font-weight-bold">Expense Types</v-toolbar-title>
                                    <v-divider class="mx-4" inset vertical></v-divider>
                                    <v-text-field v-model="search" label="Search" solo-inverted
                                        prepend-icon="mdi-magnify" class="mr-3 mt-5"></v-text-field>
                                    <v-btn color="blue" dark class="mb-2 mr-2" @click="fetchExpenseTypes">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                    <v-btn color="success" dark class="mb-2 mr-2" @click="openDialog">
                                        <v-icon>mdi-plus-thick</v-icon>
                                    </v-btn>
                                </v-toolbar>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-icon small class="mr-2" :style="{ color: 'blue' }" @click="updateItem(item)">
                                    mdi-pencil
                                </v-icon>
                                <v-icon small :style="{ color: 'red' }" @click="deleteItem(item)">
                                    mdi-delete
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-dialog v-model="dialogOpen" max-width="700">
                <v-card class="dialog-card" outlined>
                    <ExpenseTypeCard :Index="editedIndex" :Item="editedItem" :key="dialogOpen.toString()"
                        @sent-info="handleInfoFromChild" />
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import { bus } from '../../main.js';
import ExpenseTypeCard from '../../components/Accounting/ExpenseTypeCard.vue'
import { getExpenseTypes, deleteExpenseType } from '../../utils/expense_type_CRUD.js'

export default {
    components: {
        ExpenseTypeCard
    },
    data: () => ({
        headers: [
            { text: 'Expense Type', value: 'expense_type' },
            { text: 'Bill', value: 'bill' },
            { text: 'Actions', value: 'actions', sortable: false },
        ],
        dialogOpen: false,
        search: '',
        expenseTypes: [],
        editedIndex: -1,
        editedItem: {
            expense_type: '',
            bill: '',
        },
        defaultEditedItem: {
            expense_type: '',
            bill: '',
        }
    }),
    computed: {
        filteredExpenseTypes() {
            if (!this.search.trim()) {
                return this.expenseTypes;
            } else {
                return this.expenseTypes.filter(expenseType =>
                    expenseType.expense_type.toLowerCase().includes(this.search.toLowerCase())
                );
            }
        }
    },
    async mounted() {
        //console.log("-------------------MOUNTED.......................")
        bus.$emit('login-success');
        bus.$emit('permissions');
        await this.fetchExpenseTypes();
        console.log("Mounted -- this.expenseTypes: ", this.expenseTypes);
    },
    methods: {
        handleInfoFromChild(info) {
            // Manejar la información recibida desde el hijo
            console.log('Información recibida desde el hijo:', info);
            this.dialogOpen = info; //Close dialog
            this.fetchExpenseTypes(); //Refresh employees table
        },
        updateItem(item) {
            console.log("item: ", item)
            this.editedIndex = item.id;
            this.editedItem = item;
            this.dialogOpen = true;
        },
        async deleteItem(item) {
            console.log("item: ", item)
            const response = await deleteExpenseType(item.id);
            if (response.ok) {
                this.fetchExpenseTypes();
            }
        },
        openDialog() {
            console.log("Opening dialog.....")
            this.dialogOpen = true;
        },
        async fetchExpenseTypes() {
            try {
                this.expenseTypes = await getExpenseTypes();
            } catch (error) {
                console.error("Error fetching expense types: ", error);
            }
        }
    },
    watch: {
        dialogOpen(val) {
            console.log("val: ", val);
            if (!val) {
                this.editedItem = this.defaultEditedItem;
                this.editedIndex = -1;
            }
        }
    }
}
</script>

<style scoped>
.dialog-card {
    padding: 0;
    /* Elimina el relleno interno */
    margin: 0;
    /* Elimina los márgenes */
}
</style>