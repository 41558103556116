import { ZOHO_CURRENCY } from '../../../utils/endpoints'

export async function fetchAllCurrencies() {
    try {
        // Realiza la solicitud al endpoint /currency
        const response = await fetch(ZOHO_CURRENCY, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        // Verificar si la respuesta es exitosa
        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }

        // Convertir la respuesta a JSON
        const data = await response.json();

        // Verificar el estado de la respuesta
        if (data.success) {
            console.log('Currencies:', data.data);  // Aquí puedes manejar los datos obtenidos
            return data.data;  // Devolver los datos si es necesario
        } else {
            console.error('Error fetching currencies:', data.error);
        }

    } catch (error) {
        console.error('Fetch error:', error);
    }
}
