export const expenseData = {
    account_id: "", // ID de la cuenta de gasto (Required).
    date: "", // Fecha del gasto en formato YYYY-MM-DD (Required).
    amount: 0.00, // Monto total del gasto (Required).
    paid_through_account_id: "", // ID de la cuenta por la cual se pagó el gasto (Required).
    vendor_id: "", // ID del proveedor que realizó el gasto (Opcional).
    currency_id: "2103882000000000097", // ID de la moneda utilizada para el gasto.
    exchange_rate: 1.00, // Tasa de cambio si es diferente a la moneda base.
    is_inclusive_tax: false, // Indica si el impuesto está incluido en el monto total.
    reference_number: "", // Número de referencia del gasto.
    description: "", // Descripción del gasto.
    is_billable: false, // Indica si el gasto es facturable a un cliente.
    line_items: [ // Lista de ítems relacionados con el gasto.
        /* {
            account_id: "", // ID de la cuenta del ítem.
            description: "", // Descripción del ítem.
            amount: 0.00, // Monto del ítem.
            item_order: 1, // Orden del ítem en la lista de ítems.
            // tags: [ // Lista de etiquetas relacionadas con el ítem.
            //     {
            //         tag_option_id: "", // ID de la opción de etiqueta.
            //         tag_name: "", // Nombre de la etiqueta.
            //         tag_option_name: "" // Valor de la opción de la etiqueta.
            //     }
            // ]
        } */
    ],
    payment_mode: "", // Modo de pago utilizado para el gasto.
    tags: []
};


export const getNewExpenseData = () => {
    return JSON.parse(JSON.stringify(expenseData));
};