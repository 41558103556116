<template>
    <locations />
</template>

<script>
import Locations from '../../components/Employees/Locations.vue'

export default {
    name: 'locationsView',
    components: {
        Locations,
    },
}
</script>