<template>
    <chart-of-account />
</template>

<script>
import ChartOfAccount from '../../components/Accounting/ChartOfAccount.vue'

export default {
    name: 'ChartOfAccountView',
    components: {
        ChartOfAccount,
    },
}
</script>