<template>
    <div>
        <v-data-table :headers="headers" :items="filteredTrainingLogs" class="elevation-1" :item-class="getRowClass"
            sort-by="percentage" :sort-desc="true"><!-- @click:row="redirect" @click:row="openModal"-->
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title class="font-weight-bold">Admin Training Log Console</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-select v-model="logsFilter" :items="status"
                        style="max-width: 220px; margin-top: 30px; margin-right: 10px;" label="Filter Closed/Not Closed"
                        @input="getLogsStatus"></v-select><!-- @input="getLogs" -->
                    <v-select v-model="logsType" :items="types_of_log"
                        style="max-width: 220px; margin-top: 30px; margin-right: 10px;" label="Filter Type of Log"
                        @input="getLogsType"></v-select><!-- @input="getLogs" -->
                    <v-text-field v-model="search" label="Search" solo-inverted prepend-icon="mdi-magnify"
                        class="mr-3 mt-5"></v-text-field>
                    <v-btn color="blue" dark class="mb-2 mr-2" @click="refreshForms">
                        <v-icon left>mdi-refresh</v-icon>
                        Refresh
                    </v-btn>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item, index }">
                <v-icon small class="mr-2" @click="preview(item, index)" color="#006400">
                    mdi-eye
                </v-icon><!-- :style="{ color: 'blue' }" -->
                <v-icon small class="mr-2" color="#00008B"
                    @click="openModal(item)">
                    mdi-account-plus
                </v-icon>
                <v-icon small class="mr-2" color="purple"
                    @click="openModalHistory(item)">
                    mdi-book-open-page-variant
                </v-icon>
                <v-icon small :style="{ color: 'red' }" @click="deleteItem(item)">
                    mdi-delete
                </v-icon>
            </template>
            <template v-slot:[`item.percentage`]="{ item }">
                <span class="blackBold">{{ parseFloat(item.percentage).toFixed(2) }} %</span>
            </template>
        </v-data-table>
        <new-training-log-modal v-if="modalOpen" :modalOpen.sync="modalOpen" :title="modalTitle"
            :itemEditedIndex="itemEditedIndex" :itemTrainingLog="itemTrainingLog"
            :logsFilter="logsFilter"></new-training-log-modal>
        <training-log-modal-history v-if="modalOpenHistory" :modalOpen.sync="modalOpenHistory" :title="modalTitle"
            :itemEditedIndex="itemEditedIndex" :itemTrainingLog="itemTrainingLog"
            :logsFilter="logsFilter"></training-log-modal-history>
        <v-dialog v-model="dialog" max-width="1200">
            <NewTrainingLogForm :Item="itemLog" :EditedIndex="editedIndex" :key="dialog.toString()"
                :trainingPreview="trainingPreview" @sent-info="handleInfoFromChild" />
        </v-dialog>
        <div class="loader-container" v-if="showLoader">
            <div class="loader"></div>
        </div>
    </div>
</template>

<script>
import { get_employee_number } from '../../utils/get_employee_number.js'
import NewTrainingLogForm from './NewTrainingLogForm.vue'
import { GET_ALL_TRAINING_LOGS_COMPLETE, NEW_GET_ALL_TRAINING_LOGS } from '../../utils/endpoints';

import { bus } from '../../main.js';
import NewTrainingLogModal from '../../components/Modals/NewTrainingLogModal.vue'
import TrainingLogModalHistory from '../../components/Modals/TrainingLogModalHistory'
import { fetchLogs, deleteTrainingLog, fetchTrainingLogsClosedStatus } from '../../utils/TrainingLogs/trainingLogs_CRUD.js'
import _ from 'lodash';

export default {

    components: {
        NewTrainingLogModal,
        NewTrainingLogForm,
        TrainingLogModalHistory,
    },
    data: () => ({
        showLoader: false,
        modalOpenHistory: false,
        dialog: false,
        itemLog: null,
        // Type of Logs Filter 
        logsType: 'ALL',
        types_of_log: [
            "ALL",
            "Learning Nova`s Standards",
            "Be the Student",
            "Be the Instructor",
            "Observation with Actual Student",
            "Being Observed teaching an actual student"
        ],

        trainingPreview: false,
        logsFilter: 'Not Closed',
        status: ['Closed', 'Not Closed'],
        //Modal
        modalOpen: false,
        modalTitle: 'Assigned to:',
        itemTrainingLog: null,
        itemEditedIndex: -1,
        //fileUrl: '',
        /////////
        formIndex: -1,
        editedIndex: -1,
        search: '',
        headers: [
            { text: 'Trainee`s name', value: 'trainee.name' },
            { text: 'Trainee`s last name', value: 'trainee.last_name' },
            { text: 'UUID', value: 'uuid' },
            { text: 'Type of Log', value: 'type_of_log' },
            { text: 'Percentage', value: 'percentage' },
            { text: 'Actions', value: 'actions', sortable: false },
        ],
        trainingLogs: [],

        employee_number: '',


        editedItem: {
            history: '',
            id: null,
            status: false,
            trainee_lastName: '',
            trainee_name: '',
            uuid: '',
            type_of_log: '',
            percentage: 0.0,
        }
    }),
    computed: {
        filteredTrainingLogs() {
            if (this.trainingLogs) {
                return this.trainingLogs.filter(trainingLog =>
                    trainingLog.trainee.name.toLowerCase().includes(this.search.toLowerCase())
                );
            }
            else {
                return [];
            }
        },
    },
    async mounted() {
        //console.log("-------------------MOUNTED.......................")
        bus.$emit('login-success');
        bus.$emit('permissions');
        this.showLoader = true;
        this.employee_number = get_employee_number();
        //let forms = await this.get_TrainingLog_Forms();
        console.log("this.logsType: ", this.logsType);
        this.trainingLogs = await fetchLogs(false, this.logsType);
        console.log("this.trainingLogs: ", this.trainingLogs);
        this.showLoader = false;
    },
    methods: {
        async handleInfoFromChild(info) {
            // Manejar la información recibida desde el hijo
            this.showLoader = true;
            console.log('Información recibida desde el hijo:', info);
            this.dialog = info; //Close dialog
            this.trainingLogs = await fetchLogs(false, this.logsType);
            this.showLoader = false;
            //this.refreshForms();
            //this.refreshTable();
        },
        async deleteItem(item) {
            this.showLoader = true;
            console.log("deleteItem = ", item);
            const res = await deleteTrainingLog(item.uuid);
            console.log("res - ", res);
            if (res.ok) {
                this.trainingLogs = await fetchLogs(false, this.logsType);
                this.showLoader = false;
            }
        },
        openModalHistory(item) {
            console.log("Opening modla history: ", item);
            console.log("item: ", item);
            this.editedIndex = this.trainingLogs.indexOf(item);
            console.log("------this.editedIndex: ", this.editedIndex);
            //this.editedItem = Object.assign({}, item);
            this.itemTrainingLog = item;
            console.log("this.itemTrainingLog: ", this.itemTrainingLog);
            console.log("Opening modal....")
            this.modalTitle = 'History';
            this.modalOpenHistory = true;
        },
        getRowClass(item) {
            switch (item.type_of_log) {
                case "Learning Nova`s Standards":
                    return 'gold-background';
                case "Be the Student":
                    return 'pink-background';
                case "Be the Instructor":
                    return 'skyblue-background';
                case "Observation with Actual Student":
                    return 'palegreen-background';
                case "Being Observed teaching an actual student":
                    return 'tomato-background';
                default:
                    return '';
            }
        },
        preview(item, index) {
            console.log("<<<<<<<<<<<  openLog: ", item);
            this.editedIndex = this.trainingLogs.indexOf(item);
            console.log("this.editedIndex: ", this.editedIndex);
            this.itemLog = _.cloneDeep(item);
            //this.itemLog = Object.assign({}, item);
            console.log("this.itemLog: ", this.itemLog);
            this.trainingPreview = true;
            console.log("---------- this.trainingPreview: ", this.trainingPreview);
            this.dialog = true;
        },
        async getLogsStatus(item) {
            console.log("Filtering......", item);
            this.logsFilter = item;
            //this.logsType = "ALL";
            if (item === 'Closed') {                
                //this.trainingLogs = await this.get_TrainingLog_Forms();
                //this.trainingLogs = await fetchLogs(false, this.logsType);
                //this.trainingLogs = await fetchTrainingLogsClosedStatus(true, false)
                this.trainingLogs = await fetchLogs(true, this.logsType);
                console.log("this.trainingLogs: ", this.trainingLogs);
            } else {
                //this.trainingLogs = await this.get_TrainingLog_FormsComplete();
                //this.trainingLogs = await fetchLogs(true, this.logsType);
                //this.trainingLogs = await fetchTrainingLogsClosedStatus(false, false)
                this.trainingLogs = await fetchLogs(false, this.logsType);
            }
        },
        async getLogsType(item) {
            console.log("Filtering......", item);
            //this.types_of_log = item;
            if (this.logsFilter === 'Closed') {
                //this.trainingLogs = await this.get_TrainingLog_Forms();
                this.trainingLogs = await fetchLogs(true, this.logsType);
            } else {
                //this.trainingLogs = await this.get_TrainingLog_FormsComplete();
                this.trainingLogs = await fetchLogs(false, this.logsType);
            }
        },
        async openModal(item) {
            console.log("item: ", item);
            this.editedIndex = this.trainingLogs.indexOf(item);
            console.log("------this.editedIndex: ", this.editedIndex);
            //this.editedItem = Object.assign({}, item);
            this.itemTrainingLog = item;
            console.log("this.itemTrainingLog: ", this.itemTrainingLog);
            console.log("Opening modal....")
            this.modalOpen = true;
        },
        async refreshForms() {
            try {
                this.showLoader = true;
                
                if (this.logsFilter === 'Closed') {
                    console.log("refreshing incomplete......")
                    //this.trainingLogs = await this.get_TrainingLog_Forms();
                    this.trainingLogs = await fetchLogs(true, this.logsType);
                    this.showLoader = false;
                } else{
                    console.log("refreshing Complete......")
                    //this.trainingLogs = await this.get_TrainingLog_FormsComplete();
                    this.trainingLogs = await fetchLogs(false, this.logsType);
                    this.showLoader = false;
                }
            } catch (error) {
                console.error("Error/refreshForms: ", error);
            }
        },
        async get_TrainingLog_FormsComplete() {
            try {
                const response = await fetch(GET_ALL_TRAINING_LOGS_COMPLETE); // Replace "your_endpoint" with the URL of your endpoint
                if (!response.ok) {
                    throw new Error('Failed to fetch record');
                }
                const res = await response.json();
                //return res;
                //this.trainingLogs = res;
                console.log("res get_TrainingLog_Forms: ", res);

                return res;
            } catch (error) {
                console.error('Error fetching record:', error);
                //throw error;
            }
        },
        async get_TrainingLog_Forms() {
            try {
                const response = await fetch(NEW_GET_ALL_TRAINING_LOGS); // Replace "your_endpoint" with the URL of your endpoint
                if (!response.ok) {
                    throw new Error('Failed to fetch record');
                }
                const res = await response.json();
                //return res;
                //this.trainingLogs = res;
                console.log("res get_TrainingLog_Forms: ", res);

                return res;
            } catch (error) {
                console.error('Error fetching record:', error);
                //throw error;
            }
        },
    },
    watch: {
        dialog(val) {
            console.log(">>>>>>>>>>>>> Dialog: ", val);
            if (!val) {
                this.trainingPreview = false;
            }
        },
        trainingPreview(item) {
            console.log("Dialog opened.....");
        }
    }
}
</script>

<style scoped>
::v-deep .gold-background {
    background-color: #FFE066 !important;
    /* Light Gold */
}

::v-deep .pink-background {
    background-color: #FFCCE5 !important;
    /* Light Pink */
}

::v-deep .skyblue-background {
    background-color: #A0DFF8 !important;
    /* Light Sky Blue */
}

::v-deep .palegreen-background {
    background-color: #B2F2BB !important;
    /* Light Pale Green */
}

::v-deep .tomato-background {
    background-color: #FF8A75 !important;
    /* Light Tomato */
}

/* ::v-deep .gold-background {
    background-color: #FFD700 !important;
}

::v-deep .pink-background {
    background-color: #FFB6C1 !important;
}

::v-deep .skyblue-background {
    background-color: #87CEEB !important;
}

::v-deep .palegreen-background {
    background-color: #98FB98 !important;
}

::v-deep .tomato-background {
    background-color: #FF6347 !important;
} */

::v-deep .dialog-card {
    padding: 0;
    /* Elimina el relleno interno */
    margin: 0;
    /* Elimina los márgenes */
}

::v-deep .v-dialog__content {
    padding: 0;
    /* Elimina el relleno interno */
    margin: 0;
    /* Elimina los márgenes */
}

::v-deep .custom-btn {
    margin: 20px auto;
    /* Centra el botón verticalmente */
    width: 70px;
    /* Tamaño cuadrado del botón */
    height: 70px !important;
    padding-top: 10px;
    /* Añade separación superior */
}

::v-deep .align-center {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    height: 100%;
}

::v-deep .blackBold {
    color: black !important;
    font-weight: bold !important;
}

/* Loader */
.loader-container {
    position: fixed;
    /* Para que el loader esté por encima de todo el contenido */
    top: 0;
    left: 0;
    width: 100%;
    /* Ocupa todo el ancho del viewport */
    height: 100%;
    /* Ocupa todo el alto del viewport */
    display: flex;
    justify-content: center;
    /* Centra horizontalmente */
    align-items: center;
    /* Centra verticalmente */
    background-color: rgba(255, 255, 255, 0.5);
    /* Fondo semitransparente */
    z-index: 9999;
    /* Coloca el loader por encima de todo el contenido */
}

.loader {
    width: 120px;
    height: 60px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    background: #fff;
    filter: blur(5px) contrast(10) hue-rotate(60deg);
    mix-blend-mode: darken;
}

.loader:before,
.loader:after {
    content: "";
    width: 40px;
    border-radius: 50%;
    background: #ff00ff;
    animation: l3 1s infinite alternate;
}

.loader:after {
    --s: -1;
}
</style>