<template>
    <div>
        <v-card>
            <v-card-title class="orange-bg text-center">NEW DRIVING INSTRUCTOR TRAINING LOG</v-card-title>
            <v-card-text>
                <!-- Iniciar el formulario -->
                <v-form ref="form" v-model="formValid" class="mt-4 ">
                    <v-container class="pa-0">
                        <!-- <v-row>                            
                            <v-col cols="4">
                                <v-row>
                                    <v-col cols="2">
                                        <h5>UUID: </h5>
                                    </v-col>
                                    <v-col>
                                        <span>{{ Item.uuid }}</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row> -->
                        <v-row dense>
                            <v-col>
                                <v-combobox v-model="selectedOption" :items="options" label="Select Training Type"
                                    outlined dense @change="updateBackground" :rules="[requiredRule]" required
                                    :readonly="EditedIndex != -1" item-value="value">
                                    <template v-slot:item="{ item, on, attrs }">
                                        <v-list-item v-bind="attrs" v-on="on" :class="getItemClass(item)">
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-combobox>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>
                                <v-text-field v-model="trainee.name" label="Trainee Name" outlined
                                    :rules="[requiredRule]" required
                                    :readonly="trainingPreview === true || Item.closed === true"></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field v-model="trainee.last_name" label="Trainee Last Name" outlined
                                    :rules="[requiredRule]" required
                                    :readonly="trainingPreview === true || Item.closed === true"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>

                    <div class="text-center blackBold pt-0 pb-3">
                        <!-- COMPLETE: {{ percentage }} % -->
                        <span style="color: #1E88E5;">COMPLETE: </span>
                        <span style="color: #66BB6A;">{{ percentage }} %</span>
                    </div>

                    <v-container class="scrollable-content" style="max-height: 1000px; overflow-y: auto;"
                        :style="{ backgroundColor: selectedBackground }"><!-- max-height: 900px; -->
                        <v-expansion-panels>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="small-title">
                                    INSTRUCTOR'S VEHICLE RESPONSIBILITIES - 
                                    <strong>{{ calculateSelectedPercentage('selectedTasks_one', 'tasks_one') }} %</strong>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-responsive>                                        
                                        <v-data-table v-model="selectedTasks_one" :headers="headers_one"
                                            :items="tasks_one" :single-select="singleSelect_one" item-key="name"
                                            show-select 
                                            class="elevation-1 wrap-table"
                                            :items-per-page="tasks_one.length" hide-default-footer mobile-breakpoint="0"
                                            @item-selected="onItemSelected('tasks_one', 'selected_one', $event)"
                                            @toggle-select-all="onToggleSelectAll('tasks_one', 'selected_one', $event)">
                                            <template v-slot:[`header.name`]="{ header }">
                                                <span class="blackBold header-wrap small-header">{{ header.text }}</span>
                                            </template>
                                            <template v-slot:item.name="{ item }">
                                                <div class="text-wrap">{{ item.name }}</div>
                                            </template>
                                        </v-data-table>
                                    </v-responsive>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="small-title">
                                    AT THE START OF EACH LESSON - <span class="small-title blackBold">{{
                                        calculateSelectedPercentage('selectedTasks_two', 'tasks_two') }} %</span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-responsive>
                                        <v-data-table v-model="selectedTasks_two" :headers="headers_two"
                                            :items="tasks_two" :single-select="singleSelect_two" item-key="name"
                                            show-select class="elevation-1 wrap-table"
                                            :items-per-page="tasks_two.length" hide-default-footer mobile-breakpoint="0"
                                            @item-selected="onItemSelected('tasks_two', 'selected_two', $event)"
                                            @toggle-select-all="onToggleSelectAll('tasks_two', 'selected_two', $event)">
                                            <template v-slot:[`header.name`]="{ header }">
                                                <span class="blackBold header-wrap small-header">{{ header.text }}</span>
                                            </template>
                                            <template v-slot:item.name="{ item }">
                                                <div class="text-wrap">{{ item.name }}</div>
                                            </template>
                                        </v-data-table>
                                    </v-responsive>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="small-title">
                                    BEGINNING OF FIRST LESSON - <span class="small-title blackBold">{{
                                        calculateSelectedPercentage('selectedTasks_three', 'tasks_three') }} %</span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-responsive>
                                        <v-data-table v-model="selectedTasks_three" :headers="headers_three"
                                            :items="tasks_three" :single-select="singleSelect_three" item-key="name"
                                            show-select class="elevation-1 wrap-table"
                                            :items-per-page="tasks_three.length" hide-default-footer
                                            mobile-breakpoint="0"
                                            @item-selected="onItemSelected('tasks_three', 'selected_three', $event)"
                                            @toggle-select-all="onToggleSelectAll('tasks_three', 'selected_three', $event)">
                                            <template v-slot:[`header.name`]="{ header }">
                                                <span class="blackBold header-wrap small-header">{{ header.text }}</span>
                                            </template>
                                            <template v-slot:item.name="{ item }">
                                                <div class="text-wrap">{{ item.name }}</div>
                                            </template>
                                        </v-data-table>
                                    </v-responsive>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="small-title">
                                    BEGINNING OF EVERY LESSON - <span class="small-title blackBold">{{
                                        calculateSelectedPercentage('selectedTasks_four', 'tasks_four') }} %</span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-responsive>
                                        <v-data-table v-model="selectedTasks_four" :headers="headers_four"
                                            :items="tasks_four" :single-select="singleSelect_four" item-key="name"
                                            show-select class="elevation-1 wrap-table"
                                            :items-per-page="tasks_four.length" hide-default-footer
                                            mobile-breakpoint="0"
                                            @item-selected="onItemSelected('tasks_four', 'selected_four', $event)"
                                            @toggle-select-all="onToggleSelectAll('tasks_four', 'selected_four', $event)">
                                            <template v-slot:[`header.name`]="{ header }">
                                                <span class="blackBold header-wrap small-header">{{ header.text }}</span>
                                            </template>
                                            <template v-slot:item.name="{ item }">
                                                <div class="text-wrap">{{ item.name }}</div>
                                            </template>
                                        </v-data-table>
                                    </v-responsive>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="small-title">
                                    END OF EVERY LESSON - <span class="small-title blackBold">{{
                                        calculateSelectedPercentage('selectedTasks_five', 'tasks_five') }} %</span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-responsive>
                                        <v-data-table v-model="selectedTasks_five" :headers="headers_five"
                                            :items="tasks_five" :single-select="singleSelect_five" item-key="name"
                                            show-select class="elevation-1 wrap-table"
                                            :items-per-page="tasks_five.length" hide-default-footer
                                            mobile-breakpoint="0"
                                            @item-selected="onItemSelected('tasks_five', 'selected_five', $event)"
                                            @toggle-select-all="onToggleSelectAll('tasks_five', 'selected_five', $event)">
                                            <template v-slot:[`header.name`]="{ header }">
                                                <span class="blackBold header-wrap small-header">{{ header.text }}</span>
                                            </template>
                                            <template v-slot:item.name="{ item }">
                                                <div class="text-wrap">{{ item.name }}</div>
                                            </template>
                                        </v-data-table>
                                    </v-responsive>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="small-title">
                                    SKILLSET ONE - <span class="small-title blackBold">{{
                                        calculateSelectedPercentage('selectedTasks_six', 'tasks_six') }} %</span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-responsive>
                                        <v-data-table v-model="selectedTasks_six" :headers="headers_six"
                                            :items="tasks_six" :single-select="singleSelect_six" item-key="name"
                                            show-select class="elevation-1 wrap-table"
                                            :items-per-page="tasks_six.length" hide-default-footer mobile-breakpoint="0"
                                            @item-selected="onItemSelected('tasks_six', 'selected_six', $event)"
                                            @toggle-select-all="onToggleSelectAll('tasks_six', 'selected_six', $event)">
                                            <template v-slot:[`header.name`]="{ header }">
                                                <span class="blackBold header-wrap small-header">{{ header.text }}</span>
                                            </template>
                                            <template v-slot:item.name="{ item }">
                                                <div class="text-wrap">{{ item.name }}</div>
                                            </template>
                                        </v-data-table>
                                    </v-responsive>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="small-title">
                                    SKILLSET TWO - <span class="small-title blackBold">{{
                                        calculateSelectedPercentage('selectedTasks_seven', 'tasks_seven') }} %</span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-responsive>
                                        <v-data-table v-model="selectedTasks_seven" :headers="headers_seven"
                                            :items="tasks_seven" :single-select="singleSelect_seven" item-key="name"
                                            show-select class="elevation-1 wrap-table"
                                            :items-per-page="tasks_seven.length" hide-default-footer
                                            mobile-breakpoint="0"
                                            @item-selected="onItemSelected('tasks_seven', 'selected_seven', $event)"
                                            @toggle-select-all="onToggleSelectAll('tasks_seven', 'selected_seven', $event)">
                                            <template v-slot:[`header.name`]="{ header }">
                                                <span class="blackBold header-wrap small-header">{{ header.text }}</span>
                                            </template>
                                            <template v-slot:item.name="{ item }">
                                                <div class="text-wrap">{{ item.name }}</div>
                                            </template>
                                        </v-data-table>
                                    </v-responsive>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="small-title">
                                    SKILLSET THREE - <span class="small-title blackBold">{{
                                        calculateSelectedPercentage('selectedTasks_eight', 'tasks_eight') }} %</span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-responsive>
                                        <v-data-table v-model="selectedTasks_eight" :headers="headers_eight"
                                            :items="tasks_eight" :single-select="singleSelect_eight" item-key="name"
                                            show-select class="elevation-1 wrap-table"
                                            :items-per-page="tasks_eight.length" hide-default-footer
                                            mobile-breakpoint="0"
                                            @item-selected="onItemSelected('tasks_eight', 'selected_eight', $event)"
                                            @toggle-select-all="onToggleSelectAll('tasks_eight', 'selected_eight', $event)">
                                            <template v-slot:[`header.name`]="{ header }"> <span
                                                    class="blackBold header-wrap small-header">{{
                                                        header.text }}</span>
                                            </template> <template v-slot:item.name="{ item }">
                                                <div class="text-wrap">{{ item.name }}</div>
                                            </template> </v-data-table>
                                    </v-responsive>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="small-title">
                                    SKILLSET FOUR - <span class="small-title blackBold">{{
                                        calculateSelectedPercentage('selectedTasks_nine', 'tasks_nine') }} %</span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-responsive>
                                        <v-data-table v-model="selectedTasks_nine" :headers="headers_nine"
                                            :items="tasks_nine" :single-select="singleSelect_nine" item-key="name"
                                            show-select class="elevation-1 wrap-table"
                                            :items-per-page="tasks_nine.length" hide-default-footer
                                            mobile-breakpoint="0"
                                            @item-selected="onItemSelected('tasks_nine', 'selected_nine', $event)"
                                            @toggle-select-all="onToggleSelectAll('tasks_nine', 'selected_nine', $event)">
                                            <template v-slot:[`header.name`]="{ header }"> <span
                                                    class="blackBold header-wrap small-header">{{
                                                        header.text }}</span>
                                            </template> <template v-slot:item.name="{ item }">
                                                <div class="text-wrap">{{ item.name }}</div>
                                            </template> </v-data-table>
                                    </v-responsive>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="small-title">
                                    RT PREP LESSON - <span class="small-title blackBold">{{
                                        calculateSelectedPercentage('selectedTasks_ten', 'tasks_ten') }} %</span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-responsive>
                                        <v-data-table v-model="selectedTasks_ten" :headers="headers_ten"
                                            :items="tasks_ten" :single-select="singleSelect_ten" item-key="name"
                                            show-select class="elevation-1 wrap-table"
                                            :items-per-page="tasks_ten.length" hide-default-footer mobile-breakpoint="0"
                                            @item-selected="onItemSelected('tasks_ten', 'selected_ten', $event)"
                                            @toggle-select-all="onToggleSelectAll('tasks_ten', 'selected_ten', $event)">
                                            <template v-slot:[`header.name`]="{ header }"> <span
                                                    class="blackBold header-wrap small-header">{{
                                                        header.text }}</span>
                                            </template> <template v-slot:item.name="{ item }">
                                                <div class="text-wrap">{{ item.name }}</div>
                                            </template> </v-data-table>
                                    </v-responsive>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="small-title">
                                    ADVANCE MANEUVERS - <span class="small-title blackBold">{{
                                        calculateSelectedPercentage('selectedTasks_eleven', 'tasks_eleven') }} %</span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-responsive>
                                        <v-data-table v-model="selectedTasks_eleven" :headers="headers_eleven"
                                            :items="tasks_eleven" :single-select="singleSelect_eleven" item-key="name"
                                            show-select class="elevation-1 wrap-table"
                                            :items-per-page="tasks_eleven.length" hide-default-footer
                                            mobile-breakpoint="0"
                                            @item-selected="onItemSelected('tasks_eleven', 'selected_eleven', $event)"
                                            @toggle-select-all="onToggleSelectAll('tasks_eleven', 'selected_eleven', $event)">
                                            <template v-slot:[`header.name`]="{ header }"> <span
                                                    class="blackBold header-wrap small-header">{{
                                                        header.text }}</span>
                                            </template> <template v-slot:item.name="{ item }">
                                                <div class="text-wrap">{{ item.name }}</div>
                                            </template> </v-data-table>
                                    </v-responsive>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-container>

                </v-form>
                <!-- <v-divider class="mb-4"></v-divider> -->
            </v-card-text>
            <v-container>
                <v-form>
                    <v-row>
                        <v-col cols="12" md="4">
                            <v-card class="mb-5 pa-4" elevation="2">
                                <v-card-title class="small-title-survey font-weight-bold">Attendance</v-card-title>
                                <v-card-text>
                                    <v-radio-group v-model="attendanceRating" class="pl-4"
                                        :disabled="trainingPreview || Item.closed">
                                        <v-radio label="Bad" value="Bad"></v-radio>
                                        <v-radio label="Deficient" value="Deficient"></v-radio>
                                        <v-radio label="Regular" value="Regular"></v-radio>
                                        <v-radio label="Good" value="Good"></v-radio>
                                        <v-radio label="Very Good" value="Very Good"></v-radio>
                                        <v-radio label="Excellent" value="Excellent"></v-radio>
                                    </v-radio-group>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn v-if="trainingPreview === false && !Item.closed" color="blue darken-1" text
                                        @click="clearAttendance" class="my-4 mx-2">
                                        Clear
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>

                        <v-col cols="12" md="4">
                            <v-card class="mb-5 pa-4" elevation="2">
                                <v-card-title class="small-title-survey font-weight-bold">Participation</v-card-title>
                                <v-card-text>
                                    <v-radio-group v-model="participationRating" class="pl-4"
                                        :disabled="trainingPreview || Item.closed">
                                        <v-radio label="Bad" value="Bad"></v-radio>
                                        <v-radio label="Deficient" value="Deficient"></v-radio>
                                        <v-radio label="Regular" value="Regular"></v-radio>
                                        <v-radio label="Good" value="Good"></v-radio>
                                        <v-radio label="Very Good" value="Very Good"></v-radio>
                                        <v-radio label="Excellent" value="Excellent"></v-radio>
                                    </v-radio-group>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn v-if="trainingPreview === false && !Item.closed" color="blue darken-1" text
                                        @click="clearParticipation" class="my-4 mx-2">
                                        Clear
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>

                        <v-col cols="12" md="4">
                            <v-card class="mb-5 pa-4" elevation="2">
                                <v-card-title class="small-title-survey font-weight-bold">Knowledge</v-card-title>
                                <v-card-text>
                                    <v-radio-group v-model="knowledgeRating" class="pl-4"
                                        :disabled="trainingPreview || Item.closed">
                                        <v-radio label="Bad" value="Bad"></v-radio>
                                        <v-radio label="Deficient" value="Deficient"></v-radio>
                                        <v-radio label="Regular" value="Regular"></v-radio>
                                        <v-radio label="Good" value="Good"></v-radio>
                                        <v-radio label="Very Good" value="Very Good"></v-radio>
                                        <v-radio label="Excellent" value="Excellent"></v-radio>
                                    </v-radio-group>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn v-if="trainingPreview === false && !Item.closed" color="blue darken-1" text
                                        @click="clearKnowledge" class="my-4 mx-2">
                                        Clear
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
            <v-card-actions>
                <!-- Botón Save Log -->
                <v-btn v-if="trainingPreview === false && !Item.closed" color="light-blue lighten-4" @click="saveLog"
                    class="my-4 mx-2">
                    Save Log
                </v-btn>

                <!-- Botón Close Training -->
                <v-btn v-if="pageRoute === '/new-admin-trainer-interface' && !Item.closed" color="pink lighten-4"
                    @click="close_training" class="my-4 mx-2">
                    Close Training
                </v-btn>

                <!-- Espaciador para empujar los botones de impresión a la derecha -->
                <v-spacer></v-spacer>

                <!-- Botón Print Single -->
                <v-btn v-if="pageRoute === '/new-admin-trainer-interface'" color="teal lighten-4" @click="print_single"
                    elevation="2" class="my-4 mx-2">
                    <v-icon left>mdi-printer</v-icon>
                    Print Single
                </v-btn>

                <!-- Botón Print Complete -->
                <v-btn v-if="pageRoute === '/new-admin-trainer-interface'" color="amber lighten-4"
                    @click="print_complete" elevation="2" class="my-4 mx-2">
                    <v-icon left>mdi-printer</v-icon>
                    Print Complete
                </v-btn>
            </v-card-actions>

        </v-card>
        <!-- </v-container> -->
        <div class="loader-container" v-if="showLoader">
            <div class="loader"></div>
        </div>
    </div>
</template>

<!-- This section should be completed at the end of the training. Please provide your rating on these three aspects and give your approval with any final feedback. -->

<script>
import { bus } from '../../main.js';
import { get_employee_number } from '../../utils/get_employee_number.js'
import { createTrainingLog, updateTrainingLog, updateClosed, sendEvaluationData } from '../../utils/TrainingLogs/trainingLogs_CRUD.js'

export default {
    name: 'NewTrainingLogForm',
    props: {
        Item: Object,
        EditedIndex: Number,
        trainingPreview: Boolean,
    },
    data() {
        return {
            attendanceRating: '',
            participationRating: '',
            knowledgeRating: '',
            pageRoute: window.location.pathname,
            /* Loader */
            showLoader: false,
            //////////////
            percentage: 0.0,
            type_of_log: '',
            selectedBackground: '',
            selectedOption: '', // Valor seleccionado
            options: [
                "Learning Nova`s Standards",
                "Be the Student",
                "Be the Instructor",
                "Observation with Actual Student",
                "Being Observed teaching an actual student"
            ],

            //selectedTasks: [],
            selectedTasks_one: [],
            selectedTasks_two: [],
            selectedTasks_three: [],
            selectedTasks_four: [],
            selectedTasks_five: [],
            selectedTasks_six: [],
            selectedTasks_seven: [],
            selectedTasks_eight: [],
            selectedTasks_nine: [],
            selectedTasks_ten: [],
            selectedTasks_eleven: [],
            employee_number: '',
            formValid: false,
            requiredRule: v => !!v || 'This field is required',  // Regla reutilizable
            trainee: {
                name: "",
                last_name: ""
            },
            //type_of_training: 'learningStandards',
            type_of_training: '',
            training_log: {
                learningStandards: {},  // Representa "Learning Nova’s Standards"
                studentRole: {},        // Representa "Be the Student"
                instructorRole: {},     // Representa "Be the Instructor"
                observedStudent: {},     // Representa "Observation with Actual Student"
                observedTeaching: {},   // Representa "Being Observed teaching an actual student"                
            },
            singleSelect_one: false,
            /* INSTRUCTOR'S VEHICLE RESPONSIBILITIES */
            selected_one: [],
            headers_one: [
                {
                    text: "INSTRUCTOR'S VEHICLE RESPONSIBILITIES",
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
            ],
            tasks_one: [
                {
                    name: 'Pump gas if possible (student may not put the nozzle in the fuel line, but should do everything else)',
                },
                {
                    name: 'Check tire pressure if possible (gauge, what should it be, where is that info, how to inflate, etc.)',
                },
                {
                    name: 'Go over necessary supplies/what everyone should have in a car and why',
                },
                {
                    name: 'Under the hood: battery, fluid receptacles/lines',
                },
                {
                    name: 'Check oil (when car is off and cool): how to read, how to refill, check windshield washer fluid',
                },
                {
                    name: 'Pictures of Coolant and oil levels must be sent to Fleet weekly. And weekly carwashes must be made.',
                },
            ],
            /* AT THE START OF EACH LESSON */
            singleSelect_two: false,
            selected_two: [],
            headers_two: [
                {
                    text: "AT THE START OF EACH LESSON",
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
            ],
            tasks_two: [
                {
                    name: 'DRIVING INSTRUCTOR MUST WEAR THEIR LICENSE BADGE FOR THE FULL LESSON',
                },
                {
                    name: 'Text student upon arrival. Call if no response. @BTW on WhatsApp if no answer -- BTW will call student (and/or parent if student is a teen)',
                },
                {
                    name: 'NO-SHOW: If the student is a no-show from the pick up point (not office) inform BTW immediately. Then, after text/calls from you & BTW, you can go after 15 mins. If it’s a school pick up, you can go after 30 mins. Contact BTW to get a replacement lesson, otherwise lesson is canceled with fee.',
                },
            ],
            /* BEGINNING OF FIRST LESSON */
            singleSelect_three: false,
            selected_three: [],
            headers_three: [
                {
                    text: "BEGINNING OF FIRST LESSON",
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
            ],
            tasks_three: [
                {
                    name: 'Use our Parking Lot Map to find the nearest parking lot before lesson/plug into GPS',
                },
                {
                    name: 'Introduce yourself.',
                },
                {
                    name: 'Ask the student if the name on the permit is the name they prefer to be called',
                },
                {
                    name: 'Explain 5-10 minute eval will take place at end of each lesson',
                },
                {
                    name: 'Ask if student has any driving experience (when, where and how much time)',
                },
            ],
            /* BEGINNING OF EVERY LESSON */
            singleSelect_four: false,
            selected_four: [],
            headers_four: [
                {
                    text: "BEGINNING OF EVERY LESSON",
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
            ],
            tasks_four: [
                {
                    name: 'Study student progress: Must review previous instructors evaluations, comments of what was covered, deficiencies, needs covered (with exception of the first lesson).',
                },
                {
                    name: 'Check original permit/license (5 key points)',
                },
                {
                    name: 'Correct shoes and no cellphone for both (student & instructor)',
                },
            ],
            /* END OF EVERY LESSON */
            singleSelect_five: false,
            selected_five: [],
            headers_five: [
                {
                    text: "END OF EVERY LESSON",
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
            ],
            tasks_five: [
                {
                    name: 'EVALUATION MUST BE COMPLETED IMMEDIATELY ENDING THE LESSON: Indicate skills covered, deficiencies, cover on next lesson. Entire evaluation must be satisfactory before RT.',
                },
                {
                    name: 'Sale: Suggest upgrade/more lessons if necessary and explain why',
                },
                {
                    name: 'Reviews & Testimonials: must receive minimum one review/video per week. Must provide student with qr code',
                },
            ],
            /* SKILLSET ONE */
            singleSelect_six: false,
            selected_six: [],
            headers_six: [
                {
                    text: "SKILLSET ONE",
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
            ],
            tasks_six: [
                {
                    name: 'BEFORE LEAVING TO THE PARKING LOT INSIDE THE CAR: Foot work, 5 places to put your eyes at, turning hand over hand, Explain the right of way rules for traffic.',
                },
                {
                    name: 'Locations to stop the car: stop line, crosswalk, intersection, behind another vehicle should see the rear tires meet the road, what is a full stop? (no movement).',
                },
                {
                    name: 'ON THE WAY TO THE PARKING LOT (lead by example): Instructor must drive using Nova standards.',
                },
                {
                    name: 'Explain the rules of the road (speed limits, right of ways, traffic signals, stopping distances, etc.).',
                },
                {
                    name: 'Explain where to look (center of the lane), keep the car straight using your peripheral view.',
                },
                {
                    name: 'Explain how to use the three second following distance rule.',
                },
                {
                    name: 'Explain the coordination of eyes, hands and feet whenever driving.',
                },
                {
                    name: 'Instructor demonstrate correct hand-over-hand steering and explain when, how much, how slow or fast to turn the steering wheel.',
                },
                {
                    name: 'Explain how to let the steering wheel slide through your fingers gradually while you gently accelerating.',
                },
                {
                    name: 'DEMONSTRATE (AT THE PARKING LOT): Once in the parking lot, show the route the student will follow.',
                },
                {
                    name: 'Give reference points where the center point is in each direction (tree, post, etc.).',
                },
                {
                    name: 'Also how to use your eyes (5 point of reference), hands (wide, regular & sharp turns) and allowing the steering wheel to slide back gradually through the fingers while gently accelerating, and foot (how to move from brake to gas and back) while explaining the route.',
                },
                {
                    name: 'AT THE PARKING LOT: BOTH OUTSIDE OF THE CAR: Width of the car (hugging the back of Nova`s car).',
                },
                {
                    name: 'Explain all lights front and rear of the car (color, shape, meaning, use, light-lever position).',
                },
                {
                    name: 'Length of the car (tire location, mirror location, driver seat location).',
                },
                {
                    name: 'Explain the location of seat adjustments before entering the car.',
                },
                {
                    name: 'AT THE PARKING LOT: DRIVING INSTRUCTOR OUTSIDE OF CAR, STUDENT IN DRIVER`S SEAT: Adjust: seat (up/down, back/forth); steering wheel, seat-back angle, head restraint, seatbelt (instructor assists the student adjusting ALL of this).',
                },
                {
                    name: 'Explain the door panel controls and the mirror adjustments.',
                },
                {
                    name: 'BLIND SPOTS: Explain where to stand and move to check/why to check (chin to shoulder, do not adjust the mirror at this point).',
                },
                {
                    name: 'AT THE PARKING LOT: BOTH INSIDE THE CAR: Begin from driver`ss-side; turn signal lever and head light switch; all dashboard gauges, indicators, and warning lights; wipers lever; hazard-light button; climate control, & vents.',
                },
                {
                    name: 'Explain ignition, gearshift lever (explain full use of Neutral), parking brake.',
                },
                {
                    name: 'Explain the correct adjustment for the inside rearview mirror, including what the lever underneath the mirror is for.',
                },
                {
                    name: 'Explain the correct adjustments for the outside mirrors.',
                },
                {
                    name: 'Brake positions (5 4 3 2 1 0-but not to the floor).',
                },
                {
                    name: 'Hold RPM under 2,000 (as many times as necessary: look at gauge and listen for tone; with eyes open first and then, eyes closed; ears only).',
                },
                {
                    name: 'Drive across parking lot at 5MPH / 10MPH by using a visual reference point straight ahead.',
                },
                {
                    name: 'Instructor must back the car up (not the student, do not teach reverse at this point).',
                },
            ],
            /* SKILLSET TWO */
            singleSelect_seven: false,
            selected_seven: [],
            headers_seven: [
                {
                    text: "SKILLSET TWO: BASIC MANEUVERS IN THE PARKING LOT (EYES, HANDS, AND FEET)",
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
            ],
            tasks_seven: [
                /* {
                    name: 'BASIC MANEUVERS IN THE PARKING LOT (EYES, HANDS, AND FEET).',
                }, */
                {
                    name: 'HANDS AT 9 AND 3: Explain the three basic Hand over hand turns, but practice only the 3 quarter turn.',
                },
                {
                    name: 'Wide Turns: 1/2 turn.',
                },
                {
                    name: 'Regular Turns: 3/4 turn.',
                },
                {
                    name: 'Sharp Turns: 1 full turn.',
                },
                {
                    name: 'Practice LEFT turns first exercises: 1. Look at the center of your intended path of travel. 2. Make sure your speed is within 5-10 miles/hour. 3. While peripherally allining the front of your car with either the yellow line for left turns or the right corner for right turns. 4. Make sure the student looks at the 3/4 marks on the steering wheel for regular turns. 5. Look at the center of the lane you are turning into. Then practice RIGHT turns exercises using the same 5 steps.',
                },
                {
                    name: 'Repeat all exercises above until proficient.',
                },
                {
                    name: 'You must not put any student on the road until they can successfully make 4 consecutive left & 4 consecutive right turns without instruction or assistance (verbal o physical). NO EXCEPTIONS.',
                },
            ],
            /* SKILLSET THREE */
            singleSelect_eight: false,
            selected_eight: [],
            headers_eight: [
                {
                    text: "SKILLSET THREE: BASIC MANEUVERS IN THE WIDE SIDESTREETS (no narrow streets)",
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
            ],
            tasks_eight: [
                {
                    name: 'Review everything from above first, then continue with basic maneuvers',
                },
                {
                    name: 'Pulling away from the curb: can explain each step in order',
                },
                {
                    name: 'To center in the lane, while looking far into the center of your lane, keep track of the left and right edges peripherally.',
                },
                {
                    name: 'Explain how to identify one way sidestreets vs. two way streets (direction of the park cars, the facing of the street sign, the stop lines)',
                },
                {
                    name: 'Stop sign/Traffic lights: full legal stop-no counting too 3, “check left, check front, check right, check left again”, use pedestrian walk signal countdown to gauge stale green light.',
                },
                {
                    name: 'Rules of the road (Unprotected left turn, rigth of way, etc.).',
                },
                {
                    name: 'Turning on red: make sure it\'s allowed, must make full legal stop, make sure intersection is clear of pedestrians and traffic',
                },
                {
                    name: 'Identify the shape of the corner to determine whether it\'s a regular turn, wide turn or sharp turn.',
                },
                {
                    name: 'Speed bumps (usually in parking lots), pot holes & bumps (every where), speed humps (usually in small streets), speed tables (usually found at crosswalks)',
                },
                {
                    name: 'Bike lane awareness / Bicycle green boxes',
                },
                {
                    name: 'Stopping behind another vehicle: must be able to see a little of the street under the back driver of the car in front, and a car should not fit in front of you.',
                },
                {
                    name: 'Following distance/3-second rule.',
                },
                {
                    name: 'To maintain a steady speed limit: student must be able to continually adjust the pressure applied onto the accelator.',
                },
                {
                    name: 'Scan pattern: visual sweep two blocks ahead/look for potential problems on current block',
                },
                {
                    name: 'Pulling up to the curb: look at the center of the parking lane-not at the curb',
                },
            ],
            /* SKILLSET FOUR */
            singleSelect_nine: false,
            selected_nine: [],
            headers_nine: [
                {
                    text: "SKILLSET FOUR: INTERMEDIATE MANEUVERS",
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
            ],
            tasks_nine: [
                {
                    name: 'Review everything from above first, then continue with intermediate maneuvers',
                },
                {
                    name: 'Smith System: Explain the 5 keys.',
                },
                {
                    name: 'IPDE Process: Explain Identify, Predict, Decide, Execute.',
                },
                {
                    name: 'Lane changing: signal first check inside and outside mirror and blind spot (do not force the steering wheel, just move your eyes into the center of the new lane).',
                },
                {
                    name: 'When turning left move 1/3 into the intersection before turning',
                },
                {
                    name: 'When turning right the front of your car needs the right corner.',
                },
                {
                    name: 'Wide (1/2 turn) & Sharp turns (1 full turn)',
                },
                {
                    name: 'Must turn into the closest open lane',
                },
                {
                    name: 'All 3 Reverse Straight Maneuvers: looking at inside the mirror, looking at the outside mirrors, and out the back window.',
                },
                {
                    name: '3 Types of Turnabouts: using alley to the left, backing around the corner, three-point turn no alley.',
                },
                {
                    name: 'Parking lot parking: angled & perpendicular',
                },
                {
                    name: 'If it´s the last lesson before the Road Test prep lesson, make sure everything has been covered to a satisfactory level.',
                },
            ],
            /* RT PREP LESSON  */
            singleSelect_ten: false,
            selected_ten: [],
            headers_ten: [
                {
                    text: "RT PREP LESSON",
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
            ],
            tasks_ten: [
                {
                    name: 'TIP: Use NOVA\'s RT Routes as a form of determining whether the student is at a satisfactory level',
                },
                {
                    name: 'If the student is able to complete all skill levels at a satisfactory level allow the students to schedule the RT. This is the final filter to make sure the student is RT ready. Student must had cover all skillsets at a satisfactory level. If not instructor must not allow the student to schedule a RT.',
                },
                {
                    name: 'If the student is not RT ready instructor must attempt to sale appropriate amount of lessons necessary to complete satisfactory level in all skillsets.',
                },
                {
                    name: 'If student is RT Ready, instructor must attempt sale of advance maneuvers/winter driving after obtaining driver\'s licence',
                },
            ],
            /* ADVANCE MANEUVERS */
            singleSelect_eleven: false,
            selected_eleven: [],
            headers_eleven: [
                {
                    text: "ADVANCE MANEUVERS",
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
            ],
            tasks_eleven: [
                {
                    name: 'Before teaching any advanced maneuver, be absolutely sure that the student is satisfactory in all previous maneuvers before allowing to schedule the Road Test',
                },
                {
                    name: 'Parallel parking on the right: explain the full procedure according to the on the road curriculum',
                },
                {
                    name: 'Parallel parking on the left: same as parallel parking on the right',
                },
                {
                    name: 'Express-way Driving: Entering, exiting and changing lanes. Give detailed step-by-step instructions',
                },
                {
                    name: 'Winter driving pending weather: Instructor must coordinate an assignment with HR for winter driving training with new instructors',
                },
            ],
        }
    },
    watch: {
        selectedOption(val) {
            console.log(val);
            // Asignamos el tipo de entrenamiento basado en el valor de selectedOption
            if (val === 'Learning Nova`s Standards') {
                this.type_of_training = 'learningStandards';
            } else if (val === 'Be the Student') {
                this.type_of_training = 'studentRole';
            } else if (val === 'Be the Instructor') {
                this.type_of_training = 'instructorRole';
                console.log("this.type_of_training = ", this.type_of_training);
            } else if (val === 'Observation with Actual Student') {
                this.type_of_training = 'observedStudent';
            } else if (val === 'Being Observed teaching an actual student') {
                this.type_of_training = 'observedTeaching';
            }
        }
    },
    mounted() {
        //console.log("------------------TrainingLog Form MOUNTED------------------");
        bus.$emit('login-success');
        bus.$emit('permissions');
        this.employee_number = get_employee_number();
        //console.log("this.employee_number: ", this.employee_number);
        console.log("<<<<<<<<<<<<<<<<<<< Item: ", this.Item);
        console.log("<<<<<<<<<<<<<<<<<<< EditedIndex: ", this.EditedIndex);
        console.log("<<<<<<<<<<<<<<<<<<< trainingPreview: ", this.trainingPreview);

        if (this.EditedIndex != -1) {
            // Verificar y asignar 'learningStandards'
            if (this.Item && this.Item.data && this.Item.data.learningStandards) {
                this.training_log.learningStandards = this.Item.data.learningStandards;
            } else {
                console.warn("learningStandards no está definido en Item.data");
                this.training_log.learningStandards = {}; // Inicializar con un objeto vacío
            }

            // Verificar y asignar 'studentRole'
            if (this.Item && this.Item.data && this.Item.data.studentRole) {
                this.training_log.studentRole = this.Item.data.studentRole;
            } else {
                console.warn("studentRole no está definido en Item.data");
                this.training_log.studentRole = {}; // Inicializar con un objeto vacío
            }

            // Verificar y asignar 'instructorRole'
            if (this.Item && this.Item.data && this.Item.data.instructorRole) {
                this.training_log.instructorRole = this.Item.data.instructorRole;
            } else {
                console.warn("instructorRole no está definido en Item.data");
                this.training_log.instructorRole = {}; // Inicializar con un objeto vacío
            }

            // Verificar y asignar 'observedStudent'
            if (this.Item && this.Item.data && this.Item.data.observedStudent) {
                this.training_log.observedStudent = this.Item.data.observedStudent;
            } else {
                console.warn("observedStudent no está definido en Item.data");
                this.training_log.observedStudent = {}; // Inicializar con un objeto vacío
            }

            // Verificar y asignar 'observedTeaching'
            if (this.Item && this.Item.data && this.Item.data.observedTeaching) {
                this.training_log.observedTeaching = this.Item.data.observedTeaching;
            } else {
                console.warn("observedTeaching no está definido en Item.data");
                this.training_log.observedTeaching = {}; // Inicializar con un objeto vacío
            }
            this.trainee = this.Item.trainee;
            this.selectedOption = this.Item.type_of_log;
            this.percentage = this.Item.percentage;
            /* this.attendanceRating = this.Item.survey.attendance;
            this.knowledgeRating = this.Item.survey.knowledge;
            this.participationRating = this.Item.survey.participation; */
            this.attendanceRating = this.Item?.survey?.attendance || '';
            this.knowledgeRating = this.Item?.survey?.knowledge || '';
            this.participationRating = this.Item?.survey?.participation || '';
            this.updateBackground();
            // Definir un array que contenga todas las tablas y las selecciones correspondientes
            const tables = [
                { tasks: this.tasks_one, selected: 'selected_one', selectedTasks: 'selectedTasks_one' },
                { tasks: this.tasks_two, selected: 'selected_two', selectedTasks: 'selectedTasks_two' },
                { tasks: this.tasks_three, selected: 'selected_three', selectedTasks: 'selectedTasks_three' },
                { tasks: this.tasks_four, selected: 'selected_four', selectedTasks: 'selectedTasks_four' },
                { tasks: this.tasks_five, selected: 'selected_five', selectedTasks: 'selectedTasks_five' },
                { tasks: this.tasks_six, selected: 'selected_six', selectedTasks: 'selectedTasks_six' },
                { tasks: this.tasks_seven, selected: 'selected_seven', selectedTasks: 'selectedTasks_seven' },
                { tasks: this.tasks_eight, selected: 'selected_eight', selectedTasks: 'selectedTasks_eight' },
                { tasks: this.tasks_nine, selected: 'selected_nine', selectedTasks: 'selectedTasks_nine' },
                { tasks: this.tasks_ten, selected: 'selected_ten', selectedTasks: 'selectedTasks_ten' },
                { tasks: this.tasks_eleven, selected: 'selected_eleven', selectedTasks: 'selectedTasks_eleven' }
            ];

            // Recorrer cada tabla y aplicar la lógica
            // Verificar que la propiedad en el objeto training_log esté definida y sea un array

            tables.forEach(({ tasks, selected, selectedTasks }) => {
                console.log("selected = ", selected);
                if (this.selectedOption === 'Learning Nova`s Standards') {
                    this[selected] = this.training_log.learningStandards[selected];
                } else if (this.selectedOption === "Be the Student") {
                    this[selected] = this.training_log.studentRole[selected] || [];
                } else if (this.selectedOption === "Be the Instructor") {
                    this[selected] = this.training_log.instructorRole[selected] || [];
                } else if (this.selectedOption === "Observation with Actual Student") {
                    this[selected] = this.training_log.observedStudent[selected] || [];
                } else if (this.selectedOption === "Being Observed teaching an actual student") {
                    this[selected] = this.training_log.observedTeaching[selected] || [];
                }
                //console.log(`${selected}: `, this[selected]);
                this.selectTasksBasedOnSelected(tasks, this[selected], selectedTasks);
                //console.log(`${selectedTasks}: `, this[selectedTasks]);
            });
        }
    },
    computed: {
        /* selectedTasksPercentage() {
            return ((this.selectedTasks_one.length / this.tasks_one.length) * 100).toFixed(2);
        }, */
    },
    methods: {
        cleanNamesAndSurnames(field) {
            // Primero, elimina los espacios al principio y al final
            field = field.trim();

            // Luego, si hay más de un nombre o apellido, garantiza que solo haya un espacio entre ellos
            const parts = field.split(' ').filter(Boolean); // Divide en partes y elimina cadenas vacías

            if (parts.length === 1) {
                return parts[0]; // Si es solo un nombre o apellido, lo retorna sin cambios
            } else {
                return parts.join(' '); // Si hay más de uno, los une con un solo espacio entre ellos
            }
        },
        clearKnowledge() {
            this.knowledgeRating = null;
        },
        clearParticipation() {
            this.participationRating = null;
        },
        clearAttendance() {
            this.attendanceRating = null;
        },
        async print_complete() {
            console.log("Printing complete.....")
            console.log("data: ", this.Item.data);
            console.log("trainee_name: ", this.Item.trainee.name + ' ' + this.Item.trainee.last_name);
            const data = {
                "trainee_name": this.Item.trainee.name,
                "trainee_last_name": this.Item.trainee.last_name,
            }
            console.log("data: ", data);
            const res = await sendEvaluationData(data);
            if (res) {
                console.log("SUCCESS..............");
            }
        },
        async print_single() {
            console.log("Printing single.....")
            console.log("Item: ", this.Item);
            console.log("data: ", this.Item.data);
            //const data = this.Item.data;
            const data = {
                "data": this.Item.data,
                "uuid": this.Item.uuid,
                "trainee": this.Item.trainee,
            }
            console.log("data = ", data);

            const res = await sendEvaluationData(data);
            if (res) {
                console.log("SUCCESS..............");
            }
        },
        async close_training() {
            console.log("close_training - ", this.Item);
            this.showLoader = true;
            const res = await updateClosed(this.Item.uuid, true);
            console.log("res ", res);
            if (res.ok) {
                console.log("Closed.....");
                this.showLoader = false;
                this.$emit('sent-info', false);
            }
        },
        getItemClass(option) {
            switch (option) {
                case "Learning Nova`s Standards":
                    return 'golden-background';
                case "Be the Student":
                    return 'pink-background';
                case "Be the Instructor":
                    return 'skyblue-background';
                case "Observation with Actual Student":
                    return 'lightgreen-background';
                case "Being Observed teaching an actual student":
                    return 'tomato-background';
                default:
                    return '';
            }
        },
        calculateAverageCompletion() {
            console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Calculating Average Completion......");

            console.log("selectedTasks_one: ", this.selectedTasks_one);
            // Calcula el promedio de las 11 tablas
            const percentages = [
                parseFloat(this.calculateSelectedPercentage('selectedTasks_one', 'tasks_one')),
                parseFloat(this.calculateSelectedPercentage('selectedTasks_two', 'tasks_two')),
                parseFloat(this.calculateSelectedPercentage('selectedTasks_three', 'tasks_three')),
                parseFloat(this.calculateSelectedPercentage('selectedTasks_four', 'tasks_four')),
                parseFloat(this.calculateSelectedPercentage('selectedTasks_five', 'tasks_five')),
                parseFloat(this.calculateSelectedPercentage('selectedTasks_six', 'tasks_six')),
                parseFloat(this.calculateSelectedPercentage('selectedTasks_seven', 'tasks_seven')),
                parseFloat(this.calculateSelectedPercentage('selectedTasks_eight', 'tasks_eight')),
                parseFloat(this.calculateSelectedPercentage('selectedTasks_nine', 'tasks_nine')),
                parseFloat(this.calculateSelectedPercentage('selectedTasks_ten', 'tasks_ten')),
                parseFloat(this.calculateSelectedPercentage('selectedTasks_eleven', 'tasks_eleven')),
            ];

            // Calcula el promedio
            const totalPercentage = percentages.reduce((acc, val) => acc + val, 0);
            console.log("totalPercentage: ", totalPercentage);
            this.percentage = (totalPercentage / percentages.length).toFixed(2);
            console.log("this.percentage: ", this.percentage);
        },
        calculateSelectedPercentage(selectedTasksKey, tasksKey) {
            // console.log("-------------------------")
            // console.log("<<<<<<<<<<<<<<<<<<<<<<<<<<<<< selectedTasksKey: ", selectedTasksKey);
            // console.log("<<<<<<<<<<<<<<<<<<<<<<<<<<<<< tasksKey: ", tasksKey);
            const selectedTasks = this[selectedTasksKey];
            // console.log("selectedTasks: ", selectedTasks);
            const tasks = this[tasksKey];
            if (tasks.length === 0) return 0;
            return ((selectedTasks.length / tasks.length) * 100).toFixed(2);
        },
        updateBackground() {
            //console.log("Changing background...");
            this.type_of_log = this.selectedOption;
            //console.log("this.type_of_log: ", this.type_of_log);
            // Cambiar el color del fondo basado en la opción seleccionada
            switch (this.selectedOption) {
                case "Learning Nova`s Standards":
                    this.selectedBackground = '#FFD700'; // Color dorado
                    break;
                case "Be the Student":
                    this.selectedBackground = '#FFB6C1'; // Color rosa claro
                    break;
                case "Be the Instructor":
                    this.selectedBackground = '#87CEEB'; // Color azul cielo
                    break;
                case "Observation with Actual Student":
                    this.selectedBackground = '#98FB98'; // Color verde pálido
                    break;
                case "Being Observed teaching an actual student":
                    this.selectedBackground = '#FF6347'; // Color tomate
                    break;
                default:
                    this.selectedBackground = ''; // Sin color (por defecto)
            }
        },
        selectTasksBasedOnSelected(tasks, selected, selectedTasksKey) {
            ////console.log("<<<<<<<<<<<<<<<<< selectTasksBasedOnSelected...");
            this[selectedTasksKey] = tasks.filter((task, index) => selected[index]);
            ////console.log(`<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< ${selectedTasksKey}: `, this[selectedTasksKey]);
        },
        async onToggleSelectAll(tableName, selectedArrayName, value) {
            await this.$nextTick();
            console.log("--------------------------------");
            console.log("++++++++++++++ onToggleSelectAll/value: ", value);
            console.log("this.selectedTasks_one: ", this.selectedTasks_one);
            console.log("tableName: ", tableName);
            console.log("selectedArrayName: ", selectedArrayName);
            console.log("value: ", value);
            const tableData = this[tableName];
            this[selectedArrayName] = [];
            if (value.value) {
                console.log("----------- TRUE......");
                //Seleccionar todos los ítems (marcar como true)
                this[selectedArrayName] = tableData.map(() => true);
                console.log("this[selectedArrayName]: ", this[selectedArrayName])
                console.log("this.selectedTasks_one: ", this.selectedTasks_one);
            } else {
                console.log("----------- FALSE......");
                //Deseleccionar todos los ítems (marcar como false)
                this[selectedArrayName] = tableData.map(() => false);
                console.log("this[selectedArrayName]: ", this[selectedArrayName])
                console.log("this.selectedTasks_one: ", this.selectedTasks_one);
            }
            console.log("this.selectedTasks_one: ", this.selectedTasks_one);
            this.calculateAverageCompletion();
            console.log("--------------------------------");
            //console.log("---- this[selectedArrayName]: ", this[selectedArrayName]);
        },
        async onItemSelected(tableName, selectedArrayName, { item, value }) {
            await this.$nextTick();
            console.log("<<<<<<<<<<<<<<< onItemSelected");
            console.log("item: : ", item);
            console.log("value: : ", value);

            const tableData = this[tableName];
            const selectedIndexArray = this[selectedArrayName];
            const index = tableData.indexOf(item);
            if (index !== -1) {
                this.$set(selectedIndexArray, index, value);
            }

            this.calculateAverageCompletion();
        },
        async saveLog() {
            if (this.$refs.form.validate()) {
                this.showLoader = true;
                let response = null;
                this.add_array();
                //console.log("this.trainee: ", this.trainee);
                this.calculateAverageCompletion();
                let survey = {
                    "attendance": this.attendanceRating,
                    "participation": this.participationRating,
                    "knowledge": this.knowledgeRating
                };
                console.log("survey = ", survey);
                this.trainee.name = this.cleanNamesAndSurnames(this.trainee.name);
                this.trainee.last_name = this.cleanNamesAndSurnames(this.trainee.last_name);
                let data = {
                    "data": this.training_log,
                    "trainee": this.trainee,
                    "type_of_log": this.type_of_log,
                    "employee_number": this.employee_number,
                    "percentage": this.percentage,
                    "survey": survey
                }
                console.log("<<<<<<<<<<<<<< data: ", data);

                if (this.EditedIndex != -1) {
                    //console.log("<<<<<<<<<<<<<<<<<<<<<<<< ITEM: ", this.Item);
                    //console.log("Updating.... ", data);
                    //console.log("training_log: ", this.training_log);
                    response = await updateTrainingLog(data, this.Item.uuid);
                    //console.log("response: ", response);
                } else {
                    //console.log("Adding/data: ", data);
                    response = await createTrainingLog(this.employee_number, data);
                    //console.log("response: ", response);
                }


                if (response.ok) {
                    this.showLoader = false;
                    this.$emit('sent-info', false);
                }

            }
            // Add your saving logic here
        },
        add_array() {
            if (this.type_of_training === 'learningStandards') {
                //this.training_log.training_log.learningStandards = this.selected_one;
                this.addSelectedArraysToObject(this.training_log.learningStandards);
                //console.log("this.training_log: ", this.training_log);
            } else if (this.type_of_training === 'studentRole') {
                // Para "Be the Student"
                this.addSelectedArraysToObject(this.training_log.studentRole);
            } else if (this.type_of_training === 'instructorRole') {
                // Para "Be the Instructor"
                this.addSelectedArraysToObject(this.training_log.instructorRole);
            } else if (this.type_of_training === 'observedStudent') {
                // Para "Observation with Actual Student"
                this.addSelectedArraysToObject(this.training_log.observedStudent);
            } else if (this.type_of_training === 'observedTeaching') {
                // Para "Being Observed teaching an actual student"
                this.addSelectedArraysToObject(this.training_log.observedTeaching);
            } else {
                // En caso de que el valor no coincida con ninguno
                console.error("Tipo de entrenamiento no reconocido:", this.type_of_training);
            }
        },
        addSelectedArraysToObject(obj) {
            obj.selected_one = this.selected_one;
            obj.selected_two = this.selected_two;
            obj.selected_three = this.selected_three;
            obj.selected_four = this.selected_four;
            obj.selected_five = this.selected_five;
            obj.selected_six = this.selected_six;
            obj.selected_seven = this.selected_seven;
            obj.selected_eight = this.selected_eight;
            obj.selected_nine = this.selected_nine;
            obj.selected_ten = this.selected_ten;
            obj.selected_eleven = this.selected_eleven;
            return obj;
        },

    },
}
</script>

<style scoped>
.small-title {
    font-size: 13px !important;
    /* Ajusta según tus necesidades */
    display: inline; /* Asegura que todo esté en línea */
    /* white-space: nowrap;  *//* Previene saltos de línea */
}

.small-title-survey {
    font-size: 16px !important;
    /* Ajusta según tus necesidades */
    /* display: inline; */ /* Asegura que todo esté en línea */
    /* white-space: nowrap;  *//* Previene saltos de línea */
}

.v-radio-group .v-radio__label {
    font-size: 8px !important;
    /* Ajusta el tamaño de las etiquetas de los radios */
}

.v-radio-group {
    font-size: 8px !important;
    /* Asegúrate de reducir el tamaño de todos los textos del grupo */
}

.scrollable-content {
    max-height: 450px;
    overflow-y: auto;
}

.header-wrap {
    word-wrap: break-word;
    white-space: pre-wrap;
    text-align: left;
    line-height: 1.5;
    width: 100%;
    font-weight: bold;
}

.blackBold {
    color: black;
    font-weight: bold;
    font-size: 1.0rem;
}

.wrap-table .v-data-table__wrapper {
    white-space: normal;
    overflow-x: auto;
    /* Add horizontal scroll to table wrapper */
}

.wrap-table .v-data-table__cell {
    word-wrap: break-word !important;
    white-space: pre-wrap !important;
    line-height: 1.5 !important;
    /* Hace que el texto haga wrap automáticamente */
}

.custom-card {
    width: 100%;
    max-width: 600px;
    /* Set max-width to 600px */
    margin: 0 15px;
    /* Margen para pantallas pequeñas */
}

.orange-bg {
    /* background-color: rgb(255, 71, 11); */
    background-color: rgb(255, 148, 47) !important;
}

.v-card-title {
    font-size: 1.5rem;
    /* Increased font size to 1.5rem*/
    text-align: center;
}

.text-wrap {
    word-wrap: break-word !important;
    white-space: pre-wrap !important;
    line-height: 1.5 !important;
    /* font-size: 16px !important; */
}

/* Ajustes para pantallas pequeñas */
@media (max-width: 1200px) {
    .v-card-title {
        font-size: 1.4rem;
    }

    .wrap-table .v-data-table__cell {
        font-size: 1.1rem;
    }
}

@media (max-width: 992px) {
    .v-card-title {
        font-size: 1.3rem;
    }

    .wrap-table .v-data-table__cell {
        font-size: 1rem;
    }
}

@media (max-width: 768px) {
    .v-card-title {
        font-size: 1.2rem;
    }

    .wrap-table .v-data-table__cell {
        font-size: 0.95rem;
    }
}

@media (max-width: 600px) {
    /* .v-card-title {
        font-size: 1.1rem;
    } */

    .wrap-table .v-data-table {
        font-size: 1rem;
    }

    .wrap-table .v-data-table__header {
        display: none;
        /* Hide table headers on smaller screens */
    }

    .wrap-table .v-data-table__cell {
        font-size: 0.95rem;
        padding: 10px;
        /* Add some padding to make it more readable */
    }

    .custom-card {
        margin: 0 10px;
        /* Reduce margin for smaller screens */
    }

    .v-card-title {
        font-size: 0.5rem;
    }
}

@media (max-width: 480px) {
    /* .v-card-title {
        font-size: 1rem;
    } */

    .wrap-table .v-data-table__cell {
        font-size: 0.9rem;
    }

    .custom-card {
        margin: 0 5px;
    }

    .v-card-title {
        font-size: 0.5rem;
    }
}

@media (max-width: 360px) {
    /* .v-card-title {
        font-size: 0.95rem;
    } */

    .wrap-table .v-data-table__cell {
        font-size: 0.85rem;
    }

    .custom-card {
        margin: 0 3px;
    }

    .v-card-title {
        font-size: 0.5rem;
    }
}

/* Loader */
.loader-container {
    position: fixed;
    /* Para que el loader esté por encima de todo el contenido */
    top: 0;
    left: 0;
    width: 100%;
    /* Ocupa todo el ancho del viewport */
    height: 100%;
    /* Ocupa todo el alto del viewport */
    display: flex;
    justify-content: center;
    /* Centra horizontalmente */
    align-items: center;
    /* Centra verticalmente */
    background-color: rgba(255, 255, 255, 0.5);
    /* Fondo semitransparente */
    z-index: 9999;
    /* Coloca el loader por encima de todo el contenido */
}

.loader {
    width: 120px;
    height: 60px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    background: #fff;
    filter: blur(5px) contrast(10) hue-rotate(60deg);
    mix-blend-mode: darken;
}

.loader:before,
.loader:after {
    content: "";
    width: 40px;
    border-radius: 50%;
    background: #ff00ff;
    animation: l3 1s infinite alternate;
}

.loader:after {
    --s: -1;
}

/* Aplicamos los colores de fondo usando selectores deep para los items del menú */
::v-deep .v-list-item.golden-background {
    background-color: #FFD700 !important;
}

::v-deep .v-list-item.pink-background {
    background-color: #FFB6C1 !important;
}

::v-deep .v-list-item.skyblue-background {
    background-color: #87CEEB !important;
}

::v-deep .v-list-item.lightgreen-background {
    background-color: #98FB98 !important;
}

::v-deep .v-list-item.tomato-background {
    background-color: #FF6347 !important;
}

/* Aseguramos que el texto siga siendo legible con los colores */
::v-deep .v-list-item__content {
    color: #000 !important;
}

.small-header {
    font-size: 12px; /* Ajusta el tamaño según lo necesites */
    font-weight: bold; /* Mantén el estilo si lo deseas */
    text-align: left; /* Asegúrate de que el texto esté alineado como necesitas */
}
</style>
