import { REPORT_TO } from '../../utils/endpoints'

export async function createReportTo(data) {
    console.log("<<<<<<<<<<<< data/createReportTo: ", data);
    //const url = '/report_to';  // Endpoint del servidor
    /* const data = {
        name: name,
        last_name: lastName
    }; */

    try {
        console.log("REPORT_TO: ", REPORT_TO);
        const response = await fetch(REPORT_TO, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }

        //const result = await response.json();
        //console.log('ReportTo creado:', result);
        return response;

    } catch (error) {
        console.error('Error al crear ReportTo:', error);
    }
}

export async function getAllReportTo() {
    //const url = '/report_to';  // Endpoint del servidor

    try {
        const response = await fetch(REPORT_TO, {
            method: 'GET'
        });

        console.log("response/getAllReportTo: ", response);
        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }

        const result = await response.json();
        console.log('Lista de ReportTo:', result);
        return result;

    } catch (error) {
        console.error('Error al obtener ReportTo:', error);
    }
}

export async function getReportToById(id) {
    const url = `${REPORT_TO}/${id}`;  // Endpoint con el ID

    try {
        const response = await fetch(url, {
            method: 'GET'
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }

        const result = await response.json();
        console.log(`ReportTo con ID ${id}:`, result);
        return result;

    } catch (error) {
        console.error(`Error al obtener ReportTo con ID ${id}:`, error);
    }
}


export async function updateReportTo(id, data) {
    const url = `${REPORT_TO}/${id}`;  // Endpoint con el ID
    /* const data = {
        name: name,
        last_name: lastName
    }; */

    try {
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }

        //const result = await response.json();
        //console.log(`ReportTo actualizado con ID ${id}:`, result);
        return response;

    } catch (error) {
        console.error(`Error al actualizar ReportTo con ID ${id}:`, error);
    }
}

export async function deleteReportTo(id) {
    const url = `${REPORT_TO}/${id}`;  // Endpoint con el ID

    try {
        const response = await fetch(url, {
            method: 'DELETE'
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }

        //const result = await response.json();
        //console.log(`ReportTo eliminado con ID ${id}:`, result);
        return response;

    } catch (error) {
        console.error(`Error al eliminar ReportTo con ID ${id}:`, error);
    }
}
