<template>
    <admin-trainer-interface />
</template>

<script>
import AdminTrainerInterface from '../../components/Training_log/AdminTrainerInterface'

export default {
    name: 'AdminTrainerInterfaceView',
    components: {
        AdminTrainerInterface,
    },
}
</script>