<template>
    <div>
        <v-row class="pt-4 px-4">
            <v-col cols="12" sm="12" md="12" class="d-flex justify-center">
                <v-card height="auto"><!-- width="1200" -->
                    <v-card-text>
                        <v-data-table v-model="selected" :headers="headers" :items="filteredItems"
                            :single-select="singleSelect" item-key="uuid" :show-select="statusFilter === 'Not Approved'"
                            class="elevation-1" :item-class="setRowClass" sort-by="created_date" :sort-desc="true">
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-toolbar-title class="font-weight-bold">Validate Expense Records</v-toolbar-title>
                                    <v-divider class="mx-4" inset vertical></v-divider>

                                    <!-- <v-switch v-if="statusFilter === 'Not Approved'" v-model="singleSelect"
                                        label="Single select" class="pa-3 mt-5"></v-switch>
                                    <v-divider v-if="statusFilter === 'Not Approved'" class="mx-4" inset
                                        vertical></v-divider> -->
                                    <v-btn v-if="statusFilter === 'Not Approved'" color="green" dark class="mb-2 mr-2"
                                        @click="validate_expenses">
                                        <v-icon left>mdi-check-circle</v-icon>
                                        Validate
                                    </v-btn>
                                    <v-divider v-if="statusFilter === 'Not Approved'" class="mx-4" inset
                                        vertical></v-divider>
                                    <v-select v-model="statusFilter" :items="status" @input="filterByStatus"
                                        style="max-width: 220px; margin-top: 30px; margin-right: 10px;"
                                        label="Filter Approved/Not Approved"></v-select>
                                    <v-text-field v-model="search" label="Search" solo-inverted
                                        prepend-icon="mdi-magnify" class="mr-3 mt-5"></v-text-field>
                                    <v-btn color="blue" dark class="mb-2 mr-2"
                                        @click="fetchItems(statusFilter === 'Approved')">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                </v-toolbar>
                            </template>
                            <template v-slot:item.amount="{ item }">
                                <!-- <span>$ {{ formatCurrency(item.expense_type.type[0].amount) }}</span> -->
                                <span>
                                    $ {{ formatCurrency(item.prorated ? item.amount :
                                        item.expense_type.type[0].amount) }}
                                </span>
                            </template>
                            <template v-slot:item.discount="{ item }">
                                <span>$ {{ formatCurrency(item.expense_type.type[0].discount) }}</span>
                            </template>
                            <template v-slot:item.total="{ item }">
                                <!-- <span>$ {{ formatCurrency(item.expense_type.type[0].total) }}</span> -->
                                <span>
                                    $ {{ formatCurrency(item.prorated ? item.amount :
                                        item.expense_type.type[0].total) }}
                                </span>
                            </template>
                            <!-- Template para la columna "Actions" -->
                            <template v-slot:item.status="{ item }">
                                <v-chip :color="item.status ? 'success' : 'error'" class="text-caption font-weight-bold"
                                    outlined>
                                    <v-icon left>{{ item.status ? 'mdi-check-circle' : 'mdi-alert-circle' }}</v-icon>
                                    {{ item.status ? 'Approved' : 'Pending approval' }}
                                </v-chip>
                            </template>

                            <template v-slot:item.actions="{ item }">
                                <v-btn @click="toggleStatus(item)" :color="item.status ? 'blue' : 'green'" :elevation="2"
                                    outlined rounded class="text-caption font-weight-bold">
                                    <v-icon left>{{ item.status ? 'mdi-close' : 'mdi-check' }}</v-icon>
                                    {{ item.status ? 'Revoke Approval' : 'Approve' }}
                                </v-btn><!-- deleteItem(item) -->

                                <v-icon class="ml-2 mr-2" :size="24" :style="{ color: 'orange', fontSize: '25px' }"
                                    @click="openHistoryDialog(item)">
                                    mdi-history
                                </v-icon>
                                <v-icon v-if="item.status === false" small class="mr-2" :style="{ color: 'blue' }"
                                    @click="updateItem(item)">
                                    mdi-pencil
                                </v-icon>
                            </template>
                            <template v-slot:item.vehicle_name="{ item }">
                                <span>{{ item.vehicle_name || 'N/A' }}</span>
                            </template>
                            <!-- Concatenar Management User (name + last_name), manejando nulos -->
                            <template v-slot:item.management_name="{ item }">
                                <span>
                                    <!-- Verifica si es un department, si lo es, muestra el nombre del department -->
                                    <template v-if="item.management_name?.type === 'department'">
                                        {{ item.management_name?.name || 'N/A' }}
                                    </template>

                                    <template v-else-if="item.management_name?.type === 'employee'">
                                        {{ item.management_name?.name || 'N/A' }}
                                        {{ item.management_name?.last_name || '' }}
                                    </template>

                                    <!-- De lo contrario, muestra el nombre y apellido concatenado del assigned_user -->
                                    <template v-else-if="item.management_name?.assigned_user">
                                        {{ item.management_name?.assigned_user?.name?.name || 'N/A' }}
                                        {{ item.management_name?.assigned_user?.name?.last_name || '' }}
                                    </template>

                                    <template v-else>
                                        {{ item.management_name || 'N/A' }}
                                    </template>
                                </span>
                            </template>
                            <!-- <template v-slot:item.management_name="{ item }">
                                <span>
                                    
                                    <template v-if="item.management_name?.type === 'department'">
                                        {{ item.management_name?.name || 'N/A' }}
                                    </template>

                                    <template v-else="item.management_name?.type === 'employee'">
                                        {{ item.management_name?.name || 'N/A' }}
                                        {{ item.management_name?.last_name || '' }}
                                    </template>
                                    
                                    <template v-else>
                                        {{ item.management_name?.assigned_user?.name?.name || 'N/A' }}
                                        {{ item.management_name?.assigned_user?.name?.last_name || '' }}
                                    </template>
                                </span>
                            </template> -->
                            <template v-slot:item.instructor="{ item }">
                                <span>
                                    {{ item.instructor || 'N/A' }}
                                </span>
                            </template>
                            <!-- Date Formatting -->
                            <template v-slot:item.created_date="{ item }">
                                <span>{{ formatDate(item.created_date) }}</span>
                            </template>
                            <template v-slot:item.expense_type="{ item }">
                                <!-- Mostrar un ícono de archivo por cada elemento en files_names -->
                                <div
                                    v-if="item.expense_type.type[0].files_names && item.expense_type.type[0].files_names.length > 0">
                                    <v-icon v-for="(file, index) in item.expense_type.type[0].files_names" :key="index"
                                        color="purple" @click="previewFile(file, item.uuid)">
                                        mdi-file <!-- class="d-flex justify-center align-center" Ícono de archivo -->
                                    </v-icon>
                                </div>
                            </template>
                            <template v-slot:item.prorated="{ item }">
                                <span>
                                    {{ item.prorated ? 'YES' : 'NO' }}
                                </span>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <!-- Modal de Historial -->
        <v-dialog v-model="historyDialog" max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">Approved History</span>
                </v-card-title>
                <v-card-subtitle class="title"
                    style="font-size: 1.2rem; font-weight: bold; color: #3f51b5; margin-top: -10px;">
                    {{ uuid_history }}
                </v-card-subtitle>
                <v-card-text>
                    <v-data-table :headers="historyHeaders" :items="selectedItemHistory" class="elevation-1">
                        <template v-slot:[`item.approved`]="{ item }">
                            <span>{{ item.approved === true ? 'Approved' : (item.approved === false ? 'Not Approved' :
                                'N/A') }}</span>
                        </template>
                        <template v-slot:[`item.dateTime`]="{ item }">
                            <span>{{ item.dateTime ? formatDate(item.dateTime) : 'No Date' }}</span>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="fileDialog" max-width="1000px">
            <v-card>
                <v-card-title>{{ uuid }}</v-card-title>
                <v-card-text>
                    <div v-if="selectedFileUrl && isS3Image(selectedFileUrl)">
                        <v-img :src="selectedFileUrl" max-width="100%" />
                    </div>
                    <div v-else-if="selectedFileUrl && isS3PDF(selectedFileUrl)">
                        <embed :src="selectedFileUrl" width="100%" height="800px" type="application/pdf" />
                    </div>
                    <div v-else>
                        <p>No File</p>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-if="dialogOpen" v-model="dialogOpen" max-width="1800">
            <v-card class="dialog-card" outlined>
                <ExpenseRecordCard :suppliers="supliers" :EditedIndex="editedIndex" :Item="editedItem"
                    :Employee_number="employee_number" @sent-info="handleInfoFromChild" :index="editedIndex"
                    :key="dialogOpen.toString()" />
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="600px">
            <v-card>
                <v-card-title class="text-h5">
                    Are you sure you want to REVOKE APROVAL?</v-card-title>
                <!-- <v-card-text>
                <v-col cols="12" sm="6" md="6">
                  <v-menu v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-text-field v-model="dialog_end_date" label="Position End Date" readonly
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="dialog_end_date" @input="menuEndDate = false"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-card-text> -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- <v-btn color="blue darken-1" text @click="close">Cancel</v-btn> -->
                    <v-btn color="red darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <div class="loader-container" v-if="showLoader">
            <div class="loader"></div>
        </div>

    </div>
</template>

<script>
import { bus } from '../../main.js';
import { getExpenses, getExpenseRecordsByEmployeeNumber, getExpenseRecordsByStatus } from '../../utils/Accounting/expense_record_CRUD.js'
import ExpenseRecordCard from './ExpenseRecordCard.vue';
import { get_role } from '../../utils/role.js'
import { createExpense_ZOHO, checkAuthorizationStatus, updateStatus, deleteZohoExpense, updateApprovedHistory, updateLastModification } from '../../utils/Accounting/expense_record_CRUD'
import { getNewExpenseData } from '../../utils/Accounting/ZOHO/zoho_expense_json'
import { DateTime } from 'luxon';
import { generatePublicURL } from '../../utils/presignedUrl'
import { fetchZohoVendors } from '../../utils/Accounting/ZOHO/vendors_CRUD'

export default {
    components: {
        ExpenseRecordCard
    },
    data: () => ({
        // Dialog Delete
        dialogDelete: false,
        ///////////////
        supliers: null,
        uuid: '',
        selectedFileUrl: null,
        fileDialog: false,
        // History Modal
        uuid_history: '',
        selectedItemHistory: null,
        historyDialog: false,
        historyHeaders: [
            { text: 'Employee Number', value: 'employee_number' },
            { text: 'Date Time', value: 'dateTime' },
            { text: 'Approved', value: 'approved' }
        ],
        ////////////////////
        singleSelect: false,
        selected: [],
        expenses: [],
        showLoader: false,
        // STATUS FILTER
        statusFilter: 'Not Approved',
        status: ['Approved', 'Not Approved', 'All'],
        employee_number: '',
        /* dialogInfoInsurance: false, */
        headers: [
            { text: 'Actions', value: 'actions', sortable: false, width: '200px' },
            { text: 'UUID', value: 'uuid' },
            { text: 'Zoho ID', value: 'id_zoho' },            
            { text: 'Card Number', value: 'card_number' },
            { text: 'Ledger Account', value: 'card_account.account_name' },
            { text: 'Vehicle Name', value: 'vehicle_name' },
            { text: 'Management User', value: 'management_name' },
            { text: 'Prorated', value: 'prorated' },
            { text: 'Location', value: 'location' },
            { text: 'Supplier', value: 'expense_type.type[0].supplier.contact_name' },
            { text: 'Expense Type', value: 'expense_type.type[0].type.account_name' },
            { text: 'Expense Date', value: 'expense_date' },
            { text: 'Created Date', value: 'created_date' },
            { text: 'Created by', value: 'created_by' },
            { text: 'Amount', value: 'amount' },
            { text: 'Discount', value: 'discount' },
            { text: 'Total', value: 'total' },
            { text: 'Attachment', value: 'expense_type' },
        ],
        dialogOpen: false,
        search: '',
        items: [],
        editedIndex: '',
        editedItem: {},
        defaultItem: {},
    }),
    computed: {
        filteredItems() {
            if (!this.search.trim()) {
                return this.items;
            } else {
                return this.items.filter(item =>
                    item.uuid.toLowerCase().includes(this.search.toLowerCase())
                );
            }
        }
    },
    async mounted() {
        //console.log("-------------------MOUNTED.......................")
        bus.$emit('login-success');
        bus.$emit('permissions');
        this.employee_number = get_role().employee_number;
        await this.fetchItems(false);
        this.supliers = await fetchZohoVendors();
    },
    methods: {
        deleteItem(item) {
            console.log("item: ", item);
            //this.editedIndex = this.employees.indexOf(item);
            this.editedItem = Object.assign({}, item);
            console.log("Delete / this.editedItem: ", this.editedItem);
            this.dialogDelete = true;
        },
        async deleteItemConfirm() {
            console.log("Confirm delete....");
            this.showLoader = true;
            await this.handleStatusChange(this.editedItem);            
            this.showLoader = false;
            this.dialogDelete = false;
        },
        openInfo(item) {
            console.log("Openning info....", item);
            this.editedIndex = item.uuid;
            //this.editedItem = Object.assign({}, item);
            this.editedItem = _.cloneDeep(item);
            if (this.editedItem.management_name != null) {
                console.log("Assigning management_name...");
                this.editedItem.management_name = Object.assign({}, item.management_name);
            }
            console.log("this.editedItem: ", this.editedItem);
            this.dialogOpen = true;
        },
        updateItem(item) {
            console.log("UPDATING..... ", item);
            this.openInfo(item);
        },
        async openFileDialogS3Bucket(file, uuid) {
            try {
                console.log("uuid: ", uuid);
                if (uuid !== '' && uuid !== undefined) {
                    console.log("OPEN FILE DIALOG S3...........");
                    const folderName = `Accounting/Expense_Record/${uuid}`;
                    console.log("folderName: ", folderName);
                    const presignedUrl = await generatePublicURL(folderName, file);  // Genera la URL presignada
                    console.log("presignedUrl: ", presignedUrl);
                    this.selectedFileUrl = presignedUrl.url;  // Asigna la URL generada
                    console.log("this.selectedFileUrl: ", this.selectedFileUrl);
                    this.fileDialog = true;  // Abre el diálogo para mostrar la vista previa
                } /* else {
                    console.log("OPEN FILE DIALOG LOCAL...........");
                    this.selectedFile = file;
                    if (file.type === 'application/pdf') {
                        this.selectedFile.preview = URL.createObjectURL(file);
                    }
                    this.dialog = true;
                } */
            } catch (error) {
                console.error("Error al manejar el archivo:", error);
            }
        },
        isS3Image(url) {
            return /\.(jpe?g|png|gif)(?=\?|$)/i.test(url);  // Verifica si el archivo es una imagen
        },
        isS3PDF(url) {
            return /\.pdf(?=\?|$)/i.test(url);  // Verifica si el archivo es un PDF
        },
        async previewFile(file, uuid) {
            this.showLoader = true;
            console.log("preview:", file);
            console.log("uuid:", uuid);
            this.uuid = uuid;
            await this.openFileDialogS3Bucket(file, uuid);
            this.showLoader = false;
        },
        formatDate(dateTime) {
            if (!dateTime) return '';
            const date = new Date(dateTime);
            return date.toISOString().slice(0, 19).replace('T', ' ');
        },
        openHistoryDialog(item) {
            console.log("item: ", item);
            // Verificar si `approved_history` existe y contiene la propiedad `history`
            if (item && item.approved_history && Array.isArray(item.approved_history.history)) {
                this.selectedItemHistory = item.approved_history.history;
            } else {
                // Si no existe o es `null`, asignar un array vacío
                this.selectedItemHistory = [];
            }
            this.uuid_history = item.uuid;

            // Abrir el modal de historial
            this.historyDialog = true;
        },
        async validate_expenses() {
            try {
                if (this.selected.length > 0) {

                    console.log("validating: ", this.selected);
                    this.showLoader = true; // Activa el loader
                    // Usa Promise.all para esperar a que todas las validaciones se completen
                    await Promise.all(this.selected.map(item => this.validate_expenses_array(item)));
                    this.showLoader = false; // Desactiva el loader después de que todas las validaciones terminen
                    await this.fetchItems(false);
                    this.selected = [];
                } else {
                    alert("No expenses selected");
                }
            } catch (error) {
                console.error("Error/validate_expenses: ", error);
            }

            // aquí puedes agregar cualquier otra lógica adicional
        },
        async toggleStatus(item) {
            console.log("item: ", item);
            //item.status = !item.status;
            // Actualiza `selectedStatus` en función de `status`
            if (item.status) {
                item.selectedStatus = 'Not Approved'
                console.log("item.selectedStatus: ", item.selectedStatus);
                this.deleteItem(item)                           
            } else {
                item.selectedStatus = 'Approved'
                console.log("item.selectedStatus: ", item.selectedStatus);
                await this.handleStatusChange(item);                
            }            
        },
        formatDate(date) {
            const chicagoTime = DateTime.fromISO(date, { zone: 'utc' }).setZone('America/Chicago');
            // Format the date in 'yyyy-MM-dd HH:mm:ss' format
            return chicagoTime.toFormat('yyyy-MM-dd HH:mm:ss');
        },
        async handleStatusChange(item) {
            try {
                this.showLoader = true;
                let uuid = item.uuid;
                if (item.selectedStatus === 'Approved') {
                    //this.showLoader = true;
                    console.log("item: ", item);
                    console.log(`Selected status for ${item.uuid}: ${item.selectedStatus}`);
                    //let uuid = item.uuid;
                    console.log("uuid: ", uuid);
                    console.log("item:", JSON.stringify(item, null, 2));
                    const res = await this.validate(item);
                    if (res) {
                        this.showLoader = false;
                    }
                } else {
                    console.log(`Selected status for ${item.uuid}: ${item.selectedStatus}`);
                    const id_zoho = item.id_zoho;
                    let res_delete = await deleteZohoExpense(id_zoho)
                    console.log("res_delete: ", res_delete);
                    if (res_delete.ok || (res_delete.status === 404) || (res_delete.details.error === 'Invalid expense_id format.')) {
                        const res_approve = await updateApprovedHistory(this.employee_number, false, uuid);
                        if (res_approve.ok) {
                            let res_update = await updateStatus(uuid, false);
                            if (res_update.ok) {
                                await updateLastModification(uuid, this.employee_number, 'desapproved')
                                await this.fetchItems(true);
                                return true;
                            }
                        } else {
                            console.error("Error, Update approve history");
                            this.showLoader = false;
                        }

                    } else {
                        this.showLoader = false;
                        alert("Status does not change!")
                    }
                }
            } catch (error) {
                this.showLoader = false;
                console.error("handleStatusChange/Error: ", error);
            }
        },
        async validate_expenses_array(item) {
            this.showLoader = true;
            let uuid = item.uuid;
            // Generar el payload a partir del formulario
            let payload = this.generate_json(item);
            try {
                //createExpense_ZOHO
                // Intentar enviar el payload al endpoint
                //let response = await createExpense_ZOHO(payload);
                let response = await createExpense_ZOHO(payload, uuid);
                // Verificar si la respuesta es exitosa               
                console.log("---- response.message: ", response.message);
                if (response.message === "Expense and receipts added successfully" || response.message === "Expense created successfully, NO FILES FOUND IN 'files_names'") {
                    console.log("SUCCESFUL / response: ", response);
                    console.log("UPDATING STATUS.....");
                    const res_approve = await updateApprovedHistory(this.employee_number, true, uuid);
                    if (res_approve.ok) {
                        await updateStatus(uuid, true);
                        await updateLastModification(uuid, this.employee_number, 'approved')
                    }
                }
            } catch (error) {
                // Manejar cualquier error de la operación asincrónica
                console.error("Error during expense creation:", error);
            }
        },
        async validate(item) {
            this.showLoader = true;
            let uuid = item.uuid;
            // Generar el payload a partir del formulario
            let payload = this.generate_json(item);
            try {
                //createExpense_ZOHO
                // Intentar enviar el payload al endpoint
                //let response = await createExpense_ZOHO(payload);


                let response = await createExpense_ZOHO(payload, uuid);

                // Verificar si la respuesta es exitosa
                console.log("<<<<<<<<<<<response: ", response);
                console.log("<<<<<<<<<<<<<<< response: ", response);
                console.log("<<<<<<<<<<<<<<< response.message: ", response.message);
                console.log("<<<<<<<<<<<<<<< response.status: ", response.status);
                if (response && typeof response.url !== 'undefined') {
                    console.log("response.url: ", response.url);
                    // Abrir la URL en una nueva pestaña del navegador
                    window.open(response.url, '_blank');
                } else {
                    console.log("La respuesta no contiene una URL válida.");
                }

                if (response.message === "Browser opened succesfully") {
                    ////console.log("Autorización pendiente. Consultando el estado...");
                    let response_check = await checkAuthorizationStatus(response.unique_id);
                    ////console.log("response/checkAuthorizationStatus: ", response_check);
                    ////console.log("UPDATING STATUS.....");
                    let res_update = await updateStatus(uuid, true);
                    console.log("res_update: ", res_update)
                    if (res_update.ok) {
                        await updateLastModification(uuid, this.employee_number, 'approved')
                        return true;
                    }
                } else if (response.message === 'Expense and receipts added successfully' || response.message === "Expense created successfully, NO FILES FOUND IN 'files_names'") {
                    console.log("SUCCESFUL / response: ", response);
                    console.log("UPDATING STATUS.....");

                    const res_approve = await updateApprovedHistory(this.employee_number, true, uuid);

                    let res_update = null;
                    if (res_approve.ok) {
                        res_update = await updateStatus(uuid, true);
                        await updateLastModification(uuid, this.employee_number, 'approved')
                    }

                    console.log("res_update: ", res_update)
                    if (res_update.ok) {
                        this.showLoader = false;
                        await this.fetchItems(false);
                        return true;
                    }
                } else {
                    console.error("Error to create Expense in zoho books");
                    this.showLoader = false;
                }
            } catch (error) {
                // Manejar cualquier error de la operación asincrónica
                console.error("Error during expense creation:", error);
            }
        },
        generate_json(item) {
            console.log("<<<<< ITEM:", JSON.stringify(item, null, 2));
            const new_zoho_expense = getNewExpenseData();
            const account_id = item.expense_type.type[0].type.account_id;
            new_zoho_expense.account_id = account_id;
            new_zoho_expense.date = item.expense_date;

            //new_zoho_expense.amount = item.amount;
            new_zoho_expense.amount = item.expense_type.type[0].total;

            new_zoho_expense.paid_through_account_id = item.card_account.account_id;
            //new_zoho_expense.vendor_id = item.supplier.contact_address_id;
            new_zoho_expense.vendor_id = item.expense_type.type[0].supplier.contact_address_id;

            const last_4_digits = item.card_number.slice(-4);
            //const reference = `${last_4_digits} - NovaDrivingSystem`;            
            //new_zoho_expense.reference_number = reference;
            let user = '';
            let reference = '';
            if (item.vehicle_name !== null) {
                user = `${item.vehicle_name} | Driver: ${item.instructor}`;
                reference = `${last_4_digits} | ${item.vehicle_name} | NDSys`;
                //console.log("user: ", user);
            }
            if (item.management_name !== null) {
                user = `${item.management_name.name} ${item.management_name.last_name}`;
                reference = `${last_4_digits} | ${user} | NDSys`;
                //console.log("user: ", user);
            }
            new_zoho_expense.reference_number = reference;
            //const new_zoho_description = `${item.description} - User: ${user} - UUID: ${item.uuid}`
            let new_zoho_description = "";
            if (item.expense_type.type[0].discount != null) {
                if (item.prorated) {
                    new_zoho_description = `PRORATED | Amount: ${item.amount} | ${item.expense_type.type[0].description} | User: ${user} | UUID: ${item.uuid}`
                } else {
                    new_zoho_description = `${item.expense_type.type[0].description} | Discount: $ ${parseFloat(item.expense_type.type[0].discount).toFixed(2)} | User: ${user} | UUID: ${item.uuid}`
                }
            } else {
                new_zoho_description = `${item.expense_type.type[0].description} | User: ${user} | UUID: ${item.uuid}`
            }
            //console.log("new_zoho_description: ", new_zoho_description);

            new_zoho_expense.description = new_zoho_description; //item.description;
            new_zoho_expense.payment_mode = item.payment_method;
            const expense_types = item.expense_type;
            // Iterar sobre cada objeto y crear el nuevo formato
            expense_types.type.forEach((item, index) => {
                let new_description = '';
                if (parseFloat(item.discount) > 0.0) {
                    new_description = `${item.type.description} - Discount: $ ${parseFloat(item.discount).toFixed(2)}`;
                } else {
                    new_description = item.type.description;
                }
                const newItem = {
                    account_id: item.type.account_id,       // type.account_id
                    description: new_description,    // type.description
                    amount: parseFloat(item.total),      // amount, convertido a número
                    item_order: index + 1,                  // item_order basado en el índice
                    tags: item.tags
                };
                // Añadir el nuevo objeto al array line_items
                new_zoho_expense.line_items.push(newItem);
            });
            new_zoho_expense.tags = expense_types.type[0].tags;
            console.log("<<<<< filled_expenseData:", JSON.stringify(new_zoho_expense, null, 2))
            return new_zoho_expense;
        },
        setRowClass(item) {
            return item.status ? 'green-background' : 'red-background';
        },
        filterByStatus() {
            let status = false;
            if (this.statusFilter === 'Approved') {
                status = true
            } else if (this.statusFilter === 'Not Approved') {
                status = false
            } else {
                status = 'all'
            }
            this.fetchItems(status)
        },
        formatCurrency(value) {
            const number = parseFloat(value);
            return !isNaN(number) ? number.toFixed(2) : "0.00";
        },
        handleInfoFromChild(info) {
            // Manejar la información recibida desde el hijo
            console.log('Información recibida desde el hijo:', info);
            this.dialogOpen = info; //Close dialog
            this.fetchItems(false); //Refresh employees table
        },
        async fetchItems(status) {
            try {
                console.log("status: ", status);
                this.showLoader = true;
                //this.items = await getExpenses();
                //this.items = await getExpenseRecordsByEmployeeNumber(this.employee_number);
                this.items = await getExpenseRecordsByStatus(status);
                console.log("this.items: ", this.items);
                this.showLoader = false;
            } catch (error) {
                console.error("Error fetching: ", error);
            }
        },
        openDialog() {
            console.log("Opening dialog.....")
            this.dialogOpen = true;
        },
    }
}


</script>

<style>
/* Estilos para resaltar las filas según el status */
.green-background {
    background-color: rgba(0, 255, 0, 0.1);
    /* Verde bajo */
}

.red-background {
    background-color: rgba(255, 0, 0, 0.1);
    /* Rojo bajo */
}

/* LOADER */
.loader-container {
    position: fixed;
    /* Para que el loader esté por encima de todo el contenido */
    top: 0;
    left: 0;
    width: 100%;
    /* Ocupa todo el ancho del viewport */
    height: 100%;
    /* Ocupa todo el alto del viewport */
    display: flex;
    justify-content: center;
    /* Centra horizontalmente */
    align-items: center;
    /* Centra verticalmente */
    background-color: rgba(255, 255, 255, 0.5);
    /* Fondo semitransparente */
    z-index: 9999;
    /* Coloca el loader por encima de todo el contenido */
}

.loader {
    width: 120px;
    height: 60px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    background: #fff;
    filter: blur(5px) contrast(10) hue-rotate(60deg);
    mix-blend-mode: darken;
}

.loader:before,
.loader:after {
    content: "";
    width: 40px;
    border-radius: 50%;
    background: #ff00ff;
    animation: l3 1s infinite alternate;
}

.loader:after {
    --s: -1;
}

@keyframes l3 {

    90%,
    100% {
        transform: translate(calc(var(--s, 1)*30px))
    }
}
</style>