<template>
    <porcetages-Interface />
</template>

<script>
import PorcetagesInterface from '../../components/Accounting/PorcetagesInterface.vue'

export default {
    name: 'PorcetagesInterfaceView',
    components: {
        PorcetagesInterface,
    },
}
</script>