export function get_employee_number() {
    const token = localStorage.getItem('token');

    if (token) {
        try {
            const tokenParts = token.split('.');
            const payload = JSON.parse(atob(tokenParts[1]));
            const employee_number = payload.user_id;
            return employee_number;
        } catch (error) {
            return { permissions: null };
        }
    } else {
        return { permissions: null };
    }
}
