import { CREDIT_CARDS } from '../utils/endpoints.js'

/**
 * Handles the response from fetch requests.
 * 
 * @param {Response} response - The response object from the fetch request.
 * @returns {Promise<Object>} - The JSON response if the request is successful.
 * @throws Will throw an error if the response is not OK.
 */
export async function handleResponse(response) {
    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Unknown error');
    }
    return response;
}

/**
 * Creates a new credit card management entry.
 * 
 * @param {Object} cardData - Object containing the credit card management data.
 * @returns {Promise<Object>} - Data of the created credit card management entry.
 * @throws Will throw an error if the fetch request fails.
 */
export async function createCreditCardManagement(cardData) {
    try {
        const response = await fetch(CREDIT_CARDS, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(cardData)
        });

        const result = await handleResponse(response);
        console.log('Credit card management entry created successfully:', result);
        return result;
    } catch (error) {
        console.error('Error creating credit card management entry:', error);
        throw error;
    }
}

/**
 * Retrieves all credit card management entries.
 * 
 * @returns {Promise<Array>} - List of all credit card management entries.
 * @throws Will throw an error if the fetch request fails.
 */
export async function getAllCreditCardManagements() {
    try {
        const response = await fetch(CREDIT_CARDS, {
            method: 'GET',
        });
        const result = await handleResponse(response);
        console.log('Credit card management entries retrieved successfully:', result);
        return result.json();
    } catch (error) {
        console.error('Error retrieving credit card management entries:', error);
        throw error;
    }
}

/**
 * Retrieves a credit card management entry by ID.
 * 
 * @param {number} id - ID of the credit card management entry.
 * @returns {Promise<Object>} - Data of the credit card management entry.
 * @throws Will throw an error if the fetch request fails.
 */
export async function getCreditCardManagementById(id) {
    try {
        const response = await fetch(`${CREDIT_CARDS}/${id}`, {
            method: 'GET',
        });

        const result = await handleResponse(response);
        console.log('Credit card management entry retrieved successfully:', result);
        return result;
    } catch (error) {
        console.error('Error retrieving credit card management entry:', error);
        throw error;
    }
}


/**
 * Updates a credit card management entry by ID.
 * 
 * @param {number} id - ID of the credit card management entry to update.
 * @param {Object} cardData - Updated data of the credit card management entry.
 * @returns {Promise<Object>} - Updated data of the credit card management entry.
 * @throws Will throw an error if the fetch request fails.
 */
export async function updateCreditCardManagement(id, cardData) {
    try {
        console.log("id: ", id);
        console.log("cardData: ", cardData);
        const response = await fetch(`${CREDIT_CARDS}/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(cardData)
        });

        const result = await handleResponse(response);
        console.log('Credit card management entry updated successfully:', result);
        return result;
    } catch (error) {
        console.error('Error updating credit card management entry:', error);
        throw error;
    }
}

/**
 * Deletes a credit card management entry by ID.
 * 
 * @param {number} id - ID of the credit card management entry to delete.
 * @returns {Promise<Object>} - Response of the request.
 * @throws Will throw an error if the fetch request fails.
 */
export async function deleteCreditCardManagement(id) {
    try {
        const response = await fetch(`${CREDIT_CARDS}/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        const result = await handleResponse(response);
        console.log('Credit card management entry deleted successfully:', result);
        return result;
    } catch (error) {
        console.error('Error deleting credit card management entry:', error);
        throw error;
    }
}
