<template>
    <expense-record />
</template>

<script>
import ExpenseRecord from '../../components/Accounting/ExpenseRecord.vue'

export default {
    name: 'ExpenseRecordView',
    components: {
        ExpenseRecord,
    },
}
</script>