import { TYPE_OF_COVERAGE } from '../../utils/endpoints.js'

export async function createCoverage(coverage) {
  /**
   * Creates a new type of coverage.
   * 
   * Parameters:
   * - coverageName (string): The name of the coverage to create.
   * 
   * Returns:
   * - The created coverage object on success.
   * - Error message on failure.
   * 
   * Author: JDGT
   */
  try {
    console.log("coverage: ", coverage);
    const response = await fetch(TYPE_OF_COVERAGE, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(coverage)
      
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    /* return response.json(); */
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getCoverages() {
  /**
   * Retrieves a list of all types of coverage.
   * 
   * Returns:
   * - An array of coverage objects.
   * 
   * Author: JDGT
   */
  try {
    const response = await fetch(TYPE_OF_COVERAGE);
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getCoverage(id) {
  /**
   * Retrieves a single type of coverage by its ID.
   * 
   * Parameters:
   * - id (int): The ID of the coverage to retrieve.
   * 
   * Returns:
   * - The coverage object if found.
   * - Error message if not found.
   * 
   * Author: JDGT
   */
  try {
    const response = await fetch(`${TYPE_OF_COVERAGE}/${id}`);
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function updateCoverage(id, coverage) {
  /**
   * Updates an existing type of coverage.
   * 
   * Parameters:
   * - id (int): The ID of the coverage to update.
   * - coverageName (string): The new name of the coverage.
   * 
   * Returns:
   * - The updated coverage object.
   * - Error message on failure.
   * 
   * Author: JDGT
   */
  try {
    const response = await fetch(`${TYPE_OF_COVERAGE}/${id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(coverage)
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function deleteCoverage(id) {
  /**
   * Deletes a type of coverage by its ID.
   * 
   * Parameters:
   * - id (int): The ID of the coverage to delete.
   * 
   * Returns:
   * - A message confirming the deletion.
   * - Error message if not found.
   * 
   * Author: JDGT
   */
  try {
    const response = await fetch(`${TYPE_OF_COVERAGE}/${id}`, { method: 'DELETE' });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
}