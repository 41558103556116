<template>
    <computer-equipment />
</template>

<script>
import ComputerEquipment from '../../components/It/ComputerEquipment.vue'

export default {
    name: 'ComputerEquipmentView',
    components: {
        ComputerEquipment,
    },
}
</script>