<template>
    <div>
        <v-data-table :headers="headers" :items="filteredInstructors" sort-by="name" class="elevation-1">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title class="font-weight-bold">Instructors</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-text-field v-model="search" label="Search" solo-inverted prepend-icon="mdi-magnify"
                        class="mr-3 mt-5"></v-text-field>
                    <v-btn color="blue" dark class="mb-2 mr-2" @click="fetchActiveInstructors">
                        <v-icon>mdi-refresh</v-icon>                        
                    </v-btn>
                </v-toolbar>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { GET_ACTIVE_INSTRUCTORS } from '../../utils/endpoints';
import { bus } from '../../main.js';


export default {
    components: {

    },
    data: () => ({
        search: '',
        headers: [
            { text: 'Vehicle', value: 'vehicle_name' },
            { text: 'Employee Number', value: 'employee_number' },
            { text: 'Name', value: 'name' },
            { text: 'Last Name', value: 'last_name' },
            { text: 'Permit', value: 'permit' },
            { text: 'Permit Expiration', value: 'permit_expiration' },
        ],

        instructors: [],

    }),
    computed: {
        filteredInstructors() {
            if (this.instructors) {
                return this.instructors.filter(instructor =>
                    instructor.name.toLowerCase().includes(this.search.toLowerCase())
                );
            }
            else {
                return [];
            }
        },
    },
    async mounted() {
        bus.$emit('login-success');
        bus.$emit('permissions');
        this.instructors = await this.fetchActiveInstructors();
    },
    methods: {
        async fetchActiveInstructors() {
            try {
                const response = await fetch(GET_ACTIVE_INSTRUCTORS);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                console.log(data); // Aquí puedes procesar los datos como desees
                return data;
            } catch (error) {
                console.error('There has been a problem with your fetch operation:', error);
            }
        },
    },
    watch: {

    }
}
</script>