import { LOCATIONS_ENDPOINT } from '../utils/endpoints'

export async function getLocations() {
   
  try {
    const response = await fetch(LOCATIONS_ENDPOINT, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch locations. Status: ' + response.status);
    }

    const data = await response.json(); // Parse the response as JSON
    console.log("getLocations/data: ", data);

    return data; // Return the fetched data
  } catch (error) {
    console.error('An error occurred while fetching locations:', error);
    alert('An error occurred while fetching locations. Please try again later.');
    return null; // Return null or an appropriate fallback value in case of an error
  }
}
