<template>
    <div>
        <v-row class="pt-4 px-4">
            <v-col cols="12" sm="12" md="12">
                <v-card height="520">
                    <v-card-text>
                        <v-data-table :headers="headers" :items="filteredItems" sort-by="last_4_digits"
                            class="elevation-1">
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-toolbar-title class="font-weight-bold">Paid Through Account</v-toolbar-title>
                                    <v-divider class="mx-4" inset vertical></v-divider>
                                    <v-text-field v-model="search" label="Search" solo-inverted
                                        prepend-icon="mdi-magnify" class="mr-3 mt-5"></v-text-field>
                                    <v-btn color="blue" dark class="mb-2 mr-2" @click="fetchItems">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                    <v-btn color="success" dark class="mb-2 mr-2" @click="openDialog">
                                        <v-icon>mdi-plus-thick</v-icon>
                                    </v-btn>
                                </v-toolbar>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-icon small class="mr-2" :style="{ color: 'blue' }" @click="updateItem(item)">
                                    mdi-pencil
                                </v-icon>
                                <v-icon small :style="{ color: 'red' }" @click="deleteItem(item)">
                                    mdi-delete
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-dialog v-model="dialogOpen" max-width="700">
                <v-card class="dialog-card" outlined>
                    <PaidThroughAccountCard :Index="editedIndex" :Item="editedItem" :key="dialogOpen.toString()"
                        @sent-info="handleInfoFromChild" />
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import { bus } from '../../main.js';
import { getAllPaidThroughAccounts, deletePaidThroughAccount } from '../../utils/paid_through_account_CRUD.js'
import PaidThroughAccountCard from '../../components/Accounting/PaidThroughAccountCard.vue'

export default {
    components: {        
        PaidThroughAccountCard
    },
    data: () => ({
        headers: [            
            { text: 'Actions', value: 'actions', sortable: false },
            { text: 'Paid Through Account Name', value: 'paid_through_account_name' },
            { text: 'Bank', value: 'bank_name' }
        ],
        dialogOpen: false,
        search: '',
        items: [],
        editedIndex: -1,
        editedItem: {
            id:'',
            bank:'',
            paid_through_account_name: ''
        },
        defaultEditedItem: {
            id:'',
            bank:'',
            paid_through_account_name: ''           
        }
    }),
    computed: {
        filteredItems() {
            if (!this.search.trim()) {
                return this.items;
            } else {
                return this.items.filter(item =>
                    item.paid_through_account_name.toLowerCase().includes(this.search.toLowerCase())
                );
            }
        }
    },
    async mounted() {
        //console.log("-------------------MOUNTED.......................")
        bus.$emit('login-success');
        bus.$emit('permissions');
        await this.fetchItems();
        
    },
    methods: {
        handleInfoFromChild(info) {
            // Manejar la información recibida desde el hijo
            console.log('Información recibida desde el hijo:', info);
            this.dialogOpen = info; //Close dialog
            this.fetchItems(); //Refresh employees table
        },
        updateItem(item) {
            console.log("item: ", item)
            this.editedIndex = item.id;
            this.editedItem = item;
            this.dialogOpen = true;
        },
        async deleteItem(item) {
            console.log("item: ", item)
            const response = await deletePaidThroughAccount(item.id);
            if (response.ok) {
                this.fetchItems();
            }
        },
        openDialog() {
            console.log("Opening dialog.....")
            this.dialogOpen = true;
        },
        async fetchItems() {
            try {
                this.items = await getAllPaidThroughAccounts();
                console.log("this.items: ", this.items);
            } catch (error) {
                console.error("Error fetching Banks: ", error);
            }
        }
    },
    watch: {
        dialogOpen(val) {
            console.log("val: ", val);
            if (!val) {
                this.editedItem = this.defaultEditedItem;
                this.editedIndex = -1;
            }
        }
    }
}
</script>

<style scoped>
.dialog-card {
    padding: 0;
    /* Elimina el relleno interno */
    margin: 0;
    /* Elimina los márgenes */
}
</style>