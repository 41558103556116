<template>
  <v-dialog :value="isModalOpen" max-width="1050" @input="updateModal">
    <v-card>
      <v-card-title :style="{ color: 'green' }">{{ title }}</v-card-title>
      <v-card-text>
        <!-- Visor de archivos -->
        <iframe v-if="fileUrl" :src="fileUrl" width="1000" height="900px"></iframe>
        <div v-else>No file</div>
      </v-card-text>
      <v-card-actions>
        <v-btn v-if="pageRoute === '/employeesform' && formStatus === false" color="primary" @click="save">Save</v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    modalOpen: Boolean,
    title: String,
    /* fileUrl: String, */
    formStatus: Boolean,
    dataFileUrl: String,
  },
  data() {
    return {
      pageRoute: window.location.pathname,
      fileUrl: '',
    }
  },
  computed: {
    isModalOpen: {
      get() {
        return this.modalOpen;
      },
      set(value) {
        this.$emit('update:modalOpen', value);
      }
    }
  },
  methods: {
    updateModal(value) {
      this.$emit('update:modalOpen', value);
    },
    closeModal() {
      console.log("Closing modal...");
      this.isModalOpen = false; // Cierra el modal
      this.fileUrl = '';
      /* this.title = '';
      this.fileUrl = ''; */
    },
    save() {
      this.$emit('save'); // Emitir evento para guardar el PDF      
      this.closeModal();
    },
  },
  watch: {
    isModalOpen(val) {
      console.log("Modal Status: ", val);
      
      if (val === false) {
        this.fileUrl = '';
        console.log("this.fileUrl: ", this.fileUrl);
      } else {
        console.log("Opening modal: ", val);
        console.log("this.dataFileUrl: ", this.dataFileUrl);
        this.fileUrl = this.dataFileUrl;
      }
    }
  }
}
</script>
