<template>
    <banks />
</template>

<script>
import Banks from '../../components/Accounting/Banks.vue'

export default {
    name: 'BanksView',
    components: {
        Banks,
    },
}
</script>