import {UPDATE_OBSERVATIONS, GET_OBSERVATIONS} from '../../endpoints'

export async function updateObservations(uuid, observations) {
    const url = `${UPDATE_OBSERVATIONS}${uuid}`;
    console.log("uuid: ", uuid);
    console.log("observations: ", observations);

    const headers = {
        'Content-Type': 'application/json',
    };

    const body = JSON.stringify({
        observation: observations
    });

    try {
        const response = await fetch(url, {
            method: 'PUT',
            headers: headers,
            body: body
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error:', errorData);
            //return { success: false, message: errorData.error };
            return response
        }

        const result = await response.json();
        console.log('Success:', result);
        //return { success: true, data: result };
        return response;
    } catch (error) {
        console.error('Error updating observations:', error);
        return { success: false, message: 'An error occurred while updating observations.' };
    }
}

export async function getObservations(uuid) {
    const url = `${GET_OBSERVATIONS}${uuid}`;
    
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error:', errorData);
            return { success: false, message: errorData.error };
        }

        const result = await response.json();
        console.log('Observations:', result.observations);
        //return { success: true, data: result.observations };
        return result.observations;
    } catch (error) {
        console.error('Error fetching observations:', error);
        return { success: false, message: 'An error occurred while fetching observations.' };
    }
}
