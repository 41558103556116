import { ZOHO_BANKS } from '../../../utils/endpoints'

export async function getBankAccounts() {
    try {
        const response = await fetch(ZOHO_BANKS, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();
        console.log('Bank Accounts:', data);
        return data; // Puedes trabajar con los datos aquí o retornarlos
    } catch (error) {
        console.error('There was an error fetching the bank accounts:', error);
    }
}
