import { UPLOAD_LEAD_SYSTEM } from '../../utils/endpoints.js'

/**
 * Author: JDGT
 * Date: 2024-09-11
 * Description: Function to upload leads file with action data. Handles file upload, JSON parsing, and server responses.
 * 
 * @param {File} file - The file object representing the .xlsx file to upload.
 * @param {Object} action - An object representing the action data to be sent along with the file.
 * @param {string} url - The endpoint URL to send the request to.
 * 
 * @returns {Promise<Object>} - A promise that resolves to the server's response in JSON format, or an error object.
 */

export async function uploadLeads(file, action) {
    // Ensure that file is an instance of File and action is a valid object
    if (!(file instanceof File)) {
      throw new Error('Invalid file object');
    }
  
    if (typeof action !== 'object' || Array.isArray(action) || action === null) {
      throw new Error('Invalid action object');
    }
  
    // Prepare form data
    const formData = new FormData();
    formData.append('file', file); // Append the file to the form data
    formData.append('action', JSON.stringify(action)); // Append the action object as a JSON string
  
    try {
      // Make the POST request to the server
      const response = await fetch(UPLOAD_LEAD_SYSTEM, {
        method: 'POST',
        body: formData, // Send the form data with the request
        headers: {
          'Accept': 'application/json'
        }
      });
  
      // Check if the response is successful
      if (!response.ok) {
        const errorResponse = await response.json(); // Parse error response
        throw new Error(`Error: ${errorResponse.error || 'Unknown error'}`);
      }
  
      // Parse and return the successful response
      const data = await response.json();
      return data; // Return the JSON response from the server
    } catch (error) {
      console.error('Error uploading leads:', error.message);
      throw error; // Re-throw the error to handle it at a higher level
    }
  }