<template>
    <div>
        <v-row class="pt-4 px-4">
            <v-col cols="12" sm="12" md="12" class="d-flex justify-center">
                <v-card height="auto" width="900">
                    <v-card-text>
                        <v-data-table :headers="headers" :items="filteredItems" sort-by="coverage" class="elevation-1"
                            @click:row="openInfo">
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-toolbar-title class="font-weight-bold">Type of Coverage</v-toolbar-title>
                                    <v-divider class="mx-4" inset vertical></v-divider>
                                    <v-text-field v-model="search" label="Search" solo-inverted
                                        prepend-icon="mdi-magnify" class="mr-3 mt-5"></v-text-field>
                                    <v-btn color="blue" dark class="mb-2 mr-2" @click="fetchItems">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                    <v-btn color="success" dark class="mb-2 mr-2" @click="openDialog">
                                        <v-icon>mdi-plus-thick</v-icon>
                                    </v-btn>
                                </v-toolbar>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-icon small class="mr-2" :style="{ color: 'blue' }" @click="editItem(item)">
                                    mdi-pencil
                                </v-icon>
                                <v-icon small :style="{ color: 'red' }" @click="deleteItem(item)">
                                    mdi-delete
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-dialog v-model="dialogOpen" max-width="700">
                <v-card class="dialog-card" outlined>
                    <TypeOfCoverageCard :Index="editedIndex" :Item="editedItem" :key="dialogOpen.toString()"
                        @sent-info="handleInfoFromChild" />
                </v-card>
            </v-dialog>
            <!-- <v-dialog v-model="dialogInfoInsurance" max-width="1100px" content-class="dialog-height">
                <template v-if="dialogInfoInsurance">                    
                    <InfoInsuranceCard :Item="editedItem" :key="dialogInfoInsurance.toString()"/>
                </template>
            </v-dialog> -->
        </v-row>
    </div>
</template>

<script>
import { bus } from '../../../main.js';
import { createCoverage, getCoverages, deleteCoverage } from '../../../utils/Insurance/typeOfCoverageCRUD.js'
import TypeOfCoverageCard from '../../../components/Fleet/Insurance/TypeOfCoverageCard.vue'
/* import { getInsurances, deleteInsurance } from '../../utils/insurance_CRUD.js'
import InsuranceCard from '../../components/Fleet/InsuranceCard.vue'
import InfoInsuranceCard from '../../components/Fleet/InfoInsuranceCard.vue'
 */
export default {
    components: {
        TypeOfCoverageCard
        /*  InsuranceCard,
         InfoInsuranceCard, */
    },
    data: () => ({
        dialogInfoInsurance: false,
        headers: [
            /* { text: 'Actions', value: 'actions', sortable: false }, */
            { text: 'Type of coverage', value: 'coverage' },
            { text: 'Actions', value: 'actions', sortable: false },
            /* { text: 'Total cost', value: 'total_cost' }, */
        ],
        dialogOpen: false,
        search: '',
        items: [],
        editedIndex: -1,
        editedItem: {
            coverage: '',
        },
        defaultItem: {
            coverage: '',
        },
    }),
    computed: {
        filteredItems() {
            if (!this.search.trim()) {
                return this.items;
            } else {
                return this.items.filter(item =>
                    item.coverage.toLowerCase().includes(this.search.toLowerCase())
                );
            }
        }
    },
    async mounted() {
        //console.log("-------------------MOUNTED.......................")
        bus.$emit('login-success');
        bus.$emit('permissions');
        await this.fetchItems();
    },
    methods: {
        editItem(item) {
            console.log("item: ", item);
            this.editedIndex = item.id;
            this.editedItem = Object.assign({}, item);
            this.dialogOpen = true;
            //this.sendInfoToChild();
        },

         /* deleteItem(item) {
           this.editedIndex = this.employees.indexOf(item)
           this.editedItem = Object.assign({}, item)
           this.dialogDelete = true
         }, */
        openInfo(item) {
            console.log("Openning info....", item);
            this.editedItem = item;
            this.dialogInfoInsurance = true;
        },
        handleInfoFromChild(info) {
            // Manejar la información recibida desde el hijo
            console.log('Información recibida desde el hijo:', info);
            this.dialogOpen = info; //Close dialog
            this.fetchItems(); //Refresh employees table
        },
        updateItem(item) {
            console.log("item: ", item)
            /* this.editedIndex = item.id;
            this.editedItem = item; */
            //this.editedIndex = this.items.indexOf(item);            
            console.log("this.editedIndex: ", this.editedIndex);
            this.editedItem = Object.assign({}, item);
            this.editedIndex = this.editedItem.id;
            console.log("this.editedIndex: ", this.editedIndex);
            console.log("<<<<<<<<<<<<<<<<<<<< this.editedItem: ", this.editedItem);
            this.dialogOpen = true;
        },
        async deleteItem(item) {
            console.log("item: ", item)
            const response = await deleteCoverage(item.id);
            if (response.ok) {
                this.fetchItems();
            }
        },
        openDialog() {
            console.log("Opening dialog.....")
            this.dialogOpen = true;
        },
        async fetchItems() {
            try {
                this.items = await getCoverages();
                console.log("this.items: ", this.items);
            } catch (error) {
                console.error("Error fetching: ", error);
            }
        }
    },
    watch: {
        dialogOpen(val) {
            console.log(">>>>>>>>>>>>>>>>>> val: ", val);
            if (!val) {
                this.editedItem = this.defaultEditedItem;
                this.editedIndex = -1;
                this.fetchItems();
            }
        },
        dialogInfoInsurance(val) {
            console.log(">>>>>>>>>>>>>>>>>>dialogInfoInsurance/val: ", val);
            if (!val) {
                this.editedItem = this.defaultEditedItem;
                this.editedIndex = -1;
                this.fetchItems();
            }
        },
    }
}
</script>

<style scoped>
.dialog-height {
    max-height: auto !important;
    min-height: auto !important;
}

.dialog-card {
    padding: 0;
    /* Elimina el relleno interno */
    margin: 0;
    /* Elimina los márgenes */
}
</style>