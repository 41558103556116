const BASE_URL = process.env.VUE_APP_BASE_URL;
const BASE_URL_SALES = process.env.VUE_APP_BASE_URL_SALES;

export const LOGIN_ENDPOINT = `${BASE_URL}/login`;
export const LOCATIONS_ENDPOINT = `${BASE_URL}/locations`;
export const GET_ALL_LOCATIONS_JSON = `${BASE_URL}/allLocations`;
export const ADD_LOCATION = `${BASE_URL}/locations/add`;
export const DELETE_LOCATION = `${BASE_URL}/locations/`;
export const ADDRESSES_ENDPOINT = `${BASE_URL}/locations/address`;
export const STAFFS_ENDPOINT = `${BASE_URL}/staffs`;

// WALK INS
export const WALKINS_ENDPOINT = `${BASE_URL}/walk_ins`;
export const GET_ALL_EMPLOYEES_WALKINS = `${BASE_URL}/employees/walkins`;
export const GET_ALL_WALKINS_TODAY = `${BASE_URL}/walkins/today`;
export const GET_ALL_WALKINS_TODAY_AND_EMPLOYEE_NUMBER = `${BASE_URL}/walk_ins/records/today`;
export const GET_WALK_INS_FORMS_BY_EMPLOYEE = `${BASE_URL}/walk_ins/records/employee_number?employee_number=`;


// EMPLOYEES
export const GET_ALL_EMPLOYEES = `${BASE_URL}/employees`;
export const UPDATE_END_DATE_STATUS_EMPLOYEE = `${BASE_URL}/employees/update-end-date/`;
export const GET_ALL_EMPLOYEES_ACTIVE = `${BASE_URL}/employeesByStatus`;
//export const GET_ROLES = `${BASE_URL}/roles`;
export const ADD_NEW_EMPLOYEE = `${BASE_URL}/addEmployee`;
export const CHANGE_STATUS_EMPLOYEE = `${BASE_URL}/updateStatus`;

export const UPDATE_EMPLOYEE = `${BASE_URL}/updateEmployee`;
export const UPDATE_CREDENTIAL = `${BASE_URL}/password`;
/* export const UPLOAD_FILES = `${BASE_URL}/s3/upload`; */
export const UPLOAD_FILES = `${BASE_URL}/s3/upload/too-large?employee_number=`;
export const ACTIVATE_EMPLOYEE = `${BASE_URL}/activate-employee/`;



// S3
export const GET_PUBLIC_URL = `${BASE_URL}/s3/publicurl`;
export const GET_PUBLIC_URLS = `${BASE_URL}/s3/publicurls`;


// EMPLOYEE
export const GET_EMPLOYEE_BY_EMPLOYEE_NUMBER = `${BASE_URL}/employees/employee_number`;
export const EMPLOYEE = `${BASE_URL}/employees`;
export const UPDATE_DOCS_URL = `${BASE_URL}/employees/update_docs_urls?employee_number=`;
export const JOB_OFFER = `${BASE_URL}/employee/`;
export const SEND_JOB_OFFER = `${BASE_URL}/send-job-offer/`;
export const GET_ALL_EMPLOYEES_BY_STATUS = `${BASE_URL}/employeesByStatus?status=`;



// TRAINING LOG
export const ADD_TRAINING_LOG = `${BASE_URL}/trainingLog/add`;
export const ADD_TRAINING_LOG_EMPLOYEE = `${BASE_URL}/trainingLog-employee/add`;
export const GET_TRAINING_LOG_BY_EMPLOYEE_NUMBER_STATUS = `${BASE_URL}/trainingLog/records?employee_number=`;
export const GET_ALL_TRAINING_LOGS = `${BASE_URL}/trainingLog/all-records`;
export const UPDATE_OBSERVATIONS = `${BASE_URL}/trainingLog/update_observations/`;
export const GET_OBSERVATIONS = `${BASE_URL}/trainingLog/observations/`;

export const NEW_UPDATE_OBSERVATIONS = `${BASE_URL}/new-trainingLog/update_observations/`;
export const NEW_GET_OBSERVATIONS = `${BASE_URL}/new-trainingLog/observations/`;
export const DELETE_TRAINING_LOG_EMPLOYEE = `${BASE_URL}/delete-training-log-employee/`;
export const DELETE_TRAINING_LOG = `${BASE_URL}/delete-training-log/`;
export const UPDATE_STATUS_TRAINING_LOG = `${BASE_URL}/update-training-status/`;
export const UPDATE_CLOSED_TRAINING_LOG = `${BASE_URL}/update-training-closed/`;


/* NEW TRAINING LOG */
export const NEW_GET_TRAINING_LOG_BY_EMPLOYEE_NUMBER_CLOSED = `${BASE_URL}/new_trainingLog/records?employee_number=`;
export const NEW_UPDATE_TRAINING_LOG = `${BASE_URL}/new_trainingLog/update`;
export const NEW_GET_ALL_TRAINING_LOGS = `${BASE_URL}/new-trainingLog/all-records`;
export const NEW_TRAINING_LOGS_FETCH = `${BASE_URL}/new_logs`;
export const GET_TRAINING_LOG_EMPLOYEE = `${BASE_URL}/new-trainingLog`;
export const GET_NEW_TRAINING_LOG_CLOSED_STATUS= `${BASE_URL}/new-training-logs/closed-status`;



export const UPDATE_TRAINING_LOG = `${BASE_URL}/trainingLog/update`;
//export const SEND_JOB_OFFER = `${BASE_URL}/employees/send_job_offer?employee_number=`;
export const GET_ALL_TRAINING_LOGS_COMPLETE = `${BASE_URL}/trainingLog/all-records-complete`;
export const GET_TRAINERS_BY_UUID = `${BASE_URL}/trainingLog-employee/get-trainers?uuid=`;
export const GET_EMPLOYEES_BY_STATUS_TRAININGLOG = `${BASE_URL}/employees/ByStatusTrainingLog`;
export const SEND_VERIFICATION_CODE = `${BASE_URL}/generate-verification-code?employee_number=`;
export const UPDATE_PERMISSIONS = `${BASE_URL}/employees/updatePermissions`;

export const GET_EMPLOYEES_BY_STATUS_WALK_INS = `${BASE_URL}/walk_ins/employees/permissions`;
export const GET_ALL_WALK_INS_FORMS = `${BASE_URL}/walk_ins/employees`;
export const UPLOAD_POLICIES = `${BASE_URL}/s3/upload/policies`;
export const GET_POLICIES_STATUS = `${BASE_URL}/s3/policies/status`;
export const UPLOAD_SIGNATURE = `${BASE_URL}/s3/upload/signature`;
export const ADD_DEPARTMENT = `${BASE_URL}/departments`;
export const DELETE_DEPARTMENT_BY_ID = `${BASE_URL}/departments/`;
export const GET_ALL_DEPARTMENT_JSON = `${BASE_URL}/departments/all`;
export const TRAINING_LOG_REPORT_PDF = `${BASE_URL}/training-report/evaluate`;

// Vehicles
export const GET_ALL_VEHICLES_JSON = `${BASE_URL}/all_vehicles`;
export const FEED_DATABASE = `${BASE_URL}/feedTableVehicles`;
export const UPDATE_VEHICLE = `${BASE_URL}/vehicle/editVehicle/`;
export const UPLOAD_FLEET_FILE = `${BASE_URL}/fleet/s3/uploadFile?vin=`;
export const GET_DRIVER_NAME = `${BASE_URL}/vehicles/drivers`;

export const GET_ACTIVE_INSTRUCTORS = `${BASE_URL}/active_instructors`;
export const GET_INACTIVE_INSTRUCTORS = `${BASE_URL}/inactive_instructors`;
export const GET_ACTIVE_ZIP_CODES = `${BASE_URL}/active_zipcodes`;
export const DELETE_ZIP_CODE_BY_ID = `${BASE_URL}/delete_zipcode/`;
export const ADD_ZIP_CODE = `${BASE_URL}/add_zipcode`;
export const UPDATE_ZIP_CODE = `${BASE_URL}/edit_zipcode/`;
// LOCATION
export const UPDATE_LOCATION = `${BASE_URL}/update-location/`;
export const GET_EXCLUDED_LOCATION = `${BASE_URL}/locations/excluded`;

export const GET_CHART_OF_ACCOUNT = `${BASE_URL}/chart_of_account`;
export const POST_CHART_OF_ACCOUNT_BULK = `${BASE_URL}/chart_of_account/bulk`;
export const EXPENSE_TYPE = `${BASE_URL}/expense-type`;
export const PAID_THROUGH_ACCOUNTS = `${BASE_URL}/paid_through_accounts`;
export const BANKS = `${BASE_URL}/banks`;

// CREDIT CARD MANAGEMENT
export const CREDIT_CARDS = `${BASE_URL}/credit_card_managements`;

// INSURANCE
export const INSURANCE = `${BASE_URL}/insurance`;

// PAYROLL
export const PAYROLL = `${BASE_URL}/payrolls/all/employee_number?employee_number=`;

// INSURANCE
export const INSURANCE_BREAKDOWN = `${BASE_URL}/insurance_breakdown`;
export const INSURANCE_BREAKDOWN_STATUS = `${BASE_URL}/insurance_breakdown/status`;
//export const INSURANCE_BREAKDOWN_DEACTIVATE = `${BASE_URL}/insurance_breakdown/deactivate`
export const INSURANCE_BREAKDOWN_DEACTIVATE = `${BASE_URL}/update_insurance_status`;
export const INSURANCE_BREAKDOWN_S3 = `${BASE_URL}/insurance_breakdown/s3/uploadFile?vin=`;
export const INSURANCE_BREAKDOWN_BY_VIN = `${BASE_URL}/insurance_breakdown/filter_by_vin?vin=`;
export const INSURANCE_BY_NAME = `${BASE_URL}/insurance/search_by_name?insurance_name=`;
export const GET_ALL_INSURANCE_BREAKDOWN_BY_VIN = `${BASE_URL}/insurance_breakdown/all_by_vin?vin=`;

// TYPE OF COVERAGE
export const TYPE_OF_COVERAGE = `${BASE_URL}/coverage`;

// TYPE OF COMISSIONS
export const TYPE_OF_COMISSIONS = `${BASE_URL}/comissions`;

// S3
export const S3_LIST_INSURANCE_FILES = `${BASE_URL}/s3/listfiles`;

// ACCOUNTING
export const ADD_EXPENSE_RECORD = `${BASE_URL}/expenses`;
export const DELETE_EXPENSE_RECORD = `${BASE_URL}/expenses`;
export const GET_ALL_EXPENSE_RECORD = `${BASE_URL}/expenses`;
export const UPDATE_EXPENSE_RECORD_BY_UUID = `${BASE_URL}/expenses/`;
export const UPDATE_STATUS_INACTIVE_EXPENSE_RECORD_BY_UUID = `${BASE_URL}/expenses/`;
export const EXPENSE_RECORD_S3 = `${BASE_URL}/expenses/s3/uploadFile?uuid=`;
export const GET_ALL_EXPENSE_RECORD_BY_EMPLOYEE_NUMBER = `${BASE_URL}/expenses/byEmployeeNumber?employee_number=`;
export const GET_ALL_EXPENSE_RECORD_BY_STATUS = `${BASE_URL}/expenses/byStatus?status=`;
export const GET_ALL_EXPENSE_RECORD_BY_STATUS_AND_EMPLOYEE_NUMBER = `${BASE_URL}/expenses/byStatusEmployeeNumber?employee_number=`;
export const UPDATE_APPROVE_HISTORY = `${BASE_URL}/expenses/approve`;
export const PORCENTAGES = `${BASE_URL}/porcentages`;
export const UPDATE_LAST_MODIFICATION = `${BASE_URL}/expenses/update_last_modification/`;
export const PROCESS_EXCEL = `${BASE_URL}/process-excel`;

// TRAINING LOGS
export const ADD_NEW_TRAINING_LOG = `${BASE_URL}/training_log/`;

// MARKETING
export const UPLOAD_LEAD_SYSTEM = `${BASE_URL}/upload_leads`;

// REPORT TO
export const REPORT_TO = `${BASE_URL}/report_to`;

// EMPLOYEES
export const EMPLOYEES_FILTER = `${BASE_URL}/employees/filter`;

// IT - COMPUTER EQUIPMENT
export const COMPUTER_EQUIPMENT = `${BASE_URL}/computer_equipment`;
export const COMPUTER_EQUIPMENT_ASSIGNED = `${BASE_URL}/computer_equipment_assigned`;
export const UPLOAD_CERTIFICATE = `${BASE_URL}/computer_equipment_assigned/s3/upload?employee_number=`;
export const GENERATE_CERTIFICATE = `${BASE_URL}/generate-pdf/`;



// ZOHO
export const ZOHO_CHART_OF_ACCOUNTS = `${BASE_URL}/zoho_chart_of_accounts`;
export const ZOHO_VENDORS = `${BASE_URL}/vendors`;
export const ZOHO_CURRENCY = `${BASE_URL}/currency`;
export const ZOHO_BANKS = `${BASE_URL}/bank_accounts`;
export const CREATE_ZOHO_EXPENSE = `${BASE_URL}/create-expense`;
export const AUTH_STATE_ZOHO_EXPENSE = `${BASE_URL}/check-authorization-status/`;
export const UPDATE_STATUS_EXPENSE_RECORD = `${BASE_URL}/update-status/`;
export const DELETE_EXPENSE_RECORD_ZOHO = `${BASE_URL}/delete_expense/`;

// SALES
export const GET_ACTIVITY_TODAY_EMPLOYEE = `${BASE_URL_SALES}/activities/today?employee_number=`;
export const ACTIVITY_REPORT = `${BASE_URL_SALES}/activities`;