<template>
  <v-dialog :value="isPolicyModalOpen" max-width="1050" @input="updatePolicyModal">
    <v-card>
      <v-card-title :style="{ color: 'green' }">{{ policyTitle }}</v-card-title>
      <v-card-text>
        <!-- Visor de archivos -->
        <iframe v-if="policyFileUrl" :src="policyFileUrl" width="1000" height="900px"></iframe>
        <div v-else>No file</div>
        <!-- <div class="signature-pad-container">
          <vue-signature-pad ref="signaturePad" :options="signaturePadOptions"/>
        </div> -->
        <v-row dense>
          <v-col>
            <v-checkbox v-model="accepted" label="Accept terms and conditions" @change="sign"></v-checkbox>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6" sm="6" md="6">
            <div v-if="isPolicyModalOpen" class="signature-pad-container" ref="signaturePadContainer">
              <canvas ref="signaturePadCanvas" width="1000" height="400"></canvas>
            </div>
          </v-col>          
        </v-row>
        <v-row>
          <!-- <v-col cols="6" sm="6" md="6">
            <v-btn v-if="pageRoute === '/employeesform' && formStatus === false" color="#7ab6e0" @click="sign" dark
               :disabled="statusPolicy">digital signature</v-btn>
          </v-col> -->
        </v-row>
        <!-- <v-row>
          <v-col cols="6" sm="6" md="6">
            <div v-if="isPolicyModalOpen" class="signature-pad-container" ref="signaturePadContainer">
              <canvas ref="signaturePadCanvas" width="1000" height="400"></canvas>
            </div>            
          </v-col>
          <v-col>
            <v-btn v-if="pageRoute === '/employeesform' && formStatus === false" color="purple" @click="accepted_policy"
          dark>Accept Policy</v-btn>
          </v-col>          
        </v-row> -->
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn v-if="pageRoute === '/employeesform' && formStatus === false" color="purple" @click="accepted_policy"
          dark :disabled="statusPolicy">Accept Policy</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import SignaturePad from 'signature_pad';
import { get_employee_number } from '../../utils/get_employee_number.js';
import { uploadFile } from '../../utils/upload_file.js'
import { upload_signature } from '../../utils/upload_signature.js'
import { UPLOAD_SIGNATURE } from '../../utils/endpoints.js'
import { generatePublicURL } from '../../utils/presignedUrl.js'



export default {
  components: {

  },
  props: {
    policyModalOpen: Boolean,
    policyTitle: String,
    policyFileUrl: String,
    formStatus: Boolean,
    //Policies
    policy: String,
    digitalSignature: String,
    statusPolicy: Boolean,
    s3_urlImage: String,
    /* employee_handbookurl: String,
    acknowledgment_of_handbookurl: String,
    code_of_discipline_policyurl: String,
    dress_code_policyurl: String,
    sales_enrollment_policyurl: String,
    attendance_policyurl: String,
    advances_and_loan_policyurl: String,
    no_show_and_late_cancellationurl: String,
    sexual_harassment_training_videourl: String,
    sexual_harassment_training_acknowledgmenturl: String,
    timekeeping_policyurl: String,
    remote_staff_working_policyurl: String,
    cash_handling_policyurl: String, */
  },
  data() {
    return {
      pageRoute: window.location.pathname,
      accepted: false,
      signaturePad: null,
      employee_number: '',
      signatureImage: '',
      
    }
  },
  computed: {
    isPolicyModalOpen: {
      get() {
        return this.policyModalOpen;
      },
      set(value) {
        this.$emit('update:policyModalOpen', value);
      },
      signaturePadInitialized() {
        const canvas = this.$refs.signaturePadCanvas;
        const container = this.$refs.signaturePadContainer;

        if (!canvas || !container) {
          console.error('Canvas or container element not found.');
          return false;
        }

        canvas.width = container.clientWidth;
        canvas.height = container.clientHeight;

        this.signaturePad = new SignaturePad(canvas, {
          backgroundColor: 'rgb(255, 255, 255)',
          penColor: 'rgb(0, 0, 0)',
          minWidth: 1,
          maxWidth: 3.5,
        });

        return true; // Indicamos que el canvas ha sido inicializado correctamente
      }

    }
  },
  async mounted() {
    console.log("MOUNTED.................");
    console.log("this.policyModalOpen: ", this.policyModalOpen);
    console.log("statusPolicy: ", this.statusPolicy);
    console.log("PolicyModal - s3_urlImage: ", this.s3_urlImage);
    if (this.policyModalOpen) {
      this.accepted = this.statusPolicy;
      if (this.statusPolicy === true) {
        if (this.s3_urlImage === '') {
          this.$nextTick(() => {
            //this.initSignaturePad();
            console.log("this.digitalSignature: ", this.digitalSignature);
            //this.signaturePad = this.digitalSignature;
            this.$nextTick(() => {
              if (this.digitalSignature) {
                const canvas = this.$refs.signaturePadCanvas;
                const context = canvas.getContext('2d');
                const image = new Image();
                image.onload = () => {
                  context.drawImage(image, 0, 0, canvas.width, canvas.height);
                };
                image.src = this.digitalSignature;
                this.signaturePad = true;
              }
            });
          });
        } else {
          this.loadImageToCanvas();
        }
      }
    }
  },
  watch: {
    /* isPolicyModalOpen(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.initSignaturePad();
        });
      }
    }, */
  },
  methods: {
    loadImageToCanvas() {
      const canvas = this.$refs.signaturePadCanvas;
      if (!canvas) {
        console.error('Canvas no encontrado');
        return;
      }

      const context = canvas.getContext('2d');
      if (!context) {
        console.error('Contexto 2D no disponible');
        return;
      }

      const image = new Image();
      image.src = this.s3_urlImage;

      image.onload = () => {
        canvas.width = image.width;
        canvas.height = image.height;
        context.drawImage(image, 0, 0);
      };

      image.onerror = (error) => {
        console.error('Error al cargar la imagen', error);
      };
    },
    sign() {
      if (this.policyModalOpen) {
        this.$nextTick(() => {
          //this.initSignaturePad();
          console.log("this.digitalSignature: ", this.digitalSignature);
          //this.signaturePad = this.digitalSignature;
          this.$nextTick(() => {
            if (this.digitalSignature != '') {
              const canvas = this.$refs.signaturePadCanvas;
              const context = canvas.getContext('2d');
              const image = new Image();
              image.onload = () => {
                context.drawImage(image, 0, 0, canvas.width, canvas.height);
              };
              image.src = this.digitalSignature;
              this.signaturePad = true;
            } else {
              if (this.s3_urlImage != '') {
                this.loadImageToCanvas();
                this.signaturePad = true;
              }
            }
          });
        });
        console.log("signaturePad: ", this.signaturePad);
      }
    },
    clearPad() {
      this.signaturePad.clear();
    },
    initSignaturePad() {
      const canvas = this.$refs.signaturePadCanvas;
      if (!canvas) {
        console.error('Canvas element not found.');
        return;
      }

      // Ajustar el tamaño del canvas al contenedor
      canvas.width = this.$refs.signaturePadContainer.clientWidth;
      canvas.height = this.$refs.signaturePadContainer.clientHeight;

      this.signaturePad = new SignaturePad(canvas, {
        backgroundColor: 'rgb(255, 255, 255)',
        penColor: 'rgb(0, 0, 0)',
        minWidth: 1,
        maxWidth: 3.5,
      });

      // Asegurar que el canvas se actualice correctamente después de inicializar SignaturePad
      this.signaturePad.clear(); // Limpiar el canvas inicialmente si es necesario
    },
    updatePolicyModal(value) {
      this.$emit('update:policyModalOpen', value);
    },
    closePolicyModal() {
      this.isPolicyModalOpen = false; // Cierra el modal
      /* this.policyTitle = '';
      this.policyFileUrl = ''; */
    },
    /* save() {
      this.$emit('save'); // Emitir evento para guardar el PDF      
      this.closeModal();
    }, */
    accepted_policy() {
      
      if (this.accepted === true && this.signaturePad) {
        this.$emit('accepted_policy');
        this.closePolicyModal();
      } else {
        if (!this.accepted) {
          alert("You must accept terms and conditions!")
        }
        if (!this.signaturePad) {
          alert("You must sign!")
        }
      }
    }
  }
}
</script>


<style scoped>
.signature-pad-container {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  width: 100%;
  /* Ajusta el ancho del contenedor al 100% */
  max-width: 1000px;
  /* Establece un ancho máximo si es necesario */
  height: 200px;
  /* Establece una altura fija o ajusta según tus necesidades */
  margin-top: 20px;
  overflow: hidden;
  /* Asegura que el contenido no se desborde */
}

.signature-pad-container canvas {
  width: 100%;
  /* Ajusta el ancho del canvas al 100% del contenedor */
  height: 100%;
  /* Ajusta la altura del canvas al 100% del contenedor */
  display: block;
  /* Asegura que el canvas se muestre como un bloque */
}
</style>