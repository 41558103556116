<template>
    <v-dialog :value="isModalOpen" max-width="1400" @input="updateModal">
        <v-card>
            <v-card-title>{{ title }}</v-card-title><!-- :style="{ color: 'green' }" -->
            <v-card-text>
                <v-form>
                    <v-container>
                        <v-divider class="thick-black-divider"></v-divider>
                        <v-row>
                            <v-col>
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left wrap-header">
                                                    Employee Number
                                                </th>
                                                <th class="text-left wrap-header">
                                                    Name
                                                </th>
                                                <!-- <th class="text-left wrap-header">
                                                    Observations
                                                </th> -->
                                                <th class="text-left wrap-header">
                                                    Assigned Date
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in trainingLogs_Employee" :key="item.employee_number">
                                                <td class="wrap-column">{{ item.employee_number }}</td>
                                                <td class="wrap-column">{{ item.trainer_name + ' ' +
                                                    item.trainer_lastname
                                                    }}
                                                </td>
                                                <!-- <td class="wrap-column">{{ item.observations }}</td> -->
                                                <td class="wrap-column">{{ formatDate(item.date_time) }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                        <v-divider class="thick-black-divider"></v-divider>
                        <v-row>
                            <v-col>
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left wrap-header">
                                                    Observations
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in observationsItems" :key="index">
                                                <td class="wrap-column">{{ item.observation }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                        <v-divider class="thick-black-divider"></v-divider>
                        <v-row>
                            <v-col cols="6">
                                <v-select v-if="logsFilter === 'Incomplete' || logsFilter === ''" v-model="employee"
                                    :items="computedEmployees" item-text="fullName" label="Assign to"
                                    @input="printEmployee" return-object></v-select>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-end">
                            <v-col cols="6">
                                <v-textarea v-model="observations" label="Observations" rows="3" auto-grow></v-textarea>
                            </v-col>
                            <v-col cols="6" class="mb-5">
                                <v-btn :disabled="!observations" color="primary" @click="update_observations">Add
                                    Observations</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn v-if="logsFilter === 'Incomplete' || logsFilter === ''" :disabled="!employee" color="primary"
                    @click="employee_assing">Assign</v-btn>
                <!-- v-if="pageRoute === '/employeesform'" -->
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import { GET_TRAINERS_BY_UUID, GET_EMPLOYEES_BY_STATUS_TRAININGLOG, ADD_TRAINING_LOG_EMPLOYEE } from '../../utils/endpoints';
import { getTrainingLogsByUUID } from '../../utils/TrainingLogs/trainingLogs_CRUD'
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { updateObservations, getObservations } from '../../utils/TrainingLogs/OldTrainingLogs/old_training_log_CRUD'

export default {
    props: {
        modalOpen: Boolean,
        title: String,
        itemTrainingLog: Object,
        logsFilter: String
    },
    data() {
        return {
            observationsItems: null,
            observations: '',
            pageRoute: window.location.pathname,
            uuid: '',
            employee_number: '',
            trainer_name: '',
            trainer_lastname: '',
            trainers: [],
            employees: [],
            employee: null,
            trainingLog: null,
            trainingLogs_Employee: null,
        }
    },
    async mounted() {
        console.log("MOUNTED................")
        console.log("this.itemTrainingLog: ", this.itemTrainingLog);
        this.uuid = this.itemTrainingLog['uuid'];
        console.log("uuid: ", this.uuid);
        this.trainers = await this.get_trainers_by_uuid();
        console.log("this.trainers: ", this.trainers);
        this.employees = await this.get_EmmployeeByStatusTrainingLog();
        this.trainingLogs_Employee = await getTrainingLogsByUUID(this.uuid);
        console.log("trainingLogs_Employee: ", this.trainingLogs_Employee);
        this.observationsItems = await getObservations(this.uuid);
        console.log("this.observationsItems: ", this.observationsItems);
    },
    computed: {
        computedEmployees() {
            return this.employees.map(employee => ({
                ...employee,
                fullName: `${employee.name} ${employee.last_name}`
            }));
        },
        isModalOpen: {
            get() {
                return this.modalOpen;
            },
            set(value) {
                this.$emit('update:modalOpen', value);
            }
        }
    },
    methods: {
        async update_observations() {
            const res = await updateObservations(this.uuid, this.observations);
            console.log("res: ", res);
            if (res.ok) {
                this.observationsItems = await getObservations(this.uuid);
                this.observations = '';
            }
        },
        formatDate(dateString) {
            if (!dateString) return '';

            const timeZone = 'America/Chicago';  // Zona horaria de Chicago
            const zonedDate = toZonedTime(new Date(dateString), timeZone);  // Convierte a la hora de Chicago
            return format(zonedDate, 'yyyy-MM-dd HH:mm:ss');
        },
        printEmployee() {
            console.log(this.employee);
        },
        async employee_assing() {
            console.log(this.employee['employee_number']);
            console.log(this.itemTrainingLog)
            const data = {
                "employee_number": this.employee['employee_number'],
                "trainer_name": this.employee['name'],
                "trainer_lastname": this.employee['last_name'],
                "uuid_trainingform": this.uuid,
                "trainee_name": this.itemTrainingLog['trainee_name'],
                "trainee_lastname": this.itemTrainingLog['trainee_lastname'],
                /* "observations": this.observations */
            };
            const response = await this.assign(data);
            if (response.ok) {
                this.employee = null;
                this.trainingLogs_Employee = await getTrainingLogsByUUID(this.uuid);
                const res_update = await updateObservations(this.uuid, this.observations);
                console.log("res_update: ", res_update);
                if (res_update.ok) {
                    this.observationsItems = await getObservations(this.uuid);

                    this.observations = '';
                }
            }
        },
        async assign(data) {
            console.log(this.employee['employee_number']);
            console.log(this.itemTrainingLog)
            try {
                const response = await fetch(ADD_TRAINING_LOG_EMPLOYEE, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                });

                if (!response.ok) {
                    this.employee = null;
                    alert("The instructor has already assigned the form");
                } else {
                    return response;
                }

            } catch (error) {
                console.error("Error adding training log employee: ", error);
            }
        },
        async get_EmmployeeByStatusTrainingLog() {
            try {
                const response = await fetch(GET_EMPLOYEES_BY_STATUS_TRAININGLOG); // Replace "your_endpoint" with the URL of your endpoint
                if (!response.ok) {
                    throw new Error('Failed to fetch record');
                }
                const res = await response.json();
                console.log("employees: ", res);
                return res;
            } catch (error) {
                console.error('Error fetching record:', error);                
            }
        },
        async get_trainers_by_uuid() {
            console.log("get_trainers_by_uuid.......");
            try {
                const url = `${GET_TRAINERS_BY_UUID}${this.uuid}`;
                console.log("url: ", url);

                const response = await fetch(url); // Replace "your_endpoint" with the URL of your endpoint
                if (!response.ok) {
                    throw new Error('Failed to fetch record');
                }
                const res = await response.json();
                console.log("trainers: ", res);
                return res;
            } catch (error) {
                console.error('Error fetching record:', error);
            }
        },
        updateModal(value) {
            this.$emit('update:modalOpen', value);
        },
        closeModal() {
            this.isModalOpen = false; // Cierra el modal
        },
        test() {
            console.log("itemTrainingLog: ", this.itemTrainingLog)
            console.log("uuid: ", this.itemTrainingLog['uuid'])
        }
    }
}
</script>

<style scoped>
.wrap-header {
    white-space: normal;
    /* Permite el wrap en los encabezados */
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.wrap-column {
    white-space: normal;
    /* Permite el wrap en las celdas */
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.thick-black-divider {
    border-width: 2px;
    border-color: black !important;
}
</style></v-divider>