<template>
    <div class="training-log-console">
        <!-- Encabezado elegante -->
        <v-app-bar color="gray" dark flat>
            <v-app-bar-title class="font-weight-bold text-h5">
                Trainer Console
            </v-app-bar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="blue" @click="refreshForms">
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn icon color="green" @click="openLog">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-app-bar>

        <!-- Contenedor principal -->
        <!-- Contenedor principal -->
        <v-container fluid class="mt-4">
            <v-layout row wrap>
                <!-- Filtro de estado -->
                <v-flex xs12 sm6 md4 lg3>
                    <v-select v-model="logsFilter" :items="status" @input="getLogs" label="Filter Logs" outlined
                        dense></v-select>
                </v-flex>

                <!-- Separador (visible solo en pantallas grandes) -->
                <v-flex d-lg-block d-none lg1></v-flex>

                <!-- Búsqueda -->
                <v-flex xs12 sm6 md4 lg3>
                    <v-text-field v-model="search" label="Search" solo-inverted prepend-icon="mdi-magnify"
                        dense></v-text-field>
                </v-flex>
            </v-layout>

            <!-- <v-card class="elevation-2 mt-4"> -->
                                <!-- <div class="table-wrapper">
                                        <v-data-table :headers="headers" :items="filteredTrainingLogs"
                                            class="rounded-table elevation-1 wrapped-columns" @click:row="openLog" :item-class="getRowClass"
                                            sort-by="percentage" :sort-desc="true" dense>
                                            
                                            <template v-slot:[`item.uuid`]="{ item }">
                                                <div class="wrap-column">
                                                    {{ item.uuid }}
                                                </div>
                                            </template>


                    <template v-slot:[`item.type_of_log`]="{ item }">
                                                <div class="wrap-column">
                                                    {{ item.type_of_log }}
                                                </div>
                                            </template>


                    <template v-slot:[`item.percentage`]="{ item }">
                                                <span class="text-primary font-weight-bold">
                                                    {{ parseFloat(item.percentage).toFixed(2) }} %
                                                </span>
                                            </template>
                    </v-data-table>
                    </div> -->
                                <!-- </v-card> -->


        </v-container>
        <!-- <div class="table-wrapper"> -->
            <v-data-table :headers="headers" :items="filteredTrainingLogs"
                class="rounded-table elevation-1 wrapped-columns" @click:row="openLog" :item-class="getRowClass"
                sort-by="percentage" :sort-desc="true" dense>
                <!-- UUID Column -->
                <template v-slot:[`item.uuid`]="{ item }">
                    <div class="wrap-column">
                        {{ item.uuid }}
                    </div>
                </template>

                <!-- Type of Log Column -->
                <template v-slot:[`item.type_of_log`]="{ item }">
                    <div class="wrap-column">
                        {{ item.type_of_log }}
                    </div>
                </template>

                <!-- Percentage Column -->
                <template v-slot:[`item.percentage`]="{ item }">
                    <span class="text-primary font-weight-bold">
                        {{ parseFloat(item.percentage).toFixed(2) }} %
                    </span>
                </template>
            </v-data-table>
        <!-- </div> -->

        <!-- Diálogo -->
        <v-dialog v-model="dialog" max-width="600px" class="elevation-3">
            <template v-if="dialog">
                <NewTrainingLogForm :Item="itemLog" :EditedIndex="editedIndex" :key="dialog.toString()"
                    @sent-info="handleInfoFromChild" />
            </template>
        </v-dialog>

        <!-- Loader -->
        <div class="loader-container" v-if="showLoader">
            <div class="loader"></div>
        </div>
    </div>
</template>


<!-- <template>
    <div>
        <v-data-table :headers="headers" :items="filteredTrainingLogs" class="elevation-1" @click:row="openLog"
            :item-class="getRowClass" sort-by="percentage" :sort-desc="true">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title class="font-weight-bold">Trainer Training Log Console</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-select v-model="logsFilter" :items="status" @input="getLogs"
                        style="max-width: 220px; margin-top: 30px; margin-right: 10px;"
                        label="Filter Closed/Not Closed"></v-select>
                    <v-text-field v-model="search" label="Search" solo-inverted prepend-icon="mdi-magnify"
                        class="mr-3 mt-5"></v-text-field>
                    <v-btn color="blue" dark  @click="refreshForms" class="mb-2 mr-2">
                        <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                    <v-btn color="success" @click="openLog" class="mb-2 mr-2">
                        <v-icon >mdi-plus</v-icon>
                    </v-btn>
                </v-toolbar>
            </template>
            <template v-slot:[`item.percentage`]="{ item }">
                <span class="blackBold">{{ parseFloat(item.percentage).toFixed(2) }} %</span>
            </template>
        </v-data-table>
        <v-dialog v-model="dialog" max-width="1200px">
            <template v-if="dialog">
                <NewTrainingLogForm :Item="itemLog" :EditedIndex="editedIndex" :key="dialog.toString()"
                    @sent-info="handleInfoFromChild" />
            </template>
        </v-dialog>
        <div class="loader-container" v-if="showLoader">
            <div class="loader"></div>
        </div>
    </div>
</template> -->

<script>
import { get_employee_number } from '../../utils/get_employee_number.js'
import { bus } from '../../main.js';
import { new_get_TrainingLog_Forms } from '../../utils/TrainingLogs/trainingLogs_CRUD.js'
import NewTrainingLogForm from '../../components/Training_log/NewTrainingLogForm'
import _ from 'lodash';

export default {
    components: {
        NewTrainingLogForm,
    },
    data: () => ({
        showLoader: false,
        itemLog: null,
        dialog: false,
        logsFilter: 'Not Closed',
        status: ['Closed', 'Not Closed'],
        formIndex: -1,
        editedIndex: -1,
        search: '',
        headers: [
            { text: 'Trainee`s name', value: 'trainee.name' },
            { text: 'Trainee`s last name', value: 'trainee.last_name' },
            { text: 'UUID', value: 'uuid' },
            { text: 'Type of Log', value: 'type_of_log' },
            { text: 'Percentage', value: 'percentage' },
        ],
        trainingLogs: [],
        employee_number: '',
        editedItem: {
            history: '',
            id: null,
            status: false,
            trainee_lastName: '',
            trainee_name: '',
            uuid: '',
            type_of_log: '',
            percentage: 0.0,
        }
    }),
    computed: {
        filteredTrainingLogs() {
            if (this.trainingLogs) {
                return this.trainingLogs.filter(trainingLog =>
                    trainingLog.trainee.name.toLowerCase().includes(this.search.toLowerCase())
                );
            }
            else {
                return [];
            }
        },
    },
    async mounted() {
        this.showLoader = true;
        console.log("-------------------MOUNTED.......................")
        bus.$emit('login-success');
        bus.$emit('permissions');
        this.employee_number = get_employee_number();
        let forms = await new_get_TrainingLog_Forms('Not Closed', this.employee_number);
        console.log("forms: ", forms);
        this.trainingLogs = forms;
        this.showLoader = false;
    },
    methods: {
        getRowClass(item) {
            switch (item.type_of_log) {
                case "Learning Nova`s Standards":
                    return 'gold-background';
                case "Be the Student":
                    return 'pink-background';
                case "Be the Instructor":
                    return 'skyblue-background';
                case "Observation with Actual Student":
                    return 'palegreen-background';
                case "Being Observed teaching an actual student":
                    return 'tomato-background';
                default:
                    return '';
            }
        },
        async handleInfoFromChild(info) {
            // Manejar la información recibida desde el hijo
            console.log('Información recibida desde el hijo:', info);
            this.dialog = info; //Close dialog  
            this.refreshForms();
            //this.refreshTable();
        },
        openLog(item) {
            console.log("<<<<<<<<<<<  openLog: ", item);
            if (item.uuid != '') {
                this.editedIndex = this.trainingLogs.indexOf(item);
                this.itemLog = _.cloneDeep(item);
                //this.itemLog = Object.assign({}, item);                
            }
            console.log("this.editedIndex: ", this.editedIndex);
            console.log("this.itemLog: ", this.itemLog);

            this.dialog = true;
        },
        async getLogs(item) {
            console.log("Filtering......", item);
            this.logsFilter = item;
            this.showLoader = true;
            if (item === 'Not Closed') {
                this.trainingLogs = await new_get_TrainingLog_Forms('Not Closed', this.employee_number);
                console.log("this.trainingLogs: ", this.trainingLogs);
                this.showLoader = false;
            } else {
                console.log("CLOSED.");
                this.trainingLogs = await new_get_TrainingLog_Forms('Closed', this.employee_number);
                console.log("this.trainingLogs: ", this.trainingLogs);
                this.showLoader = false;
            }
        },
        /* redirect(item) {
            this.editedIndex = this.trainingLogs.indexOf(item);
            console.log(this.editedIndex);
            this.editedItem = Object.assign({}, item);
            console.log("**************************this.editedItem : ", this.editedItem)
            const dataForm = this.editedItem;
            this.$router.push({ name: 'new-training-log', params: { dataForm, editedIndex: this.editedIndex } });
        }, */
        async refreshForms() {
            this.showLoader = true;
            console.log("this.logsFilter: ", this.logsFilter);
            this.trainingLogs = await new_get_TrainingLog_Forms(this.logsFilter, this.employee_number);
            this.showLoader = false;
        },
        /* openForm(item) {
            console.log("Opening form......")
            this.$router.push({ name: 'new-training-log' });
        }, */
    },
}
</script>

<style scoped>
.training-log-console {
    background-color: #f9f9f9;
    min-height: 100vh;
    padding: 16px;
}

/* Tabla con bordes redondeados */
.rounded-table {
    border-radius: 8px;
    overflow: hidden;
}

/* Personalización de filas */
::v-deep(.high-percentage) {
    background-color: rgba(76, 175, 80, 0.2); /* Verde claro */
}

::v-deep(.low-percentage) {
    background-color: rgba(244, 67, 54, 0.2); /* Rojo claro */
}

::v-deep(.gold-background) {
    background-color: #FFD700 !important;
}

::v-deep(.pink-background) {
    background-color: #FFB6C1 !important;
}

::v-deep(.skyblue-background) {
    background-color: #87CEEB !important;
}

::v-deep(.palegreen-background) {
    background-color: #98FB98 !important;
}

::v-deep(.tomato-background) {
    background-color: #FF6347 !important;
}

.custom-btn {
    margin: 20px auto; /* Centra el botón verticalmente */
    width: 70px; /* Tamaño cuadrado del botón */
    height: 70px !important;
    padding-top: 10px; /* Añade separación superior */
}

.align-center {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    height: 100%;
}

/* Selector específico para Vuetify con ::v-deep */
::v-deep(.v-data-table tr) {
    cursor: pointer;
}

::v-deep(.blackBold) {
    color: black !important;
    font-weight: bold !important;
}

/* Loader */
.loader-container {
    position: fixed; /* Para que el loader esté por encima de todo el contenido */
    top: 0;
    left: 0;
    width: 100%; /* Ocupa todo el ancho del viewport */
    height: 100%; /* Ocupa todo el alto del viewport */
    display: flex;
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    background-color: rgba(255, 255, 255, 0.5); /* Fondo semitransparente */
    z-index: 9999; /* Coloca el loader por encima de todo el contenido */
}

.loader {
    width: 120px;
    height: 60px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    background: #fff;
    filter: blur(5px) contrast(10) hue-rotate(60deg);
    mix-blend-mode: darken;
}

.loader:before,
.loader:after {
    content: "";
    width: 40px;
    border-radius: 50%;
    background: #ff00ff;
    animation: l3 1s infinite alternate;
}

.loader:after {
    --s: -1;
}

/* Forzar el wrapping en las columnas UUID y Type of Log */
::v-deep(.wrap-column) {
    white-space: normal !important; /* Permitir el ajuste en múltiples líneas */
    word-wrap: break-word !important; /* Forzar el ajuste de palabras largas */
    word-break: break-word !important; /* Romper palabras largas si es necesario */
    overflow-wrap: anywhere !important; /* Compatibilidad adicional */
    max-width: 100%; /* Asegurarse de que se limite al ancho de la celda */
    text-align: left; /* Alineación del texto a la izquierda */
}

/* Ajustes para pantallas pequeñas */
@media (max-width: 600px) {
    ::v-deep(.wrap-column) {
        font-size: 12px; /* Reducir el tamaño del texto para dispositivos pequeños */
        padding: 4px 2px; /* Ajustar el espaciado en celdas */
    }
}
</style>
