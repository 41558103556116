<template>
    <v-col cols="12" sm="12" md="12">
        <v-card>
            <v-card-title>
                <span class="headline">Expense type</span>
            </v-card-title>

            <v-card-text>
                <v-form>
                    <v-textarea v-model="editedItem.expense_type" label="Expense Type" required counter="100"
                        hint="At least 100 characters" persistent-hint></v-textarea>

                    <v-combobox v-model="editedItem.bill" :items="bills" label="Bills" required></v-combobox>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="submitForm">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
</template>

<script>
import { getAllChartOfAccounts } from '../../utils/chart_of_account.js'
import { bus } from '../../main.js'
import { updateExpenseType, createExpenseType } from '../../utils/expense_type_CRUD.js'

export default {
    props: {
        Index: Number,
        Item: Object
    },
    data() {
        return {
            //expenseType: '',
            //selectedBill: null,            
            billsData: [], // Ejemplo de datos para el dropdown de Bills
            //bookIds: [1, 2, 3, 4, 5] // Ejemplo de datos para el dropdown de ID Books
            editedIndex: -1,
            editedItem: {
                expense_type: '',
                bill: '',
            }
        };
    },
    computed: {
        bills() {
            return this.billsData.map(bill => `${bill.account_code} - ${bill.account_name}`);
        }
    },
    methods: {
        async submitForm() {
            // Lógica para manejar el submit del formulario
            console.log('Expense Type:', this.editedItem.expense_type);
            console.log('Selected Bill:', this.editedItem.bill);
            // Aquí puedes agregar la lógica para enviar los datos al backend
            console.log("this.Index: ", this.Index);
            if (this.Index == -1) {
                console.log("this.editedItem: ", this.editedItem);
                const response = await createExpenseType(this.editedItem);
                console.log("response: ", response);
                if (response.ok) {
                    this.$emit('sent-info', false);
                }
            } else {
                console.log("this.editedItem: ", this.editedItem);
                const response = await updateExpenseType(this.editedItem, this.Index);
                if (response.ok) {
                    this.$emit('sent-info', false);
                }
            }
        }
    },
    async mounted() {
        bus.$emit('login-success');
        bus.$emit('permissions');
        console.log("Index: ", this.Index);
        const data = await getAllChartOfAccounts();
        console.log("Type of fetched data:", typeof data.data);
        console.log("data: ", data.data);
        this.billsData = data.data;
        if (this.Index == -1) {

        } else {
            console.log("Updating.....")
            this.editedItem = this.Item;
        }
    },
};
</script>

<style scoped>
.headline {
    font-size: 24px;
    font-weight: bold;
}
</style>