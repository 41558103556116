<template>
    <v-dialog :value="isModalOpen" max-width="800" @input="updateModal">
        <v-card>
            <v-card-title>{{ title }}</v-card-title><!-- :style="{ color: 'green' }" -->
            <v-card-text>
                <v-form>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left wrap-header">
                                                    Employee Number
                                                </th>
                                                <th class="text-left wrap-header">
                                                    Name
                                                </th>                                               
                                                <th class="text-left wrap-header">
                                                    Modification Date
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in history" :key="item.last_trainer.last_modification">
                                                <td class="wrap-column">{{ item.last_trainer.employee_number }}</td>
                                                <td class="wrap-column">{{ item.last_trainer.name + ' ' +
                                                    item.last_trainer.last_name
                                                    }}
                                                </td>                                                
                                                <td class="wrap-column">{{ formatDate(item.last_trainer.last_modification) }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

import { GET_TRAINERS_BY_UUID, GET_EMPLOYEES_BY_STATUS_TRAININGLOG, ADD_TRAINING_LOG_EMPLOYEE } from '../../utils/endpoints';
import { getTrainingLogsByUUID } from '../../utils/TrainingLogs/trainingLogs_CRUD'
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

export default {
    props: {
        modalOpen: Boolean,
        title: String,
        itemTrainingLog: Object,
        logsFilter: String
    },
    data() {
        return {
            history: null,
            observations: '',
            pageRoute: window.location.pathname,
            uuid: '',
            employee_number: '',
            trainer_name: '',
            trainer_lastname: '',
            trainers: [],
            employees: [],
            employee: null,
            trainingLog: null,
            trainingLogs_Employee: null,
        }
    },
    async mounted() {
        console.log("MOUNTED................")
        console.log("this.itemTrainingLog: ", this.itemTrainingLog);
        this.history = this.itemTrainingLog.history.trainer;
        console.log("this.history: ", this.history);
       /*  this.uuid = this.itemTrainingLog['uuid'];
        console.log("uuid: ", this.uuid);
        this.trainers = await this.get_trainers_by_uuid();
        console.log("this.trainers: ", this.trainers);
        this.employees = await this.get_EmmployeeByStatusTrainingLog();
        this.trainingLogs_Employee = await getTrainingLogsByUUID(this.uuid);
        console.log("trainingLogs_Employee: ", this.trainingLogs_Employee); */
    },
    computed: {
        computedEmployees() {
            return this.employees.map(employee => ({
                ...employee,
                fullName: `${employee.name} ${employee.last_name}`
            }));
        },
        isModalOpen: {
            get() {
                return this.modalOpen;
            },
            set(value) {
                this.$emit('update:modalOpen', value);
            }
        }
    },
    methods: {
        formatDate(dateString) {
            if (!dateString) return '';

            const timeZone = 'America/Chicago';  // Zona horaria de Chicago
            const zonedDate = toZonedTime(new Date(dateString), timeZone);  // Convierte a la hora de Chicago
            return format(zonedDate, 'yyyy-MM-dd HH:mm:ss');
        },
        printEmployee() {
            console.log(this.employee);
        },
        /* getFullName(employee) {
            console.log("employee: ", employee);
            return `${employee.name} ${employee.last_name}`;
        }, */
        async assign() {
            console.log(this.employee['employee_number']);
            console.log(this.itemTrainingLog)
            const data = {
                "employee_number": this.employee['employee_number'],
                "trainer_name": this.employee['name'],
                "trainer_lastname": this.employee['last_name'],
                "uuid_trainingform": this.uuid,
                "trainee_name": this.itemTrainingLog['trainee_name'],
                "trainee_lastname": this.itemTrainingLog['trainee_lastname'],
                "observations": this.observations
            };
            try {
                const response = await fetch(ADD_TRAINING_LOG_EMPLOYEE, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                });

                if (!response.ok) {
                    this.employee = null;
                    alert("The instructor has already assigned the form");
                    /* const errorMessage = await response.text();
                    throw new Error(`Error al guardar el registro: ${errorMessage}`); */
                } else {
                    this.employee = null;
                    this.observations = '';
                    //this.trainers = await this.get_trainers_by_uuid();
                    this.trainingLogs_Employee = await getTrainingLogsByUUID(this.uuid);
                }

            } catch (error) {
                console.error("Error adding training log employee: ", error);
            }
        },
        async get_EmmployeeByStatusTrainingLog() {
            try {
                const response = await fetch(GET_EMPLOYEES_BY_STATUS_TRAININGLOG); // Replace "your_endpoint" with the URL of your endpoint
                if (!response.ok) {
                    throw new Error('Failed to fetch record');
                }
                const res = await response.json();
                //return res;
                //this.trainingLogs = res;
                console.log("employees: ", res);

                return res;

            } catch (error) {
                console.error('Error fetching record:', error);
                //throw error;
            }
        },
        async get_trainers_by_uuid() {
            console.log("get_trainers_by_uuid.......");
            try {                //console.log("Getting training logs.....")
                //console.log("employee_number: ", this.employee_number);
                const url = `${GET_TRAINERS_BY_UUID}${this.uuid}`;
                console.log("url: ", url);

                const response = await fetch(url); // Replace "your_endpoint" with the URL of your endpoint
                if (!response.ok) {
                    throw new Error('Failed to fetch record');
                }
                const res = await response.json();
                //return res;
                //this.trainingLogs = res;
                console.log("trainers: ", res);

                return res;

            } catch (error) {
                console.error('Error fetching record:', error);
                //throw error;
            }
        },
        updateModal(value) {
            this.$emit('update:modalOpen', value);
        },
        closeModal() {
            this.isModalOpen = false; // Cierra el modal
        },
        test() {
            console.log("itemTrainingLog: ", this.itemTrainingLog)
            console.log("uuid: ", this.itemTrainingLog['uuid'])
        }
        /* save() {
          this.$emit('save'); // Emitir evento para guardar el PDF      
          this.closeModal();
        } */
    }
}
</script>

<style scoped>
.wrap-header {
    white-space: normal;
    /* Permite el wrap en los encabezados */
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.wrap-column {
    white-space: normal;
    /* Permite el wrap en las celdas */
    word-wrap: break-word;
    overflow-wrap: break-word;
}
</style>