<template>
    <paid-through-account />
</template>

<script>
import PaidThroughAccount from '../../components/Accounting/PaidThroughAccount.vue'

export default {
    name: 'PaidThroughAccountView',
    components: {
        PaidThroughAccount,
    },
}
</script>