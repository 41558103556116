<template>
    <v-col cols="12" sm="12" md="12">
        <v-card>
            <v-card-title>
                <span class="headline">Payment Cards Manager</span>
            </v-card-title>
            <v-col>
                <v-card-text>
                    <v-form ref='form'>

                        <v-text-field v-model="editedItem.last_4_digits" :rules="[rules.required]" label="Last 4 digits"
                            required type="number"></v-text-field>

                        <v-divider></v-divider>
                        <!-- Radio Button Group -->
                        <v-radio-group v-model="selection" row>
                            <v-radio label="Vehicle" value="vehicle"></v-radio>
                            <v-radio label="Department" value="department"></v-radio>
                            <v-radio label="User" value="employee"></v-radio>
                        </v-radio-group>

                        <v-combobox v-if="showCombobox" v-model="selectedItem" :items="currentItems"
                            :label="currentLabel" :rules="[rules.required]" required return-object clearable outlined
                            :item-text="itemText" :key="selection"></v-combobox>
                        <v-divider class="mb-4"></v-divider>
                        <v-combobox v-model="editedItem.type" :items="typeOfCard" label="Select Type Of Card" clearable
                            outlined :rules="[rules.required]" required></v-combobox>
                        <v-combobox v-model="editedItem.company" :items="company" label="Select Company" clearable
                            outlined :rules="[rules.required]" required :key="'combobox-' + selection"></v-combobox>
                        <v-row>
                            <!-- Link Date -->
                            <v-col cols="6">
                                <v-menu ref="link_date" v-model="link_dateMenu" :close-on-content-click="false"
                                    :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="formattedDate" label="Link Date"
                                            prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                                            :rules="[rules.required]" required></v-text-field>
                                    </template>
                                    <v-date-picker v-model="editedItem.link_date"
                                        @input="onDateSelected"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <!-- Due Date -->
                            <v-col cols="6">
                                <v-menu ref="due_date" v-model="due_dateMenu" :close-on-content-click="false"
                                    :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="formattedDueDate" label="Due Date"
                                            prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                                            :rules="[rules.required]" required></v-text-field>
                                    </template>
                                    <v-date-picker v-model="editedItem.due_date"
                                        @input="onDueDateSelected"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-combobox v-model="ledgerAccountSelected" :items="itemLedgerAccount"
                            :item-text="formatAccountText" label="Ledger Account" :rules="[rules.required]" required
                            return-object clearable outlined></v-combobox>
                    </v-form>
                </v-card-text>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="submitForm">
                    SAVE
                </v-btn>
            </v-card-actions>
        </v-card>
        <div class="loader-container" v-if="showLoader">
            <div class="loader"></div>
        </div>
    </v-col>
</template>

<script>
import { createCreditCardManagement, updateCreditCardManagement } from '../../utils/credit_card_managements_CRUD.js'
import { fetchVehicles } from '../../utils/vehicles_CRUD.js'
import { getAllChartOfAccounts } from '../../utils/chart_of_accounts_CRUD.js'
import _ from 'lodash';
import { getEmployeesByStatus } from '../../utils/employees_CRUD.js'
import { getAllPaidThroughAccounts } from '../../utils/paid_through_account_CRUD.js'
import { getBankAccounts } from '../../utils/Accounting/ZOHO/banks_CRUD.js'
import { getReportingTags } from '../../utils/variables.js'

export default {
    name: 'CreditCardManagementCard',
    props: {
        Index: Number,
        Item: Object
    },
    data: () => ({
        showLoader: false,
        selection: null,
        ledgerAccountSelected: '',
        itemLedgerAccount: [],
        employeeSelected: null,
        showEmployees: false,
        employees: [],
        showVhicles: true,
        showDepartments: false,
        vehicleSelected: null,
        departmentSelected: null,
        carros: [
            { id: 1, name: 'Carro 1' },
            { id: 2, name: 'Carro 2' },
            { id: 3, name: 'Carro 3' },
        ],
        departamentos: ['Departamento A', 'Departamento B', 'Departamento C'],

        formattedDueDate: '',
        formattedDate: '',
        selection: 'vehicle',
        itemsDepartment: [],
        /* itemsDepartment: [
            { id: 1, name: 'Sales Department' },
            { id: 2, name: 'Retentions Department' },
            { id: 3, name: 'Calendar Department' },
            { id: 4, name: 'Human Resources Department' },
            { id: 5, name: 'Accounting Department' },
            { id: 6, name: 'Education Department' },
            { id: 7, name: 'Corporate Department' },
            { id: 8, name: 'Marketing Department' },
            { id: 9, name: 'Learning and Development Department' },
            { id: 10, name: 'Information Technology Department' },
            { id: 11, name: 'Fleet Department' },
            { id: 12, name: 'General/Prorated' }
        ], */
        accountSelected: null,
        itemVehicles: [],
        due_dateMenu: false,
        link_dateMenu: false,
        editedItem: {
            assigned_user: {
                type: '',
                name: null,
            },
            email: '',
            type: 'Credit',
            last_4_digits: '',
            company: 'Nova Driving School',
            link_date: '',
            due_date: '',
            ledger_account: {},
        },
        rules: {
            required: value => !!value || 'Required.',
        },
        defaultEditedItem: {
            assigned_user: {
                type: '',
                name: null,
            },
            email: '',
            type: 'Credit',
            last_4_digits: '',
            company: 'Nova Driving School',
            link_date: '',
            due_date: '',
            ledger_account: {},
        },
        editedIndex: -1,
        typeOfCard: ['Credit', 'Debit'],
        company: ['Nova Driving School', 'V-Outsourcing']
    }),
    async mounted() {
        //console.log("-------------------MOUNTED.......................")
        this.showLoader = true;
        const [vehicles, ledgerAccount, employees] = await Promise.all([
            fetchVehicles(),
            getBankAccounts(),
            getEmployeesByStatus('Active')
        ]);
        this.showLoader = false;

        this.itemVehicles = vehicles;
        this.itemLedgerAccount = ledgerAccount;
        //console.log("this.itemLedgerAccount: ", this.itemLedgerAccount);

        this.employees = employees;

        if (this.Index === 1) {
            this.$nextTick(() => {
                this.$refs.form.resetValidation();
            });
        }

        const reporting_tags = getReportingTags();
        console.log("reporting_tags: ", reporting_tags);
        this.itemsDepartment = reporting_tags.Departments.tag_options;
        console.log("this.itemsDepartment: ", this.itemsDepartment);

        if (this.Index != -1) {
            //console.log("EDITING............");
            // EDIT CARD
            console.log("this.Item: ", this.Item);
            console.log("this.Item:", JSON.stringify(this.Item, null, 2));
            //this.editedItem = _.cloneDeep(this.Item);            
            this.editedItem = Object.assign({}, this.Item);
            console.log("<<<<<<<<<<<<<this.editedItem: ", this.editedItem);
            console.log("this.editedItem:", JSON.stringify(this.editedItem, null, 2));
            console.log("Company: ", this.editedItem.company)


            this.editedIndex = this.Index;
            this.selection = this.Item.assigned_user.type;
            //console.log("this.selection: ", this.selection);
            if (this.selection === 'department') {
                console.log("DEPARTMENT...........");
                this.departmentSelected = this.Item.assigned_user.name.name;
                //console.log("this.departmentSelected: ", this.departmentSelected);
            } else if (this.selection === 'vehicle') {
                this.vehicleSelected = this.Item.assigned_user.name.name;
                //console.log("this.vehicleSelected: ", this.vehicleSelected);
            } else if (this.selection === 'employee') {
                this.employeeSelected = `${this.Item.assigned_user.name.name} ${this.Item.assigned_user.name.last_name}`
                //console.log("this.employeeSelected: ", this.employeeSelected);
            }
            this.ledgerAccountSelected = this.Item.ledger_account;
        }
    },
    created() {
    },
    computed: {
        showCombobox() {
            return this.selection !== null;
        },
        currentItems() {
            if (this.selection === 'vehicle') {
                return this.itemVehicles;
            } else if (this.selection === 'department') {
                return this.itemsDepartment;
            } else if (this.selection === 'employee') {
                return this.employees;
            }
            return [];
        },
        currentLabel() {
            if (this.selection === 'vehicle') {
                return 'Select Vehicle';
            } else if (this.selection === 'department') {
                return 'Select Department';
            } else if (this.selection === 'employee') {
                return 'Select User';
            }
            return '';
        },
        selectedItem: {
            get() {
                if (this.selection === 'vehicle') {
                    return this.vehicleSelected;
                } else if (this.selection === 'department') {
                    return this.departmentSelected;
                } else if (this.selection === 'employee') {
                    return this.employeeSelected;
                }
                return null;
            },
            set(value) {
                if (this.selection === 'vehicle') {
                    this.vehicleSelected = value;
                } else if (this.selection === 'department') {
                    this.departmentSelected = value;
                } else if (this.selection === 'employee') {
                    this.employeeSelected = value;
                }
            }
        },
        itemText() {
            if (this.selection === 'employee') {
                return this.getEmployeeFullName;
            }
            return 'name';
        }
    },
    methods: {
        getEmployeeFullName(employee) {
            return `${employee.name} ${employee.last_name}`;
        },
        formatAccountText(item) {
            ////console.log("formatAccountText/Item: ", item);
            if (item) {
                //return `${item.account_code} - ${item.account_name}`;
                return `${item.account_name}`;
            }
            return '';
        },
        initializeLedgerAccountSelected() {
            try {
                if (this.editedItem.ledger_account) {
                    //console.log("this.editedItem.ledger_account: ", this.editedItem.ledger_account);
                    this.ledgerAccountSelected = this.editedItem.ledger_account.account_name;
                    //console.log("this.ledgerAccountSelected: ", this.accountSelected);
                }
            } catch (error) {
                console.error("Error: ", error)
            }

        },
        onDueDateSelected(date) {
            this.editedItem.due_date = date;
            this.updateFormattedDueDate(date);
            this.due_dateMenu = false;
        },
        updateFormattedDueDate(date) {
            if (date) {
                const formatted = new Date(date).toISOString().split('T')[0];
                this.formattedDueDate = formatted;
            } else {
                this.formattedDueDate = '';
            }
        },
        onDateSelected(date) {
            this.editedItem.link_date = date;
            this.updateFormattedDate(date);
            this.link_dateMenu = false;
        },
        updateFormattedDate(date) {
            if (date) {
                const formatted = new Date(date).toISOString().split('T')[0];
                this.formattedDate = formatted;
            } else {
                this.formattedDate = '';
            }
        },
        async submitForm() {
            if (this.$refs.form.validate()) {
                let response = null;
                // ADD new record
                this.showLoader = true;
                //console.log("this.editedItem: ", this.editedItem);
                if (this.editedIndex === -1) {
                    response = await createCreditCardManagement(this.editedItem);
                } else {
                    // Updating record
                    console.log("Updating record/this.editedItem: ", this.editedItem);
                    response = await updateCreditCardManagement(this.editedIndex, this.editedItem);
                }
                if (response.ok) {
                    this.showLoader = false;
                    this.clear_fields();
                    this.$refs.form.resetValidation();
                    this.$emit('sent-info', false);
                }
            } else {
                //console.log('Form validation failed.');
            }
        },
        clear_fields() {
            this.editedItem = this.defaultEditedItem;
            if (this.employeeSelected !== null) {
                this.employeeSelected = null;
            } else if (this.vehicleSelected !== null) {
                this.vehicleSelected = null;
            } else {
                this.departmentSelected = null;
            }
            this.ledgerAccountSelected = null;
        },
        findVehicleByName(vehicleName, itemsVehicles) {
            return itemsVehicles.find(item => item.name === vehicleName) || null;
        },
    },
    watch: {
        selection(item) {
            console.log("SELECTION......", item);

            if (item === 'vehicle') {
                this.departmentSelected = null;
                this.employeeSelected = null;
            } else if (item === 'department') {
                this.vehicleSelected = null;
                this.employeeSelected = null;
            } else {
                this.vehicleSelected = null;
                this.departmentSelected = null;
            }
            // Limpiar el valor seleccionado cada vez que cambias la selección en el radio group
            /* this.vehicleSelected = null;
            this.departmentSelected = null;
            this.employeeSelected = null;
            this.editedItem.company = '' */
        },
        ledgerAccountSelected(val) {
            if (val) {
                //console.log("Ledger Account: ", val);
                this.editedItem.ledger_account = val;
            }
        },
        'editedItem.due_date': {
            handler(newDate) {
                this.updateFormattedDueDate(newDate);
            },
            immediate: true,
        },
        'editedItem.link_date': {
            handler(newDate) {
                this.updateFormattedDate(newDate);
            },
            immediate: true,
        },
        departmentSelected(item) {
            console.log("<<<<<<<<<<<<<<<<<<< departmentSelected/item: ", item);
            try {
                if (item !== null && (typeof item === 'object')) {
                    console.log("ITEM OBJECT........");
                    this.editedItem.assigned_user.type = this.selection;
                    this.editedItem.assigned_user.name = item;
                }
            } catch (error) {
                //console.log("error: ", error);
            }
        },
        vehicleSelected(item) {
            console.log("<<<<<<<<<<<<<<<<<<< vehicleSelected/item: ", item);
            //console.log("<<<<<<<<<<<<<<<<<<< this.editedItem.assigned_user: ", this.editedItem.assigned_user);

            try {
                if (item != null && (typeof item === 'object')) {
                    console.log("ITEM OBJECT........");
                    this.editedItem.assigned_user.type = this.selection;
                    this.editedItem.assigned_user.name = item;
                } else {
                    console.log("else -- item: ", item);
                    this.editedItem.assigned_user.name = this.findVehicleByName(item, this.itemVehicles);
                }
            } catch (error) {
                console.error("error: ", error);
            }
        },
        employeeSelected(item) {
            console.log("<<<<<<<<<<<<<<<<<<< employeeSelected/item: ", item);
            try {
                if (item != null) {
                    this.editedItem.assigned_user.type = this.selection;
                    this.editedItem.assigned_user.name = item;
                    this.editedItem.editedItem.company = item.company;


                }
            } catch (error) {
                //console.log("error: ", error);
            }
        },
        accountSelected(item) {
            if (item != null) {
                this.editedItem.ledger_account = `${item.account_code} - ${item.account_name}`;
            } else {
                this.editedItem.ledger_account = '';
            }
        }
    },
}
</script>

<style>
/* LOADER */
.loader-container {
    position: fixed;
    /* Para que el loader esté por encima de todo el contenido */
    top: 0;
    left: 0;
    width: 100%;
    /* Ocupa todo el ancho del viewport */
    height: 100%;
    /* Ocupa todo el alto del viewport */
    display: flex;
    justify-content: center;
    /* Centra horizontalmente */
    align-items: center;
    /* Centra verticalmente */
    background-color: rgba(255, 255, 255, 0.5);
    /* Fondo semitransparente */
    z-index: 9999;
    /* Coloca el loader por encima de todo el contenido */
}

.loader {
    width: 120px;
    height: 60px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    background: #fff;
    filter: blur(5px) contrast(10) hue-rotate(60deg);
    mix-blend-mode: darken;
}

.loader:before,
.loader:after {
    content: "";
    width: 40px;
    border-radius: 50%;
    background: #ff00ff;
    animation: l3 1s infinite alternate;
}

.loader:after {
    --s: -1;
}
</style>