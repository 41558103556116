
import { GET_CHART_OF_ACCOUNT } from '../utils/endpoints';

export async function getAllChartOfAccounts() {
    try {
        const response = await fetch(GET_CHART_OF_ACCOUNT, {
            method: 'GET',
        });
        const result = await response.json();
        if (!response.ok) {
            throw new Error(result.message || 'Error al obtener los charts of account');
        }
        console.log("result: ", result);
        return result;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}