<template>
    <div>
        <v-row class="pt-4 px-4">
            <v-col cols="12" sm="12" md="12" class="d-flex justify-center">
                <v-card height="auto" width="900">
                    <v-card-text>
                        <v-data-table :headers="headers" :items="filteredItems" sort-by="insurance_name"
                            class="elevation-1" @click:row="openInfo">
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-toolbar-title class="font-weight-bold">Insurance Company</v-toolbar-title>
                                    <v-divider class="mx-4" inset vertical></v-divider>
                                    <v-text-field v-model="search" label="Search" solo-inverted
                                        prepend-icon="mdi-magnify" class="mr-3 mt-5"></v-text-field>
                                    <v-btn color="blue" dark class="mb-2 mr-2" @click="fetchItems">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                    <v-btn color="success" dark class="mb-2 mr-2" @click="openDialog">
                                        <v-icon>mdi-plus-thick</v-icon>
                                    </v-btn>
                                </v-toolbar>
                            </template>
                            <!-- Personaliza la celda de 'total_cost' -->
                            <template v-slot:item.total_cost="{ item }">
                                <span>$ {{ formatCurrency(item.total_cost) }}</span>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-dialog v-model="dialogOpen" max-width="700">
                <v-card class="dialog-card" outlined>
                    <InsuranceCard :Index="String(editedIndex)" :Item="editedItem" :key="dialogOpen.toString()"
                        @sent-info="handleInfoFromChild" />
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogInfoInsurance" max-width="1500px" content-class="dialog-height">
                <template v-if="dialogInfoInsurance">
                    <InfoInsuranceCard :Item="editedItem" :key="dialogInfoInsurance.toString()" />
                </template>
            </v-dialog>
        </v-row>

        <div class="loader-container" v-if="showloader">
            <!-- <l-bouncy size="150" speed="0.8" color="#FF00F7"></l-bouncy> -->
            <l-grid size="75" speed="1.5" color="red"></l-grid>
        </div>
    </div>
</template>



<script>
import { bus } from '../../main.js';
import { getInsurances, deleteInsurance } from '../../utils/insurance_CRUD.js'
import InsuranceCard from '../../components/Fleet/InsuranceCard.vue'
import InfoInsuranceCard from '../../components/Fleet/InfoInsuranceCard.vue'
import { bouncy, grid } from 'ldrs'
bouncy.register()
grid.register()

export default {
    components: {
        InsuranceCard,
        InfoInsuranceCard,
    },
    data: () => ({
        showloader: false,
        dialogInfoInsurance: false,
        headers: [
            /* { text: 'Actions', value: 'actions', sortable: false }, */
            { text: 'Insurance name', value: 'insurance_name' },
            { text: 'Total cost', value: 'total_cost' },
        ],
        dialogOpen: false,
        search: '',
        items: [],
        editedIndex: -1,
        editedItem: {
            id: '',
            insurance_name: '',
            insured_under: '',
            address: '',
            ein: '',
            drivers: {
                drivers: [],
            },
            policy_number: '',
            total_cost: 0.0,
            status: true,
            policy_period: {
                period: [],
            }
        },
        defaultEditedItem: {
            id: '',
            insurance_name: '',
            insured_under: '',
            address: '',
            ein: '',
            drivers: {
                drivers: [],
            },
            policy_number: '',
            total_cost: 0.0,
            status: true,
            policy_period: {
                period: [],
            }
        }
    }),
    computed: {
        filteredItems() {
            if (!this.search.trim()) {
                return this.items;
            } else {
                return this.items.filter(item =>
                    item.insurance_name.toLowerCase().includes(this.search.toLowerCase())
                );
            }
        }
    },
    async mounted() {
        //console.log("-------------------MOUNTED.......................")
        bus.$emit('login-success');
        bus.$emit('permissions');

        const response = await this.fetchItems();
        console.log("response: ", response);
        /* if (response.ok) {
            this.showloader = false;
        } */
    },
    methods: {
        formatCurrency(value) {
            const number = parseFloat(value);
            return !isNaN(number) ? number.toFixed(2) : "0.00";
        },
        openInfo(item) {
            console.log("Openning info....", item);
            this.editedItem = item;
            this.dialogInfoInsurance = true;
        },
        handleInfoFromChild(info) {
            // Manejar la información recibida desde el hijo
            console.log('Información recibida desde el hijo:', info);
            this.dialogOpen = info; //Close dialog
            this.fetchItems(); //Refresh employees table
        },
        updateItem(item) {
            console.log("item: ", item)
            /* this.editedIndex = item.id;
            this.editedItem = item; */
            //this.editedIndex = this.items.indexOf(item);            
            console.log("this.editedIndex: ", this.editedIndex);
            this.editedItem = Object.assign({}, item);
            this.editedIndex = this.editedItem.id;
            console.log("this.editedIndex: ", this.editedIndex);
            console.log("<<<<<<<<<<<<<<<<<<<< this.editedItem: ", this.editedItem);
            this.dialogOpen = true;
        },
        async deleteItem(item) {
            console.log("item: ", item)
            const response = await deleteInsurance(item.id);
            if (response.ok) {
                this.fetchItems();
            }
        },
        openDialog() {
            console.log("Opening dialog.....")
            this.dialogOpen = true;
        },
        async fetchItems() {

            try {
                this.showloader = true;
                this.items = await getInsurances();
                console.log("this.items: ", this.items);
                this.showloader = false;
                return {
                    ok: true
                }
            } catch (error) {
                console.error("Error fetching: ", error);
            }
        }
    },
    watch: {
        dialogOpen(val) {
            console.log(">>>>>>>>>>>>>>>>>> val: ", val);
            if (!val) {
                this.editedItem = this.defaultEditedItem;
                this.editedIndex = -1;
                this.fetchItems();
            }
        },
        dialogInfoInsurance(val) {
            console.log(">>>>>>>>>>>>>>>>>>dialogInfoInsurance/val: ", val);
            if (!val) {
                this.editedItem = this.defaultEditedItem;
                this.editedIndex = -1;
                this.fetchItems();
            }
        },
    }
}
</script>

<style scoped>
::v-deep .loader-container {
    position: fixed;
    /* Para que el loader esté por encima de todo el contenido */
    top: 0;
    left: 0;
    width: 100%;
    /* Ocupa todo el ancho del viewport */
    height: 100%;
    /* Ocupa todo el alto del viewport */
    display: flex;
    justify-content: center;
    /* Centra horizontalmente */
    align-items: center;
    /* Centra verticalmente */
    background-color: rgba(255, 255, 255, 0.5);
    /* Fondo semitransparente */
    z-index: 9999;
    /* Coloca el loader por encima de todo el contenido */
}

::v-deep .dialog-height {
    max-height: auto !important;
    min-height: auto !important;
}

::v-deep .dialog-card {
    padding: 0;
    /* Elimina el relleno interno */
    margin: 0;
    /* Elimina los márgenes */
}
</style>