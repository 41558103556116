<template>
    <policies />
</template>

<script>
import Policies from '../../components/Employees/Policies.vue'

export default {
    name: 'policiesView',
    components: {
        Policies,
    },
}
</script>