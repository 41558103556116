<template>
    <v-container class="main-content" fluid>
        <v-row justify="center">
            <v-col cols="12" md="6">
                <v-card>
                    <v-card-title class="title-container mb-5">
                        <v-icon size="100" class="icon">mdi-chart-line</v-icon> <!-- Ícono de gráfico de líneas -->
                        <span class="title-text">NOVA Analytics</span>
                    </v-card-title>
                    <v-card-subtitle>
                        <v-form ref="form" @submit.prevent="showData">
                            <v-file-input v-model="file" label="UPLOAD DATA" accept=".xlsx" outlined required
                                class="mb-3 file-input-custom"></v-file-input>
                            <v-btn type="submit" color="primary" class="w-100">
                                Analize
                            </v-btn>
                        </v-form>
                    </v-card-subtitle>
                </v-card>
                <v-container class="mt-4">
                    <!-- Total Enrolled by Location -->
                    <v-row>
                        <v-col cols="12">
                            <v-card>
                                <v-card-title class="text-center">
                                    <h2>Total Enrolled by Location</h2>
                                </v-card-title>
                                <v-card-subtitle class="text-center">
                                    <canvas id="locationChart" width="300" height="150"></canvas>
                                    <p id="totalEnrolledSum" class="mt-3"></p> <!-- Total Enrolled -->
                                </v-card-subtitle>
                            </v-card>
                        </v-col>
                    </v-row>

                    <!-- Teens Enrolled vs Seats Open by Location -->
                    <v-row class="mt-4">
                        <v-col cols="12">
                            <v-card>
                                <v-card-title class="text-center">
                                    <h2>(Teens) Enrolled vs Seats Open by Location</h2>
                                </v-card-title>
                                <v-card-subtitle class="text-center">
                                    <canvas id="teenChart" width="400" height="200"></canvas>
                                    <p id="totalTeenEnrolledSum" class="mt-3"></p> <!-- Total 30CLS (Teens) Enrolled -->
                                </v-card-subtitle>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { bus } from '../../main.js'
import * as xlsx from 'xlsx';
import Chart from 'chart.js/auto';

export default {
    data() {
        return {
            file: null
        };
    },
    methods: {
        analize() {
            const file = this.file;
            if (!file) {
                console.log("No File");
                return;
            }

            const reader = new FileReader();
            reader.onload = (event) => {
                try {
                    const dataArray = new Uint8Array(event.target.result);
                    const workbook = xlsx.read(dataArray, { type: 'array' });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const jsonData = xlsx.utils.sheet_to_json(sheet);

                    // Inicializar totales
                    let totalEnrolledSum = 0;
                    let totalTeenEnrolledSum = 0;

                    // Preparar datos para el gráfico de Total Enrolled by Location
                    const totalEnrolledByLocation = {};
                    jsonData.forEach(row => {
                        const location = row['Location'];
                        const enrolled = row['Enrolled'] || 0;
                        totalEnrolledByLocation[location] = (totalEnrolledByLocation[location] || 0) + enrolled;
                        totalEnrolledSum += enrolled;
                    });

                    // Preparar datos para el gráfico de 30CLS (Teens) Enrolled vs Seats Open por locación
                    const teenEnrolledByLocation = {};
                    const seatsOpenByLocation = {};
                    jsonData.forEach(row => {
                        if (row['CR Type'] === '30CLS') {
                            const location = row['Location'];
                            const enrolled = row['Enrolled'] || 0;
                            const seatsOpen = row['Seats Open'] || 0;

                            if (!teenEnrolledByLocation[location]) {
                                teenEnrolledByLocation[location] = 0;
                            }
                            if (!seatsOpenByLocation[location]) {
                                seatsOpenByLocation[location] = 0;
                            }

                            teenEnrolledByLocation[location] += enrolled;
                            seatsOpenByLocation[location] += seatsOpen;
                            totalTeenEnrolledSum += enrolled;
                        }
                    });

                    // Llamar a renderCharts después de procesar los datos
                    this.renderCharts(
                        totalEnrolledByLocation,
                        totalEnrolledSum,
                        teenEnrolledByLocation,
                        seatsOpenByLocation,
                        totalTeenEnrolledSum
                    );
                } catch (error) {
                    console.error("Error processing the file:", error);
                }
            };
            reader.readAsArrayBuffer(file);
        },
        renderCharts(totalEnrolledByLocation, totalEnrolledSum, teenEnrolledByLocation, seatsOpenByLocation, totalTeenEnrolledSum) {
            // Asegúrate de que los IDs coincidan con los elementos en tu plantilla            
            const ctx1 = document.getElementById('locationChart').getContext('2d');
            new Chart(ctx1, {
                type: 'bar',
                data: {
                    labels: Object.keys(totalEnrolledByLocation),
                    datasets: [{
                        label: 'Total Enrolled by Location',
                        data: Object.values(totalEnrolledByLocation),
                        backgroundColor: 'rgba(75, 192, 192, 0.6)',
                        borderColor: 'rgba(75, 192, 192, 1)',
                        borderWidth: 1
                    }]
                },
                options: {
                    responsive: true,
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            });

            document.getElementById('totalEnrolledSum').textContent = `Total Enrolled: ${totalEnrolledSum || 0}`;

            const ctx2 = document.getElementById('teenChart').getContext('2d');
            new Chart(ctx2, {
                type: 'bar',
                data: {
                    labels: Object.keys(teenEnrolledByLocation),
                    datasets: [
                        {
                            label: 'Enrolled',
                            data: Object.values(teenEnrolledByLocation),
                            backgroundColor: 'rgba(153, 102, 255, 0.6)',
                            borderColor: 'rgba(153, 102, 255, 1)',
                            borderWidth: 1
                        },
                        {
                            label: 'Seats Open',
                            data: Object.values(seatsOpenByLocation),
                            backgroundColor: 'rgba(255, 159, 64, 0.6)',
                            borderColor: 'rgba(255, 159, 64, 1)',
                            borderWidth: 1
                        }
                    ]
                },
                options: {
                    responsive: true,
                    scales: {
                        x: {
                            stacked: true,
                        },
                        y: {
                            stacked: true,
                            beginAtZero: true,
                        }
                    }
                }
            });

            document.getElementById('totalTeenEnrolledSum').textContent = `Total 30CLS (Teens) Enrolled: ${totalTeenEnrolledSum || 0}`;
        },
        showData() {
            this.analize();
        }
    },
    mounted() {
        bus.$emit('login-success');
        bus.$emit('permissions');
    },
};
</script>

<style scoped>
.title-container {
    display: flex;
    align-items: center;
    /* Alinea verticalmente el ícono y el texto al centro */
    justify-content: center;
    /* Centra horizontalmente el ícono y el texto */
    text-align: center;
}

.icon {
    color: #d500f9 !important;
    /* Color fucsia forzado */
}

.title-text {
    font-weight: bold;
    /* Negrita */
    color: black;
    /* Color negro */
    font-size: 32px;
    /* Tamaño mayor del título */
    margin-left: 16px;
    /* Espacio entre ícono y texto */
}

.file-input-custom {
    max-width: 300px;
    width: 100%;
}

.main-content {
    padding: 20px;
}
</style>