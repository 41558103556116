<template>
    <v-row class="pt-4 px-4">
        <v-col cols="12" sm="12" md="12">
            <v-card height="800">
                <v-card-text>
                    <v-toolbar flat>
                        <v-toolbar-title class="font-weight-bold">Zoho Currency</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-text-field v-model="search" label="Search by Account Code" solo-inverted
                            prepend-icon="mdi-magnify" class="mr-3 mt-5"></v-text-field>
                        <v-btn color="blue" dark class="mb-2 mr-2" @click="getItems">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-data-table :headers="headers" :items="filteredCharts" item-key="id" class="elevation-1"
                        :search="search" :items-per-page="10" sort-by="account_code">
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { bus } from '../../../main.js';
import { fetchAllCurrencies } from '../../../utils/Accounting/ZOHO/currency_CRUD.js'

export default {
    data: () => ({
        dialogOpen: false,
        search: '',
        currencies: [],
        headers: [
            { text: 'Id', value: 'currency_id' },
            { text: 'Name', value: 'currency_name' },
        ],
    }),
    async mounted() {
        bus.$emit('login-success');
        bus.$emit('permissions');
        this.getItems();
    },
    methods: {
        handleInfoFromChild(info) {
            // Manejar la información recibida desde el hijo
            console.log('Información recibida desde el hijo:', info);
            this.dialogOpen = info; //Close dialog
            this.getItems(); //Refresh employees table
        },
        async getItems() {
            this.currencies = await fetchAllCurrencies();
        },
        openDialog(item) {
            console.log("Opening dialog....");
            console.log("Item: ", item);
            this.dialogOpen = true;
        },
    },
    computed: {
        filteredCharts() {
            if (!this.search.trim()) {
                return this.currencies;
            } else {
                return this.currencies.filter(currency =>
                    currency.currency_name.toLowerCase().includes(this.search.toLowerCase())
                );
            }
        }
    },
}
</script>