<template>
    <v-row class="pt-4 px-4">
        <v-col cols="12" sm="12" md="12">
            <v-card height="800">
                <v-card-text>
                    <v-toolbar flat>
                        <v-toolbar-title class="font-weight-bold">Zoho Chart of Accounts (Expense
                            Type)</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-text-field v-model="search" label="Search by Account Code" solo-inverted
                            prepend-icon="mdi-magnify" class="mr-3 mt-5"></v-text-field>
                        <v-btn color="blue" dark class="mb-2 mr-2" @click="getItems">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-data-table :headers="headers" :items="filteredCharts" item-key="id" class="elevation-1"
                        :search="search" :items-per-page="10" sort-by="account_code">
                        <template v-slot:items="props">
                            <td>{{ props.item.account_anme }}</td>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { bus } from '../../../main.js';
import { fetchZohoChartOfAccounts } from '../../../utils/Accounting/ZOHO/zoho_chart_of_accounts_CRUD.js'


export default {
    data: () => ({
        dialogOpen: false,
        search: '',
        charts: [],
        headers: [
            { text: 'Account ID', value: 'account_id' },
            { text: 'Account Code', value: 'account_code' },
            { text: 'Account Name', value: 'account_name' },
            { text: 'Account Type', value: 'account_type' },
            { text: 'Description', value: 'description' },
            { text: 'Mileage Rate', value: 'mileage_rate' },
            { text: 'Mileage Unit', value: 'mileage_unit' },
            { text: 'Is Mileage', value: 'is_mileage' },
            { text: 'Account Number', value: 'account_number' },
            { text: 'Currency', value: 'currency' },
            { text: 'Parent Account', value: 'parent_account' },
        ],
    }),
    async mounted() {
        //console.log("-------------------MOUNTED.......................")
        bus.$emit('login-success');
        bus.$emit('permissions');
        this.getItems();
    },
    methods: {
        handleInfoFromChild(info) {
            // Manejar la información recibida desde el hijo
            console.log('Información recibida desde el hijo:', info);
            this.dialogOpen = info; //Close dialog
            this.getItems(); //Refresh employees table
        },
        async getItems() {
            this.charts = await fetchZohoChartOfAccounts();
            console.log("this.charts: ", this.charts);
        },
    },
    computed: {
        filteredCharts() {
            if (!this.search.trim()) {
                return this.charts;
            } else {
                return this.charts.filter(chart =>
                    chart.account_name.toLowerCase().includes(this.search.toLowerCase()) ||
                    (chart.account_code && chart.account_code.toLowerCase().includes(this.search.toLowerCase()))
                );
            }
        }
    },
    watch: {
        dialogOpen(val) {
            console.log("val: ", val);
            if (!val) {
                this.editedItem = this.defaultEditedItem;
                this.editedIndex = -1;
            }
        }
    }
}
</script>