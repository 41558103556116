<template>
    <computer-equipment-assignation />
</template>

<script>
import ComputerEquipmentAssignation from '../../components/It/ComputerEquipmentAssignation.vue'

export default {
    name: 'ComputerEquipmentAssignationView',
    components: {
        ComputerEquipmentAssignation,
    },
}
</script>