<template>
    <zoho-currency />
</template>

<script>
import ZohoCurrency from '../../../components/Accounting/Zoho/ZohoCurrency.vue'

export default {
    name: 'ZohoCurrencyView',
    components: {
        ZohoCurrency,
    },
}
</script>