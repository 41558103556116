<template>
    <div>
        <v-data-table :headers="headers" :items="filteredTrainingLogs" sort-by="trainee_name" class="elevation-1"
            @click:row="redirect">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title class="font-weight-bold">Trainer Training Log Console</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-select v-model="logsFilter" :items="status" @input="getLogs"
                        style="max-width: 220px; margin-top: 30px; margin-right: 10px;"
                        label="Filter Complete/Incomplete"></v-select>
                    <v-text-field v-model="search" label="Search" solo-inverted prepend-icon="mdi-magnify"
                        class="mr-3 mt-5"></v-text-field>
                    <v-btn color="blue" dark class="mb-2 mr-2" @click="refreshForms">
                        <v-icon left>mdi-refresh</v-icon>
                        Refresh
                    </v-btn>
                </v-toolbar>
            </template>
        </v-data-table>
        <div class="align-center">
            <v-btn color="success" @click="openForm" class="custom-btn">
                <v-icon style="font-size: 40px">mdi-plus</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
import { get_employee_number } from '../../utils/get_employee_number.js'
import { GET_TRAINING_LOG_BY_EMPLOYEE_NUMBER_STATUS } from '../../utils/endpoints';
import { bus } from '../../main.js';

export default {
    data: () => ({
        logsFilter: 'Incomplete',
        status: ['Complete', 'Incomplete'],
        formIndex: -1,
        editedIndex: -1,
        search: '',
        headers: [
            { text: 'Trainee`s name', value: 'trainee_name' },
            { text: 'Trainee`s last name', value: 'trainee_lastName' },
            { text: 'UUID', value: 'uuid' },
        ],
        trainingLogs: [],

        employee_number: '',


        editedItem: {
            history: '',
            id: null,
            status: false,
            trainee_lastName: '',
            trainee_name: '',
            uuid: '',
        }

    }),
    computed: {
        filteredTrainingLogs() {
            if (this.trainingLogs) {
                return this.trainingLogs.filter(trainingLog =>
                    trainingLog.trainee_name.toLowerCase().includes(this.search.toLowerCase())
                );
            }
            else {
                return [];
            }
        },
    },
    async mounted() {
        console.log("-------------------MOUNTED.......................")
        bus.$emit('login-success');
        bus.$emit('permissions');
        //this.get_EmployeeData();
        this.employee_number = get_employee_number();
        //this.get_TrainingLog_Forms();

        let forms = await this.get_TrainingLog_Forms('Incomplete');
        console.log("forms: ", forms);
        this.trainingLogs = forms;

        /* setTimeout(() => {
            (async () => {
                this.get_TrainingLog_Forms();
            })();
        }, 1000); */
    },
    methods: {
        async getLogs(item) {
            console.log("Filtering......", item);
            this.logsFilter = item;
            if (item === 'Incomplete') {
                this.trainingLogs = await this.get_TrainingLog_Forms('Incomplete');
            } else {
                this.trainingLogs = await this.get_TrainingLog_Forms('Complete');
            }
        },
        redirect(item) {
            this.editedIndex = this.trainingLogs.indexOf(item);
            console.log(this.editedIndex);
            this.editedItem = Object.assign({}, item);
            console.log("**************************this.editedItem : ", this.editedItem)
            const dataForm = this.editedItem;
            this.$router.push({ name: 'training-log', params: { dataForm, editedIndex: this.editedIndex } });
        },
        /* async get_EmployeeData() {
            try {

                this.employee_number = get_employee_number();
                console.log("employee_number: ", this.employee_number);
                const url = `${GET_EMPLOYEE_BY_EMPLOYEE_NUMBER}?employee_number=${this.employee_number}`;
                console.log("url: ", url);

                const response = await fetch(url); // Replace "your_endpoint" with the URL of your endpoint
                if (!response.ok) {
                    throw new Error('Failed to fetch record');
                }
                const data = await response.json();
                console.log("data: ", data);
                console.log("name: ", data.name);
                console.log("last_name: ", data.last_name);
                return data; // Returns the object retrieved from the endpoint
            } catch (error) {
                console.error('Error fetching record:', error);
                throw error;
            }
        }, */
        async refreshForms() {
            this.trainingLogs = await this.get_TrainingLog_Forms(this.logsFilter);
        },
        async get_TrainingLog_Forms(filter) {
            try {
                let status = null;
                if (filter === 'Incomplete' || filter === '') {
                    status = false;
                } else {
                    status = true;
                }
                console.log("Getting training logs.....")
                console.log("employee_number: ", this.employee_number);
                const url = `${GET_TRAINING_LOG_BY_EMPLOYEE_NUMBER_STATUS}${this.employee_number}&status=${status}`;
                console.log("url: ", url);

                const response = await fetch(url); // Replace "your_endpoint" with the URL of your endpoint
                if (!response.ok) {
                    throw new Error('Failed to fetch record');
                }
                const res = await response.json();
                //return res;
                //this.trainingLogs = res;
                console.log("res get_TrainingLog_Forms: ", res);

                return res;

                /* if (res) {
                    console.log("Records......")
                    console.log("res", res);
                    return res;
                } else {
                    console.log("NO records")
                    return [];
                } */
                //this.trainingLogs = await response.json();
                //console.log("this.trainingLogs: ", this.trainingLogs);
                //return this.trainingLogs; // Returns the object retrieved from the endpoint
            } catch (error) {
                console.error('Error fetching record:', error);
                //throw error;
            }
        },
        openForm(item) {
            console.log("Opening form......")
            this.$router.push({ name: 'training-log' });

            // Imprimir un mensaje en la consola cuando se hace clic en el botón
            /*  console.log("this.employee_number: ", this.employee_number)
             console.log('Opening new form');
             this.editedIndex = this.trainingLogs.indexOf(item);
             console.log(this.editedIndex);
             this.editedItem = Object.assign({}, item);
             console.log("**********************");
             console.log(this.editedItem);
             console.log("**********************"); */
        },
    },
}
</script>

<style scoped>
.custom-btn {
    margin: 20px auto;
    /* Centra el botón verticalmente */
    width: 70px;
    /* Tamaño cuadrado del botón */
    height: 70px !important;
    padding-top: 10px;
    /* Añade separación superior */
}

.align-center {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    height: 100%;
}
</style>