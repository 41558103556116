import {ZOHO_CHART_OF_ACCOUNTS} from '../../endpoints'

export async function fetchZohoChartOfAccounts() {
    try {
        // Hacer la solicitud al endpoint
        const response = await fetch(ZOHO_CHART_OF_ACCOUNTS, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        // Verificar si la respuesta es exitosa
        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }

        // Convertir la respuesta a JSON
        const data = await response.json();

        // Verificar si la solicitud fue exitosa
        if (data.success) {
            console.log('Data:', data.data);  // Aquí puedes manejar los datos obtenidos
            return data.data;
        } else {
            console.error('Error fetching data:', data.error);
        }

    } catch (error) {
        console.error('Fetch error:', error);
    }
}
