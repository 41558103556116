import { GET_PUBLIC_URL } from '../utils/endpoints';

export async function generatePublicURL(folderName, fileName) {
    console.log("<------------------- generatePublicURL");
    console.log("folderName: ", folderName);

      // Objeto JSON a enviar en el cuerpo de la solicitud
      const data = {
        folder_name: folderName,
        file_name: fileName
      };
      console.log("data: ", data);

      // URL del endpoint
      // const url = 'http://tu_dominio/s3/publicurl';

      // Opciones de la solicitud
      const options = {
        method: 'POST', // Método POST
        headers: {
          'Content-Type': 'application/json' // Tipo de contenido JSON
        },
        body: JSON.stringify(data) // Convertir el objeto JSON a una cadena y enviarlo en el cuerpo de la solicitud
      };

      try {
        // Realizar la solicitud al endpoint utilizando fetch
        const response = await fetch(GET_PUBLIC_URL, options);
        console.log("response-generateURL: ", response);
        if (!response.ok) {
          //throw new Error('Error en la solicitud');
          console.error(new Error(`Error en la solicitud: ${fileName}`));
          return response;
        }
        // Devolver la respuesta en formato JSON
        const responseData = await response.json();
        console.log(`<---------------- publicURL-${fileName}: `, responseData);
        return responseData; // Devolver la URL generada
      } catch (error) {
        // Capturar cualquier error y mostrarlo en la consola
        console.error('Error:', error.message);
        throw error; // Lanzar el error para que sea manejado por el código que llama a generatePublicURL
      }
}