<template>
    <credit-card-car />
</template>

<script>
import CreditCardCar from '../../components/Accounting/CreditCardCar.vue'

export default {
    name: 'CreditCardCarView',
    components: {
        CreditCardCar,
    },
}
</script>