<template>
    <type-of-coverage />
</template>

<script>
import TypeOfCoverage from '../../../components/Fleet/Insurance/TypeOfCoverage.vue'

export default {
    name: 'TypeOfCoverageView',
    components: {
        TypeOfCoverage,
    },
}
</script>