<template>
    <email-validator />
</template>

<script>
import EmailValidator from '../../components/Marketing/EmailValidator.vue'

export default {
    name: 'EmailValidatorView',
    components: {
        EmailValidator,
    },
}
</script>