<template>
    <instructors />
</template>

<script>
import Instructors from '../../components/Fleet/Instructors.vue'

export default {
    name: 'InstructorsView',
    components: {
        Instructors,
    },
}
</script>