<template>
    <v-row class="pt-4 px-4">
        <v-col cols="12" sm="12" md="12">
            <v-card height="800">
                <v-card-text>
                    <v-toolbar flat>
                        <v-toolbar-title class="font-weight-bold">Zoho Vendors (Suppliers)</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-text-field v-model="search" label="Search by Account Code" solo-inverted prepend-icon="mdi-magnify"
                            class="mr-3 mt-5"></v-text-field>
                        <v-btn color="blue" dark class="mb-2 mr-2" @click="getItems">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                        <v-btn color="success" dark class="mb-2 mr-2" @click="openDialog(null)">
                            <v-icon>mdi-plus-thick</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-data-table :headers="headers" :items="filteredCharts" item-key="id" class="elevation-1"
                        :search="search" :items-per-page="10" sort-by="account_code">
                        <template v-slot:items="props">
                            <td>{{ props.item.account_anme }}</td>
                        </template>
                        <!-- <template v-slot:item.actions="{ item }">
                            <v-icon small class="mr-2" :style="{ color: 'blue' }" @click="openDialog(item)">
                                mdi-pencil
                            </v-icon>
                            <v-icon small :style="{ color: 'red' }" @click="deleteItem(item)">
                                mdi-delete
                            </v-icon>
                        </template> -->
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-col>
        <v-dialog v-model="dialogOpen" max-width="700" :key="dialogOpen.toString()">
            <v-card class="dialog-card" outlined>
                <ZohoVendorsCard @sent-info="handleInfoFromChild" />
            </v-card>
        </v-dialog>
        <div class="loader-container" v-if="showLoader">
            <div class="loader"></div>
        </div>
    </v-row>
</template>

<script>
import { bus } from '../../../main.js';
import { deleteChartOfAccount, getAllChartOfAccounts } from '../../../utils/chart_of_accounts_CRUD.js'
import ZohoVendorsCard from '../../Accounting/Zoho/ZohoVendorsCard.vue';
import { fetchZohoVendors } from '../../../utils/Accounting/ZOHO/vendors_CRUD.js'

export default {
    components: {
        ZohoVendorsCard
    },
    data: () => ({
        showLoader: false,
        dialogOpen: false,
        search: '',
        vendors: [],
        headers: [
            { text: 'Id', value: 'contact_address_id' },
            { text: 'Name', value: 'contact_name' },
        ],
    }),
    async mounted() {
        //console.log("-------------------MOUNTED.......................")
        bus.$emit('login-success');
        bus.$emit('permissions');
        this.getItems();
    },
    methods: {
        handleInfoFromChild(info) {
            // Manejar la información recibida desde el hijo
            console.log('Información recibida desde el hijo:', info);
            this.dialogOpen = info; //Close dialog
            this.getItems(); //Refresh employees table
        },
        async getItems() {
            this.showLoader = true;
            this.vendors = await fetchZohoVendors();
            this.showLoader = false;
        },
        openDialog(item) {
            console.log("Opening dialog....");
            console.log("Item: ", item);
            this.dialogOpen = true;
        },
    },
    computed: {
        filteredCharts() {
            if (!this.search.trim()) {
                return this.vendors;
            } else {
                return this.vendors.filter(vendor =>
                    vendor.contact_name.toLowerCase().includes(this.search.toLowerCase())
                );
            }
        }
    },
}
</script>

<style scoped>
.loader-container {
    position: fixed;
    /* Para que el loader esté por encima de todo el contenido */
    top: 0;
    left: 0;
    width: 100%;
    /* Ocupa todo el ancho del viewport */
    height: 100%;
    /* Ocupa todo el alto del viewport */
    display: flex;
    justify-content: center;
    /* Centra horizontalmente */
    align-items: center;
    /* Centra verticalmente */
    background-color: rgba(255, 255, 255, 0.5);
    /* Fondo semitransparente */
    z-index: 9999;
    /* Coloca el loader por encima de todo el contenido */
}

.loader {
    width: 120px;
    height: 60px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    background: #fff;
    filter: blur(5px) contrast(10) hue-rotate(60deg);
    mix-blend-mode: darken;
}

.loader:before,
.loader:after {
    content: "";
    width: 40px;
    border-radius: 50%;
    background: #ff00ff;
    animation: l3 1s infinite alternate;
}

.loader:after {
    --s: -1;
}

@keyframes l3 {

    90%,
    100% {
        transform: translate(calc(var(--s, 1)*30px))
    }
}
</style>