import { UPLOAD_POLICIES } from './endpoints.js';

export async function uploadPolicy(file) {
    console.log("Uploadin files......")
    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await fetch(UPLOAD_POLICIES, {
            method: 'PUT',
            body: formData
        });

        console.log("response: ", response);

        const data = await response.json();
        console.log("Data: ", data);

        if (response.ok) {
            console.log(`Política subido exitosamente: ${file}`, data.message);
            //this.clearFilesFields();
            return response;
        } else {
            console.error(`Error al subir política: ${file}`, data.error);
        }
    } catch (error) {
        console.error(`Error en la solicitud/política: ${file}`, error);
    }
}