import { ADD_NEW_TRAINING_LOG, NEW_GET_TRAINING_LOG_BY_EMPLOYEE_NUMBER_CLOSED, NEW_UPDATE_TRAINING_LOG, NEW_TRAINING_LOGS_FETCH, GET_TRAINING_LOG_EMPLOYEE, NEW_UPDATE_OBSERVATIONS, NEW_GET_OBSERVATIONS, DELETE_TRAINING_LOG_EMPLOYEE, DELETE_TRAINING_LOG, UPDATE_STATUS_TRAINING_LOG, UPDATE_CLOSED_TRAINING_LOG, TRAINING_LOG_REPORT_PDF,GET_NEW_TRAINING_LOG_CLOSED_STATUS } from '../../utils/endpoints'

export async function createTrainingLog(employeeNumber, requestData) {
    const url = `${ADD_NEW_TRAINING_LOG}${employeeNumber}`;

    /* const requestData = {
        trainee: traineeData,
        data: logData
    }; */

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        });

        if (response.ok) {
            //const result = await response.json();
            //console.log('Training log created successfully:', result);
            return response;
        } else {
            const errorData = await response.json();
            console.error('Error creating training log:', errorData);
            throw new Error(errorData.error || 'Failed to create training log');
        }
    } catch (error) {
        console.error('Error:', error);
    }
}

/* This function is form the new training log */
export async function new_get_TrainingLog_Forms(filter, employee_number) {
    try {
        let closed = null;
        if (filter === 'Not Closed') {
            closed = false;
        } else {
            closed = true;
        }
        console.log("Getting training logs..... ", closed)
        //console.log("employee_number: ", this.employee_number);
        const url = `${NEW_GET_TRAINING_LOG_BY_EMPLOYEE_NUMBER_CLOSED}${employee_number}&closed=${closed}`;
        console.log("url: ", url);

        const response = await fetch(url); // Replace "your_endpoint" with the URL of your endpoint
        if (!response.ok) {
            throw new Error('Failed to fetch record');
        }
        const res = await response.json();
        //return res;
        //this.trainingLogs = res;
        console.log("res get_TrainingLog_Forms: ", res);

        return res;
    } catch (error) {
        console.error('Error fetching record:', error);
        //throw error;
    }
}

export async function updateTrainingLog(data, uuid) {
    //const url = `/new_trainingLog/update/${log_uuid}`;

    // Crear el cuerpo de la solicitud
    /* const payload = {
        employee_number: employee_number,
        data: data,
        trainee: trainee,
        type_of_log: type_of_log
    }; */

    try {
        const response = await fetch(`${NEW_UPDATE_TRAINING_LOG}/${uuid}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data), // Convertir los datos a JSON
        });

        if (!response.ok) {
            // Si no fue exitosa, lanzar un error con el estado de la respuesta
            throw new Error(`Error: ${response.status}`);
        }

        const responseData = await response.json(); // Obtener la respuesta como JSON
        console.log('Training log updated successfully:', responseData);

        return response; // Devolver los datos de la respuesta si es necesario
    } catch (error) {
        console.error('Error updating training log:', error);
        throw error; // Lanza el error para que pueda ser manejado por quien llame a esta función
    }
}

export async function fetchLogs(closed, logType) {
    console.log("closed: ", closed);
    console.log("logType: ", logType);
    const url = new URL(NEW_TRAINING_LOGS_FETCH);

    // Añadir los parámetros a la URL
    if (closed !== undefined) {
        //url.searchParams.append('status', status);
        url.searchParams.append('closed', closed);
    }
    if (logType !== undefined) {
        url.searchParams.append('log_type', logType);
    }

    console.log("url: ", url);
    try {
        // Realizar la solicitud GET al endpoint
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        // Verificar si la respuesta es exitosa
        if (!response.ok) {
            throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }

        // Convertir la respuesta a JSON
        const logs = await response.json();

        // Devolver los datos obtenidos
        return logs;

    } catch (error) {
        // Manejar cualquier error en la solicitud
        console.error('Error fetching logs:', error);
        return null;
    }
}

export async function getTrainingLogsByUUID(uuid) {
    const url = `${GET_TRAINING_LOG_EMPLOYEE}/${uuid}`;
    console.log("url: ", url);

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }

        const data = await response.json();
        if (data.training_logs) {
            console.log('Training Logs:', data.training_logs);
            return data.training_logs;
        } else {
            console.log('Message:', data.message);
            return null;
        }

    } catch (error) {
        console.error('Fetch error:', error);
    }
}

export async function updateObservations(uuid, observations) {
    const url = `${NEW_UPDATE_OBSERVATIONS}${uuid}`;
    console.log("url: ", url);
    console.log("uuid: ", uuid);
    console.log("observations: ", observations);

    const headers = {
        'Content-Type': 'application/json',
    };

    const body = JSON.stringify({
        observation: observations
    });

    try {
        const response = await fetch(url, {
            method: 'PUT',
            headers: headers,
            body: body
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error:', errorData);
            //return { success: false, message: errorData.error };
            return response
        }

        const result = await response.json();
        console.log('Success:', result);
        //return { success: true, data: result };
        return response;
    } catch (error) {
        console.error('Error updating observations:', error);
        return { success: false, message: 'An error occurred while updating observations.' };
    }
}

export async function getObservations(uuid) {
    const url = `${NEW_GET_OBSERVATIONS}${uuid}`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error:', errorData);
            return { success: false, message: errorData.error };
        }

        const result = await response.json();
        console.log('Observations:', result.observations);
        //return { success: true, data: result.observations };
        return result.observations;
    } catch (error) {
        console.error('Error fetching observations:', error);
        return { success: false, message: 'An error occurred while fetching observations.' };
    }
}

export async function deleteTrainingLogEmployee(uuid) {
    const url = `${DELETE_TRAINING_LOG_EMPLOYEE}${uuid}`;

    try {
        const response = await fetch(url, {
            method: 'DELETE',
        });

        if (response.ok) {
            const data = await response.json();
            console.log(`Training log with UUID ${uuid} deleted successfully.`, data);
            return response;
        } else if (response.status === 404) {
            console.log(`Training log with UUID ${uuid} not found.`);
        } else {
            const errorData = await response.json();
            console.log(`Failed to delete training log. Status code: ${response.status}, Message: ${errorData.message}`);
        }
    } catch (error) {
        console.error(`An error occurred: ${error}`);
    }
}


export async function deleteTrainingLog(uuid) {
    console.log("deleteTrainingLog - ", uuid);
    const url = `${DELETE_TRAINING_LOG}${uuid}`;

    try {
        const response = await fetch(url, {
            method: 'DELETE',
        });

        if (response.ok) {
            const data = await response.json();
            console.log(`Training log with UUID ${uuid} deleted successfully.`, data);
            console.log("response - ", response);
            return response;
        } else if (response.status === 404) {
            console.log(`Training log with UUID ${uuid} not found.`);
        } else {
            const errorData = await response.json();
            console.log(`Failed to delete training log. Status code: ${response.status}, Message: ${errorData.message}`);
        }
    } catch (error) {
        console.error(`An error occurred: ${error}`);
    }
}


export async function updateStatus(uuid, newStatus) {
    const url = `${UPDATE_STATUS_TRAINING_LOG}${uuid}`;

    try {
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ status: newStatus })
        });

        if (response.ok) {
            const data = await response.json();
            console.log(`Status updated successfully for UUID ${uuid}.`, data);
            return response;
        } else if (response.status === 404) {
            console.log(`Training log with UUID ${uuid} not found.`);
        } else {
            const errorData = await response.json();
            console.log(`Failed to update status. Status code: ${response.status}, Message: ${errorData.message}`);
        }
    } catch (error) {
        console.error(`An error occurred: ${error}`);
    }
}

export async function updateClosed(uuid, newClosedStatus) {
    const url = `${UPDATE_CLOSED_TRAINING_LOG}${uuid}`;
    console.log("url - ", url);

    try {
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ closed: newClosedStatus })
        });

        if (response.ok) {
            const data = await response.json();
            console.log(`Closed status updated successfully for UUID ${uuid}.`, data);
            return response;
        } else if (response.status === 404) {
            console.log(`Training log with UUID ${uuid} not found.`);
        } else {
            const errorData = await response.json();
            console.log(`Failed to update closed status. Status code: ${response.status}, Message: ${errorData.message}`);
        }
    } catch (error) {
        console.error(`An error occurred: ${error}`);
    }
}

export async function sendEvaluationData(data) {
    try {
        console.log("sendEvaluationData/data: ", data);
        
        // Hacer la solicitud POST al endpoint
        const response = await fetch(TRAINING_LOG_REPORT_PDF, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        // Comprobar si la solicitud fue exitosa
        if (response.ok) {
            response.headers.forEach((value, key) => {
                console.log(`<< ${key}: ${value}`);
            });
            // Obtener el nombre del archivo desde el encabezado de la respuesta
            const contentDisposition = response.headers.get('Content-Disposition');
            console.log("contentDisposition = ", contentDisposition);
            let fileName = 'training_report.pdf'; // Nombre predeterminado

            if (contentDisposition) {                
                // Extraer el nombre del archivo del encabezado
                const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
                if (matches != null && matches[1]) {
                    fileName = matches[1].replace(/['"]/g, ''); // Eliminar comillas
                }
            }

            // Convertir la respuesta en un blob
            const blob = await response.blob();
            console.log("blob = ", blob)
            
            // Crear una URL para el blob
            const url = window.URL.createObjectURL(blob);

            // Crear un enlace temporal para descargar el archivo
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = fileName; // Usar el nombre del archivo obtenido
            document.body.appendChild(a);
            a.click(); // Simular el clic para descargar el archivo

            // Liberar la URL del blob
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a); // Limpiar el DOM

            return true;
        } else {
            console.error('Error al hacer la solicitud:', response.statusText);
            alert('Error al descargar el archivo. Intenta nuevamente.'); // Mensaje al usuario
        }
    } catch (error) {
        console.error('Error:', error);
        alert('Ocurrió un error inesperado.'); // Mensaje al usuario
    }
}

export async function fetchTrainingLogsClosedStatus(closed, status) {
    try {
        // Construir la URL con los parámetros de consulta dinámicos
        let url = GET_NEW_TRAINING_LOG_CLOSED_STATUS;
        const params = new URLSearchParams();
        console.log("closed: ", closed)
        console.log("status: ", status)

        if (closed !== undefined) {
            params.append('closed', closed);
        }
        if (status !== undefined) {
            params.append('status', status);
        }

        // Si hay parámetros, agregar a la URL
        if (params.toString()) {
            url += `?${params.toString()}`;
        }

        // Realizar la solicitud GET
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        // Verificar si la respuesta es correcta
        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }

        // Convertir la respuesta a JSON
        const data = await response.json();

        // Retornar los datos recibidos
        return data;
    } catch (error) {
        console.error('Error al obtener los logs:', error);
        return null;
    }
}

/* export async function sendEvaluationData(data) {
    // Crear el JSON con los datos a enviar    
  
    try {
      // Hacer la solicitud POST al endpoint
      const response = await fetch(TRAINING_LOG_REPORT_PDF, {
        method: 'POST', // Método POST
        headers: {
          'Content-Type': 'application/json', // Tipo de contenido
        },
        body: JSON.stringify(data), // Convertir los datos a JSON para enviarlos
      });
  
      // Comprobar si la solicitud fue exitosa
      if (response.ok) {
        const result = await response.json();
        console.log('Respuesta del servidor:', result);
        return response;
      } else {
        console.error('Error al hacer la solicitud:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  } */