<template>
    <div>
        <v-card>
            <v-card-title>
                <h2 class="color-text">{{ editedItem.insurance_name }}</h2>
                <v-spacer></v-spacer>
                <v-btn icon color="blue" class="large-btn">
                    <v-icon @click="refreshData()">mdi-refresh</v-icon>
                </v-btn>
                <v-btn icon color="orange" class="large-btn">
                    <v-icon @click="updateItem()">mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon color="green" class="large-btn">
                    <v-icon @click="addInsuranceCar()">mdi-plus</v-icon>
                </v-btn>
                
            </v-card-title>

            <v-card-text>
                <v-divider class="mb-4" style="border-width: 1px;"></v-divider>
                <v-row>
                    <v-col>
                        <!-- Insured Under -->
                        <div><strong>Insured Under: </strong>{{ editedItem.insured_under }}</div>

                        <!-- Address -->
                        <div><strong>Address: </strong> {{ editedItem.address }}</div>

                        <!-- EIN -->
                        <div><strong>EIN: </strong>{{ editedItem.ein }}</div>

                        <!-- Policy Number -->
                        <div><strong>Policy Number: </strong> {{ editedItem.policy_number }}</div>

                        <!-- Policy Period -->
                        <div>
                            <strong>Policy Period: </strong> {{ latestPolicyPeriod.startDate }} - {{
                                latestPolicyPeriod.endDate
                            }}
                        </div>
                        <!-- Total Cost -->
                        <div><strong>Total Cost: </strong> $ {{ editedItem.total_cost.toFixed(2) }}</div>

                        <!-- Drivers -->
                        <div>
                            <strong>Drivers:</strong>
                            <ul>
                                <li v-for="(driver, index) in editedItem.drivers.drivers" :key="index">
                                    {{ driver }}
                                </li>
                            </ul>
                        </div>
                    </v-col>
                </v-row>
                <v-divider class="mt-4 mb-6" style="border-width: 1px;"></v-divider><!-- class="mt-4 mb-6" -->
                <!-- VEHICLES CARDS -->
                <v-row class="justify-center">
                    <v-col v-for="(vehicle, index) in vehicles" :key="index" cols="12" md="4">
                        <v-card style="height: 100%; overflow-y: auto;">
                            <v-card-title><!-- class="color-title" -->
                                <!-- {{ vehicle.car_assigned.name }} -->
                                <v-col>
                                    <v-row>
                                        <span style="color: #FF009D">{{ vehicle.car_assigned }}</span>
                                        <span>&nbsp;/&nbsp;</span>
                                        <span style="color: #0000FF">{{ getVehicleProperty(vehicle.car_assigned, 'year')
                                            }}</span>
                                        <!-- <span>&nbsp;-&nbsp;</span> -->
                                        <!-- <span style="color: black">{{ getVehicleProperty(vehicle.car_assigned,
                                            'driver_name')
                                            }}</span> -->
                                        <v-spacer></v-spacer>
                                        <v-btn icon
                                            :class="{ 'purple--text': vehicle.insurance_file === true, 'gray--text': !vehicle.insurance_file }"><!-- color="purple" -->
                                            <v-icon
                                                @click="openModal('Insurance', vehicle.file_name, 'insurance_file', vehicle)">mdi-file</v-icon>
                                        </v-btn>
                                        <v-btn icon color="orange" class="large-btn">
                                            <v-icon @click="updateInsuranceCar(vehicle)">mdi-pencil</v-icon>
                                        </v-btn>
                                        <v-btn icon color="red" class="large-btn">
                                            <v-icon @click="deleteVehicle(vehicle)">mdi-delete</v-icon>
                                        </v-btn>
                                    </v-row>
                                    <v-row>
                                        <span style="color: black">{{ getVehicleProperty(vehicle.car_assigned,
                                            'driver_name')
                                            }}</span>
                                    </v-row>
                                </v-col>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <template>
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left"
                                                        style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                        Coverage
                                                    </th>
                                                    <th class="text-left"
                                                        style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                        Description
                                                    </th>
                                                    <th class="text-left"
                                                        style="color: #000; font-weight: bold !important; font-size: 14px;">
                                                        Price
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in vehicle.coverage.type" :key="item.type">
                                                    <td style="white-space: normal; word-wrap: break-word;">
                                                        {{ item.type }}</td>
                                                    <td style="white-space: normal; word-wrap: break-word;">{{
                                                        item.description }}</td>
                                                    <td>$ {{ parseFloat(item.price).toFixed(2) }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </template>
                                <v-divider></v-divider>
                                <div class="text-right mt-4">
                                    <strong style="color: #000; font-weight: bold;">Total Cost:</strong> $ {{
                                        vehicle.total_cost.toFixed(2) }}
                                </div>
                            </v-card-text>
                            <file-modal :modalOpen.sync="modalOpen" :title="modalTitle"
                                :dataFileUrl="dataFileUrl"></file-modal>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-dialog v-model="dialogOpen" max-width="700">
            <v-card class="dialog-card" outlined>
                <InsuranceCard :Item="propEditedItem" :key="dialogOpen.toString()" :Index="editedIndex"
                    @sent-info="handleInfoFromChild" />
                <!-- @sent-info="handleInfoFromChild"  -->
            </v-card>
        </v-dialog>
        <v-dialog v-model="breakdownDialog" max-width="700">
            <v-card class="dialog-card" outlined>
                <InsuranceBreakdownCard :Item="propEditedItem" :Index="editedIndex" :TypeAction="type"
                    :key="breakdownDialog.toString()" @sent-info="handleInfoFromChild" :ItemInsurance="ItemInsurance"
                    :ItemVehicle="ItemVehicle" /><!-- :Item="propEditedItem" -->
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="550px" :key="dialogDelete.toString()">
            <v-card>
                <v-card-title class="text-h5">Confirm removal of the following vehicle from active
                    status?</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-checkbox v-model="terminationDate" label="Termination Date"></v-checkbox>
                        </v-col>
                        <v-col v-if="terminationDate">
                            <!-- Termination Date -->
                            <v-menu v-model="startMenu" :close-on-content-click="false" transition="scale-transition"
                                offset-y>
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="itemDelete.termination_date" label="Termination Date"
                                        readonly v-on="on"
                                        prepend-icon="mdi-calendar"></v-text-field><!-- :rules="[rules.required]" required-->
                                </template>
                                <v-date-picker v-model="itemDelete.termination_date"
                                    @input="startMenu = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- <v-btn color="blue darken-1" text @click="close">Cancel</v-btn> -->
                    <v-btn color="red darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>


<script>
import InsuranceCard from '../../components/Fleet/InsuranceCard.vue'
import InsuranceBreakdownCard from '../../components/Fleet/InsuranceBreakdownCard.vue'
import { getInsurance } from '../../utils/insurance_CRUD.js'
import { getInsuranceBreakdownByCompanyStatus, updateInsuranceStatus } from '../../utils/Insurance/insuranceBreakdownCRUD.js'
import _ from 'lodash';
import { fetchVehicles } from '../../utils/vehicles_CRUD.js'
import FileModal from '../../components/Modals/FileModal.vue'
import { generatePublicURL } from '../../utils/presignedUrl.js'

export default {
    components: {
        InsuranceCard,
        InsuranceBreakdownCard,
        FileModal
    },
    name: 'InfoInsurance',
    props: {
        Item: Object
    },
    data: () => ({
        // File Modal
        modalTitle: '',
        modalOpen: false,
        dataFileUrl: '',
        //
        ItemInsurance: {},
        ItemVehicle: {},
        samsaraVehicles: [],
        terminationDate: false,
        itemDelete: {},
        dialogDelete: '',
        type: '',
        breakdownDialog: false,
        propEditedItem: null,
        dialogOpen: false,
        showEditDates: false,
        editDates: false,
        startMenu: false,
        endMenu: false,
        driver: '',
        period: {
            startDate: null,
            endDate: null,
        },
        editedItem: {
            id: '',
            insurance_name: '',
            insured_under: '',
            address: '',
            ein: '',
            drivers: {
                drivers: ["item1", "item2", "item3"],
            },
            policy_number: '',
            total_cost: 0.0,
            status: true,
            policy_period: {
                period: [{ "endDate": "2024-08-05", "startDate": "2024-08-05" }, { "endDate": "2024-08-05", "startDate": "2024-08-05" }],
            },
        },
        defaultItem: {
            id: '',
            insurance_name: '',
            insured_under: '',
            address: '',
            ein: '',
            drivers: {
                drivers: [],
            },
            policy_number: '',
            total_cost: 0.0,
            status: true,
            policy_period: {
                period: [],
            },
            /* last_user: '',
            created_date: null,
            last_modification_date: null, */
        },
        rules: {
            required: value => !!value || 'Required.',
        },
        editedIndex: '',
        cards: [
            { name: 'Vehicle 1', tipo: 'Type A', cost: 100 },
            { name: 'Vehicle 2', tipo: 'Type B', cost: 200 },
            { name: 'Vehicle 3', tipo: 'Type C', cost: 300 },
            { name: 'Vehicle 4', tipo: 'Type D', cost: 400 },
            { name: 'Vehicle 5', tipo: 'Type E', cost: 500 },
            { name: 'Vehicle 6', tipo: 'Type F', cost: 600 }
        ],
        vehicles: [],
    }),
    watch: {
        terminationDate(val) {
            //console.log("terminationDate.... ", val);
            if (!val) {
                this.itemDelete.termination_date = null;
            }

        },
        dialogDelete(val) {
            //console.log("Watch/dialogDelete... ", val)
            if (!val) {
                this.terminationDate = false;
            }
        },
        breakdownDialog(val) {
            //console.log("breakdownDialog: ", val);
            if (!val) {
                this.editedIndex = _.cloneDeep(this.defaultItem);//Object.assign({}, this.defaultItem);
                this.editedIndex = '';
            }
        },
        async dialogOpen(val) {
            //console.log(">>>>>>>>>>>>>>>>>> val: ", val);
            if (!val) {
                this.editedItem = await getInsurance(this.editedItem.id);
            }
        },
    },
    computed: {
        latestPolicyPeriod() {
            const periods = this.editedItem.policy_period.period;
            return periods[periods.length - 1] || { startDate: '', endDate: '' };
        },
    },
    async mounted() {

        try {
            this.editedItem = this.Item;
            //console.log("Mounted/InfoInsurance - this.editedItem: ", this.editedItem);
            this.samsaraVehicles = await fetchVehicles();
            //console.log("this.samsaraVehicles: ", this.samsaraVehicles);
            this.vehicles = await getInsuranceBreakdownByCompanyStatus(this.editedItem.insurance_name);
        } catch (error) {
            //console.error("Error InfoInsuranceCard: ", error);
        }

    },
    methods: {
        async refreshData(){
            try {
                this.vehicles = await getInsuranceBreakdownByCompanyStatus(this.editedItem.insurance_name);                
            } catch (error) {
                console.error("Error/refreshData: ", error);
            }
        },
        async openModal(title, fileName, property, item) {
            console.log("Open Modal.... ", item);
            this.modalTitle = title;
            if (item.insurance_file) {
                const presignedUrl = await generatePublicURL(`Fleet/${item.vin}`, fileName);
                console.log("presignedUrl: ", presignedUrl);
                if (presignedUrl.hasOwnProperty('url')) {
                    this.dataFileUrl = presignedUrl.url;
                    this.modalOpen = true;
                } else {
                    //console.log("No se generó ninguna , debido a que no existe el archivo");
                    return;
                }
            }
        },
        getVehicleProperty(carAssigned, property) {
            //console.log("carAssigned: ", carAssigned);
            //console.log("property: ", property)
            const vehicle = this.samsaraVehicles.find(v => v.name === carAssigned);
            //console.log("vehicle: ", vehicle);
            return vehicle ? vehicle[property] : 'Not found';
        },
        async deleteItemConfirm() {
            //console.log("<<<<<<<<<<<<Confirmed Deleting..... ", this.itemDelete);
            const response = await updateInsuranceStatus(this.itemDelete.id, this.itemDelete.termination_date);
            if (response.ok) {
                //console.log("AFTER Deleting an insurance breakdown....");
                this.dialogDelete = false;
                await this.fetchInsurance();
                this.vehicles = await getInsuranceBreakdownByCompanyStatus(this.editedItem.insurance_name);
            }
        },
        async deleteVehicle(item) {
            //console.log("<<<<<<<<<<<< Deleting..... ", item);
            this.itemDelete = _.cloneDeep(item);
            //console.log("this.itemDelete: ", this.itemDelete);
            this.dialogDelete = true;
        },
        addInsuranceCar() {
            console.log("Adding Car.....");
            //this.propEditedItem = Object.assign({}, this.editedItem);
            console.log("this.editedItem: ", this.editedItem);
            this.ItemInsurance = Object.assign({}, this.editedItem);
            this.type = 'add';
            this.breakdownDialog = true;
        },
        updateInsuranceCar(item) {
            console.log("Updating Insurance Car: ", item);
            //this.propEditedItem = Object.assign({}, item);
            this.ItemInsurance = Object.assign({}, this.editedItem);
            this.ItemVehicle = Object.assign({}, item);
            this.editedIndex = item.id;
            this.type = 'update';
            this.breakdownDialog = true;
        },
        async handleInfoFromChild(info) {
            // Manejar la información recibida desde el hijo
            //console.log('Información recibida desde el hijo:', info);
            //console.log("this.editedItem: ", this.editedItem);
            this.dialogOpen = info; //Close dialog
            this.breakdownDialog = info
            //this.fetchItems(); //Refresh employees table
            await this.fetchInsurance();
            //console.log("<<<<<<<<<<<<<AFTER this.editedItem: ", this.editedItem);
            this.vehicles = await getInsuranceBreakdownByCompanyStatus(this.editedItem.insurance_name);
        },
        updateItem() {
            //console.log("updating Item....");
            //console.log("item: ", this.editedItem)
            this.propEditedItem = Object.assign({}, this.editedItem);
            this.propEditedItem.policy_period = Object.assign({}, this.editedItem.policy_period);
            this.propEditedItem.drivers = Object.assign({}, this.editedItem.drivers);
            //console.log("this.propEditedItem: ", this.propEditedItem);
            this.editedIndex = String(this.editedItem.id);
            //console.log("this.editedIndex: ", this.editedIndex);
            //this.editedItem = item;
            //this.editedIndex = this.items.indexOf(item);            
            ////console.log("this.editedIndex: ", this.editedIndex);
            //this.editedItem = Object.assign({}, item);
            //this.editedIndex = this.editedItem.id;
            ////console.log("this.editedIndex: ", this.editedIndex);
            ////console.log("<<<<<<<<<<<<<<<<<<<< this.editedItem: ", this.editedItem);
            this.dialogOpen = true;
        },
        async fetchInsurance() {
            //console.log("id: ", this.editedItem.id);
            try {
                this.editedItem = await getInsurance(this.editedItem.id);
            } catch (error) {
                //console.log("error: ", error);
            }
        }
    }
}
</script>

<style>
::v-deep .wrap-text {
    white-space: normal;
}

::v-deep .color-title {
    color: rgb(255, 0, 157);
}

::v-deep .dialog-height {
    max-height: auto !important;
    min-height: auto !important;
}

::v-deep .large-btn {
    height: 48px;
    width: 48px;
}

::v-deep .my-4 {
    margin-top: 16px;
    margin-bottom: 16px;
}

::v-deep .color-text {
    color: rgb(155, 2, 216);
    /* Puedes ajustar el color con un código hexadecimal si lo deseas */
    /* color: #0000FF; */
}

::v-deep .v-card {
    max-width: 800px !important;
    /* margin: 20px auto; */
    /* padding: 20px; */
}
</style>