<template>
    <v-col cols="12" sm="12" md="12">
        <v-card>
            <v-card-title>
                <span class="headline">Banks</span>
            </v-card-title>
            <v-col>
                <v-card-text>
                    <v-form ref='form'>
                        <v-text-field v-model="editedItem.bank_name" :rules="[rules.required]" label="Bank Name"
                            required></v-text-field>
                    </v-form>
                </v-card-text>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="submitForm">
                    SAVE
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
</template>

<script>
import { createBank, updateBank } from '../../utils/banks_CRUD.js'

export default {
    name: 'ChartOfAccountCard',
    props: {
        Index: Number,
        Item: Object
    },
    data: () => ({
        //chart_of_account_file: null,
        editedItem: {
            id: '',
            bank_name: '',
        },
        rules: {
            required: value => !!value || 'Required.',
        },
        defaultEditedItem: {
            id: '',
            bank_name: '',
        },
        editedIndex: -1,
    }),
    async mounted() {
        console.log("-------------------MOUNTED.......................")
        console.log("this.Index: ", this.Index);
        this.$nextTick(() => {
            this.$refs.form.resetValidation();
        });
        if (this.Index != -1) {
            this.editedItem = this.Item;
            this.editedIndex = this.Index
            console.log("this.editedIndex: ", this.editedIndex);
            console.log("this.editedItem: ", this.editedItem);
        }
    },
    methods: {
        async submitForm() {

            if (this.$refs.form.validate()) {
                console.log('Form submitted:', this.editedItem);
                let response = null;
                // ADD new record
                if (this.editedIndex === -1) {
                    response = await createBank(this.editedItem);
                    console.log("response: ", response);
                } else {
                    // Updating record
                    response = await updateBank(this.editedIndex, this.editedItem);
                    console.log("Updating - response: ", response);
                }
                if (response.ok) {
                    this.$emit('sent-info', false);
                }
            } else {
                console.log('Form validation failed.');
            }
        },
    },
}
</script>