<template>
    <report-to />
</template>

<script>
import ReportTo from '../../components/Employees/ReportTo'

export default {
    name: 'ReportToView',
    components: {
        ReportTo,
    },
}
</script>