<template>
    <login />
</template>

<script>
import Login from '../../components/Login/Login.vue'

export default {
    name: 'loginView',
    components: {
        Login,
    },
}
</script>