import {COMPUTER_EQUIPMENT_ASSIGNED, UPLOAD_CERTIFICATE, GENERATE_CERTIFICATE } from '../../utils/endpoints'

export async function createEquipmentAssigned(equipmentData) {
    try {
        const response = await fetch(COMPUTER_EQUIPMENT_ASSIGNED, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(equipmentData),
        });

        if (!response.ok) {
            throw new Error('Error creating equipment assignment');
        }

        /* const data = await response.json(); */
        return response;
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
}

export async function getAllEquipmentAssigned(status = 'All') {
    try {
        // Construir la URL con el parámetro de consulta 'status'
        const url = `${COMPUTER_EQUIPMENT_ASSIGNED}?status=${status}`;

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        console.log("response/getAllEquipmentAssigned: ", response);
        // Verificar si la respuesta es exitosa
        if (!response.ok) {
            return [];
            /* throw new Error('Error fetching equipment assignments'); */
        }

        // Convertir la respuesta a formato JSON
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        return [];
    }
}

export async function getEquipmentAssignedByUuid(equipmentUuid) {
    try {
        const response = await fetch(`${COMPUTER_EQUIPMENT_ASSIGNED}/${equipmentUuid}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Error fetching equipment assignment by UUID');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
}

export async function updateEquipmentAssigned(equipmentUuid, updatedData) {
    try {
        const response = await fetch(`${COMPUTER_EQUIPMENT_ASSIGNED}/${equipmentUuid}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedData),
        });

        if (!response.ok) {
            throw new Error('Error updating equipment assignment');
        }

        //const data = await response.json();
        return response;
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
}

export async function deleteEquipmentAssigned(equipmentUuid) {
    try {
        const response = await fetch(`${COMPUTER_EQUIPMENT_ASSIGNED}/${equipmentUuid}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Error deleting equipment assignment');
        }

        return true;  // Operación exitosa
    } catch (error) {
        console.error('Error:', error);
        return false;  // Hubo un error
    }
}

export async function updateAssignedEquipment(data) {    
    /* const data = {
        employee_number: employeeNumber
    }; */

    try {
        const response = await fetch(COMPUTER_EQUIPMENT_ASSIGNED, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ data })
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error:', errorData);
            return { success: false, error: errorData.error };
        }

        const result = await response.json();
        console.log('Success:', result);
        return response;//{ success: true, message: result.message };

    } catch (error) {
        console.error('Fetch error:', error);
        return { success: false, error: error.message };
    }
}

export async function uploadCertificate(employeeNumber, file) {
    const endpoint = `${UPLOAD_CERTIFICATE}${encodeURIComponent(employeeNumber)}`;

    // Crear el objeto FormData para incluir el archivo
    const formData = new FormData();
    formData.append('file', file);

    try {
        // Realizar la solicitud al endpoint
        const response = await fetch(endpoint, {
            method: 'PUT',
            body: formData,
        });

        // Procesar la respuesta
        const result = await response.json();

        if (response.ok) {
            console.log("File uploaded successfully:", result);
            return result; // Devuelve el resultado en caso de éxito
        } else {
            console.error("Error uploading file:", result);
            throw new Error(result.error || "Failed to upload file.");
        }
    } catch (error) {
        console.error("Unexpected error:", error);
        throw error;
    }
}


export async function searchByEmployeeNumber(employeeNumber) {
    try {
        if (!employeeNumber) {
            throw new Error("Employee number is required.");
        }

        const response = await fetch(`${COMPUTER_EQUIPMENT_ASSIGNED}/search?employee_number=${encodeURIComponent(employeeNumber)}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || "An error occurred while fetching the data.");
        }

        const data = await response.json();
        console.log("Equipment found:", data.data);
        return data.data;

    } catch (error) {
        console.error("Error fetching employee data:", error.message);
        return null;
    }
}

export async function generateCertificate(recordId, data) {
    try {
        const response = await fetch(`${GENERATE_CERTIFICATE}${recordId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                signature: data.signature,
                assignation_date: data.assignation_date,
                observations: data.observations,
                delivery_date: data.delivery_date,
                handover: data.handover,
                receipt: data.receipt
            }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error("Error generating PDF:", errorData.error);
            alert("Failed to generate PDF: " + errorData.error);
            return;
        }

        const pdfBlob = await response.blob();

        // Crear una URL para el archivo PDF y abrirlo en una nueva pestaña
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, '_blank');
    } catch (error) {
        console.error("Error consuming the endpoint:", error);
        alert("An unexpected error occurred: " + error.message);
    }
}
