<template>
    <admin-walk-ins-console />
</template>

<script>
import AdminWalkInsConsole from '../../components/Walk_ins/AdminWalkInsConsole.vue'

export default {
    name: 'AdminWalkIns',

    components: {
        AdminWalkInsConsole,
    },
}
</script>