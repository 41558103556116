<template>
    <location-excel />
</template>

<script>
import LocationExcel from '../../components/Accounting/LocationExcel.vue'

export default {
    name: 'LocationExcelView',
    components: {
        LocationExcel,
    },
}
</script>