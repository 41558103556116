
import {PROCESS_EXCEL} from '../../utils/endpoints'

export async function processExcel(file) {
    // Crear el objeto FormData para enviar el archivo
    const formData = new FormData();
    formData.append("file", file);

    try {
        // Realizar la petición POST al endpoint
        const response = await fetch(PROCESS_EXCEL, {
            method: "POST",
            body: formData
        });

        if (!response.ok) {
            // Manejar errores de la API
            const error = await response.json();
            console.error("Error:", error);
            return;
        }

        // Descargar el archivo procesado
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);

        // Crear un enlace para la descarga
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = "processed_file.xlsx";
        document.body.appendChild(link);
        link.click();
        link.remove();

        console.log("File processed and downloaded successfully.");
    } catch (error) {
        console.error("Error uploading file:", error);
    }
}
