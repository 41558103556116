<template>
    <nova-analytics />
</template>

<script>
import NovaAnalytics from '../../components/Marketing/NovaAnalytics.vue'

export default {
    name: 'NovaAnalyticsView',
    components: {
        NovaAnalytics,
    },
}
</script>