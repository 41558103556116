<template>
    <zoho-banks />
</template>

<script>
import ZohoBanks from '../../../components/Accounting/Zoho/ZohoBanks.vue'

export default {
    name: 'ZohoBanksView',
    components: {
        ZohoBanks,
    },
}
</script>