<template>
    <div>
        <v-row class="pt-4 px-4">
            <v-col cols="6" sm="6" md="6">
                <v-card>
                    <v-card-title>
                        <span class="headline">Enter Location</span>
                    </v-card-title>
                    <v-col cols="7">
                        <v-card-text>
                            <v-form>
                                <v-text-field v-model="editedItem.location" label="Location" required></v-text-field>
                                <v-select v-model="editedItem.zipcode" :items="zip_codes" label="Zip Code"
                                    required></v-select>
                                <v-text-field v-model="editedItem.address" label="Address" required></v-text-field>
                            </v-form>
                        </v-card-text>
                    </v-col>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="saveLocation">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="6" sm="6" md="6">
                <v-card height="520">
                    <v-card-title>Locations</v-card-title>
                    <v-card-text>
                        <v-text-field v-model="search" label="Search" class="mb-4"></v-text-field>
                        <v-data-table :headers="headers" :items="filteredLocations" item-key="id" class="elevation-1"
                            :search="search" :items-per-page="5" sort-by="location">
                            <template v-slot:items="props">
                                <td>{{ props.item.location }}</td>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-icon small class="mr-2" :style="{ color: 'blue' }" @click="updateItem(item)">
                                    mdi-pencil
                                </v-icon>
                                <v-icon small :style="{ color: 'red' }" @click="deleteLocation(item)">
                                    mdi-delete
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { ADD_LOCATION, GET_ALL_LOCATIONS_JSON, DELETE_LOCATION, GET_ACTIVE_ZIP_CODES, UPDATE_LOCATION } from '../../utils/endpoints';
import { bus } from '../../main.js';

export default {

    /* components: {
        TrainingLogModal,
        TrainingLogForm
    }, */
    data: () => ({
        headers: [
            { text: 'Location', value: 'location' },
            { text: 'Actions', value: 'actions', sortable: false },
            { text: 'Address', value: 'address' },
            { text: 'Zip Code', value: 'zipcode' },
        ],
        locations: [],
        zip_codes: [],
        search: '',
        location: '',
        editedItem: {
            location: '',
            address: '',
            zipcode: '',
        },
        defaultItem: {
            location: '',
            address: '',
            zipcode: '',
        },
        editedIndex: -1,
    }),
    computed: {
        filteredLocations() {
            if (!this.search.trim()) {
                return this.locations;
            } else {
                return this.locations.filter(location =>
                    location.location.toLowerCase().includes(this.search.toLowerCase())
                );
            }
        }
    },
    async mounted() {
        //console.log("-------------------MOUNTED.......................")
        bus.$emit('login-success');
        bus.$emit('permissions');
        this.getLocations();
        this.fill_itemsZipCode();
    },
    methods: {
        updateItem(item) {
            console.log("ITEM: ", item);
            this.editedIndex = item.id;
            this.editedItem = item;
            console.log("this.editedIndex: ", this.editedIndex);
        },
        async updateLocation(id, data) {
            console.log("data: ", data);
            console.log("id: ", id);

            const url = `${UPDATE_LOCATION}${id}`;

            try {
                const response = await fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const responseData = await response.json();
                console.log(responseData); // Aquí puedes manejar la respuesta como desees
                return response;
            } catch (error) {
                console.error('There has been a problem with your fetch operation:', error);
            }
        },
        async fill_itemsZipCode() {
            const zip_codes_array = await this.fetchActiveZipcodes();
            console.log("zip_codes_array: ", zip_codes_array);
            this.zip_codes = zip_codes_array.map(item => item.zip_code);
            console.log("this.zip_codes: ", this.zip_codes);
        },
        async fetchActiveZipcodes() {
            try {
                const response = await fetch(GET_ACTIVE_ZIP_CODES);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                console.log(data); // Aquí puedes procesar los datos como desees
                return data;
            } catch (error) {
                console.error('There has been a problem with your fetch operation:', error);
            }
        },
        async deleteLocation(item) {
            const response = await this.deleteItem(item);
            console.log("response: ", response);
            if (response.ok) {
                this.getLocations();
            }
        },
        async deleteItem(item) {
            console.log(item.id);
            try {
                const id = item.id;
                const url = `${DELETE_LOCATION}${id}`;
                console.log("url: ", url);
                const response = await fetch(`${DELETE_LOCATION}${id}`, {
                    method: 'DELETE',
                });

                if (!response.ok) {
                    throw new Error('Failed to delete location. Status: ' + response.status);
                }

                const result = await response.json();
                console.log('Location deleted successfully:', result);
                return response;
            } catch (error) {
                console.error('Error deleting location:', error);
                throw error;
            }
        },
        async saveLocation() {
            console.log("Saving location....");
            if (this.editedIndex === -1) {
                if (this.editedItem.location != '') {
                    console.log("Adding Location: ", this.editedItem);
                    const response = await this.addLocation();
                    console.log("response: ", response);
                    if (response.ok) {
                        this.getLocations();
                        this.editedItem = { ...this.defaultItem };
                    }
                } else {
                    alert("You must type a location");
                    return;
                }
            } else {
                let response = await this.updateLocation(this.editedIndex, this.editedItem);
                if (response.ok) {
                    this.getLocations();
                    this.editedItem = { ...this.defaultItem };
                    this.editedIndex = -1;

                }
            }

        },
        async getLocations() {
            try {
                const response = await fetch(GET_ALL_LOCATIONS_JSON); // Endpoint donde obtienes las ubicaciones
                if (!response.ok) {
                    throw new Error('Failed to fetch locations');
                }
                const data = await response.json();
                this.locations = data; // Asignar datos a la variable locations
            } catch (error) {
                console.error('Error fetching locations:', error);
                alert('Error fetching locations. Please try again later.');
            }
        },
        async addLocation() {
            try {
                /* const locationData = {
                    "location": this.location,
                    "address": "",
                    "zipcode": ""
                } */
                const response = await fetch(ADD_LOCATION, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(this.editedItem),
                });

                if (!response.ok) {
                    throw new Error('Failed to add location. Status: ' + response.status);
                }
                console.log("response: ", response);
                const result = await response.json();
                console.log('Location added successfully:', result);
                return response;
            } catch (error) {
                console.error('Error adding location:', error);
                throw error;
            }
        }
    },
    watch: {

    }
}

</script>

<style scoped></style>