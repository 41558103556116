<template>
    <v-container class="fill-height d-flex align-center justify-center">
        <v-card class="rounded custom-card" width="800">
            <v-card-title>
                <span class="text-h5">Policies</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="6" sm="4" md="6">
                        <v-row>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <v-file-input label="Employee Handbook file" v-model="employee_handbook_File"
                                        @change="onFileSelected('employee_handbook_File', 'employee_handbook')"
                                        accept="application/pdf">
                                    </v-file-input>
                                    <v-btn icon
                                        :class="{ 'green--text': policiesItem.employee_handbook === true, 'red--text': !policiesItem.employee_handbook }">
                                        <!-- <v-icon @click="openModal('Recent photo', editedItem.recentphoto)">mdi-file</v-icon> -->
                                        <v-icon
                                            @click="openModal('Employee Handook', 'employee_handbook.pdf', employee_handbook_File)">mdi-file</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <v-file-input label="acknowledgement of Handbook file"
                                        v-model="acknowledgement_of_handbook_File"
                                        @change="onFileSelected('acknowledgement_of_handbook_File', 'acknowledgement_of_handbook')"
                                        accept="application/pdf">
                                    </v-file-input>
                                    <v-btn icon
                                        :class="{ 'green--text': policiesItem.acknowledgement_of_handbook === true, 'red--text': !policiesItem.acknowledgement_of_handbook }">
                                        <v-icon
                                            @click="openModal('acknowledgement of Handbook', 'acknowledgement_of_handbook.pdf', acknowledgement_of_handbook_File)">mdi-file</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <v-file-input label="Code of Discipline Policy file"
                                        v-model="code_of_discipline_policy_File"
                                        @change="onFileSelected('code_of_discipline_policy_File', 'code_of_discipline_policy')"
                                        accept="application/pdf">
                                    </v-file-input>
                                    <v-btn icon
                                        :class="{ 'green--text': policiesItem.code_of_discipline_policy === true, 'red--text': !policiesItem.code_of_discipline_policy }">
                                        <v-icon
                                            @click="openModal('Code of Discipline Policy', 'code_of_discipline_policy.pdf', code_of_discipline_policy_File)">mdi-file</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <v-file-input label="Dress Code Policy Video" v-model="dress_code_policy_video_File"
                                        @change="onFileSelected('dress_code_policy_video_File', 'dress_code_policy_video')"
                                        accept="video/mp4">
                                    </v-file-input>
                                    <v-btn icon
                                        :class="{ 'green--text': policiesItem.dress_code_policy_video === true, 'red--text': !policiesItem.dress_code_policy_video }">
                                        <v-icon
                                            @click="openModal('Dress Code Policy Video', 'dress_code_policy_video.mp4', dress_code_policy_video_File)">mdi-video</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <v-file-input label="Dress Code Policy file" v-model="dress_code_policy_File"
                                        @change="onFileSelected('dress_code_policy_File', 'dress_code_policy')"
                                        accept="application/pdf">
                                    </v-file-input>
                                    <v-btn icon
                                        :class="{ 'green--text': policiesItem.dress_code_policy === true, 'red--text': !policiesItem.dress_code_policy }">
                                        <v-icon
                                            @click="openModal('Dress Code Policy', 'dress_code_policy.pdf', dress_code_policy_File)">mdi-file</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <v-file-input label="Sales Enrollment Policy file"
                                        v-model="sales_enrollment_policy_File"
                                        @change="onFileSelected('sales_enrollment_policy_File', 'sales_enrollment_policy')"
                                        accept="application/pdf">
                                    </v-file-input>
                                    <v-btn icon
                                        :class="{ 'green--text': policiesItem.sales_enrollment_policy === true, 'red--text': !policiesItem.sales_enrollment_policy }">
                                        <v-icon
                                            @click="openModal('Sales Enrollment Policy', 'sales_enrollment_policy.pdf', sales_enrollment_policy_File)">mdi-file</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <v-file-input label="Attendance Policy file" v-model="attendance_policy_File"
                                        @change="onFileSelected('attendance_policy_File', 'attendance_policy')"
                                        accept="application/pdf">
                                    </v-file-input>
                                    <v-btn icon
                                        :class="{ 'green--text': policiesItem.attendance_policy === true, 'red--text': !policiesItem.attendance_policy }">
                                        <v-icon
                                            @click="openModal('Attendance Policy', 'attendance_policy.pdf', attendance_policy_File)">mdi-file</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <v-file-input label="Advances and Loan Policy file"
                                        v-model="advances_and_loan_policy_File"
                                        @change="onFileSelected('advances_and_loan_policy_File', 'advances_and_loan_policy')"
                                        accept="application/pdf">
                                    </v-file-input>
                                    <v-btn icon
                                        :class="{ 'green--text': policiesItem.advances_and_loan_policy === true, 'red--text': !policiesItem.advances_and_loan_policy }">
                                        <v-icon
                                            @click="openModal('Advances and Loan Policy', 'advances_and_loan_policy.pdf', advances_and_loan_policy_File)">mdi-file</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <v-file-input label="Vehicle use and care" v-model="vehicle_use_and_care_File"
                                        @change="onFileSelected('vehicle_use_and_care_File', 'vehicle_use_and_care')"
                                        accept="application/pdf">
                                    </v-file-input>
                                    <v-btn icon
                                        :class="{ 'green--text': policiesItem.vehicle_use_and_care === true, 'red--text': !policiesItem.vehicle_use_and_care }">
                                        <v-icon
                                            @click="openModal('Vehicle use and care', 'vehicle_use_and_care.pdf', vehicle_use_and_care_File)">mdi-file</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="6" sm="4" md="6">
                        <v-row>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <v-file-input label="No Show and Late Cancellation Policy file"
                                        v-model="no_show_and_late_cancellation_File"
                                        @change="onFileSelected('no_show_and_late_cancellation_File', 'no_show_and_late_cancellation')"
                                        accept="application/pdf">
                                    </v-file-input>
                                    <v-btn icon
                                        :class="{ 'green--text': policiesItem.no_show_and_late_cancellation === true, 'red--text': !policiesItem.no_show_and_late_cancellation }">
                                        <v-icon
                                            @click="openModal('No Show and Late Cancellation Policy', 'no_show_and_late_cancellation.pdf', no_show_and_late_cancellation_File)">mdi-file</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <v-file-input label="Sexual Harassment Training Video"
                                        v-model="sexual_harassment_training_video_File"
                                        @change="onFileSelected('sexual_harassment_training_video_File', 'sexual_harassment_training_video')"
                                        accept="video/mp4">
                                    </v-file-input>
                                    <v-btn icon
                                        :class="{ 'green--text': policiesItem.sexual_harassment_training_video === true, 'red--text': !policiesItem.sexual_harassment_training_video }">
                                        <v-icon
                                            @click="openModal('Sexual Harassment Training Video', 'sexual_harassment_training_video.mp4', sexual_harassment_training_video_File)">mdi-video</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <v-file-input label="Sexual Harassment Training acknowledgement file"
                                        v-model="sexual_harassment_training_acknowledgement_File"
                                        @change="onFileSelected('sexual_harassment_training_acknowledgement_File', 'sexual_harassment_training_acknowledgement')"
                                        accept="application/pdf">
                                    </v-file-input>
                                    <v-btn icon
                                        :class="{ 'green--text': policiesItem.sexual_harassment_training_acknowledgement === true, 'red--text': !policiesItem.sexual_harassment_training_acknowledgement }">
                                        <v-icon
                                            @click="openModal('Sexual Harassment Training acknowledgement', 'sexual_harassment_training_acknowledgement.pdf', sexual_harassment_training_acknowledgement_File)">mdi-file</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <v-file-input label="Time Clock Wizard Video" v-model="time_clock_wizard_video_File"
                                        @change="onFileSelected('time_clock_wizard_video_File', 'time_clock_wizard_video')"
                                        accept="video/mp4">
                                    </v-file-input>
                                    <v-btn icon
                                        :class="{ 'green--text': policiesItem.time_clock_wizard_video === true, 'red--text': !policiesItem.time_clock_wizard_video }">
                                        <v-icon
                                            @click="openModal('Time Clock Wizard Video', 'time_clock_wizard_video.mp4', time_clock_wizard_video_File)">mdi-video</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <v-file-input label="Timekeeping Policy file" v-model="timekeeping_policy_File"
                                        @change="onFileSelected('timekeeping_policy_File', 'timekeeping_policy')"
                                        accept="application/pdf">
                                    </v-file-input>
                                    <v-btn icon
                                        :class="{ 'green--text': policiesItem.timekeeping_policy === true, 'red--text': !policiesItem.timekeeping_policy }">
                                        <v-icon
                                            @click="openModal('Timekeeping Policy', 'timekeeping_policy.pdf', timekeeping_policy_File)">mdi-file</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <v-file-input label="Remote Staff Working Policy file"
                                        v-model="remote_staff_working_policy_File"
                                        @change="onFileSelected('remote_staff_working_policy_File', 'remote_staff_working_policy')"
                                        accept="application/pdf">
                                    </v-file-input>
                                    <v-btn icon
                                        :class="{ 'green--text': policiesItem.remote_staff_working_policy === true, 'red--text': !policiesItem.remote_staff_working_policy }">
                                        <v-icon
                                            @click="openModal('Remote Staff Working Policy', 'remote_staff_working_policy.pdf', remote_staff_working_policy_File)">mdi-file</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <v-file-input label="Cash Handling Policy file" v-model="cash_handling_policy_File"
                                        @change="onFileSelected('cash_handling_policy_File', 'cash_handling_policy')"
                                        accept="application/pdf">
                                    </v-file-input>
                                    <v-btn icon
                                        :class="{ 'green--text': policiesItem.cash_handling_policy === true, 'red--text': !policiesItem.cash_handling_policy }">
                                        <v-icon
                                            @click="openModal('Cash Handling Policy', 'cash_handling_policy.pdf', cash_handling_policy_File)">mdi-file</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <v-file-input label="Acknowledgement Invoice Submission file"
                                        v-model="acknowledgement_invoice_submission_File"
                                        @change="onFileSelected('acknowledgement_invoice_submission_File', 'acknowledgement_invoice_submission')"
                                        accept="application/pdf">
                                    </v-file-input>
                                    <v-btn icon
                                        :class="{ 'green--text': policiesItem.acknowledgement_invoice_submission === true, 'red--text': !policiesItem.acknowledgement_invoice_submission }">
                                        <v-icon
                                            @click="openModal('Acknowledgement Invoice Submission Policy', 'acknowledgement_invoice_submission.pdf', acknowledgement_invoice_submission_File)">mdi-file</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>                        
                    </v-col>
                </v-row>
                <file-modal :modalOpen.sync="modalOpen" :title="modalTitle" :dataFileUrl="dataFileUrl"></file-modal>
            </v-card-text>
            <v-card-actions>
                <v-btn color="blue" @click="upload">
                    Upload
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>

</template>

<script>
import { uploadPolicy } from '../../utils/upload_policy_file_s3.js'
import { generatePublicURL } from '../../utils/presignedUrl.js'
import FileModal from '../../components/Modals/FileModal.vue'
import { bus } from '../../main.js';
import { GET_POLICIES_STATUS } from '../../utils/endpoints.js'

export default {
    components: {
        FileModal
    },
    data() {
        return {
            // File modal
            modalOpen: false,
            modalTitle: '',
            dataFileUrl: '',
            //////////////////
            employee_handbook_File: null,
            acknowledgement_of_handbook_File: null,
            code_of_discipline_policy_File: null,
            dress_code_policy_File: null,
            sales_enrollment_policy_File: null,
            attendance_policy_File: null,
            advances_and_loan_policy_File: null,
            no_show_and_late_cancellation_File: null,
            sexual_harassment_training_acknowledgement_File: null,
            timekeeping_policy_File: null,
            remote_staff_working_policy_File: null,
            cash_handling_policy_File: null,
            acknowledgement_invoice_submission_File: null,
            // Videos
            sexual_harassment_training_video_File: null,
            dress_code_policy_video_File: null,
            time_clock_wizard_video_File: null,
            vehicle_use_and_care_File: null,

            //////////////////////////////////
            policiesItem: {
                employee_handbook: false,
                acknowledgement_of_handbook: false,
                code_of_discipline_policy: false,
                dress_code_policy: false,
                sales_enrollment_policy: false,
                attendance_policy: false,
                advances_and_loan_policy: false,
                no_show_and_late_cancellation: false,
                sexual_harassment_training_acknowledgement: false,
                timekeeping_policy: false,
                remote_staff_working_policy: false,
                cash_handling_policy: false,
                acknowledgement_invoice_submission: false,
                // Videos
                sexual_harassment_training_video: false,
                dress_code_policy_video: false,
                time_clock_wizard_video: false,
                vehicle_use_and_care: false,
            }

        }
    },
    methods: {
        async upload() {
            console.log("Uploading");
            const documents = [
                this.employee_handbook_File,
                this.acknowledgement_of_handbook_File,
                this.code_of_discipline_policy_File,
                this.dress_code_policy_File,
                this.sales_enrollment_policy_File,
                this.attendance_policy_File,
                this.advances_and_loan_policy_File,
                this.no_show_and_late_cancellation_File,
                this.sexual_harassment_training_acknowledgement_File,
                this.timekeeping_policy_File,
                this.remote_staff_working_policy_File,
                this.cash_handling_policy_File,
                this.acknowledgement_invoice_submission_File,
                // Videos
                this.sexual_harassment_training_video_File,
                this.dress_code_policy_video_File,
                this.time_clock_wizard_video_File,
                this.vehicle_use_and_care_File,
            ];
            for (const document of documents) {
                if (document != null) {
                    const res = await uploadPolicy(document);
                    console.log('res: ', res);
                }
            }
            this.clear_file_inputs();
            this.get_policiesItem();
        },
        async onFileSelected(property, fileName) {
            console.log("this[property]: ", this[property]);
            console.log("fileName: ", fileName);

            //console.log("Selecting file....", fileName);      
            // This method is triggered when a file is selected
            this[property] = this.renamedFile(this[property], fileName);
            /* try {
                if (this.validateExtension(this[property])) {
                    this[property] = this.renamedFile(this[property], fileName);
                }

            } catch (error) {
                console.error("ErroronFileSelected: ", error);
            } */
        },
        /* validateExtension(file) {
            const allowedExtensions = ['pdf'];
            //const file = this.recentPhotoFile;    

            // Obtener la extensión del archivo
            const extension = file.name.split('.').pop().toLowerCase();

            if (!allowedExtensions.includes(extension)) {
                // Si la extensión del archivo no está permitida, mostrar una alerta
                alert("El archivo debe ser un PDF.");
                // También puedes mostrar un mensaje en la consola para fines de depuración
                console.log("El archivo debe ser un PDF.");
                // Limpiar el campo de archivo seleccionado
                //this.recentPhotoFile = null;
                this.recentPhotoFile = null;
                return false;
            } else {
                return true;
            }
        }, */
        renamedFile(file, newName) {
            //console.log("Rename File...")
            if (file === null) {
                console.log("Archivo vacío.................");
            } else {
                console.log("Archivo NO vacío.......");
            }
            const originalFileName = file.name;
            console.log("originalFileName: ", originalFileName);
            const fileExtension = originalFileName.slice((originalFileName.lastIndexOf(".") - 1 >>> 0) + 2);
            //console.log("fileExtension: ", fileExtension);
            // Create a new file with the desired name and the same file type
            const renamedFile = new File([file], newName.toLowerCase() + '.' + fileExtension, { type: file.type });
            //console.log("renamedFile: ", renamedFile);
            return renamedFile;
        },
        async openModal(title, fileName, file) {
            console.log("Opening modal....");
            const presignedUrl = await generatePublicURL('hr', fileName);
            console.log("presignedUrl: ", presignedUrl);
            if (presignedUrl.hasOwnProperty('url')) {
                this.modalTitle = title;
                this.dataFileUrl = presignedUrl.url;
                this.modalOpen = true;
            } else {
                console.log("No se generó ninguna , debido a que no existe el archivo");
                return;
            }
        },
        clear_file_inputs() {
            this.employee_handbook_File = null;
            this.acknowledgement_of_handbook_File = null;
            this.code_of_discipline_policy_File = null;
            this.dress_code_policy_File = null;
            this.sales_enrollment_policy_File = null;
            this.attendance_policy_File = null;
            this.advances_and_loan_policy_File = null;
            this.no_show_and_late_cancellation_File = null;
            this.sexual_harassment_training_acknowledgement_File = null;
            this.timekeeping_policy_File = null;
            this.remote_staff_working_policy_File = null;
            this.cash_handling_policy_File = null;
            this.acknowledgement_invoice_submission_File = null;
            // Videos
            this.sexual_harassment_training_video_File = null;
            this.dress_code_policy_video_File = null;
            this.time_clock_wizard_video_File = null;
            this.vehicle_use_and_care_File = null;
        },
        async get_policiesItem() {
            try {
                // Realizar solicitud GET al endpoint
                const response = await fetch(GET_POLICIES_STATUS);

                // Verificar si la solicitud fue exitosa (código de estado 200-299)
                if (response.ok) {
                    // Extraer el JSON de la respuesta
                    const data = await response.json();
                    console.log('Última política:', data);
                    Object.keys(data).forEach(key => {
                        console.log("Key: ", key);
                        /* const Key = key + ''; */
                        if (this.policiesItem.hasOwnProperty(key)) {
                            this.policiesItem[key] = data[key];
                        }
                    });
                    console.log("policiesItem: ", this.policiesItem);
                    // Aquí puedes procesar los datos como lo desees
                } else {
                    // Si la solicitud no fue exitosa, mostrar el mensaje de error
                    console.error('Error al obtener la última política:', response.statusText);
                }
            } catch (error) {
                // Capturar y manejar errores de red u otros errores
                console.error('Error de red:', error);
            }
        }
    },
    mounted() {
        bus.$emit('login-success');
        bus.$emit('permissions');
        this.get_policiesItem();
    }
}
</script>

<style scoped></style>