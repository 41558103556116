<template>
    <v-card class="elevation-2 mx-auto mt-4 mb-4" max-width="80%"><!-- :sort-desc="true" -->
        <!-- Contenedor para la imagen-botón -->
        <v-container v-if="!closedDay" class="d-flex justify-center py-4">
            <!-- Fila de imágenes -->
            <v-row justify="space-around" class="py-4">
                <!-- Clock In/Clock Out -->
                <v-col v-if="!wc && !lunch" class="d-flex flex-column align-center text-center">
                    <v-img
                        :src="clockIn ? require('@/assets/Clock/clock-in.png') : require('@/assets/Clock/clock-out.png')"
                        contain max-height="100" max-width="100" class="cursor-pointer mb-2" @click="toggleClock"
                        :style="{ color: clockIn ? 'rgba(0, 128, 0, 0.8)' : 'rgba(255, 0, 0, 0.8)' }" />
                    <div class="font-weight-bold">
                        {{ clockIn ? 'CLOCK IN' : 'CLOCK OUT' }}
                    </div>
                </v-col>

                <!-- Lunch In/Lunch Out -->
                <v-col v-if="clock && !wc" class="d-flex flex-column align-center text-center">
                    <v-img
                        :src="lunchIn ? require('@/assets/Lunch/lunch-in.png') : require('@/assets/Lunch/lunch-out.png')"
                        contain max-height="100" max-width="100" class="cursor-pointer mb-2" @click="toggleLunch"
                        :style="{ color: lunchIn ? 'rgba(0, 128, 0, 0.8)' : 'rgba(255, 0, 0, 0.8)' }" />
                    <div class="font-weight-bold">
                        {{ lunchIn ? 'GO TO LUNCH' : 'BACK LUNCH' }}
                    </div>
                </v-col>

                <!-- WC In/WC Out -->
                <v-col v-if="clock && !lunch" class="d-flex flex-column align-center text-center">
                    <v-img :src="wcIn ? require('@/assets/WC/wc-in.png') : require('@/assets/WC/wc-out.png')" contain
                        max-height="100" max-width="100" class="cursor-pointer mb-2" @click="toggleWC"
                        :style="{ color: wcIn ? 'rgba(0, 128, 0, 0.8)' : 'rgba(255, 0, 0, 0.8)' }" />
                    <div class="font-weight-bold">
                        {{ wcIn ? 'BIO BREAK' : 'BACK BIO BREAK' }}
                    </div>
                </v-col>

                <!-- Imágenes sin lógica adicional pero con evento de clic -->
                <v-col v-if="clock && !lunch && !wc" v-for="(image, index) in additionalImages" :key="index"
                    class="d-flex flex-column align-center text-center">
                    <v-img :src="require(`@/assets/Reasons/${image.fileName}.png`)" contain max-height="100"
                        max-width="100" class="cursor-pointer mb-2" @click="handleClick(image.label)" />
                    <div class="font-weight-bold">
                        {{ image.label }}
                    </div>
                </v-col>
            </v-row>
            <!-- Fila adicional de imágenes con funcionalidad de clic -->
            <!-- <v-row justify="space-around" class="py-4">
               
                <v-col v-for="(image, index) in additionalImages" :key="index" cols="3"
                    class="d-flex flex-column align-center text-center">
                    <v-img :src="require(`@/assets/Reasons/${image.fileName}.png`)" contain max-height="100"
                        max-width="100" class="cursor-pointer mb-2" @click="handleClick(image.label)" />
                    <div class="font-weight-bold">
                        {{ image.label }}
                    </div>
                </v-col>
            </v-row> -->
        </v-container>

        <v-data-table :headers="headers" :items="filteredActivities" sort-by="time" :sort-desc="true"
            class="elevation-1" hide-default-footer :items-per-page="-1" :item-class="getRowClass">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title class="font-weight-bold">User Activity Report</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-text-field v-model="search" label="Search" solo-inverted prepend-icon="mdi-magnify"
                        class="mr-3 mt-5"></v-text-field>
                    <v-btn color="blue" dark class="mb-2 mr-2" @click="refreshActivities">
                        <v-icon>mdi-refresh</v-icon>
                    </v-btn>                    
                </v-toolbar>
            </template>
            <template v-slot:[`item.type_of_call.link`]="{ item }">
                <v-icon v-if="item.type_of_call?.link && item.type_of_call.link !== ''" color="primary"
                    @click="redirectToLink(item.type_of_call?.link)" style="cursor: pointer;">
                    mdi-link
                </v-icon>
            </template>

            <template v-slot:[`item.notes`]="{ item }">
                <div class="wrap-column">
                    {{ item.notes }}
                </div>
            </template>

            <!-- Sale Column -->
            <template v-slot:[`item.sale`]="{ item }">
                {{ item.sale ? `$${item.sale.toFixed(2)}` : '-' }}
            </template>

            <!-- Slot personalizado para Time -->
            <template v-slot:item.time="{ item }">
                <strong>{{ item.time }}</strong>
            </template>
        </v-data-table>
        <v-dialog v-model="dialog" max-width="450">
            <v-card>
                <v-card-title class="headline"></v-card-title>
                <v-card-text>
                    <h2>Are you sure you want to clock out and close the day?</h2>                    
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="confirmClockOut">Yes</v-btn>
                    <v-btn color="red darken-1" text @click="dialog = false">No</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import { get_employee_number } from '../../utils/get_employee_number.js'
import { GET_WALK_INS_FORMS_BY_EMPLOYEE } from '../../utils/endpoints.js';
import { bus } from '../../main.js';
import WalkInsForm from '../../components/Walk_ins/WalkInsForm.vue';
import { DateTime } from 'luxon';
import { exportWalkInsToExcel, filterWalkIns, getEmployeesWithWalkIns, getLocations, deleteWalkInByUUID, fetchTodayWalkIns, fetchTodayWalkIns_ByEmployee } from '../../utils/WalkIns/walk_ins_CRUD.js'
import { fetchTodayActivities, createActivity, addTodayActivity } from '../../utils/Sales/activity_report_CRUD.js'
import { getEmployeeByNumber } from '../../utils/employees_CRUD.js'

export default {
    components: {
        WalkInsForm
    },
    data: () => ({
        closedDay: false,
        dialog: false,
        clock: null,
        wc: null,
        lunch: null,
        extension: '',
        clockIn: true,
        lunchIn: true,
        wcIn: true,
        activities: [],
        // Filters
        period: {
            end_date: null,
            start_date: null,
            end_time: null,
            start_time: null,
        },
        start_date_menu: false,
        end_date_menu: false,
        start_time_menu: false,
        end_time_menu: false,
        /////////////////
        itemPreview: -1,
        name_staff: '',
        staffSelect: null,
        // Filters
        users: [],
        locations: null,
        labels: [
            "Information",
            "Sold",
            "HR",
            "Copies",
            "Appointment (DMV)",
            "CR + CR Online",
            "Complaint",
            "Payment",
            "Practice WE",
            "Schedule And Reschedule BTW",
            "Teen RT",
            "Documents (TEENS/ADULTS/CHAUFFEURS)",
            "Test Chauffeurs Taxi/Livery",
            "MU Teens"
        ],
        showFilters: false,
        datePickers: {
            creationDateMenu: false,
        },
        filters: {
            creationTime: null,
            creationDate: null,
            location: null,
            staff_name: null,
            reason: null,
        },
        locationSelected: null,
        employeeSelected: null,
        filteredWalkins: null,
        /////////////////
        showLoader: false,
        walkIndex: -1,
        walkItem: null,
        walkPreview: false,
        employee_number: '',
        walk_ins_forms: [],
        search: '',
        headers: [
            { text: 'Time', value: 'time' },
            { text: 'Number', value: 'number' },
            { text: 'Reason', value: 'reason' },
            { text: 'Type of Call', value: 'type_of_call.type' },
            { text: 'Sale', value: 'sale' },
            { text: 'Notes', value: 'notes' },
            { text: 'Link', value: 'type_of_call.link' },
        ],
        editedItem: {
            uuid: '',
            staff_name: '',
            employee_number: '',
            date_time: null
        },
        additionalImages: [
            { fileName: 'inbound', label: 'INBOUND' },
            { fileName: 'outbound', label: 'OUTBOUND' },
            { fileName: 'chat', label: 'CHAT' },
            { fileName: 'walk-ins', label: 'WALK IN' },        
        ]
    }),
    computed: {
        filteredActivities() {
            if (
                Array.isArray(this.activities) && // Verifica que sea un array
                this.activities.length > 0 && // Asegura que no esté vacío
                this.activities[0].activities // Verifica que `activities` exista en el primer elemento
            ) {
                return this.activities[0].activities.filter(activity =>
                    activity.reason.toLowerCase().includes(this.search.toLowerCase())
                );
            } else {
                return [];
            }
        },
        /* filteredTrainingLogs() {
            if (this.walk_ins_forms) {
                return this.walk_ins_forms.filter(walk_in =>
                    walk_in.staff_name.toLowerCase().includes(this.search.toLowerCase())
                );
            }
            else {
                return [];
            }
        }, */
    },
    watch: {
        'period.start_time'(val) {
            console.log("start_time: ", val);
            if (val != null) {
                //this.period.end_time = '18:00';
            } else {
                this.period.start_time = null;
                this.period.end_time = null;
            }
        },
        'period.start_date'(val) {
            console.log("start_date: ", val);
            if (val != null) {
                //this.period.end_date = val;
            } else {
                this.period.end_date = null;
                this.period.start_date = null;
            }
        },
        locationSelected(newLocations) {
            if (newLocations && newLocations.length) {
                // Mapea las ubicaciones seleccionadas a solo los nombres de las ubicaciones
                this.filters.location = newLocations.map(item => item.location);
            } else {
                // Si no hay ninguna ubicación seleccionada, asegúrate de limpiar el filtro
                this.filters.location = [];
            }
        },
        async showFilters(val) {
            console.log("showFilters/val: ", val);
            if (!val) {
                this.resetFilters();
            } else {
                this.showLoader = true;
                this.users = await getEmployeesWithWalkIns();
                this.locations = await getLocations();
                console.log("users: ", this.users);
                console.log("this.locations: ", this.locations);
                this.showLoader = false;
            }
        },
        walkPreview(val) {
            console.log("walkPreview: ", val);
            if (!val) {
                this.walkItem = null;
                this.walkIndex = -1;
                this.itemPreview = -1;
            }
        }
    },
    async mounted() {
        console.log("-------------------MOUNTED.......................")
        bus.$emit('login-success');
        bus.$emit('permissions');
        this.employee_number = get_employee_number();
        console.log("employee_number: ", this.employee_number);

        this.activities = await fetchTodayActivities(this.employee_number);
        if (this.activities.length > 0) {
            console.log("this.activities: ", this.activities);
            console.log("this.activities: ", this.activities[0].activities);
            this.clock = this.activities[0].actions.clock;
            this.wc = this.activities[0].actions.wc;
            this.lunch = this.activities[0].actions.lunch;
            console.log("<<<<<<<< this.clock: ", this.clock);
            console.log("this.wc: ", this.wc);
            console.log("this.lunch: ", this.lunch);
            this.clockIn = !this.clock;
            this.lunchIn = !this.lunch;
            this.wcIn = !this.wc;
            this.closedDay = this.activities[0].status;
        }

        let { extension_phone_8x8 } = await getEmployeeByNumber(this.employee_number);
        console.log("extension_phone_8x8: ", extension_phone_8x8);
        this.extension = extension_phone_8x8;
        console.log("this.extension: ", this.extension);

        //this.walk_ins_forms = await this.get_WalkIns_Forms();
        //console.log("this.walk_ins_forms: ", this.walk_ins_forms);
        //console.log("Walk ins: ", this.walk_ins_forms);

        /* this.staffSelect = await getEmployeeByNumber(this.employee_number);//this.get_EmployeeData()
        console.log("this.staffSelect: ", this.staffSelect);
        this.name_staff = `${this.staffSelect.name} ${this.staffSelect.last_name}`;
        console.log("this.name_staff: ", this.name_staff); */

    },
    methods: {
        showConfirmDialog() {
            this.dialog = true; // Shows the dialog
        },
        async confirmClockOut() {
            console.log('<<<<<<<<<<< Clocking out'); // Message when clocking out
            const response = await addTodayActivity("CLOCK OUT", this.employee_number);
            this.dialog = false; // Closes the dialog
            // Here you can handle the response if necessary
            if (response.ok) {
                this.clockIn = !this.clockIn; // Cambiar el estado
                this.activities = await fetchTodayActivities(this.employee_number);
                this.clock = this.activities[0].actions.clock;
                this.wc = this.activities[0].actions.wc;
                this.lunch = this.activities[0].actions.lunch;
                console.log("this.clock: ", this.clock);
                console.log("this.wc: ", this.wc);
                console.log("this.lunch: ", this.lunch);
                this.closedDay = this.activities[0].status;
            }
        },
        handleClick(label) {
            console.log(`Clicked on: ${label}`);
        },
        async toggleLunch() {
            //this.lunchIn = !this.lunchIn;
            let response = null;
            console.log("this.activities: ", this.activities);
            if (!this.lunch) {
                //if (this.clockIn) {
                if (this.activities.length > 0) {
                    console.log('<<<<<<<<<< GO TO LUNCH in for n time'); // Mensaje al hacer clock in
                    response = await addTodayActivity("GO TO LUNCH", this.employee_number);
                    this.lunch = true;
                }/*  else {
                    console.log('<<<<<<<<<<< WC in for first time'); // Mensaje al hacer clock in
                    const data = {
                        "employee_number": this.employee_number,
                        "extension": this.extension
                    }
                    console.log("data: ", data);
                    response = await createActivity(data);
                    console.log("response: ", response);
                    this.clock = true;
                } */
                //}
            }
            else {
                console.log('<<<<<<<<<<< BACK LUNCH out'); // Mensaje al hacer clock out
                response = await addTodayActivity("BACK LUNCH", this.employee_number);
            }

            if (response.ok) {
                this.lunchIn = !this.lunchIn; // Cambiar el estado
                this.activities = await fetchTodayActivities(this.employee_number);
                this.clock = this.activities[0].actions.clock;
                this.wc = this.activities[0].actions.wc;
                this.lunch = this.activities[0].actions.lunch;
                console.log("this.clock: ", this.clock);
                console.log("this.wc: ", this.wc);
                console.log("this.lunch: ", this.lunch);
                this.closedDay = this.activities[0].status;
            }
        },
        async toggleWC() {
            //this.wcIn = !this.wcIn;
            let response = null;
            console.log("this.activities: ", this.activities);
            if (!this.wc) {
                //if (this.clockIn) {
                if (this.activities.length > 0) {
                    console.log('<<<<<<<<<< BIO BREAK for n time'); // Mensaje al hacer clock in
                    response = await addTodayActivity("BIO BREAK", this.employee_number);
                    this.wc = true;
                } /* else {
                    console.log('<<<<<<<<<<< WC in for first time'); // Mensaje al hacer clock in
                    const data = {
                        "employee_number": this.employee_number,
                        "extension": this.extension
                    }
                    console.log("data: ", data);
                    response = await createActivity(data);
                    console.log("response: ", response);
                    this.clock = true;
                } */
                //}
            }
            else {
                console.log('<<<<<<<<<<< BACK BIO BREAK'); // Mensaje al hacer clock out
                response = await addTodayActivity("BACK BIO BREAK", this.employee_number);
            }

            if (response.ok) {
                this.wcIn = !this.wcIn; // Cambiar el estado
                this.activities = await fetchTodayActivities(this.employee_number);
                this.clock = this.activities[0].actions.clock;
                this.wc = this.activities[0].actions.wc;
                this.lunch = this.activities[0].actions.lunch;
                console.log("this.clock: ", this.clock);
                console.log("this.wc: ", this.wc);
                console.log("this.lunch: ", this.lunch);
            }
        },
        async toggleClock() {

            let response = null;
            console.log("this.activities: ", this.activities);
            if (!this.clock) {
                //if (this.clockIn) {
                if (this.activities.length > 0) {
                    console.log('<<<<<<<<<< Clocking in for n time'); // Mensaje al hacer clock in
                    response = await addTodayActivity("CLOCK IN", this.employee_number);
                    this.clock = true;
                } else {
                    console.log('<<<<<<<<<<< Clocking in for first time'); // Mensaje al hacer clock in
                    const data = {
                        "employee_number": this.employee_number,
                        "extension": this.extension
                    }
                    console.log("data: ", data);
                    response = await createActivity(data);
                    console.log("response: ", response);
                    this.clock = true;
                }
                //}
                if (response.ok) {
                    this.clockIn = !this.clockIn; // Cambiar el estado
                    this.activities = await fetchTodayActivities(this.employee_number);
                    this.clock = this.activities[0].actions.clock;
                    this.wc = this.activities[0].actions.wc;
                    this.lunch = this.activities[0].actions.lunch;
                    console.log("this.clock: ", this.clock);
                    console.log("this.wc: ", this.wc);
                    console.log("this.lunch: ", this.lunch);
                    this.closedDay = this.activities[0].status;
                }
            }
            else {
                console.log('<<<<<<<<<<< Clocking out'); // Mensaje al hacer clock out
                //response = await addTodayActivity("CLOCK OUT", this.employee_number);
                this.showConfirmDialog();
            }


        },
        async statusActions(reason) {

        },
        getRowClass(item) {
            switch (item.reason) {
                case 'Other':
                    return 'row-other';
                case 'Outbound MC':
                    return 'row-outbound-mc';
                case 'Info/Sold':
                    return 'row-info-sold';
                case 'Info/Not Sold':
                    return 'row-info-not-sold';
                case 'Complaint':
                    return 'row-complaint';
                case 'Schedule':
                    return 'row-schedule';
                case 'Outbound FU':
                    return 'row-outbound-fu';
                case 'Chat':
                    return 'row-chat';
                case 'Personal FU':
                    return 'row-personal-fu';
                case 'Walk In':
                    return 'row-walk-in';
                case 'CLOCK IN':
                    return 'row-clock-in';
                case 'CLOCK OUT':
                    return 'row-clock-out';
                default:
                    return '';
            }
        },
        redirectToLink(link) {
            if (link) {
                window.open(link, '_blank');
            }
        },
        resetFilters() {
            this.period.end_time = null;
            this.period.start_time = null;
            this.period.end_date = null;
            this.period.start_date = null;
            this.locationSelected = null;
            //this.employeeSelected = null;
            this.filters = {
                creationDate: null,
                location: null,
                staff_name: null,
                reason: null,
            };
        },
        async searchFilters() {
            this.showLoader = true;
            if (this.period.end_time === null && this.period.start_time != null) {
                this.period.end_time = '23:59';
                console.log("this.period.end_time: ", this.period.end_time);
            }
            if (this.period.end_date === null && this.period.start_date !== null) {
                //this.period.end_date = this.period.start_date
                const today = new Date();
                const yyyy = today.getFullYear();
                const mm = String(today.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son de 0 a 11, por eso sumamos 1
                const dd = String(today.getDate()).padStart(2, '0');

                // Formato yyyy-mm-dd
                this.period.end_date = `${yyyy}-${mm}-${dd}`;
            }
            this.filters.creationDate = [this.period.start_date, this.period.end_date];
            this.filters.creationTime = [this.period.start_time, this.period.end_time];
            // Aquí se realiza la búsqueda filtrada            
            console.log("filters: ", this.filters);
            this.filters.staff_name = this.name_staff;
            this.walk_ins_forms = await filterWalkIns(this.filters);
            console.log("Walk ins: ", this.walk_ins_forms);
            this.showLoader = false;
        },
        async handleInfoFromChild(info) {
            console.log("info: ", info);
            this.walkPreview = info; //Close dialog
            this.walk_ins_forms = await this.get_WalkIns_Forms();
            console.log("this.walk_ins_forms: ", this.walk_ins_forms);
            this.walkItem = null;
            this.walkIndex = -1;
        },
        openForm() {
            console.log("Opening Form Dialog");
            this.walkPreview = true;
        },
        formatDate(date) {
            const chicagoTime = DateTime.fromISO(date, { zone: 'utc' }).setZone('America/Chicago');
            // Format the date in 'yyyy-MM-dd HH:mm:ss' format
            return chicagoTime.toFormat('yyyy-MM-dd HH:mm:ss');
        },
        preview(item, index) {
            console.log("########## Preview......");
            console.log("item: ", item);
            console.log("index: ", index);
            this.walkPreview = true;
            this.walkIndex = index;
            this.walkItem = item;
            this.itemPreview = index;
        },
        async refreshActivities() {
            //this.walk_ins_forms = await this.get_WalkIns_Forms();
            this.activities = await fetchTodayActivities(this.employee_number);

            if (this.activities.length === 0) {
                this.clockIn = true;
                this.lunchIn = false;
                this.wcIn = false;
                this.clock = null;
                this.wc = null;
                this.lunch = null;
                this.closedDay = false;
            } else {
                this.clock = this.activities[0].actions.clock;
                this.wc = this.activities[0].actions.wc;
                this.lunch = this.activities[0].actions.lunch;
                this.closedDay = this.activities[0].status;
            }
        },
        async get_WalkIns_Forms() {
            try {
                this.showLoader = true;
                console.log("Getting training logs.....")
                console.log("employee_number: ", this.employee_number);

                //const url = `${GET_WALK_INS_FORMS_BY_EMPLOYEE}${this.employee_number}`;
                //const response = await fetch(url); // Replace "your_endpoint" with the URL of your endpoint

                const data = await fetchTodayWalkIns_ByEmployee(this.employee_number);
                console.log("data: ", data);

                if (data.length === 0) {
                    this.showLoader = false;
                    return [];
                    //throw new Error('Failed to fetch record');
                }

                //const res = await response.json();
                //const data = response;
                //console.log("<<<<<<<<<<<<<<<<<<< res data: ", data);
                this.showLoader = false;
                return data;
            } catch (error) {
                this.showLoader = false;
                console.error('Error fetching record:', error);
            }
        },
    },
}
</script>

<style>
.cursor-pointer {
    cursor: pointer;
    /* Cambia el cursor al pasar sobre la imagen */
}

.wrap-column {
    white-space: normal !important;
    word-wrap: break-word !important;
    overflow-wrap: anywhere !important;
    text-align: left;
}

/* Tonos pastel */
.row-other {
    background-color: #ffd1dc !important;
    /* Rosado pastel */
}

.row-outbound-mc {
    background-color: #fff9c4 !important;
    /* Amarillo pastel */
}

.row-info-sold {
    background-color: #daffb0 !important;
    /* Verde pastel */
}

.row-info-not-sold {
    background-color: #ffcdd2 !important;
    /* Rojo pastel */
}

.row-complaint {
    background-color: #b2ebf2 !important;
    /* Turquesa pastel */
}

.row-schedule {
    background-color: #ffe0b2 !important;
    /* Naranja pastel */
}

.row-outbound-fu {
    background-color: #bbdefb !important;
    /* Azul pastel */
}

.row-chat {
    background-color: #f8bbd0 !important;
    /* Rosado pastel más suave */
}

.row-personal-fu {
    background-color: #e1bee7 !important;
    /* Morado pastel */
}

.row-walk-in {
    background-color: #90caf9 !important;
    /* Azul marino pastel */
}

.row-clock-in {
    background-color: #a4ff9c !important;
    /* Azul marino pastel */
}

.row-clock-out {
    background-color: #ff9090 !important;
    /* Azul marino pastel */
}
</style>