<template>
    <user-walk-ins-console />
</template>

<script>
import UserWalkInsConsole from '../../components/Walk_ins/UserWalkInsConsole.vue'

export default {
    name: 'UserWalkIns',

    components: {
        UserWalkInsConsole,
    },
}
</script>