import { INSURANCE, INSURANCE_BY_NAME } from '../utils/endpoints.js'

// Author: JDGT

/**
 * Create a new insurance record.
 * @param {string} insuranceName - The name of the insurance to create.
 * @returns {Promise<response>} The created insurance record.
 */
export async function createInsurance(insuranceName) {
  try {
    const response = await fetch(INSURANCE, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(insuranceName)
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`Error: ${response.statusText} - ${errorData.error}`);
    }

    const data = await response.json();
    console.log('Insurance created:', data);
    return response;
  } catch (error) {
    console.error('Error creating insurance:', error);
    return null;
  }
}

/**
 * Retrieve all insurance records.
 * @returns {Promise<Array>} A list of all insurance records.
 */
export async function getInsurances() {
  try {
    const response = await fetch(INSURANCE, {
      method: 'GET',
    });
    const result = await handleResponse(response);
    console.log('Insurances entries retrieved successfully:', result);
    return result.json();
  } catch (error) {
    console.error('Error retrieving Insurances entries:', error);
    throw error;
  }
}
/**
 * Handles the response from fetch requests.
 * 
 * @param {Response} response - The response object from the fetch request.
 * @returns {Promise<Object>} - The JSON response if the request is successful.
 * @throws Will throw an error if the response is not OK.
 */
export async function handleResponse(response) {
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || 'Unknown error');
  }
  return response;
}


/**
 * Retrieve a single insurance record by ID.
 * @param {number} id - The ID of the insurance to retrieve.
 * @returns {Promise<Object>} The retrieved insurance record.
 */
export async function getInsurance(id) {
  try {
    const response = await fetch(`${INSURANCE}/${id}`);

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`Error: ${response.statusText} - ${errorData.error}`);
    }

    const data = await response.json();
    console.log(`Insurance ${id}:`, data);
    return data;
  } catch (error) {
    console.error(`Error fetching insurance ${id}:`, error);
    return null;
  }
}

/**
 * Update an existing insurance record by ID.
 * @param {number} id - The ID of the insurance to update.
 * @param {string} insuranceName - The new name of the insurance.
 * @returns {Promise<response>} The updated insurance record.
 */
export async function updateInsurance(id, insuranceName) {
  try {
    const response = await fetch(`${INSURANCE}/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(insuranceName)
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`Error: ${response.statusText} - ${errorData.error}`);
    }

    const data = await response.json();
    console.log(`Insurance ${id} updated:`, data);
    return response;
  } catch (error) {
    console.error(`Error updating insurance ${id}:`, error);
    return null;
  }
}

/**
 * Delete an existing insurance record by ID.
 * @param {number} id - The ID of the insurance to delete.
 * @returns {Promise<response>}
 */
export async function deleteInsurance(id) {
  try {
    const response = await fetch(`${INSURANCE}/${id}`, {
      method: 'DELETE'
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`Error: ${response.statusText} - ${errorData.error}`);
    }

    console.log(`Insurance ${id} deleted`);
    return response;
  } catch (error) {
    console.error(`Error deleting insurance ${id}:`, error);
  }
}

/**
 * Author: JDGT
 * Description: Function to fetch insurance records by insurance_name.
 * 
 * @param {string} insuranceName - The name of the insurance company to search for.
 * @returns {Promise<Object[]>} - Returns a promise that resolves to an array of insurance records.
 */
export async function getInsuranceByName(insuranceName) {
  try {
      // Verifica que el insuranceName no esté vacío
      if (!insuranceName) {
          throw new Error("insurance_name is required.");
      }

      // Realiza la solicitud GET al endpoint con insurance_name como parámetro
      const response = await fetch(`${INSURANCE_BY_NAME}${encodeURIComponent(insuranceName)}`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          }
      });

      // Manejo de las respuestas
      if (response.ok) {
          const data = await response.json();
          return data; // Devuelve los registros encontrados
      } else if (response.status === 404) {
          console.log("No records found for the given insurance_name.");
          return []; // Devuelve un array vacío si no se encuentran registros
      } else {
          // Manejo de otros posibles códigos de error
          const errorData = await response.json();
          console.error("Error:", errorData.error);
          throw new Error(errorData.error); // Lanza un error para ser capturado
      }
  } catch (error) {
      console.error("Error fetching insurance records:", error);
      throw error; // Lanza el error para ser manejado por el llamador
  }
}