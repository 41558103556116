<template>
  <v-container class="fill-height d-flex align-center justify-center max-width-container" >
    <EmployeesCard />
  </v-container>
</template>

<script>
import EmployeesCard from '../../components/Employees/EmployeesCard.vue';
export default {
  name: 'EmployeesForm',
  components: {
    EmployeesCard,
  },
  data() {
    return {
      //dialog: false,
    };
  },
}
</script>

<style scoped>
.max-width-container {
    max-width: 950px !important; /* Puedes ajustar este valor según tus necesidades */
}
</style>