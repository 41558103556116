<template>
    <vehicles />
</template>

<script>
import Vehicles from '../../components/Fleet/Vehicles.vue'

export default {
    name: 'VehiclesView',
    components: {
        Vehicles,
    },
}
</script>