<template>
    <div>

        <v-row class="pt-4 px-4">
            <v-col cols="6" sm="6" md="6">
                <v-card>
                    <v-card-title>
                        <span class="headline">Enter Zip Code Details</span>
                    </v-card-title>

                    <v-card-text>
                        <v-form>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field v-model="editedItem.zip_code" label="Zip Code"
                                            required></v-text-field>
                                        <v-text-field v-model="editedItem.city_district"
                                            label="City District"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field v-model="editedItem.state" label="State"></v-text-field>
                                        <v-text-field v-model="editedItem.state_code" label="State Code"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field v-model="editedItem.province" label="Province"></v-text-field>
                                        <v-text-field v-model="editedItem.province_code"
                                            label="Province Code"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field v-model="editedItem.latitude" label="Latitude"
                                            type="number"></v-text-field>
                                        <v-text-field v-model="editedItem.longitude" label="Longitude"
                                            type="number"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="saveZipCode">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>

            </v-col>
            <v-col cols="6" sm="6" md="6">
                <v-card height="520">
                    <v-card-title>Zip Codes</v-card-title>
                    <v-card-text>
                        <v-text-field v-model="search" label="Search" class="mb-4"></v-text-field>
                        <v-data-table :headers="headers" :items="filteredZipCodes" item-key="id" class="elevation-1"
                            :search="search" :items-per-page="5" sort-by="zip_code">
                            <template v-slot:items="props">
                                <td>{{ props.item.zip_code }}</td>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-icon small class="mr-2" :style="{ color: 'blue' }" @click="updateItem(item)">
                                    mdi-pencil
                                </v-icon>
                                <v-icon small :style="{ color: 'red' }" @click="deleteZipCode(item)">
                                    mdi-delete
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { GET_ALL_LOCATIONS_JSON, DELETE_ZIP_CODE_BY_ID, GET_ACTIVE_ZIP_CODES, ADD_ZIP_CODE, UPDATE_ZIP_CODE } from '../../utils/endpoints';
import { bus } from '../../main.js';

export default {
    data: () => ({
        headers: [
            { text: 'Zip Code', value: 'zip_code' },
            { text: 'Actions', value: 'actions', sortable: false },
            { text: 'City', value: 'city_district' },
            { text: 'State', value: 'state' },
            { text: 'State Code', value: 'state_code' },
            { text: 'Province', value: 'province' },
        ],
        editedIndex: -1,
        editedItem: {
            zip_code: '',
            city_district: '',
            state: '',
            state_code: '',
            province: '',
            province_code: '',
            latitude: 0.0,
            longitude: 0.0,
            active: true,
        },
        defaultItem: {
            zip_code: '',
            city_district: '',
            state: '',
            state_code: '',
            province: '',
            province_code: '',
            latitude: 0.0,
            longitude: 0.0,
            active: true,
        },        
        search: '',        
        zip_codes: [],
    }),
    computed: {
        filteredZipCodes() {
            if (!this.search.trim()) {
                return this.zip_codes;
            } else {
                return this.zip_codes.filter(zip_code =>
                    zip_code.zip_code.toLowerCase().includes(this.search.toLowerCase())
                );
            }
        }
    },
    async mounted() {
        //console.log("-------------------MOUNTED.......................")
        bus.$emit('login-success');
        bus.$emit('permissions');
        this.zip_codes = await this.fetchActiveZipcodes();
    },
    methods: {
        async editZipcode(id, data) {
            const url = `${UPDATE_ZIP_CODE}${id}`;

            try {
                const response = await fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const responseData = await response.json();
                console.log(responseData); // Aquí puedes manejar la respuesta como desees
                return response;
            } catch (error) {
                console.error('There has been a problem with your fetch operation:', error);
            }
        },
        updateItem(item) {
            console.log("item: ", item);
            this.editedItem = item;
            this.editedIndex = item.id;
        },
        clearForm() {
            this.editedItem = this.defaultItem;
            this.editedIndex = -1;
        },
        async addZipcode(data) {
            try {
                console.log("Adding zip code...");
                console.log("data: ", data);
                const response = await fetch(ADD_ZIP_CODE, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const responseData = await response.json();
                console.log(responseData); // Aquí puedes manejar la respuesta como desees
                return response;
            } catch (error) {
                console.error('There has been a problem with your fetch operation:', error);
            }
        },
        async saveZipCode() {
            console.log("Edited Item: ", this.editedItem);
            let res = null;
            console.log(this.editedItem);
            if (this.editedIndex != -1) {
                console.log("Updating new Zip Code");
                res = await this.editZipcode(this.editedIndex, this.editedItem);                                
            } else {
                console.log("Adding new Zip Code");
                res = await this.addZipcode(this.editedItem);                
            }
            console.log("res: ", res);
            if (res.ok) {
                this.zip_codes = await this.fetchActiveZipcodes();
                this.clearForm();
                this.editedIndex = -1;
            }
            // Realiza una solicitud POST a tu backend para guardar la información
        },

        async fetchActiveZipcodes() {
            try {
                const response = await fetch(GET_ACTIVE_ZIP_CODES);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                console.log(data); // Aquí puedes procesar los datos como desees
                return data;
            } catch (error) {
                console.error('There has been a problem with your fetch operation:', error);
            }
        },
        async deleteZipCode(item) {
            console.log("item: ", item);
            const id = item.id;
            console.log("id: ", id);
            const res = await this.deleteZip_code(id);
            console.log("res: ", res);
            if (res.ok) {
                this.zip_codes = await this.fetchActiveZipcodes();
            }
        },
        async deleteZip_code(id) {
            const url = `${DELETE_ZIP_CODE_BY_ID}${id}`;
            console.log("url: ", url);
            try {
                const response = await fetch(url, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                console.log(data); // Aquí puedes manejar la respuesta como desees
                return response;
            } catch (error) {
                console.error('There has been a problem with your fetch operation:', error);
            }
        },
        async getLocations() {
            try {
                const response = await fetch(GET_ALL_LOCATIONS_JSON); // Endpoint donde obtienes las ubicaciones
                if (!response.ok) {
                    throw new Error('Failed to fetch locations');
                }
                const data = await response.json();
                this.locations = data; // Asignar datos a la variable locations
            } catch (error) {
                console.error('Error fetching locations:', error);
                alert('Error fetching locations. Please try again later.');
            }
        },
    },
    watch: {

    }
}

</script>

<style scoped></style>