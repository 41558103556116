<template>
    <credit-card-management />
</template>

<script>
import CreditCardManagement from '../../components/Accounting/CreditCardManagement.vue'

export default {
    name: 'CreditCardManagementView',
    components: {
        CreditCardManagement,
    },
}
</script>