export function get_role() {
    const token = localStorage.getItem('token');

    if (token) {
        try {
            const tokenParts = token.split('.');
            const payload = JSON.parse(atob(tokenParts[1]));
            console.log("payload: ", payload);
            const role = payload.role;
            const walk_ins = payload.walk_ins;
            const training_logs = payload.training_logs;
            const employee_number = payload.employee_number;
            const name = payload.name;
            const last_name = payload.last_name;
            return {
                role: role,
                walk_ins: walk_ins,
                training_logs: training_logs,
                employee_number: employee_number,
                name: name,
                last_name: last_name
            };
        } catch (error) {
            return { role: null };
        }
    } else {
        return { role: null };
    }
}
