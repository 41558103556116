<template>
    <v-row class="pt-4 px-4">
        <v-col cols="12" sm="12" md="12">
            <v-card height="800">
                <v-card-text>
                    <v-toolbar flat>
                        <v-toolbar-title class="font-weight-bold">Chart of Accounts</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-text-field v-model="search" label="Search by Account Code" solo-inverted prepend-icon="mdi-magnify"
                            class="mr-3 mt-5"></v-text-field>
                        <v-btn color="blue" dark class="mb-2 mr-2" @click="getItems">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                        <v-btn color="success" dark class="mb-2 mr-2" @click="openDialog(null)">
                            <v-icon>mdi-plus-thick</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-data-table :headers="headers" :items="filteredCharts" item-key="id" class="elevation-1"
                        :search="search" :items-per-page="10" sort-by="account_code">
                        <template v-slot:items="props">
                            <td>{{ props.item.account_anme }}</td>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-icon small class="mr-2" :style="{ color: 'blue' }" @click="openDialog(item)">
                                mdi-pencil
                            </v-icon>
                            <v-icon small :style="{ color: 'red' }" @click="deleteItem(item)">
                                mdi-delete
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-col>
        <v-dialog v-model="dialogOpen" max-width="700" :key="dialogOpen.toString()">
            <v-card class="dialog-card" outlined>
                <ChartOfAccountCard :Index="editedIndex" :Item="editedItem" @sent-info="handleInfoFromChild" />
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { bus } from '../../main.js';
import { deleteChartOfAccount, getAllChartOfAccounts } from '../../utils/chart_of_accounts_CRUD.js'
import ChartOfAccountCard from '../../components/Accounting/ChartOfAccountCard.vue';

export default {
    components: {
        ChartOfAccountCard
    },
    data: () => ({
        dialogOpen: false,
        search: '',
        charts: [],
        headers: [
            { text: 'Actions', value: 'actions', sortable: false },
            { text: 'Account Type', value: 'account_type' },
            { text: 'Account Name', value: 'account_name' },
            { text: 'Account Code', value: 'account_code' },
            { text: 'Description', value: 'description' },
            { text: 'Mileage Rate', value: 'mileage_rate' },
            { text: 'Mileage Unit', value: 'mileage_unit' },
            { text: 'Is Mileage', value: 'is_mileage' },
            { text: 'Account Number', value: 'account_number' },
            { text: 'Currency', value: 'currency' },
            { text: 'Parent Account', value: 'parent_account' },
        ],
        editedItem: {
            id: '',
            account_code: '',
            account_name: '',
            description: '',
            account_type: '',
            mileage_rate: 0.0,
            mileage_unit: '',
            is_mileage: false,
            account_number: '',
            currency: '',
            parent_account: '',
        },
        defaultEditedItem: {
            id: '',
            account_code: '',
            account_name: '',
            description: '',
            account_type: '',
            mileage_rate: 0.0,
            mileage_unit: '',
            is_mileage: false,
            account_number: '',
            currency: '',
            parent_account: '',
        },
        editedIndex: -1,
    }),
    async mounted() {
        //console.log("-------------------MOUNTED.......................")
        bus.$emit('login-success');
        bus.$emit('permissions');
        this.getItems();
    },
    methods: {
        handleInfoFromChild(info) {
            // Manejar la información recibida desde el hijo
            console.log('Información recibida desde el hijo:', info);
            this.dialogOpen = info; //Close dialog
            this.getItems(); //Refresh employees table
        },
        async getItems() {
            this.charts = await getAllChartOfAccounts();
        },
        openDialog(item) {
            console.log("Opening dialog....");
            console.log("Item: ", item);
            if (item != null) {
                this.editedIndex = item.id;
                console.log(this.editedIndex);
                this.editedItem = Object.assign({}, item);
            }
            this.dialogOpen = true;
        },
        async deleteItem(item) {
            console.log("item: ", item);
            console.log("id: ", item.id);
            const response = await deleteChartOfAccount(item.id);
            if (response.ok) {
                this.charts = await getAllChartOfAccounts();
            }
        },
    },
    computed: {
        filteredCharts() {
            if (!this.search.trim()) {
                return this.charts.data;
            } else {
                return this.charts.data.filter(chart =>
                    chart.account_name.toLowerCase().includes(this.search.toLowerCase())
                );
            }
        }
    },
    watch: {
        dialogOpen(val) {
            console.log("val: ", val);
            if (!val) {
                this.editedItem = this.defaultEditedItem;
                this.editedIndex = -1;
            }
        }
    }
}
</script>