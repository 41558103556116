<template>
    <trainer-interface />
</template>

<script>
import TrainerInterface from '../../components/Training_log/TrainerInterface'

export default {
    name: 'TrainerInterfaceView',
    components: {
        TrainerInterface,
    },
}
</script>