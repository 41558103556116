<template>
    <div>
        <v-row class="pt-4 px-4">
            <v-col cols="12" sm="12" md="12" class="d-flex justify-center">
                <v-card height="auto"><!-- width="900" -->
                    <v-card-text>
                        <v-data-table :headers="headers" :items="filteredItems" sort-by="coverage"
                            class="elevation-1"><!-- @click:row="openDialog" -->
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-toolbar-title class="font-weight-bold">Computer Equipment
                                        Inventory</v-toolbar-title>
                                    <v-divider class="mx-4" inset vertical></v-divider>
                                    <v-select v-model="statusSelected" :items="status"
                                        style="max-width: 220px; margin-top: 30px; margin-right: 10px;"
                                        label="Filter Active/Inactive" @input="getItemsStatus"></v-select>
                                    <v-text-field v-model="search" label="Search" solo-inverted
                                        prepend-icon="mdi-magnify" class="mr-3 mt-5"></v-text-field>
                                    <v-btn color="blue" dark class="mb-2 mr-2" @click="refreshButton">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                    <v-btn color="success" dark class="mb-2 mr-2" @click="openDialog">
                                        <v-icon>mdi-plus-thick</v-icon>
                                    </v-btn>
                                </v-toolbar>
                            </template>
                            <template v-slot:item.is_active="{ item }">
                                <v-icon :color="item.is_active ? 'green' : 'red'">
                                    {{ item.is_active ? 'mdi-check-bold' : 'mdi-close-thick' }}
                                </v-icon>
                            </template>

                            <template v-slot:item.assigned="{ item }">
                                <v-icon :color="item.assigned ? 'green' : 'red'">
                                    {{ item.assigned ? 'mdi-check-bold' : 'mdi-close-thick' }}
                                </v-icon>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-icon small class="mr-2" :style="{ color: 'orange' }"
                                    @click="openHistoryDialog(item)">
                                    mdi-history
                                </v-icon>

                                <v-icon v-if="item.is_active" small class="mr-2" :style="{ color: 'blue' }"
                                    @click="openDialog(item)">
                                    mdi-pencil
                                </v-icon>
                                <v-icon v-if="item.is_active" small :style="{ color: 'red' }" @click="deleteItem(item)">
                                    mdi-delete
                                </v-icon>
                            </template>
                            <template v-slot:item.history.employee="{ item }">
                                <span v-if="item.history && item.history.employee && item.history.employee.length > 0">
                                    {{ item.history.employee[item.history.employee.length - 1].name }}
                                    {{ item.history.employee[item.history.employee.length - 1].last_name }}
                                </span>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-dialog v-model="dialogOpen" max-width="700"><!--  -->
                <v-card class="dialog-card" outlined>
                    <ComputerEquipmentCard :Index="editedIndex" :Item="editedItem" :key="dialogOpen.toString()"
                        @sent-info="handleInfoFromChild" />
                </v-card>
            </v-dialog>
            <!-- Modal de Historial -->
            <v-dialog v-model="historyDialog" max-width="600px">
                <v-card>
                    <v-card-title>
                        <span class="headline">Employee History</span>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table :headers="historyHeaders" :items="selectedItemHistory" class="elevation-1">
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>
        <div class="loader-container" v-if="showloader">
            <!-- <l-bouncy size="150" speed="0.8" color="#FF00F7"></l-bouncy> -->
            <l-grid size="75" speed="1.5" color="red"></l-grid>
        </div>
    </div>
</template>

<script>
import { bus } from '../../main.js';
import { getAllComputerEquipment, deleteComputerEquipment, deactivateComputerEquipment } from '../../utils/It/computerEquipment_CRUD.js'
import ComputerEquipmentCard from '../../components/It/ComputerEquipmentCard.vue'
import _ from 'lodash';
import { grid } from 'ldrs'

grid.register()

export default {
    components: {
        /* TypeOfCoverageCard */
        ComputerEquipmentCard
    },
    data: () => ({
        // Status
        status: 'active',
        statusSelected: 'Active',
        status: ['Active', 'Inactive', 'All'],
        // History
        historyDialog: false,
        selectedItemHistory: [], // Para almacenar el historial del equipo seleccionado
        //////////////////////////////
        showloader: false,
        historyHeaders: [
            { text: 'Employee Number', value: 'employee_number' },
            { text: 'First Name', value: 'name' },
            { text: 'Last Name', value: 'last_name' }
        ],
        headers: [
            { text: 'Actions', value: 'actions', sortable: false },
            { text: 'UUID', value: 'uuid' },
            { text: 'Equipment Name', value: 'equipment_name' },
            { text: 'Assigned to', value: 'history.employee' },
            { text: 'Brand', value: 'brand' },
            { text: 'Serial Number', value: 'serial_number' },
            { text: 'Condition', value: 'condition' },
            { text: 'Code', value: 'code' },
            { text: 'Observations', value: 'observations' },
            { text: 'Is Active', value: 'is_active' },
            { text: 'Assigned', value: 'assigned' },
            { text: 'Created At', value: 'created_at' }
        ],
        dialogOpen: false,
        search: '',
        items: [],
        editedIndex: '',
        editedItem: {
            equipment_name: '',
            brand: '',
            serial_number: 'N/A', // default value
            condition: '',
            code: '',
            observations: ''
        },
        defaultItem: {
            equipment_name: '',
            brand: '',
            serial_number: 'N/A', // default value
            condition: '',
            code: '',
            observations: ''
        },
    }),
    computed: {
        filteredItems() {
            if (!this.search.trim()) {
                return this.items;
            } else {
                return this.items.filter(item =>
                    item.equipment_name.toLowerCase().includes(this.search.toLowerCase())
                );
            }
        }
    },
    async mounted() {
        ////console.log("-------------------MOUNTED.......................")
        bus.$emit('login-success');
        bus.$emit('permissions');
        await this.fetchItems('active');
    },
    methods: {
        async refreshButton() {
            console.log("<<<<<<<<<<< refreshButton");
            const response = await this.fetchItems('active');
            console.log("response: ", response);
            if (response) {
                this.statusSelected = 'Active';
                console.log("this.statusSelected : ", this.statusSelected);
            }
        },
        async getItemsStatus(item) {
            console.log("Filtering......", item);
            this.logsFilter = item;
            if (item === 'Active') {
                await this.fetchItems('active');
            } else if (item === 'Inactive') {
                await this.fetchItems('inactive');
            } else {
                await this.fetchItems('all');
            }
        },
        openHistoryDialog(item) {
            // Almacenar el historial del equipo seleccionado
            this.selectedItemHistory = item.history.employee;
            // Abrir el modal de historial
            this.historyDialog = true;
        },
        handleInfoFromChild(info) {
            this.dialogOpen = info; //Close dialog
            this.fetchItems('active'); //Refresh employees table
        },
        updateItem(item) {
            this.editedItem = Object.assign({}, item);
            this.editedIndex = this.editedItem.uuid;
            this.dialogOpen = true;
        },
        async deleteItem(item) {
            console.log("item: ", item)
            if (!item.assigned) {
                const response = await deactivateComputerEquipment(item.uuid);
                if (response.ok) {
                    await this.fetchItems('active');
                }
            }
        },
        openDialog(item) {
            console.log("Opening dialog.....", item)
            //this.editedItem = Object.assign({}, item);
            this.editedIndex = item.uuid;
            if (this.editedIndex != '') {

                /* this.editedItem = _.cloneDeep(this.Item); */
                this.editedItem = Object.assign({}, item);

                console.log("Updating Comission...");
            }
            this.dialogOpen = true;
        },
        async fetchItems(status) {
            try {
                this.showloader = true;
                this.items = await getAllComputerEquipment(status);
                console.log("this.items: ", this.items);
                this.showloader = false;
                return true;
            } catch (error) {
                console.error("Error fetching: ", error);
            }
        }
    },
    watch: {
        dialogOpen(val) {
            //console.log(">>>>>>>>>>>>>>>>>> val: ", val);
            if (!val) {
                this.editedItem = this.defaultEditedItem;
                this.editedIndex = '';
                this.fetchItems('active');
            }
        },
    }
}
</script>

<style scoped>
.loader-container {
    position: fixed;
    /* Para que el loader esté por encima de todo el contenido */
    top: 0;
    left: 0;
    width: 100%;
    /* Ocupa todo el ancho del viewport */
    height: 100%;
    /* Ocupa todo el alto del viewport */
    display: flex;
    justify-content: center;
    /* Centra horizontalmente */
    align-items: center;
    /* Centra verticalmente */
    background-color: rgba(255, 255, 255, 0.5);
    /* Fondo semitransparente */
    z-index: 9999;
    /* Coloca el loader por encima de todo el contenido */
}

.dialog-height {
    max-height: auto !important;
    min-height: auto !important;
}

.dialog-card {
    padding: 0;
    /* Elimina el relleno interno */
    margin: 0;
    /* Elimina los márgenes */
}
</style>