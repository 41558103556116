<template>
    <user-activity-report />
</template>

<script>
import UserActivityReport from '../../components/Sales/UserActivityReport.vue'

export default {
    name: 'UserActivityReportConsole',

    components: {
        UserActivityReport,
    },
}
</script>