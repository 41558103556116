<template>
  <v-container>
    <v-row class="d-flex justify-center">
      <v-col cols="6"><!-- cols="5" sm="5" md="5" -->
        <v-card>
          <v-card-title>
            <span class="headline">Nova Lead System</span><!--  -->
          </v-card-title>
          <v-divider></v-divider><!-- class="mt-4 mb-6" -->
          <v-col>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-row>
                    <v-col>
                      <div>
                        <v-file-input label="Lead list" v-model="file"
                          accept=".xlsx,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel">
                        </v-file-input>
                        <v-combobox v-model="selectedAction" :items="actions" label="Select an Action" clearable
                          outlined></v-combobox>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="d-flex justify-end">
                  <v-btn color="blue darken-1" text @click="save">
                    UPLOAD
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
    <div class="loader-container" v-if="showLoader">
      <div class="loader"></div>
    </div>
  </v-container>
</template>


<script>
import * as xlsx from 'xlsx';
import emailValidator from 'email-validator';
import { bus } from '../../main.js'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { uploadLeads } from '../../utils/Marketing/leadsystem.js'
dayjs.extend(utc);

export default {
  data: () => ({
    showLoader: false,
    email: false,
    sms: false,
    selectedAction: '', // Variable para almacenar la acción seleccionada
    actions: ['Email', 'SMS', 'Email and SMS'],
    file: null,
    loading: false,
    results: false,
    error: false,
    validCount: 0,
    invalidCount: 0,
    repeatedCount: 0,
    validLink: '',
    invalidLink: '',
    repeatedLink: '',
  }),
  mounted() {
    bus.$emit('login-success');
    bus.$emit('permissions');
  },
  methods: {
    async save() {
      try {
        this.showLoader = true;
        if (this.selectedAction != '' && this.file != null) {
          if (this.selectedAction === 'SMS') {
            this.email = false;
            this.sms = true;
          } else if (this.selectedAction === 'Email') {
            this.email = true;
            this.sms = false;
          } else if (this.selectedAction === 'Email and SMS') {
            this.email = true;
            this.sms = true;
          }
          const action = {
            "type": [{
              "email": this.email,
              "sms": this.sms,
              "dateTime": dayjs().utc().format('YYYY-MM-DD HH:mm:ss.SSS')
            }]
          };
          console.log("action: ", action);
          const res = await uploadLeads(this.file, action);
          console.log("res: ", res);
          this.showLoader = false;
        } else {
          if (this.file === null) {
            alert("You must upload a file");
          }
          if (this.selectedAction === '') {
            alert("You must select an Action");
          }
        }
      } catch (error) {
        console.error("Error: ", error)
      }
    },

    async processFile() {
      if (!this.file) {
        this.error = true;
        return;
      }

      this.loading = true;
      this.results = false;
      this.error = false;

      const reader = new FileReader();
      reader.onload = async (event) => {
        try {
          const data = new Uint8Array(event.target.result);
          const workbook = xlsx.read(data, { type: 'array' });
          const sheet = workbook.Sheets[workbook.SheetNames[0]];
          const leads = xlsx.utils.sheet_to_json(sheet);

          const validLeads = [];
          const invalidLeads = [];
          const repeatedLeads = [];
          const emailCounts = {};
          const invalidEmails = new Set(['no@gmail.com', 'none@a.com', 'n@n.com', 'q@q.com', 'nomail@mail.com', 'none@gmail.com', 'none@none.com']);
          const invalidEmailPattern = /[^a-zA-Z0-9@._-]/;

          for (const lead of leads) {
            const email = lead.Email;
            const lowerEmail = email.toLowerCase();

            if (emailValidator.validate(email) && !invalidEmails.has(lowerEmail) && !invalidEmailPattern.test(email)) {
              if (!emailCounts[lowerEmail]) {
                emailCounts[lowerEmail] = { count: 0, lead };
              }
              emailCounts[lowerEmail].count += 1;
            } else {
              invalidLeads.push(lead);
            }
          }

          for (const [email, { count }] of Object.entries(emailCounts)) {
            if (count === 1) {
              validLeads.push({ Email: email });
            } else {
              repeatedLeads.push({ Email: email, Count: count });
            }
          }

          const validSheet = xlsx.utils.json_to_sheet(validLeads);
          const invalidSheet = xlsx.utils.json_to_sheet(invalidLeads);
          const repeatedSheet = xlsx.utils.json_to_sheet(repeatedLeads);

          const validWorkbook = xlsx.utils.book_new();
          const invalidWorkbook = xlsx.utils.book_new();
          const repeatedWorkbook = xlsx.utils.book_new();

          xlsx.utils.book_append_sheet(validWorkbook, validSheet, 'Valid Leads');
          xlsx.utils.book_append_sheet(invalidWorkbook, invalidSheet, 'Invalid Leads');
          xlsx.utils.book_append_sheet(repeatedWorkbook, repeatedSheet, 'Repeated Emails');

          const validBuffer = xlsx.write(validWorkbook, { type: 'base64' });
          const invalidBuffer = xlsx.write(invalidWorkbook, { type: 'base64' });
          const repeatedBuffer = xlsx.write(repeatedWorkbook, { type: 'base64' });

          this.validLink = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${validBuffer}`;
          this.invalidLink = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${invalidBuffer}`;
          this.repeatedLink = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${repeatedBuffer}`;

          this.validCount = validLeads.length;
          this.invalidCount = invalidLeads.length;
          this.repeatedCount = repeatedLeads.length;

          this.results = true;
        } catch (error) {
          console.error('Error:', error);
          this.error = true;
        } finally {
          this.loading = false;
        }
      };
      reader.readAsArrayBuffer(this.file);
    }
  }
};
</script>

<style scoped>
.text-center {
  text-align: center;
}

.loader-container {
  position: fixed;
  /* Para que el loader esté por encima de todo el contenido */
  top: 0;
  left: 0;
  width: 100%;
  /* Ocupa todo el ancho del viewport */
  height: 100%;
  /* Ocupa todo el alto del viewport */
  display: flex;
  justify-content: center;
  /* Centra horizontalmente */
  align-items: center;
  /* Centra verticalmente */
  background-color: rgba(255, 255, 255, 0.5);
  /* Fondo semitransparente */
  z-index: 9999;
  /* Coloca el loader por encima de todo el contenido */
}

.loader {
  width: 120px;
  height: 60px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  background: #fff;
  filter: blur(5px) contrast(10) hue-rotate(60deg);
  mix-blend-mode: darken;
}

.loader:before,
.loader:after {
  content: "";
  width: 40px;
  border-radius: 50%;
  background: #ff00ff;
  animation: l3 1s infinite alternate;
}

.loader:after {
  --s: -1;
}

@keyframes l3 {

  90%,
  100% {
    transform: translate(calc(var(--s, 1)*30px))
  }
}
</style>