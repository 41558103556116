import { PAYROLL } from '../../utils/endpoints.js'

/**
 * Get all payroll records.
 * @returns {Promise<Array>} The list of all payroll records.
 * @throws Will throw an error if the request fails.
 * @author Gabo
 */
export async function getPayrolls(employee_number) {
    try {
        const url = `${PAYROLL}${employee_number}`;
        console.log("url: ", url);
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Failed to fetch payroll records');
        }
        const data = await response.json();
        console.log("data: ", data);
        return data
    } catch (error) {
        console.error('Error fetching payroll records:', error);
        throw error;
    }
}
