<template>
    <v-col cols="12" sm="12" md="12">
        <v-card>
            <v-card-title>
                <span class="headline">Insurance Company</span>
            </v-card-title>
            <v-col>
                <v-card-text>
                    <v-form ref='form'>
                        <!-- Insurance Name -->
                        <v-row>
                            <!-- Insurance Name -->
                            <v-col cols="6">
                                <v-text-field v-model="editedItem.insurance_name" :rules="[rules.required]"
                                    label="Insurance Name" required></v-text-field>
                            </v-col>

                            <!-- Insured Under -->
                            <v-col cols="6">
                                <v-text-field v-model="editedItem.insured_under" 
                                    label="Insured Under" :rules="[rules.required]" required></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <!-- Address -->
                            <v-col cols="6">
                                <v-text-field v-model="editedItem.address" :rules="[rules.required]" label="Address"
                                    required></v-text-field>
                            </v-col>

                            <!-- EIN -->
                            <v-col cols="6">
                                <v-text-field v-model="editedItem.ein" :rules="[rules.required]" label="EIN"
                                    required></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="6">
                                <v-text-field v-model="editedItem.policy_number" :rules="[rules.required]"
                                    label="Policy Number" required></v-text-field>
                            </v-col>
                        </v-row>
                        
                        <v-divider class="mt-4 mb-6"></v-divider>
                        <h4>Drivers</h4>
                        <v-divider class="mt-4 mb-6"></v-divider>
                        <v-row>
                            <v-col cols="12">
                                <v-row>
                                    <!-- Driver Input and Add Button -->
                                    <v-col cols="6">
                                        <v-text-field v-model="driver" label="Driver" @keyup.enter="addDriver"
                                            append-outer-icon="mdi-plus-circle" @click:append-outer="addDriver"
                                            color="green"></v-text-field>
                                    </v-col>
                                </v-row>

                                <!-- List of Drivers -->
                                <v-row>
                                    <v-col cols="8">
                                        <v-list dense>
                                            <v-list-item v-for="(item, index) in editedItem.drivers.drivers"
                                                :key="index">
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ item }}</v-list-item-title>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    <v-btn icon @click="removeDriver(index)">
                                                        <v-icon color="red">mdi-delete</v-icon>
                                                    </v-btn>
                                                </v-list-item-action>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        <v-divider class="mt-4 mb-6"></v-divider>
                        <h4>Period</h4>
                        <v-divider class="mt-4 mb-6"></v-divider>
                        <v-row v-if="showEditDates">
                            <v-col>
                                <v-checkbox v-model="editDates" label="Edit Dates"></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <!-- Start Date -->
                            <v-col cols="6">
                                <v-menu v-model="startMenu" :close-on-content-click="false"
                                    transition="scale-transition" offset-y>
                                    <template v-slot:activator="{ on }">
                                        <v-text-field :readonly="editDates === false" v-model="period.startDate"
                                            label="Start Date" readonly v-on="on" prepend-icon="mdi-calendar"
                                            :rules="[rules.required]" required></v-text-field>
                                    </template>
                                    <v-date-picker :disabled="editDates === false" v-model="period.startDate"
                                        @input="startMenu = false"></v-date-picker>
                                </v-menu>
                            </v-col>

                            <!-- End Date -->
                            <v-col cols="6">
                                <v-menu v-model="endMenu" :close-on-content-click="false" transition="scale-transition"
                                    offset-y>
                                    <template v-slot:activator="{ on }">
                                        <v-text-field :readonly="editDates === false" v-model="period.endDate"
                                            label="End Date" readonly v-on="on" prepend-icon="mdi-calendar"
                                            :rules="[rules.required]" required></v-text-field>
                                    </template>
                                    <v-date-picker :disabled="editDates === false" v-model="period.endDate"
                                        @input="endMenu = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="submitForm">
                    SAVE
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
</template>

<script>
import { createInsurance, updateInsurance } from '../../utils/insurance_CRUD.js'
import _ from 'lodash';

export default {
    name: 'InsuranceCard',
    props: {
        Index: String,
        Item: Object
    },
    data: () => ({
        showEditDates: false,
        editDates: false,
        startMenu: false,
        endMenu: false,
        driver: '',
        period: {
            startDate: null,
            endDate: null,
        },
        editedItem: {
            id: '',
            insurance_name: '',
            insured_under: '',
            address: '',
            ein: '',
            drivers: {
                drivers: [],
            },
            policy_number: '',
            total_cost: 0.0,
            status: true,
            policy_period: {
                period: [],
            }
        },
        defaultItem: {
            id: '',
            insurance_name: '',
            insured_under: '',
            address: '',
            ein: '',
            drivers: {
                drivers: [],
            },
            policy_number: '',
            total_cost: 0.0,
            status: true,
            policy_period: {
                period: [],
            }
        },
        rules: {
            required: value => !!value || 'Required.',
        },
        editedIndex: -1,
    }),
    async mounted() {
        console.log("-------------------MOUNTED.......................")
        console.log("this.Index: ", this.Index);

        //this.setPeriod();
        this.$nextTick(() => {
            this.$refs.form.resetValidation();
        });
        console.log("this.Index: ", this.Index);
        if (this.Index != -1) {
            console.log("+++++++++ this.item: ", this.Item)
            //this.editedItem = this.Item;
            this.editedItem = _.cloneDeep(this.Item);
            /* this.editedItem = JSON.parse(JSON.stringify(this.Item));
            this.editedItem.policy_period = JSON.parse(JSON.stringify(this.Item.policy_period));
            this.editedItem.drivers = JSON.parse(JSON.stringify(this.Item.drivers)); */
            /* this.editedItem = Object.assign({}, this.Item);
            this.editedItem.policy_period = Object.assign({}, this.Item.policy_period);
            this.editedItem.drivers = Object.assign({}, this.Item.drivers);
            */
            this.editedIndex = this.Index;
            this.editedItem.id = this.Index;

            const lastPeriodAt = this.editedItem.policy_period.period.at(-1);
            this.period.startDate = lastPeriodAt.startDate;
            this.period.endDate = lastPeriodAt.endDate;
            this.showEditDates = true;
            console.log("lastPeriodAt: ", lastPeriodAt);

            console.log("this.editedIndex: ", this.editedIndex);
            console.log("this.editedItem: ", this.editedItem);

        } else {
            this.editedItem = this.defaultItem;
            this.editedIndex = -1;
            this.editDates = true;

        }
    },

    methods: {
        /* setPeriod() {
            if (!Array.isArray(this.editedItem.policy_period.period)) {
                console.log("Different to an array....");
                this.editedItem.policy_period.period = [];
            } else {
                console.log("It is an array...");
                if (this.editedItem.policy_period.period != null) {
                    console.log("Array different null...");
                    console.log("this.editedItem.policy_period.period: ", this.editedItem.policy_period.period);
                    const currentPeriod = this.editedItem.policy_period.period[-1]
                    console.log("currentPeriod: ", currentPeriod);
                }
            }
        }, */
        changeIconColor(color) {
            this.iconColor = color; // Cambia el color del ícono
        },
        addDriver() {
            console.log("Adding driver...", this.driver);
            try {
                console.log("type: ", typeof this.editedItem.drivers);
                console.log("Is drivers an array? ", Array.isArray(this.editedItem.drivers.drivers));
                if (this.driver.trim() !== '') {
                    /* if (!Array.isArray(this.editedItem.drivers)) {
                        console.log("ARRAY DRIVERS ES DIFERENTE DE ARRAY[......]");
                        this.editedItem.drivers.drivers = [];
                    } */
                    this.editedItem.drivers.drivers.push(this.driver);
                    this.driver = '';
                    console.log("Added driver:", this.editedItem.drivers.drivers);
                }
                console.log("----------- editedItem: ", this.editedItem);
            } catch (error) {
                console.error("Error adding driver: ", error);
            }
        },
        removeDriver(index) {
            this.editedItem.drivers.drivers.splice(index, 1);
        },
        async submitForm() {
            try {
                if (this.$refs.form.validate()) {
                    console.log('Form submitted:', this.editedItem);
                    console.log("this.editedItem.policy_period: ", this.editedItem.policy_period);
                    /* if (this.editedItem.policy_period.period === undifined) {
                        console.log("It us undefined...");
                    } else if (this.editedItem.policy_period.period === null) {
                        console.log("It is null....");
                    } else {
                        console.log("Other status.....");
                    } */
                    let response = null;
                    if (!Array.isArray(this.editedItem.policy_period.period)) {
                        console.log("Different to an array....");
                        this.editedItem.policy_period.period = [];
                    }
                    console.log("this.period: ", this.period);
                    if (this.editDates === true) {
                        this.editedItem.policy_period.period.push(this.period);
                    }
                    console.log("<<<<<<<<<<< this.editedItem: ", this.editedItem);
                    // ADD new record
                    if (this.editedIndex === -1) {
                        response = await createInsurance(this.editedItem);
                        console.log("response: ", response);
                    } else {
                        // Updating record
                        console.log("this.editedItem: ", this.editedItem);
                        response = await updateInsurance(this.editedIndex, this.editedItem);
                        console.log("Updating - response: ", response);
                    }
                    if (response.ok) {
                        this.$emit('sent-info', false);
                    }
                } else {
                    console.log('Form validation failed.');
                }
            } catch (error) {
                console.error("Error: ", error);
            }
        },
    },
}
</script>

<style scoped>
::v-deep .green-focus .v-input__control {
  border-color: green !important; /* Cambia el color del borde a verde */
}

::v-deep .green-focus .v-input__control .v-field__append-inner .v-icon {
  color: green !important; /* Cambia el color del icono a verde */
}

::v-deep .green-focus .v-input--is-focused .v-input__control .v-field__append-inner .v-icon {
  color: green !important; /* Asegúrate de que el icono se mantenga verde cuando esté enfocado */
}

::v-deep .green-focus .v-input--is-focused .v-input__control {
  border-color: green !important; /* Asegúrate de que el borde se mantenga verde cuando esté enfocado */
}
</style>
