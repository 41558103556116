<template>
    <new-admin-trainer-interface />
</template>

<script>
import NewAdminTrainerInterface from '../../components/Training_log/NewAdminTrainerInterface'

export default {
    name: 'NewAdminTrainerInterfaceView',
    components: {
        NewAdminTrainerInterface,
    },
}
</script>