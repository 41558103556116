import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {    
    tab: null
  },
  getters: {
    getTab: state => {
      return state.tab;
    }
  },
  mutations: {
    updateTab(state, newTab) {
      state.tab = newTab;
    }
  },
  actions: {
  },
  modules: {
  }
})