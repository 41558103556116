<template>
    <v-col cols="12" sm="12" md="12">
        <v-card>
            <v-card-title>
                <span class="headline">Credit Card Car</span>
            </v-card-title>
            <v-col>
                <v-card-text>
                    <v-form ref='form'>

                        <v-combobox v-model="editedItem.bill" :items="bills" label="Bills" required></v-combobox>

                        <v-text-field v-model="editedItem.assigned_user" :rules="[rules.required]" label="Assigned user"
                            required></v-text-field>

                        <v-text-field v-model="editedItem.mail" :rules="[rules.required]" label="Email"
                            required></v-text-field>

                        <v-text-field v-model="editedItem.credit_type" :rules="[rules.required]" label="Credit type"
                            required></v-text-field>

                        <v-text-field v-model="editedItem.last_4_digits" :rules="[rules.required]" label="Last 4 digits"
                            required></v-text-field>

                        <v-text-field v-model="editedItem.company" :rules="[rules.required]" label="Company"
                            required></v-text-field>

                        <v-text-field v-model="editedItem.link_date" :rules="[rules.required]" label="Link date"
                            required></v-text-field>

                        <v-text-field v-model="editedItem.due_date" :rules="[rules.required]" label="Due date"
                            required></v-text-field>

                        <v-text-field v-model="editedItem.ledger_account" :rules="[rules.required]"
                            label="Ledger account" required></v-text-field>
                    </v-form>
                </v-card-text>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="submitForm">
                    SAVE
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
</template>

<script>
import { createCreditCardManagement, updateCreditCardManagement } from '../../utils/credit_card_managements_CRUD.js'

export default {
    name: 'CreditCardCar',
    props: {
        Index: Number,
        Item: Object
    },
    data: () => ({
        billsData: [],
        editedItem: {
            id: '',
            assigned_user: '',
            email: '',
            credit_type: '',
            last_4_digits: '',
            company: '',
            link_date: '',
            due_date: '',
            ledger_account: '',
            status: '',
        },
        rules: {
            required: value => !!value || 'Required.',
        },
        defaultEditedItem: {
            id: '',
            assigned_user: '',
            email: '',
            credit_type: '',
            last_4_digits: '',
            company: '',
            link_date: '',
            due_date: '',
            ledger_account: '',
            status: '',
        },
        editedIndex: -1,
    }),
    async mounted() {
        console.log("-------------------MOUNTED.......................")
        console.log("this.Index: ", this.Index);
        this.$nextTick(() => {
            this.$refs.form.resetValidation();
        });
        if (this.Index != -1) {
            this.editedItem = this.Item;
            this.editedIndex = this.Index
            console.log("this.editedIndex: ", this.editedIndex);
            console.log("this.editedItem: ", this.editedItem);
        }
    },
    methods: {
        async submitForm() {
            if (this.$refs.form.validate()) {
                console.log('Form submitted:', this.editedItem);
                let response = null;
                // ADD new record
                if (this.editedIndex === -1) {
                    response = await createCreditCardManagement(this.editedItem);
                    console.log("response: ", response);
                } else {
                    // Updating record
                    response = await updateCreditCardManagement(this.editedIndex, this.editedItem);
                    console.log("Updating - response: ", response);
                }
                if (response.ok) {
                    this.$emit('sent-info', false);
                }
            } else {
                console.log('Form validation failed.');
            }
        },
    },
    computed: {
        bills() {
            return this.billsData.map(bill => `${bill.account_code} - ${bill.account_name}`);
        }
    },
}
</script>