
import { FEED_DATABASE, UPDATE_VEHICLE, GET_ALL_VEHICLES_JSON, UPLOAD_FLEET_FILE } from '../utils/endpoints.js'

export async function editVehicle(vehicleId, vehicleData) {
    console.log("Updating Vehicle........");
    console.log("vehicleId: ", vehicleId);
    console.log("vehicleData: ", vehicleData);

    const url = `${UPDATE_VEHICLE}${vehicleId}`;
    console.log("url: ", url);

    try {
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(vehicleData)
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'An error occurred while updating the vehicle.');
        }

        const result = await response.json();
        console.log('Vehicle updated successfully:', result);
        return {
            result,
            response
        };
    } catch (error) {
        console.error('Error updating vehicle:', error);
        throw error;
    }
}

export async function feedTableVehicles() {
    try {
        console.log("Updating Data Base....");
        const response = await fetch(FEED_DATABASE, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        // Registrar toda la respuesta para análisis
        //console.log('Response:', response);

        if (!response.ok) {
            console.log("NO OK......");
            // Tratar de extraer el mensaje de error del servidor si está disponible
            let errorMessage = `feedTableVehicles - HTTP error! status: ${response.status}`;
            try {
                const errorData = await response.json();
                if (errorData && errorData.error) {
                    errorMessage += ` - ${errorData.error}`;
                }
            } catch (parseError) {
                console.error('Error parsing response JSON:', parseError);
            }
            throw new Error(errorMessage);
        }

        const data = await response.json(); // Intentar analizar la respuesta JSON
        console.log('Response data:', data);

        return data; // Devolver los datos JSON analizados
    } catch (error) {
        console.error('Error feeding table vehicles:', error);
        // Puedes lanzar el error de nuevo si necesitas manejarlo en otro lugar
        throw error;
    }
}

export async function fetchVehicles() {
    try {
        console.log("Fetching vehicles data...");
        const response = await fetch(GET_ALL_VEHICLES_JSON); // Asegúrate de que la URL sea correcta

        // Verificar si `response` es indefinido o nulo
        if (!response) {
            throw new Error('No response received from the server.');
        }

        // Registrar toda la respuesta para análisis
        console.log('Response:', response);

        if (!response.ok) {
            // Tratar de extraer el mensaje de error del servidor si está disponible
            let errorMessage = `fetchVehicles - HTTP error! status: ${response.status}`;
            try {
                const errorData = await response.json();
                if (errorData && errorData.error) {
                    errorMessage += ` - ${errorData.error}`;
                }
            } catch (parseError) {
                console.error('Error parsing response JSON:', parseError);
            }
            throw new Error(errorMessage);
        }

        const vehicles = await response.json(); // Intentar analizar la respuesta JSON
        console.log('Vehicles data:', vehicles);

        return vehicles; // Devolver los datos JSON analizados
    } catch (error) {
        console.error('Error fetching vehicles:', error);
        // Puedes lanzar el error de nuevo si necesitas manejarlo en otro lugar
        throw error;
    }
}

export async function uploadFileToFleetS3(vin, file) {
    console.log("file: ", file);
    console.log("file.type: ", file.type);
    const url = `${UPLOAD_FLEET_FILE}${vin}`;
    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await fetch(url, {
            method: 'PUT',
            body: formData
        });
        const result = await response.json();
        if (!response.ok) {
            // Handle specific errors returned by the server
            switch (response.status) {
                case 400:
                    console.error(`Bad Request: ${result.error}`);
                    break;
                case 404:
                    console.error(`Not Found: ${result.error}`);
                    break;
                case 500:
                    console.error(`Internal Server Error: ${result.error}`);
                    break;
                default:
                    console.error(`Unexpected Error: ${result.error}`);
                    break;
            }
            throw new Error(result.error || 'Error uploading file.');
        }
        return response;

        // Handle successful response
        /* if (file.type === 'video/mp4') {
            if (response.ok) {
                return response;
            }
        } else {
            console.log('File uploaded successfully:', result.message);
            if (response.ok) {
                return response;
            }
        } */

    } catch (error) {
        // Handle fetch errors
        console.error('Error occurred during file upload:', error.message);
        throw error;
    }
}