<template>
    <v-container>
        <v-row class="d-flex justify-center">
            <v-col cols="5" sm="5" md="5">
                <v-card>
                    <v-card-title>
                        <span class="headline">Main Location Filter</span><!--  -->
                    </v-card-title>
                    <v-divider></v-divider><!-- class="mt-4 mb-6" -->
                    <v-col>
                        <v-card-text>

                            <v-row>
                                <v-col>
                                    <v-row>
                                        <v-col cols="4">
                                            <div>
                                                <v-file-input label="Locations" v-model="file"
                                                    accept=".xlsx,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel">
                                                </v-file-input>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row v-if="loading">
                                <v-col cols="12" class="text-center">
                                    <v-progress-circular indeterminate></v-progress-circular>
                                    <p>Processing your file...</p>
                                </v-col>
                            </v-row>
                            <!-- <v-row v-if="results">
                  <v-col cols="12">
                    <v-card class="mb-3">
                      <v-card-title>Summary</v-card-title>
                      <v-card-text>
                        <p>Valid Emails: {{ validCount }}</p>
                        <p>Invalid Emails: {{ invalidCount }}</p>
                        <p>Repeated Emails: {{ repeatedCount }}</p>
                      </v-card-text>
                    </v-card>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-row>
                          <v-col cols="3" sm="3" md="3">
                            <v-btn :href="validLink" color="success" download="valid_leads.xlsx">Valid
                              Leads</v-btn>
                          </v-col>
                          <v-col cols="3" sm="3" md="3">
                            <v-btn :href="invalidLink" color="error" download="invalid_leads.xlsx">Invalid
                              Leads</v-btn>
                          </v-col>
                          <v-col cols="3" sm="3" md="3">
                            <v-btn :href="repeatedLink" color="warning" download="repeated_emails.xlsx">Repeated
                              Emails</v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row> -->
                            <v-row v-if="error">
                                <v-col cols="12" class="text-center">
                                    <v-alert type="error">
                                        There was an error processing your file. Please try again.
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="d-flex justify-end">
                                    <v-btn color="blue darken-1" text @click="processFile">
                                        Process
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-col>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import * as xlsx from 'xlsx';
import emailValidator from 'email-validator';
import { bus } from '../../main.js'
import { processExcel } from '../../utils/Accounting/accounting_functions.js'

export default {
    data: () => ({
        file: null,
        loading: false,
        results: false,
        error: false,
        validCount: 0,
        invalidCount: 0,
        repeatedCount: 0,
        validLink: '',
        invalidLink: '',
        repeatedLink: '',
    }),
    mounted() {
        bus.$emit('login-success');
        bus.$emit('permissions');
    },
    methods: {
        async processFile() {
            if (!this.file) {
                this.error = true;
                return;
            }

            this.loading = true;
            //this.results = false;
            this.error = false;

            const reader = new FileReader();
            reader.onload = async (event) => {
                try {
                    processExcel(this.file);
                    /* const data = new Uint8Array(event.target.result);
                    const workbook = xlsx.read(data, { type: 'array' });
                    const sheet = workbook.Sheets[workbook.SheetNames[0]];
                    const leads = xlsx.utils.sheet_to_json(sheet);
          
                    const validLeads = [];
                    const invalidLeads = [];
                    const repeatedLeads = [];
                    const emailCounts = {};
                    const invalidEmails = new Set(['no@gmail.com', 'none@a.com', 'n@n.com', 'q@q.com', 'nomail@mail.com', 'none@gmail.com', 'none@none.com']);
                    const invalidEmailPattern = /[^a-zA-Z0-9@._-]/;
          
                    for (const lead of leads) {
                      const email = lead.Email;
                      const lowerEmail = email.toLowerCase();
          
                      if (emailValidator.validate(email) && !invalidEmails.has(lowerEmail) && !invalidEmailPattern.test(email)) {
                        if (!emailCounts[lowerEmail]) {
                          emailCounts[lowerEmail] = { count: 0, lead };
                        }
                        emailCounts[lowerEmail].count += 1;
                      } else {
                        invalidLeads.push(lead);
                      }
                    }
          
                    for (const [email, { count }] of Object.entries(emailCounts)) {
                      if (count === 1) {
                        validLeads.push({ Email: email });
                      } else {
                        repeatedLeads.push({ Email: email, Count: count });
                      }
                    }
          
                    const validSheet = xlsx.utils.json_to_sheet(validLeads);
                    const invalidSheet = xlsx.utils.json_to_sheet(invalidLeads);
                    const repeatedSheet = xlsx.utils.json_to_sheet(repeatedLeads);
          
                    const validWorkbook = xlsx.utils.book_new();
                    const invalidWorkbook = xlsx.utils.book_new();
                    const repeatedWorkbook = xlsx.utils.book_new();
          
                    xlsx.utils.book_append_sheet(validWorkbook, validSheet, 'Valid Leads');
                    xlsx.utils.book_append_sheet(invalidWorkbook, invalidSheet, 'Invalid Leads');
                    xlsx.utils.book_append_sheet(repeatedWorkbook, repeatedSheet, 'Repeated Emails');
          
                    const validBuffer = xlsx.write(validWorkbook, { type: 'base64' });
                    const invalidBuffer = xlsx.write(invalidWorkbook, { type: 'base64' });
                    const repeatedBuffer = xlsx.write(repeatedWorkbook, { type: 'base64' });
          
                    this.validLink = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${validBuffer}`;
                    this.invalidLink = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${invalidBuffer}`;
                    this.repeatedLink = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${repeatedBuffer}`;
          
                    this.validCount = validLeads.length;
                    this.invalidCount = invalidLeads.length;
                    this.repeatedCount = repeatedLeads.length;
          
                    this.results = true; */
                } catch (error) {
                    console.error('Error:', error);
                    this.error = true;
                } finally {
                    this.loading = false;
                }
            };
            reader.readAsArrayBuffer(this.file);
        }
    }
};
</script>

<style scoped>
.text-center {
    text-align: center;
}
</style>