import { GET_CHART_OF_ACCOUNT, POST_CHART_OF_ACCOUNT_BULK } from '../utils/endpoints';

/**
 * Creates multiple chart of accounts in bulk.
 * 
 * @param {Array} accounts - Array of account objects to be created.
 * @returns {Promise<Response|void>} - The response object if successful, otherwise undefined.
 * @throws Will throw an error if the fetch request fails.
 * @author JDGT
 */
export async function createChartOfAccountsInBulk(accounts) {
    try {
        const response = await fetch(POST_CHART_OF_ACCOUNT_BULK, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(accounts)
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error:', errorData);
            alert(`Error: ${errorData.message}`);
            return;
        }

        const result = await response.json();
        console.log('Success:', result);
        return response;
    } catch (error) {
        console.error('Error:', error);
        alert('Error creating chart of accounts');
    }
}

/**
 * Creates a single chart of account.
 * 
 * @param {Object} formData - The form data for the account to be created.
 * @returns {Promise<Response|void>} - The response object if successful, otherwise undefined.
 * @throws Will throw an error if the fetch request fails.
 * @author JDGT
 */
export async function createChartOfAccount(formData) {
    try {
        const response = await fetch(GET_CHART_OF_ACCOUNT, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error:', errorData);
            alert(`Error: ${errorData.message}`);
            return;
        }

        const result = await response.json();
        console.log('Success createChartOfAccount:', result);
        return response;
    } catch (error) {
        console.error('Error:', error);
        alert('Error creating chart of account');
    }
}

/**
 * Retrieves all chart of accounts.
 * 
 * @returns {Promise<Array>} - An array of all chart of accounts.
 * @throws Will throw an error if the fetch request fails.
 * @author JDGT
 */
export async function getAllChartOfAccounts() {
    try {
        const response = await fetch(GET_CHART_OF_ACCOUNT, {
            method: 'GET',
        });

        const result = await response.json();
        if (!response.ok) {
            throw new Error(result.message || 'Error retrieving chart of accounts');
        }

        console.log("result:", result);
        return result;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

/**
 * Retrieves a single chart of account by ID.
 * 
 * @param {number} id - The ID of the chart of account to retrieve.
 * @returns {Promise<Object>} - The chart of account object.
 * @throws Will throw an error if the fetch request fails.
 * @author JDGT
 */
export async function getChartOfAccount(id) {
    try {
        const response = await fetch(`${GET_CHART_OF_ACCOUNT}/${id}`, {
            method: 'GET',
        });

        const result = await response.json();
        if (!response.ok) {
            throw new Error(result.message || 'Error retrieving chart of account');
        }

        return result;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

/**
 * Updates a chart of account by ID.
 * 
 * @param {number} id - The ID of the chart of account to update.
 * @param {Object} formData - The form data for the account to be updated.
 * @returns {Promise<Response|void>} - The response object if successful, otherwise undefined.
 * @throws Will throw an error if the fetch request fails.
 * @author JDGT
 */
export async function updateChartOfAccount(id, formData) {
    const url = `${GET_CHART_OF_ACCOUNT}/${id}`;  // Asegúrate de que la URL sea la correcta

    try {
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error:', errorData);
            alert(`Error: ${errorData.message}`);
            return;
        }

        const result = await response.json();
        console.log('Success:', result);
        return response;
    } catch (error) {
        console.error('Error:', error);
        alert('Error updating chart of account');
    }
}

/**
 * Deletes a chart of account by ID.
 * 
 * @param {number} id - The ID of the chart of account to delete.
 * @returns {Promise<Response|void>} - The response object if successful, otherwise undefined.
 * @throws Will throw an error if the fetch request fails.
 * @author JDGT
 */
export async function deleteChartOfAccount(id) {
    const url = `${GET_CHART_OF_ACCOUNT}/${id}`;  // Asegúrate de que la URL sea la correcta

    try {
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error:', errorData);
            alert(`Error: ${errorData.message}`);
            return;
        }

        const result = await response.json();
        console.log('Success:', result);
        return response;
    } catch (error) {
        console.error('Error:', error);
        alert('Error deleting chart of account');
    }
}
