<template>
    <zoho-chart-of-account />
</template>

<script>
import ZohoChartOfAccount from '../../../components/Accounting/Zoho/ZohoChartOfAccount.vue'

export default {
    name: 'ZohoChartOfAccountView',
    components: {
        ZohoChartOfAccount,
    },
}
</script>