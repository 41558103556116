
import {UPLOAD_FILES} from '../utils/endpoints';

export async function uploadFile(file, fileName, employee_number) {
    console.log("File: ", fileName);
    console.log("Uploading files......", file);
    const url = `${UPLOAD_FILES}${employee_number}`;
    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await fetch(url, {
            method: 'PUT',
            body: formData
        });
        const data = await response.json();
        console.log("Data: ", data);
        if (response.ok) {
            console.log(`uploadFile/Archivo subido exitosamente: ${file}`, data.message);
            console.log("uploadFile/response: ", response);
            //return response;
            return data;
        } else {
            console.error(`uploadFile/Error al subir el archivo: ${file}`, data.error);
        }
    } catch (error) {
        console.error("uploadFile/Error en la solicitud-/archivo:", error);
    }
}