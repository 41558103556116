import { S3_LIST_INSURANCE_FILES, GET_PUBLIC_URLS } from '../../utils/endpoints'

/**
 * Fetches a list of files from a specified folder on the server using a POST request with a JSON body.
 * 
 * @author JDGT
 * @param {string} folderName - The name of the folder to retrieve the file list from.
 * @returns {Promise<Array|string>} - A promise that resolves to the list of files if successful, 
 * or an error message if the request fails.
 */
export async function getListOfFiles(folderName) {
  try {
    const response = await fetch(S3_LIST_INSURANCE_FILES, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ folder_name: folderName })
    });

    if (!response.ok) {
      switch (response.status) {
        case 400:
          throw new Error('Bad Request: The server could not understand the request due to invalid syntax.');
        case 404:
          throw new Error('Not Found: The server could not find the requested resource.');
        case 405:
          throw new Error('Method Not Allowed: The method specified in the request is not allowed.');
        case 500:
          throw new Error('Internal Server Error: The server has encountered a situation it doesn\'t know how to handle.');
        default:
          throw new Error(`Unexpected HTTP status: ${response.status}`);
      }
    }

    const files = await response.json();
    console.log(files);
    return files;

  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
    return `Error: ${error.message}`;
  }
}

export async function generatePublicURLs(folderName, fileNames) {
  console.log("<------------------- generatePublicURLs");
  console.log("folderName: ", folderName);
  console.log("fileNames: ", fileNames);

  // Objeto JSON a enviar en el cuerpo de la solicitud
  const data = {
    folder_name: folderName,
    file_names: fileNames // Ahora es un array de nombres de archivos
  };

  // Opciones de la solicitud
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  };

  try {
    // Realizar la solicitud al endpoint utilizando fetch
    const response = await fetch(GET_PUBLIC_URLS, options); // Cambia la URL si es necesario
    if (!response.ok) {
      throw new Error('Error en la solicitud');
    }

    // Devolver la respuesta en formato JSON
    const responseData = await response.json();
    console.log("<------------------- publicURLs: ", responseData);
    return responseData.urls; // Devolver el array de URLs generadas
  } catch (error) {
    console.error('Error:', error.message);
    throw error; // Lanzar el error para que sea manejado por el código que llama a generatePublicURLs
  }
}