<template>
    <v-card>
        <v-card-title>
            <div class="text-left">
                <span class="headline">Porcentages</span>
                <v-card-subtitle>Total Porcentage: {{ parseFloat(total_porcentage).toFixed(2) }} %</v-card-subtitle>
            </div>
        </v-card-title>

        <v-card-text>
            <v-form v-model="valid" ref='form'>
                <v-row>
                    <v-col cols="6">
                        <!-- <v-text-field v-model="editedItem.location" :rules="[rules.required]" label="Location"
                            required></v-text-field> -->
                        <v-combobox v-model="locationSelected" :items="itemLocations" item-text="name" label="Location"
                            return-object :rules="computedRules" required></v-combobox>
                    </v-col>
                    <v-col cols="6">
                        <!-- <v-text-field v-model="editedItem.porcentage" label="Porcentage %"
                        :rules="computedRules" required></v-text-field> -->

                        <v-text-field v-model="editedItem.porcentage" label="Porcentage %" type="number" step="0.1"
                            :rules="computedRules" required></v-text-field>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="submitForm">
                SAVE
            </v-btn>
        </v-card-actions>
        <div class="loader-container" v-if="showloader">
            <!-- <l-bouncy size="150" speed="0.8" color="#FF00F7"></l-bouncy> -->
            <l-grid size="75" speed="1.5" color="red"></l-grid>
        </div>
    </v-card>
</template>

<script>
import _ from 'lodash';
import { createPorcentage, updatePorcentage } from '../../utils/Accounting/porcentages_CRUD.js'
import { getReportingTags } from '../../utils/variables.js'


export default {
    name: 'ReportToCard',
    props: {
        Index: Number,
        Item: Object,
        existedLocations: Array,
    },
    data: () => ({
        total_porcentage: 0.0,
        showloader: false,
        valid: false,
        locationSelected: '',
        itemLocations: [],
        editedItem: {
            location: '',
            porcentage: null
        },
        defaultItem: {
            location: '',
            porcentage: null
        },
        rules: {
            required: value => {
                // Verifica si el valor es un objeto (como en el caso de un combobox con return-object)
                if (typeof value === 'object') {
                    /* eturn value && value.location ? true : 'Required';  // Verifica si el campo 'location' existe en el objeto */
                    if (value && (value.name || value.location)) {
                        return true;  // Si existe 'name' o 'location', es válido
                    } else {
                        return 'Required';
                    }
                }
                // Si no es un objeto, valida como un valor normal (string o número)
                return value !== null && value !== undefined &&
                    (typeof value === 'string' ? value.trim() !== '' : typeof value === 'number' || /^\d+(\.\d+)?$/.test(value))
                    || 'Required.';
            },
            onlyNumbers: value => {
                const pattern = /^[0-9]*$/;
                return pattern.test(value) || 'Only numbers are allowed';
            }
        },
        editedIndex: -1,
    }),
    watch: {
        locationSelected(val) {
            console.log("Val: ", val);
            if (typeof val === 'object' && val !== null) {
                console.log("ObjVal: ", val);
                this.editedItem.location = val.name;
            } else {
                console.log("NO object: ", val);
                this.editedItem.location = val;
            }
        },
    },
    computed: {
        computedRules() {
            // Define las reglas basadas en la ruta actual

            return [this.rules.required];


        }
    },
    async mounted() {
        console.log("-------------------MOUNTED.......................")
        console.log("this.Index: ", this.Index);
        this.$nextTick(() => {
            this.$refs.form.resetValidation();
        });
        /* const data = await getLocations();
        const arrayLocations = data.filter(item => !['México', 'Ecuador', 'España', 'Colombia', 'Venezuela', 'Holanda', 'Europa', 'Philipinas'].includes(item.location));
        console.log("arrayLocations: ", arrayLocations);
        //this.itemLocations = arrayLocations;
        this.itemLocations = this.filterArrayByLocation(this.existedLocations, arrayLocations);
        console.log("this.itemLocations: ", this.itemLocations); */

        //this.itemLocations = this.filterArrayByLocation(this.existedLocations, arrayLocations);
        console.log(">>>>>>>>>>>>>>>>>> this.total_porcentage: ", this.total_porcentage);
        console.log(">>>>>>>>>>>>>>>>>> this.existedLocations: ", this.existedLocations);
        this.total_porcentage = this.sumPorcentages(this.existedLocations);
        console.log(">>>>>>>>>>>>>>>>>> this.total_porcentage: ", this.total_porcentage);

        const reporting_tags = getReportingTags();
        console.log(">>>>>>>>>>>>>>>>>> reporting_tags: ", reporting_tags);
        this.itemLocations = reporting_tags.Location.tag_options;
        console.log(">>>>>>>>>>>>>>>>>> this.itemLocations: ", this.itemLocations);
        console.log(">>>>>>>>>>>>>>>>>> this.existedLocations: ", this.existedLocations);

        this.itemLocations = this.filterArrayByLocation(this.existedLocations, this.itemLocations);
        console.log(">>>>>>>>>>>>>>>>>> this.itemLocations: ", this.itemLocations);

        console.log("this.Index: ", this.Index);
        if (this.Index != -1) {
            console.log("+++++++++ this.item: ", this.Item)
            this.editedItem = _.cloneDeep(this.Item);
            console.log("this.editedItem/cloneDeep: ", this.editedItem);
            this.editedIndex = this.Index;
            this.editedItem.id = this.Index;
            console.log("this.editedIndex: ", this.editedIndex);
            console.log("this.editedItem: ", this.editedItem);
            this.locationSelected = this.editedItem.location;

        } else {
            this.editedItem = this.defaultItem;
            this.editedIndex = -1;
            this.editDates = true;

        }
    },

    methods: {
        filterArrayByLocation(arr1, arr2) {

            // Crea un conjunto con todas las ubicaciones del primer array
            const locationsSet = new Set(arr1.map(item => item.location));

            // Filtra el segundo array eliminando los elementos que tengan una ubicación ya presente en el primer array
            return arr2.filter(item => !locationsSet.has(item.name));
        },
        sumPorcentages(array) {
            let total = 0;

            array.forEach((item) => {
                if (typeof item.porcentage === 'number') {
                    console.log(`Porcentage: ${item.porcentage}`);
                    total += item.porcentage;
                } else {
                    console.warn('Invalid porcentage value:', item.porcentage);
                }
            });

            console.log(`Total Porcentage: ${total}`);
            return total;
        },
        validatePorcentage(total_porcentage, porcentage) {
            // Convertir los parámetros a float
            const total = parseFloat(total_porcentage);
            const percentage = parseFloat(porcentage);

            console.log("total_porcentage: ", total);
            console.log("porcentage: ", percentage);

            const combinedPorcentage = total + percentage;
            console.log("combinedPorcentage: ", combinedPorcentage);

            if (combinedPorcentage > 100) {
                alert("The percentage cannot exceed 100.");
                return false;
            } else {
                return true;
            }
        },
        checkLocationExists(location, locationsArray) {
            return locationsArray.some(item => item.location === location);
        },
        async submitForm() {
            console.log("<<<<<<<<<<<<<<<<<< SUBMIT");
            try {
                if (this.$refs.form.validate()) {
                    const res_porcentage = this.validatePorcentage(this.total_porcentage, this.editedItem.porcentage);
                    console.log("res_porcentage: ", res_porcentage);
                    if (this.editedIndex === -1) {
                        const res_location = this.checkLocationExists(this.editedItem.location, this.existedLocations);
                        console.log("res_location: ", res_location);
                        if (!res_location) {

                            if (res_porcentage) {
                                this.showloader = true;
                                console.log('Form submitted:', this.editedItem);

                                console.log("this.editedIndex: ", this.editedIndex)
                                console.log("CREATIMG REPORT TO..........");
                                //response = await createReportTo(this.editedItem);
                                let response = await createPorcentage(this.editedItem);
                                console.log("response: ", response);
                                /* if (this.editedIndex === -1) {
                                    console.log("CREATIMG REPORT TO..........");
                                    //response = await createReportTo(this.editedItem);
                                    response = await createPorcentage(this.editedItem);
                                    console.log("response: ", response);
                                } */ /* else {
                                    // Updating record
                                    console.log("UPDATING REPORT TO..........");
                                    response = await updateReportTo(this.editedIndex, this.editedItem);
                                    console.log("response: ", response);
                                } */
                                if (response.ok) {
                                    this.showloader = false;
                                    this.editedItem = this.defaultItem;
                                    this.locationSelected = null;
                                    /* this.$nextTick(() => {
                                        this.$refs.form.resetValidation();
                                    }); */
                                    this.$emit('sent-info', false);
                                }
                            }
                        } else {
                            alert("The location exists!");
                        }
                    } else {
                        console.log("UPDATING REPORT TO..........");
                        //let response = await updateReportTo(this.editedIndex, this.editedItem);
                        if (res_porcentage) {
                            let response = await updatePorcentage(this.editedIndex, this.editedItem);

                            console.log("response: ", response);
                            if (response.ok) {
                                this.showloader = false;
                                this.editedItem = this.defaultItem;
                                this.locationSelected = null;
                                /* this.$nextTick(() => {
                                    this.$refs.form.resetValidation();
                                }); */
                                this.$emit('sent-info', false);
                            }
                        }

                    }

                } else {
                    this.showloader = false;
                    console.error('Form validation failed.');
                }
            } catch (error) {
                this.showloader = false;
                console.error("Error: ", error);
            }
        },
    },
}
</script>

<style scoped>
.green-focus .v-input__control {
    border-color: green !important;
    /* Cambia el color del borde a verde */
}

.green-focus .v-input__control .v-field__append-inner .v-icon {
    color: green !important;
    /* Cambia el color del icono a verde */
}

.green-focus .v-input--is-focused .v-input__control .v-field__append-inner .v-icon {
    color: green !important;
    /* Asegúrate de que el icono se mantenga verde cuando esté enfocado */
}

.green-focus .v-input--is-focused .v-input__control {
    border-color: green !important;
    /* Asegúrate de que el borde se mantenga verde cuando esté enfocado */
}

.loader-container {
    position: fixed;
    /* Para que el loader esté por encima de todo el contenido */
    top: 0;
    left: 0;
    width: 100%;
    /* Ocupa todo el ancho del viewport */
    height: 100%;
    /* Ocupa todo el alto del viewport */
    display: flex;
    justify-content: center;
    /* Centra horizontalmente */
    align-items: center;
    /* Centra verticalmente */
    background-color: rgba(255, 255, 255, 0.5);
    /* Fondo semitransparente */
    z-index: 9999;
    /* Coloca el loader por encima de todo el contenido */
}

.dialog-height {
    max-height: auto !important;
    min-height: auto !important;
}

.dialog-card {
    padding: 0;
    /* Elimina el relleno interno */
    margin: 0;
    /* Elimina los márgenes */
}
</style>
