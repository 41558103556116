<template>
  <walk-ins-form />
</template>

<script>
import WalkInsForm from '../../components/Walk_ins/WalkInsForm.vue'

export default {
  name: 'WalkIns',

  components: {
    WalkInsForm,
  },
}
</script>