import html2pdf from 'html2pdf.js';

export async function generatePDF_jobOffer(element) {
    if (!element) {
        console.error("Element is not provided or not found.");
        return null;
    }

    try {
        const pdfBlob = await new Promise((resolve, reject) => {
            requestAnimationFrame(async () => {
                try {
                    console.log("Generating PDF...");

                    const opt = {
                        margin: 30,
                        filename: 'job_offer.pdf',
                        image: { type: 'jpeg', quality: 0.98 },
                        html2canvas: { scale: 2 },
                        jsPDF: { unit: 'px', format: [1000, 3500], orientation: 'portrait' }
                    };

                    const pdfBlob = await html2pdf().from(element).set(opt).outputPdf('blob');
                    resolve(pdfBlob);
                } catch (error) {
                    reject(error);
                }
            });
        });
        return pdfBlob;
    } catch (error) {
        console.error("Error: ", error);
        return null;
    }
}
