<template>
    <zip-codes />
</template>

<script>
import ZipCodes from '../../components/Accounting/ZipCodes.vue'

export default {
    name: 'ZipCodesView',
    components: {
        ZipCodes,
    },
}
</script>