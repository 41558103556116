import { PAID_THROUGH_ACCOUNTS } from '../utils/endpoints.js'

/**
 * Handles the response from fetch requests.
 * 
 * @param {Response} response - The response object from the fetch request.
 * @returns {Promise<Object>} - The JSON response if the request is successful.
 * @throws Will throw an error if the response is not OK.
 */
export async function handleResponse(response) {
    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Unknown error');
    }
    return response;
}

/**
 * Creates a new paid through account.
 * 
 * @param {Object} accountData - Object containing the account data.
 * @returns {Promise<Object>} - Data of the created account.
 * @throws Will throw an error if the fetch request fails.
 */
export async function createPaidThroughAccount(accountData) {
    try {
        const response = await fetch(PAID_THROUGH_ACCOUNTS, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(accountData)
        });

        const result = await handleResponse(response);
        console.log('Paid through account created successfully:', result);
        return result;
    } catch (error) {
        console.error('Error creating paid through account:', error);
        throw error;
    }
}

/**
 * Retrieves all paid through accounts.
 * 
 * @returns {Promise<Array>} - List of all paid through accounts.
 * @throws Will throw an error if the fetch request fails.
 */
export async function getAllPaidThroughAccounts() {
    try {
        const response = await fetch(PAID_THROUGH_ACCOUNTS, {
            method: 'GET',
        });

        const result = await handleResponse(response);
        console.log('Paid through accounts retrieved successfully:', result);
        return result.json();
    } catch (error) {
        console.error('Error retrieving paid through accounts:', error);
        throw error;
    }
}

/**
 * Retrieves a paid through account by ID.
 * 
 * @param {number} id - ID of the paid through account.
 * @returns {Promise<Object>} - Data of the paid through account.
 * @throws Will throw an error if the fetch request fails.
 */
export async function getPaidThroughAccountById(id) {
    try {
        const response = await fetch(`${PAID_THROUGH_ACCOUNTS}/${id}`, {
            method: 'GET',
        });

        const result = await handleResponse(response);
        console.log('Paid through account retrieved successfully:', result);
        return result;
    } catch (error) {
        console.error('Error retrieving paid through account:', error);
        throw error;
    }
}

/**
 * Updates a paid through account by ID.
 * 
 * @param {number} id - ID of the paid through account to update.
 * @param {Object} accountData - Updated data of the paid through account.
 * @returns {Promise<Object>} - Updated data of the paid through account.
 * @throws Will throw an error if the fetch request fails.
 */
export async function updatePaidThroughAccount(id, accountData) {
    try {
        const response = await fetch(`${PAID_THROUGH_ACCOUNTS}/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(accountData)
        });

        const result = await handleResponse(response);
        console.log('Paid through account updated successfully:', result);
        return result;
    } catch (error) {
        console.error('Error updating paid through account:', error);
        throw error;
    }
}

/**
 * Deletes a paid through account by ID.
 * 
 * @param {number} id - ID of the paid through account to delete.
 * @returns {Promise<Object>} - Response of the request.
 * @throws Will throw an error if the fetch request fails.
 */
export async function deletePaidThroughAccount(id) {
    try {
        const response = await fetch(`${PAID_THROUGH_ACCOUNTS}/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        const result = await handleResponse(response);
        console.log('Paid through account deleted successfully:', result);
        return result;
    } catch (error) {
        console.error('Error deleting paid through account:', error);
        throw error;
    }
}
