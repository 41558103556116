<template>
    <v-col cols="12" sm="12" md="12">
        <v-card>
            <v-card-title>
                <span class="headline">Report to</span>
            </v-card-title>
            <v-col>
                <v-card-text>
                    <v-form ref='form'>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field v-model="editedItem.name" :rules="[rules.required]" label="Name(s)"
                                    required></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-model="editedItem.last_name" :rules="[rules.required]"
                                    label="Last Name(s)" required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="submitForm">
                    SAVE
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
</template>

<script>
//import { createComission, updateComission } from '../../utils/Employees/type_of_comissionsCRUD.js'
import { createReportTo, updateReportTo  } from '../../utils/Employees/report_toCRUD.js'
import _ from 'lodash';

export default {
    name: 'ReportToCard',
    props: {
        Index: Number,
        Item: Object
    },
    data: () => ({
        editedItem: {
            name: '',
            last_name: null,
        },
        defaultItem: {
            name: '',
            last_name: null,
        },
        rules: {
            required: value => !!value || 'Required.',
        },
        editedIndex: -1,
    }),
    async mounted() {
        console.log("-------------------MOUNTED.......................")
        console.log("this.Index: ", this.Index);
        this.$nextTick(() => {
            this.$refs.form.resetValidation();
        });
        console.log("this.Index: ", this.Index);
        if (this.Index != -1) {
            console.log("+++++++++ this.item: ", this.Item)
            this.editedItem = _.cloneDeep(this.Item);
            console.log("this.editedItem/cloneDeep: ",this.editedItem);
            this.editedIndex = this.Index;
            this.editedItem.id = this.Index;
            console.log("this.editedIndex: ", this.editedIndex);
            console.log("this.editedItem: ", this.editedItem);

        } else {
            this.editedItem = this.defaultItem;
            this.editedIndex = -1;
            this.editDates = true;

        }
    },

    methods: {
        async submitForm() {
            console.log("<<<<<<<<<<<<<<<<<< SUBMIT");
            try {
                if (this.$refs.form.validate()) {
                    console.log('Form submitted:', this.editedItem);
                    let response = null;
                    console.log("this.editedIndex: ", this.editedIndex);
                    if (this.editedIndex === -1) {
                        console.log("CREATIMG REPORT TO..........");
                        response = await createReportTo(this.editedItem);
                        console.log("response: ", response);
                    } else {
                        // Updating record
                        console.log("UPDATING REPORT TO..........");
                        response = await updateReportTo(this.editedIndex, this.editedItem);
                        console.log("response: ", response);
                    }
                    if (response.ok) {
                        this.$emit('sent-info', false);
                    }
                } else {
                    console.log('Form validation failed.');
                }
            } catch (error) {
                console.error("Error: ", error);
            }
        },
    },
}
</script>

<style scoped>
.green-focus .v-input__control {
    border-color: green !important;
    /* Cambia el color del borde a verde */
}

.green-focus .v-input__control .v-field__append-inner .v-icon {
    color: green !important;
    /* Cambia el color del icono a verde */
}

.green-focus .v-input--is-focused .v-input__control .v-field__append-inner .v-icon {
    color: green !important;
    /* Asegúrate de que el icono se mantenga verde cuando esté enfocado */
}

.green-focus .v-input--is-focused .v-input__control {
    border-color: green !important;
    /* Asegúrate de que el borde se mantenga verde cuando esté enfocado */
}
</style>
