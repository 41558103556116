import {COMPUTER_EQUIPMENT} from '../../utils/endpoints'


export async function createComputerEquipment(equipmentData) {
    try {
        console.log("equipmentData: ", equipmentData);
        const response = await fetch(COMPUTER_EQUIPMENT, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(equipmentData),
        });

        if (!response.ok) {
            throw new Error('Error creating computer equipment');
        }

        /* const data = await response.json(); */
        return response;
    } catch (error) {
        console.error('Error/createComputerEquipment:', error);
    }
}


export async function updateEquipmentHistory(equipmentUuid, employeeData) {
    try {
        console.log("UPDATING--updateEquipmentHistory: ", employeeData);
        console.log("equipmentUuid: ", equipmentUuid);
        console.log("URL: ", `${COMPUTER_EQUIPMENT}/${equipmentUuid}/history`);
        const response = await fetch(`${COMPUTER_EQUIPMENT}/${equipmentUuid}/history`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(employeeData),
        });

        if (!response.ok) {
            throw new Error('Error updating equipment history');
        }

        /* const data = await response.json(); */
        return response;
    } catch (error) {
        console.error('Error:', error);
    }
}

export async function getAllComputerEquipment(status) {
    try {
        console.log("status: ", status);
        // Agregar el parámetro 'is_active' a la URL
        const url = `${COMPUTER_EQUIPMENT}?status=${status}`;

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        // Verificar si la respuesta fue exitosa
        if (!response.ok) {
            return []; // Retornar un array vacío si la solicitud falla
            // throw new Error('Error fetching computer equipment list');
        }

        // Convertir la respuesta en JSON
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        return []; // Retornar un array vacío en caso de error
    }
    /* try {
        const response = await fetch(COMPUTER_EQUIPMENT, {
            method: 'GET',
        });

        if (!response.ok) {
            return [];
            //throw new Error('Error fetching computer equipment list');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
    } */
}

export async function getUnassignedComputerEquipment() {
    try {
      const response = await fetch(`${COMPUTER_EQUIPMENT}/unassigned`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      // Verificar si la respuesta es exitosa
      if (!response.ok) {
        throw new Error('Error fetching unassigned computer equipment');
      }
  
      // Convertir la respuesta en formato JSON
      const data = await response.json();
      return data; // Retorna la lista de equipos no asignados
  
    } catch (error) {
      console.error('Error:', error);
      return [];
    }
  }

export async function getComputerEquipmentByUuid(equipmentUuid) {
    try {
        const response = await fetch(`${COMPUTER_EQUIPMENT}/${equipmentUuid}`, {
            method: 'GET',
        });

        if (!response.ok) {
            throw new Error('Error fetching computer equipment');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
    }
}

export async function updateComputerEquipment(equipmentUuid, updatedData) {
    try {
        const response = await fetch(`${COMPUTER_EQUIPMENT}/${equipmentUuid}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedData),
        });

        if (!response.ok) {
            throw new Error('Error updating computer equipment');
        }

        /* const data = await response.json(); */
        return response;
    } catch (error) {
        console.error('Error:', error);
    }
}

export async function deleteComputerEquipment(equipmentUuid) {
    try {
        const response = await fetch(`${COMPUTER_EQUIPMENT}/${equipmentUuid}`, {
            method: 'DELETE',
        });

        if (!response.ok) {
            throw new Error('Error deleting computer equipment');
        }

        return 'Deleted successfully';
    } catch (error) {
        console.error('Error:', error);
    }
}


export async function deactivateComputerEquipment(equipmentUuid) {
    try {
        const response = await fetch(`${COMPUTER_EQUIPMENT}/${equipmentUuid}/deactivate`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        // Verifica si la respuesta fue exitosa
        if (!response.ok) {
            throw new Error(`Error deactivating equipment: ${response.status}`);
        }

        const data = await response.json();
        console.log('Equipment deactivated successfully:', data);
        return response;
    } catch (error) {
        console.error('Error:', error);
        return null;  // Manejar el error devolviendo null o mostrando un mensaje de error
    }
}