import { TYPE_OF_COMISSIONS } from '../../utils/endpoints.js'

// Create a new type of comission
export async function createComission(data) {
    /**
     * Creates a new type of comission.
     * 
     * Parameters:
     *  - comission (string): The name of the comission (required).
     *  - porcentage (number): The percentage of the comission (required).
     * 
     * Returns:
     *  - JSON object containing the created comission details on success.
     *  - Error message and HTTP status 400 if input is invalid.
     *  - Error message and HTTP status 500 if there is a server error.
     * 
     * Author: JDGT
     */
    try {
        console.log("data: ", data);
        console.log("TYPE_OF_COMISSIONS: ", TYPE_OF_COMISSIONS);
        const response = await fetch(TYPE_OF_COMISSIONS, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)            
        });
        console.log("Response status: ", response.status);
        console.log("Response ok: ", response.ok);
        if (response.ok) {
            const responseData = await response.json();
            console.log("Response data: ", responseData);
            //return responseData;
            return response;
        } else {
            console.error("Error creating comission: ", response.statusText);
            throw new Error(response.statusText);
        }
    } catch (error) {
        console.error("Error creating comission: ", error.message);
        return { error: error.message };
    }
}

// Read all types of comissions
export async function getComissions() {
    /**
     * Retrieves a list of all types of comissions.
     * 
     * Parameters:
     *  None
     * 
     * Returns:
     *  - JSON array of all comission objects.
     * 
     * Author: JDGT
     */
    try {
        const response = await fetch(TYPE_OF_COMISSIONS);
        if (response.ok) {
            //return response.json();
            return response.json();
        } else {
            throw new Error(response.statusText);
        }
    } catch (error) {
        console.error(error);
        return { error: error.message };
    }
}

// Read a single type of comission by ID
export async function getComission(id) {
    /**
     * Retrieves a single type of comission by its ID.
     * 
     * Parameters:
     *  - id (number): The ID of the comission to retrieve.
     * 
     * Returns:
     *  - JSON object of the comission if found.
     *  - Error message and HTTP status 404 if the comission is not found.
     * 
     * Author: JDGT
     */
    try {
        const response = await fetch(`${TYPE_OF_COMISSIONS}/${id}`);
        if (response.ok) {
            return response.json();
        } else {
            throw new Error(response.statusText);
        }
    } catch (error) {
        console.error(error);
        return { error: error.message };
    }
}

// Update a type of comission
export async function updateComission(id, data) {
    /**
     * Updates an existing type of comission.
     * 
     * Parameters:
     *  - id (number): The ID of the comission to update.
     *  - comission (string): The new name of the comission (required).
     *  - porcentage (number): The new percentage of the comission (required).
     * 
     * Returns:
     *  - JSON object of the updated comission.
     *  - Error message and HTTP status 400 if input is invalid.
     *  - Error message and HTTP status 404 if the comission is not found.
     * 
     * Author: JDGT
     */
    try {
        const response = await fetch(`${TYPE_OF_COMISSIONS}/${id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });
        if (response.ok) {
            //return response.json();
            return response;
        } else {
            throw new Error(response.statusText);
        }
    } catch (error) {
        console.error(error);
        return { error: error.message };
    }
}

// Delete a type of comission
export async function deleteComission(id) {
    /**
     * Deletes a type of comission by its ID.
     * 
     * Parameters:
     *  - id (number): The ID of the comission to delete.
     * 
     * Returns:
     *  - Message confirming the deletion.
     *  - Error message and HTTP status 404 if the comission is not found.
     * 
     * Author: JDGT
     */
    try {
        const response = await fetch(`${TYPE_OF_COMISSIONS}/${id}`, { method: 'DELETE' });
        if (response.ok) {
            /* return response.json(); */
            return response;
        } else {
            throw new Error(response.statusText);
        }
    } catch (error) {
        console.error(error);
        return { error: error.message };
    }
}