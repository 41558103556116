import { PORCENTAGES } from '../../utils/endpoints'

// Create a new Porcentage
export async function createPorcentage(location, totalDll) {
    try {
        const response = await fetch(PORCENTAGES, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ location, total_dll: totalDll })
        });
        const data = await response.json();
        if (response.ok) {
            console.log('Porcentage created:', data);
        } else {
            console.error('Error creating porcentage:', data);
        }
        return response;
    } catch (error) {
        console.error('Error:', error);
    }
}

// Get all Porcentages
export async function getPorcentages() {
    try {
        const response = await fetch(PORCENTAGES);
        const data = await response.json();
        if (response.ok) {
            console.log('All porcentages:', data);
        } else {
            console.error('Error fetching porcentages:', data);
        }
        return data;
    } catch (error) {
        console.error('Error:', error);
    }
}

// Get a single Porcentage by ID
export async function getPorcentage(id) {
    try {
        const response = await fetch(`${PORCENTAGES}/${id}`);
        const data = await response.json();
        if (response.ok) {
            console.log(`Porcentage ${id}:`, data);
        } else {
            console.error(`Error fetching porcentage ${id}:`, data);
        }
        return data;
    } catch (error) {
        console.error('Error:', error);
    }
}

// Update a Porcentage by ID
export async function updatePorcentage(id, item) {
    try {
        const response = await fetch(`${PORCENTAGES}/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ item })
        });
        const data = await response.json();
        if (response.ok) {
            console.log(`Porcentage ${id} updated:`, data);
        } else {
            console.error(`Error updating porcentage ${id}:`, data);
        }
        return response;
    } catch (error) {
        console.error('Error:', error);
    }
}

// Delete a Porcentage by ID
export async function deletePorcentage(id) {
    try {
        const response = await fetch(`${PORCENTAGES}/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (response.ok) {
            console.log(`Porcentage ${id} deleted`);
            return response;
        } else {
            const data = await response.json();
            console.error(`Error deleting porcentage ${id}:`, data);
        }
    } catch (error) {
        console.error('Error:', error);
    }
}
