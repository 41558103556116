<template>
    <v-col cols="12" sm="12" md="12">
        <v-card>
            <v-card-title>
                <span class="headline">Zoho Vendors (Suppliers)</span>
            </v-card-title>
            <v-col>
                <v-card-text>
                    <v-divider class="mt-4 mb-6"></v-divider>
                    <v-row>
                        <v-col>
                            <v-row>
                                <v-col cols="12">
                                    <div class="d-flex align-center">
                                        <v-file-input label="Vendors file" v-model="vendors_file"
                                            accept=".xlsx,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel">
                                        </v-file-input>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-btn color="blue darken-1" text @click="bulk_data">
                        Import
                    </v-btn>
                    <v-divider class="mt-4 mb-6"></v-divider>
                    <!-- <v-form ref='form'>
                        <v-text-field v-model="editedItem.account_type" label="Account Type"></v-text-field>

                        <v-text-field v-model="editedItem.account_name" :rules="[rules.required]" label="Account Name"
                            required></v-text-field>

                        <v-text-field v-model="editedItem.account_code" :rules="[rules.required]" label="Account Code"
                            required></v-text-field>

                        <v-textarea v-model="editedItem.description" label="Description"></v-textarea>

                        <v-text-field v-model="editedItem.mileage_rate" label="Mileage Rate"
                            type="number"></v-text-field>

                        <v-text-field v-model="editedItem.mileage_unit" label="Mileage Unit"></v-text-field>

                        <v-checkbox v-model="editedItem.is_mileage" label="Is Mileage"></v-checkbox>

                        <v-text-field v-model="editedItem.account_number" :rules="[rules.required]"
                            label="Account Number" required></v-text-field>

                        <v-text-field v-model="editedItem.currency" label="Currency"></v-text-field>

                        <v-text-field v-model="editedItem.parent_account" label="Parent Account"></v-text-field>
                    </v-form> -->
                </v-card-text>
            </v-col>
            <!--  <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="submitForm">
                    SAVE
                </v-btn>
            </v-card-actions> -->
        </v-card>
    </v-col>
</template>

<script>
import * as XLSX from 'xlsx';
import { bus } from '../../../main.js';
import { GET_CHART_OF_ACCOUNT, POST_CHART_OF_ACCOUNT_BULK } from '../../../utils/endpoints.js';
import { createChartOfAccount, updateChartOfAccount, createChartOfAccountsInBulk } from '../../../utils/chart_of_accounts_CRUD.js'
import { bulkCreateVendors } from '../../../utils/Accounting/ZOHO/vendors_CRUD.js'

export default {
    name: 'VendorsCard',
    props: {
        Index: Number,
        Item: Object
    },
    data: () => ({
        vendors_file: null,
        /* editedItem: {
            id: '',
            account_code: '',
            account_name: '',
            description: '',
            account_type: '',
            mileage_rate: 0.0,
            mileage_unit: '',
            is_mileage: false,
            account_number: '',
            currency: '',
            parent_account: '',
        },
        rules: {
            required: value => !!value || 'Required.',
        },
        defaultEditedItem: {
            id: '',
            account_code: '',
            account_name: '',
            description: '',
            account_type: '',
            mileage_rate: 0.0,
            mileage_unit: '',
            is_mileage: false,
            account_number: '',
            currency: '',
            parent_account: '',
        },
        editedIndex: -1, */
    }),
    async mounted() {
        console.log("-------------------MOUNTED.......................")
        console.log("this.Index: ", this.Index);
        /* this.$nextTick(() => {
            this.$refs.form.resetValidation();
        }); */
        if (this.Index != -1) {
            this.editedItem = this.Item;
            this.editedIndex = this.Index
            console.log("this.editedIndex: ", this.editedIndex);
            console.log("this.editedItem: ", this.editedItem);
        }
    },
    methods: {
        /* async submitForm() {
            if (this.chart_of_account_file === null) {
                if (this.$refs.form.validate()) {
                    console.log('Form submitted:', this.editedItem);
                    let response = null;
                    // ADD new record
                    if (this.editedIndex === -1) {
                        response = await createChartOfAccount(this.editedItem);
                        console.log("response: ", response);
                    } else {
                        // Updating record
                        response = await updateChartOfAccount(this.editedIndex, this.editedItem);
                        console.log("Updating - response: ", response);
                    }
                    if (response.ok) {
                        this.$emit('sent-info', false);
                    }
                } else {
                    console.log('Form validation failed.');
                }
            }

        }, */
        async bulk_data() {
            try {
                const data = await this.handleFile();
                console.log("data: ", data);
                const response = await bulkCreateVendors(data);
                if (response.ok) {
                    this.vendors_file = null;
                    this.$emit('sent-info', false);
                }
            } catch (error) {
                console.log("error: ", error);
            }
        },
        handleFile() {
            const file = this.vendors_file;
            if (!file) {
                console.error('No file selected');
                return;
            }
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = function (event) {
                    try {
                        const data = new Uint8Array(event.target.result);
                        const workbook = XLSX.read(data, { type: 'array' });
                        const sheetName = workbook.SheetNames[0];
                        const worksheet = workbook.Sheets[sheetName];
                        const jsonData = XLSX.utils.sheet_to_json(worksheet);
                        const formattedData = jsonData.map(row => ({
                            created_time: row['Created Time'] || '',  // Cadena vacía por defecto
                            last_modified_time: row['Last Modified Time'] || '',  // Cadena vacía por defecto
                            contact_id: row['Contact ID'] || '',  // Cadena vacía por defecto
                            contact_name: row['Contact Name'] || '',  // Cadena vacía por defecto
                            company_name: row['Company Name'] || '',  // Cadena vacía por defecto
                            display_name: row['Display Name'] || '',  // Cadena vacía por defecto
                            salutation: row['Salutation'] || '',  // Cadena vacía por defecto
                            first_name: row['First Name'] || '',  // Cadena vacía por defecto
                            last_name: row['Last Name'] || '',  // Cadena vacía por defecto
                            email_id: row['EmailID'] || '',  // Cadena vacía por defecto
                            phone: row['Phone'] || '',  // Cadena vacía por defecto
                            mobile_phone: row['MobilePhone'] || '',  // Cadena vacía por defecto
                            payment_terms: row['Payment Terms'] || '',  // Cadena vacía por defecto
                            currency_code: row['Currency Code'] || '',  // Cadena vacía por defecto
                            notes: row['Notes'] || '',  // Cadena vacía por defecto
                            website: row['Website'] || '',  // Cadena vacía por defecto
                            status: row['Status'] || '',  // Cadena vacía por defecto
                            opening_balance: parseFloat(row['Opening Balance']) || 0.0,  // Número flotante por defecto
                            payment_terms_label: row['Payment Terms Label'] || '',  // Cadena vacía por defecto
                            skype_identity: row['Skype Identity'] || '',  // Cadena vacía por defecto
                            department: row['Department'] || '',  // Cadena vacía por defecto
                            designation: row['Designation'] || '',  // Cadena vacía por defecto
                            facebook: row['Facebook'] || '',  // Cadena vacía por defecto
                            twitter: row['Twitter'] || '',  // Cadena vacía por defecto
                            track_1099_payments: row['Track 1099 Payments']?.toString().toLowerCase() === 'true',  // Booleano
                            tin_type: row['TINType'] || '',  // Cadena vacía por defecto
                            tin: row['TIN'] || '',  // Cadena vacía por defecto
                            price_list: row['Price List'] || '',  // Cadena vacía por defecto
                            contact_address_id: row['Contact Address ID'] || '',  // Cadena vacía por defecto
                            billing_attention: row['Billing Attention'] || '',  // Cadena vacía por defecto
                            billing_address: row['Billing Address'] || '',  // Cadena vacía por defecto
                            billing_street2: row['Billing Street2'] || '',  // Cadena vacía por defecto
                            billing_city: row['Billing City'] || '',  // Cadena vacía por defecto
                            billing_state: row['Billing State'] || '',  // Cadena vacía por defecto
                            billing_country: row['Billing Country'] || '',  // Cadena vacía por defecto
                            billing_code: row['Billing Code'] || '',  // Cadena vacía por defecto
                            billing_phone: row['Billing Phone'] || '',  // Cadena vacía por defecto
                            billing_fax: row['Billing Fax'] || '',  // Cadena vacía por defecto
                            shipping_attention: row['Shipping Attention'] || '',  // Cadena vacía por defecto
                            shipping_address: row['Shipping Address'] || '',  // Cadena vacía por defecto
                            shipping_street2: row['Shipping Street2'] || '',  // Cadena vacía por defecto
                            shipping_city: row['Shipping City'] || '',  // Cadena vacía por defecto
                            shipping_state: row['Shipping State'] || '',  // Cadena vacía por defecto
                            shipping_country: row['Shipping Country'] || '',  // Cadena vacía por defecto
                            shipping_code: row['Shipping Code'] || '',  // Cadena vacía por defecto
                            shipping_phone: row['Shipping Phone'] || '',  // Cadena vacía por defecto
                            shipping_fax: row['Shipping Fax'] || '',  // Cadena vacía por defecto
                            source: row['Source'] || '',  // Cadena vacía por defecto
                            last_sync_time: row['Last Sync Time'] || '',  // Cadena vacía por defecto
                            location: row['Location'] || '',  // Cadena vacía por defecto
                            programs_revenue: parseFloat(row['Programs Revenue']) || 0.0,  // Número flotante
                            departments: row['Departments'] || '',  // Cadena vacía por defecto
                            responsable: row['Responsable'] || '',  // Cadena vacía por defecto
                            program_btw: row['Program BTW'] || '',  // Cadena vacía por defecto
                            program_ncl: row['Program NCL'] || '',  // Cadena vacía por defecto
                            instructors: row['Instructors'] || '',  // Cadena vacía por defecto
                            vehicles: row['Vehicles'] || '',  // Cadena vacía por defecto
                            type_refund: row['Type Refund'] || '',  // Cadena vacía por defecto
                            owners: row['Owners'] || '',  // Cadena vacía por defecto
                            exchange_rate: parseFloat(row['Exchange Rate']) || 0.0,  // Número flotante
                            owner_name: row['Owner Name'] || '',  // Cadena vacía por defecto
                            primary_contact_id: row['Primary Contact ID'] || '',  // Cadena vacía por defecto
                            cf_student_email: row['CF.Student\'s Email'] || '',  // Cadena vacía por defecto
                        }));
                        console.log("formattedData: ", formattedData);
                        resolve(formattedData);
                    } catch (error) {
                        reject(error);
                    }
                };

                reader.onerror = function (error) {
                    reject(error);
                };
                reader.readAsArrayBuffer(file);
            });
        }
    },
    computed: {
        filteredCharts() {
            if (!this.search.trim()) {
                return this.charts.data;
            } else {
                return this.charts.data.filter(chart =>
                    chart.account_name.toLowerCase().includes(this.search.toLowerCase())
                );
            }
        }
    },
}
</script>