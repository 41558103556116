import { BANKS } from '../utils/endpoints.js'

/**
 * Handles the response from fetch requests.
 * 
 * @param {Response} response - The response object from the fetch request.
 * @returns {Promise<Object>} - The JSON response if the request is successful.
 * @throws Will throw an error if the response is not OK.
 */
export async function handleResponse(response) {
    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Unknown error');
    }
    return response;
}

/**
 * Creates a new bank.
 * 
 * @param {Object} bankData - Object containing the bank data.
 * @returns {Promise<Object>} - Data of the created bank.
 * @throws Will throw an error if the fetch request fails.
 */
export async function createBank(bankData) {
    try {
        const response = await fetch(BANKS, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(bankData)
        });

        const result = await handleResponse(response);
        console.log('Bank created successfully:', result);
        return result;
    } catch (error) {
        console.error('Error creating bank:', error);
        throw error;
    }
}

/**
 * Retrieves all banks.
 * 
 * @returns {Promise<Array>} - List of all banks.
 * @throws Will throw an error if the fetch request fails.
 */
export async function getAllBanks() {
    try {
        const response = await fetch(BANKS, {
            method: 'GET',
        });

        const result = await handleResponse(response);
        console.log('Banks retrieved successfully:', result);
        return result.json();
    } catch (error) {
        console.error('Error retrieving banks:', error);
        throw error;
    }
}

/**
 * Retrieves a bank by ID.
 * 
 * @param {number} id - ID of the bank.
 * @returns {Promise<Object>} - Data of the bank.
 * @throws Will throw an error if the fetch request fails.
 */
export async function getBankById(id) {
    try {
        const response = await fetch(`${BANKS}/${id}`, {
            method: 'GET',
        });

        const result = await handleResponse(response);
        console.log('Bank retrieved successfully:', result);
        return result;
    } catch (error) {
        console.error('Error retrieving bank:', error);
        throw error;
    }
}

/**
 * Updates a bank by ID.
 * 
 * @param {number} id - ID of the bank to update.
 * @param {Object} bankData - Updated data of the bank.
 * @returns {Promise<Object>} - Updated data of the bank.
 * @throws Will throw an error if the fetch request fails.
 */
export async function updateBank(id, bankData) {
    try {
        const response = await fetch(`${BANKS}/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(bankData)
        });

        const result = await handleResponse(response);
        console.log('Bank updated successfully:', result);
        return result;
    } catch (error) {
        console.error('Error updating bank:', error);
        throw error;
    }
}

/**
 * Deletes a bank by ID.
 * 
 * @param {number} id - ID of the bank to delete.
 * @returns {Promise<Object>} - Response of the request.
 * @throws Will throw an error if the fetch request fails.
 */
export async function deleteBank(id) {
    try {
        const response = await fetch(`${BANKS}/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        const result = await handleResponse(response);
        console.log('Bank deleted successfully:', result);
        return result;
    } catch (error) {
        console.error('Error deleting bank:', error);
        throw error;
    }
}
