<template>
    <expense-type />
</template>

<script>
import ExpenseType from '../../components/Accounting/ExpenseType.vue'

export default {
    name: 'ExpenseTypeView',
    components: {
        ExpenseType,
    },
}
</script>