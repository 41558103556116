import { EXPENSE_TYPE } from '../utils/endpoints';

/**
 * Creates a new expense type.
 *
 * @param {object} data - { expense_type: expenseType, bill: bill } - The type of the expense. 
 * @param {string} expenseType - The type of the expense.
 * @param {string} bill - The bill associated with the expense type.
 * @returns {response} The created expense type response.
 * @throws Will throw an error if the fetch request fails.
 * @author JDGT
 */
export async function createExpenseType(data) {
    const response = await fetch(EXPENSE_TYPE, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    //const result = await response.json();
    return response;
}

/**
 * Retrieves all expense types.
 * 
 * @returns {array} An array of all expense types.
 * @throws Will throw an error if the fetch request fails.
 * @author JDGT
 */
export async function getExpenseTypes() {
    console.log("Getting expense types");

    try {
        console.log("EXPENSE_TYPE: ", EXPENSE_TYPE);
        // Making the GET request using fetch
        const response = await fetch(EXPENSE_TYPE, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        // Checking if the response status is OK (200)
        if (!response.ok) {
            console.error(`Failed to fetch expense types. Status: ${response.status}, Status Text: ${response.statusText}`);
            throw new Error(`Unexpected response status: ${response.status}`);
        }

        const result = await response.json();

        // Validating that the result is an array
        if (!Array.isArray(result)) {
            console.error("Expected an array but got:", typeof result);
            throw new Error("Unexpected response format: expected an array");
        }

        //console.log("Expense types fetched successfully:", result);
        return result;
    } catch (error) {
        // Handling errors and logging them
        console.error("Error fetching expense types:", error);
        throw error;
    }
}

/**
 * Retrieves an expense type by ID.
 * 
 * @param {number} id - The ID of the expense type.
 * @returns {object} The expense type with the specified ID.
 * @throws Will throw an error if the fetch request fails or if the ID does not exist.
 * @author JDGT
 */
export async function getExpenseType(id) {
    const response = await fetch(`EXPENSE_TYPE/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });
    const result = await response.json();
    return result;
}

/**
 * Updates an existing expense type.
 * 
 * @param {object} data - { expense_type: expenseType, bill: bill } - The type of the expense. 
 * @param {number} id - The ID of the expense type to update.
 * @param {string} expenseType - The new type of the expense.
 * @param {string} bill - The new bill associated with the expense type.
 * @returns {response} The updated expense type response.
 * @throws Will throw an error if the fetch request fails or if the ID does not exist.
 * @author JDGT
 */
export async function updateExpenseType(data, id) {
    const response = await fetch(`${EXPENSE_TYPE}/${id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    //const result = await response.json();
    return response;
}

/**
 * Deletes an expense type by ID.
 * 
 * @param {number} id - The ID of the expense type to delete.
 * @returns {response} A message indicating the deletion status response.
 * @throws Will throw an error if the fetch request fails or if the ID does not exist.
 * @author JDGT
 */
export async function deleteExpenseType(id) {
    const response = await fetch(`${EXPENSE_TYPE}/${id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        }
    });
    if (response.status === 204) {
        return response;
    } else {
        const result = await response.json();
        return result;
    }
}
