<template>
    <lead-system />
</template>

<script>
import LeadSystem from '../../components/Marketing/LeadSystem.vue'

export default {
    name: 'LeadSystemView',
    components: {
        LeadSystem,
    },
}
</script>