<template>
    <div>
        <v-row class="pt-4 px-4">
            <v-col cols="12" sm="12" md="12">
                <v-card height="520">
                    <v-card-text>
                        <v-data-table :headers="headers" :items="filteredItems" sort-by="last_4_digits"
                            class="elevation-1">
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-toolbar-title class="font-weight-bold">Payment Cards Manager</v-toolbar-title>
                                    <v-divider class="mx-4" inset vertical></v-divider>
                                    <v-text-field v-model="search" label="Search" solo-inverted
                                        prepend-icon="mdi-magnify" class="mr-3 mt-5"></v-text-field>
                                    <v-btn color="blue" dark class="mb-2 mr-2" @click="fetchItems">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                    <v-btn color="success" dark class="mb-2 mr-2" @click="openDialog">
                                        <v-icon>mdi-plus-thick</v-icon>
                                    </v-btn>
                                </v-toolbar>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-icon small class="mr-2" :style="{ color: 'blue' }" @click="updateItem(item)">
                                    mdi-pencil
                                </v-icon>
                                <v-icon small :style="{ color: 'red' }" @click="deleteItem(item)">
                                    mdi-delete
                                </v-icon>
                            </template>
                            <template v-slot:item.link_date="{ item }">
                                {{ formatDate(item.link_date) }}
                            </template>
                            <template v-slot:item.due_date="{ item }">
                                {{ formatDate(item.due_date) }}
                            </template>
                            <!-- Template for custom rendering in Assigned To column -->
                            <template v-slot:item.assigned_user="{ item }">
                                <span v-if="item.assigned_user.type === 'vehicle'">{{ item.assigned_user.name.name
                                    }}</span>
                                <span v-else-if="item.assigned_user.type === 'employee'">
                                    {{ item.assigned_user.name.name }} {{ item.assigned_user.name.last_name }}
                                </span>
                                <span v-else-if="item.assigned_user.type === 'department'">{{
                                    item.assigned_user.name.name }}</span>
                                <span v-else>No Assigned</span>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-dialog v-model="dialogOpen" max-width="700">
                <v-card class="dialog-card" outlined>
                    <CreditCardManagementCard v-if="dialogOpen" :Index="editedIndex" :Item="editedItem"
                        :key="dialogOpen.toString()" @sent-info="handleInfoFromChild" />
                </v-card>
            </v-dialog>
        </v-row>
        <div class="loader-container" v-if="showLoader">
            <div class="loader"></div>
        </div>
    </div>
</template>

<script>
import { bus } from '../../main.js';
import { getAllCreditCardManagements, deleteCreditCardManagement } from '../../utils/credit_card_managements_CRUD.js'
import CreditCardManagementCard from '../../components/Accounting/CreditCardManagementCard.vue'
import { format } from 'date-fns';
import _ from 'lodash';

export default {
    components: {
        CreditCardManagementCard
    },
    data: () => ({
        showLoader: false,
        headers: [
            { text: 'Actions', value: 'actions', sortable: false },
            { text: 'Last 4 Digits', value: 'last_4_digits' },
            { text: 'Assigned To', value: 'assigned_user' },
            /* { text: 'Email', value: 'email' }, */
            { text: 'Card Type', value: 'type' },
            { text: 'Account ID', value: 'ledger_account.account_code' },
            { text: 'Ledger Account', value: 'ledger_account.account_name' },
            { text: 'Company', value: 'company' },
            { text: 'Link Date', value: 'link_date' },
            { text: 'Due Date', value: 'due_date' },
        ],
        dialogOpen: false,
        search: '',
        items: [],
        editedIndex: -1,
        //editedItemDialog:{},
        editedItem: {
            assigned_user: [],
            email: '',
            credit_type: '',
            last_4_digits: '',
            company: '',
            link_date: '',
            due_date: '',
            ledger_account: ''
        },
        defaultEditedItem: {
            assigned_user: [],
            email: '',
            credit_type: '',
            last_4_digits: '',
            company: '',
            link_date: '',
            due_date: '',
            ledger_account: ''
        }
    }),
    computed: {
        filteredItems() {
            if (!this.search.trim()) {
                return this.items;
            } else {
                return this.items.filter(item =>
                    item.last_4_digits.toLowerCase().includes(this.search.toLowerCase()) ||
                    (item.assigned_user && item.assigned_user.name && item.assigned_user.name.name.toLowerCase().includes(this.search.toLowerCase()))
                );
            }
        }
    },
    async mounted() {
        //console.log("-------------------MOUNTED.......................")
        bus.$emit('login-success');
        bus.$emit('permissions');
        await this.fetchItems();

    },
    methods: {
        /* getLastAssignedUserName(users) {
            if(users[users.length - 1].type === 'employee'){
                //return `${users[users.length - 1].name.name} ${users[users.length - 1].name.last_name}`
                return users.length ? `${users[users.length - 1].name.name} ${users[users.length - 1].name.last_name}` : 'No User Assigned';
            }else{
                return users.length ? users[users.length - 1].name.name : 'No User Assigned';
            }
        }, */
        formatDate(dateString) {
            if (!dateString) return '';

            return format(new Date(dateString), 'yyyy-MM-dd');
        },
        handleInfoFromChild(info) {
            // Manejar la información recibida desde el hijo
            console.log('Información recibida desde el hijo:', info);
            //this.dialogOpen = info; //Close dialog
            this.fetchItems(); //Refresh employees table
        },
        updateItem(item) {
            console.log("item: ", item)
            this.editedIndex = item.id;
            //this.editedItem = item;
            //this.editedItem = Object.assign({}, item);
            this.editedItem = _.cloneDeep(item);
            this.dialogOpen = true;
        },
        async deleteItem(item) {
            console.log("item: ", item)
            const response = await deleteCreditCardManagement(item.id);
            if (response.ok) {
                this.fetchItems();
            }
        },
        openDialog() {
            console.log("Opening dialog.....")
            //this.editedItemDialog = Object.assign({}, this.editedItem);
            this.dialogOpen = true;
        },
        async fetchItems() {
            try {
                this.showLoader = true;
                this.items = await getAllCreditCardManagements();
                console.log("this.items: ", this.items);
                this.showLoader = false;
            } catch (error) {
                this.showLoader = false;
                console.error("Error fetching Banks: ", error);
            }
        }
    },
    watch: {
        dialogOpen(val) {
            console.log("val: ", val);
            if (!val) {
                this.editedItem = this.defaultEditedItem;
                this.editedIndex = -1;
                //this.fetchItems();
            }
        }
    }
}
</script>

<style scoped>
.dialog-card {
    padding: 0;
    /* Elimina el relleno interno */
    margin: 0;
    /* Elimina los márgenes */
}

/* LOADER */
.loader-container {
    position: fixed;
    /* Para que el loader esté por encima de todo el contenido */
    top: 0;
    left: 0;
    width: 100%;
    /* Ocupa todo el ancho del viewport */
    height: 100%;
    /* Ocupa todo el alto del viewport */
    display: flex;
    justify-content: center;
    /* Centra horizontalmente */
    align-items: center;
    /* Centra verticalmente */
    background-color: rgba(255, 255, 255, 0.5);
    /* Fondo semitransparente */
    z-index: 9999;
    /* Coloca el loader por encima de todo el contenido */
}

.loader {
    width: 120px;
    height: 60px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    background: #fff;
    filter: blur(5px) contrast(10) hue-rotate(60deg);
    mix-blend-mode: darken;
}

.loader:before,
.loader:after {
    content: "";
    width: 40px;
    border-radius: 50%;
    background: #ff00ff;
    animation: l3 1s infinite alternate;
}

.loader:after {
    --s: -1;
}
</style>