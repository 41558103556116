export function get_loginCount() {
    const token = localStorage.getItem('token');

    if (token) {
        try {
            const tokenParts = token.split('.');
            const payload = JSON.parse(atob(tokenParts[1]));
            const login_count = payload.login_count;
            return login_count;
        } catch (error) {
            return { login_count: null };
        }
    } else {
        return { login_count: null };
    }
}
