<template>
    <v-card>
        <v-card-text>
            <div style="font-size: 24px; color: fuchsia;" class="mb-4">{{ InsuranceInfo.company }}</div>
            <div style="font-size: 24px;" class="mb-4">{{ formatDate(InsuranceInfo.effective_date) }} - {{
                formatDate(InsuranceInfo.termination_date) }}</div>
            <v-data-table :headers="headersTypeOfCoverage" :items="typeOfCoverageItem" class="elevation-1">
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title class="headline orange-text">Type of Coverage</v-toolbar-title>
                    </v-toolbar>
                </template>
                <template v-slot:item.price="{ item }">
                    {{ formatPrice(item.price) }}
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { format } from 'date-fns';

export default {
    name: 'DialogTypeOfCoverage',
    props: {
        ItemTypeOfCoverage: Array,
        InsuranceInfo: Object,
    },
    data: () => ({
        // Type of Coverage
        //dialogTypeOfCoverage: false,
        typeOfCoverageItem: [],
        headersTypeOfCoverage: [
            { text: 'Type', value: 'type' },
            { text: 'Description', value: 'description', cellClass: 'text-wrap' },
            { text: 'Price', value: 'price' },
        ],
    }),
    mounted() {
        console.log("<<<<<<<<<<< MOUNTED/ITEM: ", this.Item);
        console.log("<<<<<<<<<<< MOUNTED/InsuranceInfo: ", this.InsuranceInfo);
        this.typeOfCoverageItem = this.ItemTypeOfCoverage;
    },
    methods: {
        formatDate(date) {
            if (date != null) {
                return format(new Date(date), 'yyyy-MM-dd');
            } else {
                return '';
            }
        },
        formatPrice(value) {
            const number = parseFloat(value);
            if (isNaN(number)) {
                return '$0.00'; // Retorna un valor por defecto si no es un número
            }
            return `$${number.toFixed(2)}`;
        }
    }
}

</script>

<style scoped>
.orange-text {
    color: orange;
}
</style>